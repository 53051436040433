<template>
    <NavbarVue></NavbarVue>
    <div class="w-full text-center pt-2">
        <span class="text-2xl text-center w-full">
            Hubungi Admin Untuk Reset Password
        </span>
    </div>
    <div class="flex justify-center">
        <router-link to="/login" class="rounded p-2 border button text-white bg-ltDarkGreen hover:bg-ltLightGreen">Kembali</router-link>
    </div>
</template>

<script>
import NavbarVue from '@/components/Navbar.vue';

    export default {
    components: { NavbarVue }
}
</script>