<template>
    <Dialog header="Pilih Role" v-model:visible="displayModal" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" :modal="true" >
        <Dropdown class="w-full" v-model="form.data.role" :options="roles" optionLabel="nama" optionValue="role" placeholder="Pilih role"/>
        <template #footer> 
            <button icon="pi" @click="closeModal" class="border rounded p-2 mx-2">
                Cancel
            </button>
            <button class="border rounded bg-ltDarkGreen text-white py-2 px-4 mx-2 hover:bg-ltLightGreen" label="Login" icon="pi" @click="login()" >
                Login
            </button>
        </template>
    </Dialog>
    <ConfirmDialog :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '50vw'}"></ConfirmDialog>
    <Toast/>
    <div class="flex w-full h-full  bg-yellow-200">
        <div class="flex m-5 justify-center items-center bg-ltLightGreen rounded-3xl w-full h-full flex-col sm:flex-row">
            <div class="flex basis-1/3 px-2 justify-center items-center sm:basis-1/2">
                <img src="@/assets/logo.png" class="p-10">
            </div>
            <div class="flex justify-center flex-wrap px-3 text-center bg-ltDarkGreen py-8 mb-10 basis-1/2 m-3 rounded-3xl sm:basis-1/2">
                <span class="text-xl sm:text-2xl sm:pr-2 text-white ">Selamat Datang di </span>
                <span class="text-2xl text-white mb-2 sm:mb-0"> Lan Tabur Management System</span>
                <!-- <ul class=" pt-5">
                    <li class="text-red-500 text-base sm:text-xl" v-for="data in error.errorList" :key="data">
                        <p v-if="error.errorList.length == 1"> {{ data }}</p>
                        <p v-else> {{ data.msg }}</p>
                    </li>
                </ul> -->
                <form @submit.prevent="getRole" class="flex flex-wrap justify-center items-baseline sm:p-5">
                    <div class="p-inputgroup">
                        <InputText placeholder="Username" v-model="form.data.username" required/>
                        <span class="p-inputgroup-addon">@lantabur.sch.id</span>
                    </div>
                    <!-- <label class="w-full py-2 text-white text-left" for="" >Username</label>
                        <div class="grid grid-cols-6 divide-x w-full rounded shadow px-1 bg-white justify-between items-center flex-wrap ">
                            <input class="col-span-3 sm:col-span-4 border-none" type="text" v-model="form.data.username">
                            <h1 class="col-span-3 sm:col-span-2">@lantabur.sch.id</h1>
                        </div> -->
                        <!-- <small v-if="error" class="p-error">{{ error[0] }}</small> -->
                    <!-- <div class="grid mt-2">
                        <Password panelClass="w-full m-0 p-0" inputClass="" placeholder="Password" v-model="form.data.password" :feedback="false" toggleMask required></Password>
                    </div> -->
                    <div class="inline px-1 bg-white w-full rounded shadow flex-wrap justify-between mt-3">
                        <input class=" w-5/6 border-none my-1" placeholder="Password" :type="isShowed ? 'text' : 'password'" v-model="form.data.password">
                        <button class="w-1/6" @click="isShowed = !isShowed" type="button">
                            <font-awesome-icon icon="fa-regular fa-eye"  v-if="isShowed === true"/>
                            <font-awesome-icon icon="fa-regular fa-eye-slash" v-if="isShowed === false"/>
                        </button>
                    </div>
                    <button class="rounded border w-full my-3 p-2 bg-ltLightGreen" type="submit" >Login</button>
                    <span class="text-white">
                        Lupa Password?
                        <router-link to="/reset">Reset</router-link>
                    </span>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
// import { Options, Vue } from 'vue-class-component';
import axios from '@/api/axios'
import { isArray } from '@vue/shared';
import { reactive, ref } from 'vue';
import { useStore } from 'vuex'
import router from '../router';
import Dialog from 'primevue/dialog'
import Dropdown from 'primevue/dropdown'
import ConfirmDialog from 'primevue/confirmdialog';
import { useConfirm } from 'primevue/useconfirm';
import InputText from 'primevue/inputtext'
// import Password from 'primevue/password'
import Toast from 'primevue/toast';
import { useToast } from 'primevue/usetoast';
// import Button from 'primevue/button';

export default {
    components : {
        Dialog,
        Dropdown,
        ConfirmDialog,
        InputText,
        // Password,
        Toast
        // Button
    },
    setup () {

        document.title = 'Login - Lan Tabur Management System'
        
        const store = useStore()
        const confirm = useConfirm()

        const confirmUpdatePassword = () => {
            confirm.require({
                message : `Pasword kamu masih default nih. Mau ganti sekarang?`,
                header: 'Peringatan Password Default',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => (
                    router.push('/user')
                ), 
                reject : () => {
                    router.push('/')
                    // return
                }
            })
        }
        const error = reactive({
            errorList: ''
        })
        let isShowed = ref(false) 

        const roles = ref(null)
        const displayModal = ref(false)

        const closeModal = () => {
            displayModal.value = false
        }

        const toast = useToast()
        async function getRole() {
            await axios.post('/v2/auth/get-role', form.data)
            .then (res => {
                // console.log(`Res :${res.data}`)
                roles.value = res.data.data
                displayModal.value = true
                // console.log(`Roles :`)
                // console.log(roles.value)
            })
            .catch(err => {
                console.log(err.response.data)

                if (isArray(err.response.data.error)) {
                    console.log('masuk sini')
                    const errorList = err.response.data.error
                    errorList.forEach(data => {
                        toast.add({
                            severity : 'error',
                            summary : "Login Gagal",
                            detail : data.msg,
                            life: 3000
                        })
                    })
                }
                else {
                    toast.add({
                        severity : 'error',
                        summary : "Login Gagal",
                        detail : err.response.data.message,
                        life: 3000
                    })
                    error.errorList = Array(err.response.data.error)
                }
            })
        }

        async function login () {
            // console.log(form)
            console.log()
            await axios.post('/v2/auth/login', form.data)
            .then(res => {
                console.log('Tokeh dapat')
                console.log(res.data.data)
                store.commit('setToken', res.data.data.token)
                store.commit('setLoggedIn', true)
                store.commit('setRole', res.data.data.role)
                store.commit('setUser', res.data.data.user)
                if(res.data.data.isPasswordDefault) {
                    confirmUpdatePassword()
                }
                else router.push('/')
            })
            .catch(err => {
                console.log(err.response.data)

                if (isArray(err.response.data.error)) {
                    console.log('masuk sini')
                    const errorList = err.response.data.error
                    errorList.forEach(data => {
                        toast.add({
                            severity : 'error',
                            summary : "Login Gagal",
                            detail : data.msg,
                            life: 3000
                        })
                    })
                }
                else {
                    toast.add({
                        severity : 'error',
                        summary : "Login Gagal",
                        detail : err.response.data.message,
                        life: 3000
                    })
                    error.errorList = Array(err.response.data.error)
                }
            })
        }

        const form = reactive({
            data : {
                username:'', 
                password:'',
                role:''
            }
        })

        return { 
            login, 
            getRole, 
            form, 
            roles,
            error, 
            displayModal,
            closeModal,
            isShowed 
        }
    }
}
</script>