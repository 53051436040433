import axios from '@/api/axios'
import moment from 'moment'
import { createStore } from 'vuex'

export default createStore({
  state: {
    isLoggedIn: sessionStorage.getItem('isLoggedIn') || false,
    token : sessionStorage.getItem('token') || '',
    isLoginPage: true,
    role : sessionStorage.getItem('role') ||'',
    user: JSON.parse(sessionStorage.getItem('user')) || '',

    // State 
    isWantToAddTarget: false,
    isWantToEditTarget: false,
    isWantToCheckStatusMurid: false,
    isWantToAddSession: false,
    isWantToAddMuridToHalaqoh: false,
    isLoading: false,
    
    // Datas
    inputTarget: {},
    editTarget: {},
    jamTahfidzDetails: {},
    muridList: null,

    // Universal Data
    guru: {
      halaqoh: null,
      sessionList: null,
      statusTarget: [],
      rentangTarget: null,
    },

    // Halaqoh 
    halaqoh: {
      data: {
        
      },
      editHalaqoh: {
        
      }
    }

  },
  getters: {
  },
  mutations: {
    async fetchTahfidzGuruDetail(state) {
      await axios.get('/v2/halaqoh/guru')
      .then(res => {
          console.log(res)
          state.guru.halaqoh = res.data.data.isGuru.pesantrenDetail.tahfidzDetail.halaqohList[0].muridTahfidzDetail
          state.guru.sessionList = res.data.data.isGuru.pesantrenDetail.tahfidzDetail.halaqohList[0].sesiHistory
      })
    },

    async fetchMuridList(state) { 
      await axios.get('v2/murid/nama')
      .then(res => {
          console.log(res.data)
          state.muridList = res.data.data
      })
    },

    async fetchJamTahfidzDetailsByHalaqoh(state, payload) {
      await axios.get(`/v2/jamTahfidz/single/${payload.halaqohId}/${payload.jamTahfidzId}`)
      .then(res => {
        state.jamTahfidDetails = res.data.data
      })
    },

    async fetchStatusTarget(state, payload) {
      const date = []
      date[0] = moment(payload.date[0]).set({hour: 0, minute: 0, second: 0}).toDate()
      date[1] = moment(payload.date[1]).set({ hour: 23, minute: 59, second: 59}).toDate()

      const param = {
        date: date,
        tipeSesi: payload.tipeSesi
      }
      console.log(param)
      await axios.get('/v2/halaqoh/status', {
        params: param
      })
      .then(async res => {
        state.guru.statusTarget = res.data.data
        state.guru.rentangTarget = res.data.days
      })
    },

    mutateInputTargetDialog(state, payload) {
      state.isWantToAddTarget = payload
    },
    setRole(state, payload) {
      sessionStorage.setItem('role', payload)
      state.role = payload
    },
    setUser(state, payload) {
      sessionStorage.setItem('user', JSON.stringify(payload))
      state.user = payload
    },

    setLoggedIn(state, payload) {
      if (payload === false) {
        sessionStorage.clear()
      }
      else {
        sessionStorage.setItem('isLoggedIn', payload)
      }
      state.isLoggedIn = payload
    },

    setToken(state, payload) {
      sessionStorage.setItem('token', payload)
      axios.defaults.headers.common['Authorization'] = payload
      state.token = payload
    },
    
    setIsLoginPage(state, payload) {
      state.isLoginPage = payload
    }
  },
  actions: {
  },
  modules: {
  }
})
