<template>
    <Navbar/>
    <div class="flex mt-3 mx-2 justify-around items-center">
        <span class="text-xl sm:text-2xl">{{ detailJamTahfidz.namaJam }} - {{ detailJamTahfidz.tipe }}</span>
        <div class="flex flex-col justify-end items-center">
            <span class="w-full text-xl">{{ moment(detailJamTahfidz.waktuMulai ).locale('id').format('DD MMMM YYYY')}}</span>
            <span class="w-full text-base">{{ moment(detailJamTahfidz.waktuMulai).locale('id').format('HH:MM') }} - {{ moment(detailJamTahfidz.waktuSelesai).locale('id').format('HH:MM') }}</span>
        </div>
    </div>
    <div class="card mt-3 mx-2 ">
        <h5>Jumlah Setoran Per Halaqoh</h5>
        <Chart type="line" :data="basicData" :options="basicOptions" />
    </div>
    <div class="card mt-3 mx-2">
        <DataTable :value="detailJamTahfidz.Session" responsiveLayout="scroll" :paginator="true" :rows="10">
            <template #header>
                Data Sesi
            </template>
            <template #empty>
                Tidak ada data Sesi.
            </template>
            <template #loading>
                Memuat data sesi, Mohon tunggu.
            </template>
            <Column sortable="true" header="Waktu Setoran" style="min-width:12.5rem">
                <template #body={data}>
                    {{ moment(data.waktu).locale('id').format('dddd, DD MMMM YYYY H:mm:ss') }}
                </template>
            </Column>
            <Column field="halaqoh.kode" header="Kode Halaqoh"/>
            <Column header="Guru">
                <template #body="{data}">
                    {{ data.guruTahfidzDetail.guruDetails.guru.kodeGuru }} - {{ data.guruTahfidzDetail.guruDetails.guru.nama }}
                </template>
            </Column>
            <Column header="Santri"> 
                <template #body="{data}">
                    {{ data.muridTahfidzDetail.muridDetails.murid.nama }} - {{ data.muridTahfidzDetail.muridDetails.murid.nis }}
                </template>
            </Column>
            <Column header="Awal Setoran" style="min-width:14rem">
                <template #body="{data}">
                    <div v-if="data.awalSurah != data.akhirSurah">
                        {{ parsingSetoran(data.awalSurah, data.awalAyat) }} - {{ parsingSetoran(data.akhirSurah, data.akhirAyat) }}
                    </div>
                    <div v-else>
                        {{ parsingSetoran(data.awalSurah, data.awalAyat) }} - {{ data.akhirAyat }}
                    </div>
                </template>
            </Column>
            <Column field="juz" header="Juz" />
            <Column field="halaman" header="Halaman"/>
            <Column header="Nilai">
                <template #body="{data}">
                    {{ (data.nilaiKelancaran + data.nilaiMakhroj + data.nilaiSifatHuruf + data.nilaiTajwid)/4 }}
                </template>
            </Column>
            
        </DataTable>
    </div>
</template>
<script>
import Navbar from '@/components/Navbar.vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Chart from 'primevue/chart'
import axios from 'axios';
import moment from 'moment';
import { ref, onBeforeMount } from 'vue';
import { useRoute } from 'vue-router';
    export default {
    components: { 
        DataTable,
        Column,
        Chart,
        Navbar 
    },
    setup () {
        onBeforeMount(async () => {
            await axios.get('/v2/surah')
            .then(res => {
                console.log(res)
                surahList.value = res.data.data
            })

            await axios.get(`/v2/jamTahfidz/id/${route.params.id}`)
            .then(res => {
                console.log(res.data)
                detailJamTahfidz.value = res.data.data
                sessionList.value = res.data.data.Session
            })
        })
        // Function 
        const route = useRoute()

        const parsingSetoran = (surah, ayat) => {
            return `${surahList.value[surah - 1].indo} : ${ayat}` 
        }

        // Variable
        const detailJamTahfidz = ref(null)
        const sessionList = ref(null)
        const surahList = ref(null)
        const basicData =  ref(
            {
                datasets: [
                    {
                        label: 'Setoran',
                        data: sessionList,
                        fill: false,
                        borderColor: '#42A5F5',
                        tension: .4
                    },
                ]
            }
        );
        const basicOptions = ref(
            {
                plugins: {
                    legend: {
                        labels: {
                            color: '#495057'
                        }
                    }
                },
                parsing:{
                    xAxisKey: 'waktu',
                    yAxisKey: 'halaman',
                },
                scales: {
                    x: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    },
                    y: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    }
                }
            }
        );



        return {
            detailJamTahfidz,
            sessionList,
            basicData,
            basicOptions,
            parsingSetoran,
            moment
        }
    }
}
</script>