<template>
    <Navbar />
    <TOast />
    <BlockUI :blocked="isLoading" :full-screen="true" />
    <div class="mt-2 p-2">
        <div class="flex justify-between px-2">
            <h1 class="text-2xl">Database Keluarga</h1>
            <button class="rounded border bg-ltDarkGreen px-2 py-1 text-white" @click="iWantAddKeluarga()"
                v-if="canAddFamily">Tambah Data Keluarga</button>
        </div>
        <div class="px-2 sm:px-4 mt-4">
            <div class="card shadow">
                <DataTable :value="familyList" :paginator="true" class="p-datatable-customers" showGridlines :rows="10"
                    dataKey="id" v-model:filters="familyFilter" filterDisplay="menu" :loading="loading"
                    responsiveLayout="scroll" :globalFilterFields="['nama', 'nis']">
                    <template #header>
                        <div class="flex justify-between">
                            <div class="hidden sm:block">
                                <Button type="button" icon="pi pi-filter-slash" label="Clear"
                                    class="p-button-outlined hidden sm:block" @click="clearFilter" />
                            </div>
                            <span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="familyFilter['global'].value" placeholder="Keyword Pencarian" />
                            </span>
                            <div class="hidden sm:block">
                                <Button icon="pi pi-refresh" @click="updateData" />
                            </div>
                        </div>
                    </template>
                    <template #empty>
                        Tidak ada data Keluarga.
                    </template>
                    <template #loading>
                        Memuat data Keluarga ...
                    </template>
                    <Column header="Nomor Induk Family">
                        <template #body="{ data }">
                            <router-link :to="`/family/${data.nif}`">{{ data.nif }}</router-link>
                        </template>
                    </Column>
                    <Column field="noKK" header="No Kartu Keluarga" />
                    <Column header="Nama Orang Tua">
                        <template #body="{ data }">
                            <span v-if="data.parentList[0]">{{ data.parentList[0].nama }}</span>
                            <span v-else>Kosong</span>
                        </template>
                    </Column>
                    <Column header="Jumlah Anak">
                        <template #body="{ data }">
                            {{ data.muridList.length }}
                        </template>
                    </Column>
                    <Column header="Jumlah Orang Tua">
                        <template #body="{ data }">
                            {{ data.parentList.length }}
                        </template>
                    </Column>

                </DataTable>
            </div>
        </div>
    </div>
    <Dialog :modal="true" v-model:visible="isWantAddKeluarga" header="Tambah Data Keluarga">
        <span class="p-float-label mt-6">
            <InputText type="number" v-model.trim="v$.noKK.$model" class="w-full" placeholder="167XXXXXXX"
                @blur="v$.noKK.$touch" />
            <label for="NoKK" class="text-base">Nomor Kartu Keluarga</label>
        </span>
        <div class="flex ml-2 mt-1" v-for="error of v$.noKK.$errors" :key="error.$uid">
            <small class="p-error">{{ error.$message }}</small>
        </div>

        <span class="p-float-label mt-6">
            <MultiSelect v-model="v$.muridList.$model" class="w-full" :filter="true" display="chip"
                :options="muridDropdownList" optionLabel="nama">
                <template #option="slotProps">
                    <div class="flex">
                        <img :src="slotProps.option.profileUrl" alt="" width="30" class="rounded-lg">
                        <div class="mx-3">
                            <p>
                                {{ slotProps.option.nama }}
                            </p>
                            <p class="text-xs">
                                NIS: {{ slotProps.option.nis }}
                            </p>
                        </div>
                    </div>
                </template>
            </MultiSelect>
            <label for="daftarAnak" class="text-base">Daftar Anak</label>
        </span>
        <div class="flex-ml-2 mt-1" v-for="error of v$.muridList.$errors" :key="error.$uid">
            <small class="p-error">{{ error.$message }}</small>
        </div>

        <span class="p-float-label mt-6">
            <MultiSelect v-model="v$.parentList.$model" class="w-full" :filter="true" display="chip"
                :options="parentDropdownList" optionLabel="nama">
                <template #option="slotProps">
                    <div class="flex">
                        <img :src="slotProps.option.profileUrl" alt="" width="30" class="rounded-lg">
                        <div class="mx-3">
                            <p>
                                {{ slotProps.option.nama }}
                            </p>
                            <p class="text-xs">
                                NIP: {{ slotProps.option.nip }}
                            </p>
                        </div>
                    </div>
                </template>
            </MultiSelect>
            <label for="parentList" class="text-base">Daftar Orang Tua</label>
        </span>
        <div class="flex ml-2 mt-1" v-for="error of v$.parentList.$errors" :key="error.$uid">
            <small class="p-error">{{ error.$message }}</small>
        </div>

        <span class="p-float-label mt-6">
            <InputText v-model="v$.tahunMasuk.$model" placeholder="Sejak?" class="w-full"
                @blur="v$.tahunMasuk.$touch" />
            <label for="tahunMasuk">Bergabung di Lan Tabur Sejak?</label>
        </span>
        <div class="flex ml-2 mt-1" v-for="error of v$.tahunMasuk.$errors" :key="error.$uid">
            <small class="p-error">{{ error.$message }}</small>
        </div>

        <template #footer>
            <button icon="pi" @click="isWantInputParent = false" class="border rounded p-2 mx-2">
                Cancel
            </button>
            <button class="border rounded bg-ltDarkGreen text-white py-2 px-4 mx-2 hover:bg-ltLightGreen" icon="pi"
                @click="inputFamily()">
                Simpan
            </button>
        </template>
    </Dialog>
</template>

<script setup>
import Navbar from '@/components/Navbar.vue';
import DataTable from 'primevue/datatable';
import { ref, reactive, onMounted } from 'vue';
import { FilterMatchMode, ToastSeverity } from 'primevue/api';
import axios from 'axios';
import { between, helpers, minLength, required, maxLength } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import moment from 'moment';
import { useStore } from 'vuex';
import { useToast } from 'primevue/usetoast';

const familyList = ref(null)
const store = useStore()
const toast = useToast()

const isWantAddKeluarga = ref(false)
const isLoading = ref(false)
const canAddFamily = ref(false)

const iWantAddKeluarga = async () => {
    isWantAddKeluarga.value = true
    // Get Dropdown Murid Yatim 
    await axios.get('/v2/murid/dropdown/family')
        .then(res => {
            muridDropdownList.value = res.data.data
        })
    // Get Dropdown Parent
    await axios.get('/v2/parent/dropdown')
        .then(res => {
            parentDropdownList.value = res.data.data
        })
}

onMounted(async () => {
    // Get Family List 
    await axios.get('/v2/family/')
        .then(res => {
            familyList.value = res.data.data
        })
    if (store.state.role === 'ADMIN' || store.state.role === 'TU_PESANTREN') {
        console.log(store.state.role)
        canAddFamily.value = true

    }
})

const initInputFamily = () => {
    formInputFamily.noKK = null
    formInputFamily.muridList = null
    formInputFamily.parentList = null
    formInputFamily.tahunMasuk = null
    formInputFamily.parents = null
    formInputFamily.murids = null
}


const inputFamily = async () => {

    const isFormCorret = await v$.value.$validate()

    if (!isFormCorret) {
        return
    }

    formInputFamily.parents = formInputFamily.parentList.map(({ nip }) => ({ nip }))
    formInputFamily.murids = formInputFamily.muridList.map(({ nis }) => ({ nis }))
    await axios.post('/v2/family', formInputFamily)
        .then(res => {
            toast.add({
                severity: ToastSeverity.SUCCESS,
                detail: 'Input Data Keluarga Berhasil',
                summary: res.data.message,
                life: 3000
            })
            isWantAddKeluarga.value = false
            isLoading.value = false
            initInputFamily()
            v$.value.$reset()
        })
}

const muridDropdownList = ref(null)
const parentDropdownList = ref(null)

const formInputFamily = reactive({})

const inputFamilyRules = {
    noKK: {
        required: helpers.withMessage('Nomor Kartu Keluarga tidak boleh kosong', required),
        minLength: helpers.withMessage('Nomor Kartu Keluarga harus berjumlah 16 digit angka', minLength(16)),
        maxLength: helpers.withMessage('Nomor Kartu Keluarga harus berjumlah 16 digit angka', maxLength(16)),
    },
    muridList: {
        // required: helpers.withMessage('List Anak yang terdata di dalam keluarga ini tidak boleh kosong', required)
    },
    parentList: {
        required: helpers.withMessage('Daftar Orang tua yang termasuk ke dalam keluarga ini tidak boleh kosong', required)
    },
    tahunMasuk: {
        required: helpers.withMessage('Tahun Bergabung ke Lan Tabur tidak boleh kosong', required),
        between: helpers.withMessage(`Tahun bergabung harus dari 2008 hingga ${moment(Date.now()).format('YYYY')}`, between(2008, moment(Date.now()).format('YYYY')))
    },

}

const v$ = useVuelidate(inputFamilyRules, formInputFamily)

const familyFilter = ref({
    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
    'kodeGuru': { value: null, matchMode: FilterMatchMode.CONTAINS },
    'nama': { value: null, matchMode: FilterMatchMode.CONTAINS },
    'gender': { value: null, matchMode: FilterMatchMode.CONTAINS },
    'email': { value: null, matchMode: FilterMatchMode.CONTAINS },
    'ttl': { value: null, matchMode: FilterMatchMode.CONTAINS }
})
</script>