<template>
    <NavbarVue/>
    <Toast/>
    <BlockUI :full-screen="true" :blocked="isLoading" />
    <div class="mt-2 p-2">
        <div class="flex justify-between px-2">
            <h1 class="text-2xl">Database Guru</h1>
            <button class="rounded border bg-ltDarkGreen px-2 text-white" @click="isWantInputGuru = true" v-if="canAddGuru">Create</button>
        </div>
        <div class="px-2 sm:px-4 mt-4">
            <div class="card shadow">
                <DataTable :value="guruList" :paginator="true" class="p-datatable-customers" showGridlines :rows="10"
                    dataKey="id" v-model:filters="guruFilter" filterDisplay="menu" :loading="loading1" responsiveLayout="scroll"
                    :globalFilterFields="['nama', 'kodeGuru']">
                    <template #header>
                        <div class="flex justify-between">
                            <div class="sm:block hidden">
                                <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined sm:block hidden" @click="clearFilter1()"/>
                            </div>
                            <span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="guruFilter['global'].value" placeholder="Cari Data Guru" />
                            </span>
                            <div class="sm:block hidden">
                                <Button icon="pi pi-refresh" @click="updateData"/>
                            </div>
                        </div>
                    </template>
                    <template #empty>
                        Tidak ada data Guru
                    </template>
                    <template #loading>
                        Memuat data Guru...
                    </template>
                    <Column field="kodeGuru" header="Kode Guru" style="min-width:2rem">
                        <template #body="{data}">
                            {{data.kodeGuru}}
                        </template>
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by name"/>
                        </template>
                    </Column>
                    <Column field="nama" header="Nama" style="min-width: 24rem;">
                        <template #body="{data}">
                            <router-link :to="`/guru/${data.nigs}`" class="flex items-center">
                                <img :src="data.profileUrl" alt="" width="40" :class="flag">
                                <div class="flex-row px-2">
                                    <p class="image-text text-base">{{data.nama}}</p>
                                    <p class="text-xs">NIG:{{data.nig}}</p>
                                    <p class="text-xs">NIGS:{{data.nigs}}</p>
                                </div>
                            </router-link>
                        </template>
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by name"></InputText>
                        </template>
                    </Column>
                    <Column field="gender" header="Jenis Kelamin" style="min-width: 5rem;">
                        <template #body="{data}">
                            {{data.jenisKelamin=='M' ? 'Laki-Laki' : 'Perempuan'}}
                        </template>
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value"></InputText>
                        </template>
                    </Column>
                    <Column field="email" header="Email" style="min-width: 12rem;">
                        <template #body="{data}">
                            {{data.email}}
                        </template>
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" ></InputText>
                        </template>
                    </Column>
                    <Column field="ttl" header="Tempat, Tanggal Lahir" style="min-width: 12rem;">
                        <template #body="{data}">
                            {{data.tempatLahir}}, {{new Date(data.tanggalLahir).getDate()}} {{monthName[new Date(data.tanggalLahir).getMonth()]}} {{new Date(data.tanggalLahir).getFullYear()}}
                        </template>
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" ></InputText>
                        </template>
                    </Column>
                    <Column header="Action" v-if="$store.state.role === 'ADMIN'">
                        <template #body="{data}">
                            <button v-if="data.deleted == true" class="px-2 rounded p-1 bordered bg-ltDarkGreen hover:bg-ltLightGreen text-white" @click="recoveryGuru(data.nigs)">Recovery</button>
                            <!-- <button v-else-if="$store.state.role === 'ADMIN'" class="px-2 rounded p-1 bordered bg-ltDarkGreen hover:bg-ltLightGreen text-white" @click="recoveryGuru(data.nigs)">Recovery</button> -->
                            <span v-else>No Action needed.</span>
                        </template>
                    </Column>
                </DataTable>
            </div>
        <!-- <InputGuruVue v-if="isWantInputGuru" @close="isWantInputGuru = false"/> -->
        </div>
    </div>
    <Dialog :modal="true" v-model:visible="isWantInputGuru" header="Informasi Dasar">
        <div class="flex-col justify-center">
            <div class="field">
                <span class="p-float-label mt-6">
                    <InputText id="nik" type="number" v-model="v$.nik.$model" class="w-full" placeholder="167XXXXXXXXXXXXX (16 Digit)"
                    @blur="v$.nik.$touch"/>
                    <label for="nik" class="text-base">NIK</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of v$.nik.$errors" :key="error.$uid">
                    <small v-if="(v$.nik.$error)"  class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <div class="field">
                <span class="p-float-label mt-6">
                    <InputText v-model.trim="v$.nama.$model" type="text" class="w-full" placeholder="Muhammad Agus"
                    @blur="v$.nama.$touch"/>
                    <label class="text-base">Nama Lengkap</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of v$.nama.$errors" :key="error.$uid">
                    <small v-if="(v$.nama.$error)"  class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <div class="field">
                <span class="p-float-label mt-6">
                    <InputText v-model.trim="v$.email.$model" type="email" class="w-full" placeholder="muhammad@gmail.com"
                    @blur="v$.email.$touch"/>
                    <label class="text-base">Email</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of v$.email.$errors" :key="error.$uid">
                    <small v-if="v$.email.$error" class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <div class="field">
                <span class="p-float-label mt-6">
                    <Dropdown v-model="v$.jenisKelamin.$model" class="w-full" :options="genderList" optionValue="value" optionLabel="name" placeholder="Jenis Kelamin"
                    @blur="v$.jenisKelamin.$touch"/>
                    <label class="text-base">Jenis Kelamin</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of v$.jenisKelamin.$errors" :key="error.$uid">
                    <small v-if="v$.jenisKelamin.$errors" class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <div class="field">
                <span class="p-float-label mt-6">
                    <InputText v-model="v$.tempatLahir.$model" type="text" class="w-full" placeholder="Pagar Alam"
                    @blur="v$.tempatLahir.$touch"></InputText>
                    <label class="text-base">Tempat Lahir</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of v$.tempatLahir.$errors" :key="error.$uid">
                    <small class="p-error" v-if="v$.tempatLahir.$errors">{{ error.$message }}</small>
                </div>
            </div>
            <div class="field">
                <span class="p-float-label mt-6">
                    <Calendar class="h-10 w-full text-sm" v-model="v$.tanggalLahir.$model" dateFormat="dd MM yy" placeholder="Masukkan tanggal lahir"
                    @blur="v$.tanggalLahir.$touch"/>
                    <label class="text-base">Tanggal Lahir</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of v$.tanggalLahir.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <div class="field">
                <span class="p-float-label mt-6">
                    <Dropdown v-model="v$.golonganDarah.$model" class="w-full" :options="golonganDarahList" optionValue="value" optionLabel="name" placeholder="Golongan Darah"
                    @blur="v$.golonganDarah.$touch"/>
                    <label class="text-base">Golongan Darah</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of v$.golonganDarah.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <div class="field">
                <div class="">
                    <span class="p-float-label mt-6">
                        <Dropdown v-model="v$.countryCode.$model" class="w-full"
                        :options="countryCodeList" optionValue="phone_code" optionLabel="country_name" placeholder="+62" op
                        filter="true"
                        @blur="v$.countryCode.$touch" >
                            <template #option="country">
                                <div class="flex justify-between">
                                    <span>{{ country.option.country_name }}</span>
                                    <span>+{{country.option.phone_code}}</span>
                                </div>
                            </template>
                        </Dropdown>
                        <label for="">Kode Negara</label>
                    </span>
                    <div class="flex ml-2 mt-1" v-for="error of v$.countryCode.$errors" :key="error.$uid">
                        <small class="p-error">{{error.$message}}</small>
                    </div>
                </div>
                <div class="">
                    <span class="p-float-label mt-6">
                        <InputText v-model="v$.nomorTelepon.$model" type="number" class="w-full" placeholder="08XXXXXXXXXX"
                        @blur="v$.nomorTelepon.$touch"/>
                        <label class="text-base">Nomor Telepon Aktif</label>
                    </span>
                    <div class="flex ml-2 mt-1" v-for="error of v$.nomorTelepon.$errors" :key="error.$uid">
                        <small class="p-error">{{ error.$message }}</small>
                    </div>
                </div>
            </div>
            <div class="field">
                <span class="p-float-label mt-6">
                    <Dropdown v-model="v$.statusPernikahan.$model" class="w-full" :options="statusPernikahanList" optionValue="value" optionLabel="name" placeholder="Sudah nikah belum?" @change="formInputGuru.statusPernikahan == 'Single' ? formInputGuru.jumlahAnak = 0 : ''"
                    @blur="v$.statusPernikahan.$touch"/>
                    <label class="text-base">Status Pernikahan</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of v$.statusPernikahan.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <div v-if="formInputGuru.statusPernikahan != 'Single'" class="field">
                <span class="p-float-label mt-6" >
                    <InputText v-model="v$.jumlahAnak.$model" type="number" class="w-full" placeholder="Jumlah Anak Kandung dan Tiri"
                    @blur="v$.jumlahAnak.$touch"/>
                    <label class="text-base">Jumlah Anak</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of v$.jumlahAnak.$errors" :key="error.$uid">
                    <span class="p-error">{{ error.$message }}</span>
                </div>
            </div>
                <!-- Orang Tua -->
            <div class="field">
                <span class="p-float-label mt-6">
                    <InputText v-model="v$.namaAyah.$model" type="text" class="w-full" placeholder="Usman" @blur="v$.namaAyah.$touch"/>
                    <label class="text-base">Nama Ayah Kandung</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of v$.namaAyah.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <div class="field">
                <span class="p-float-label mt-6">
                    <InputText v-model="v$.namaIbu.$model" type="text" class="w-full" placeholder="Yasniar"
                    @blur="v$.namaIbu.$touch"/>
                    <label class="text-base">Nama Ibu Kandung</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of v$.namaIbu.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>

                <!-- Foto Profil -->
            <label for="name" class="text-sm">
                Foto Profil
            </label>

            <input class="mb-5 mt-2 block w-full text-sm text-l border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none" id="file_input"
            type="file"
            ref="file"
            @change="uploadFile"
            />
            <div class="flex-ml-2 mt-1" v-for="error of v$.image.$errors" :key="error.$uid">
                <small class="p-error">{{ error.$message }}</small>
            </div>

            </div>
            <template #footer>
                <button icon="pi" @click="isWantInputGuru = false" class="border rounded p-2 mx-2">
                    Cancel
                </button>
                <button class="border rounded bg-ltDarkGreen text-white py-2 px-4 mx-2 hover:bg-ltLightGreen" label="Login" icon="pi" @click="toSecondDialog() " >
                    Berikutnya
                </button>
            </template>
    </Dialog>

    <Dialog :modal="true" v-model:visible="secondStepInputGuru" header="Alamat Guru">
        <div class="flex-col justify-center">

            <!-- Alamat -->
            <div class="field">
                <span class="p-float-label mt-6">
                    <InputText v-model="vAlamat$.namaJalan.$model" type="text" class="w-full" placeholder="Yasniar"
                    @blur="vAlamat$.namaJalan.$touch"/>
                    <label class="text-base">Nama Jalan</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of vAlamat$.namaJalan.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <div class="field">
                <span class="p-float-label mt-6">
                    <InputText v-model="vAlamat$.noRT.$model" type="number" class="w-full" placeholder="Yasniar"
                    @blur="vAlamat$.noRT.$touch"/>
                    <label class="text-base">Nomor RT</label>
                </span>
                <div class="flex ml-2 mt-2" v-for="error of vAlamat$.noRT.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <div class="field">
                <span class="p-float-label mt-6">
                    <InputText v-model="vAlamat$.noRW.$model" type="number" class="w-full" placeholder="Yasniar"
                    @blur="vAlamat$.noRW.$touch"/>
                    <label class="text-base">Nomor RW</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of vAlamat$.noRW.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <div class="field">
                <span class="p-float-label mt-6">
                    <Dropdown v-model="vAlamat$.provinsi.$model" class="w-full" :options="provinceList" optionValue="" optionLabel="name" placeholder="Pilih Provinsi" @change="fetchingKota(formInputGuru.provinsi.id)"
                    @blur="vAlamat$.provinsi.$touch"/>
                    <label class="text-base">Provinsi</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of vAlamat$.provinsi.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <div class="field">
                <span class="p-float-label mt-6">
                    <Dropdown v-model="vAlamat$.kota.$model" class="w-full" :options="kotaList" optionValue="" optionLabel="name" placeholder="Pilih Kota" @change="fetchingKecamatan(formInputGuru.kota.id)"
                    @blur="vAlamat$.kota.$touch"
                    />
                    <label class="text-base">Kota</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of vAlamat$.kota.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <div class="field">
                <span class="p-float-label mt-6">
                    <Dropdown v-model="vAlamat$.kecamatan.$model" class="w-full" :options="kecamatanList" optionValue="" optionLabel="name" placeholder="Pilih Kecamatan" @change="fetchingKelurahan(formInputGuru.kecamatan.id)"
                    @blur="vAlamat$.kecamatan.$touch"/>
                    <label class="text-base">Kecamatan</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of vAlamat$.kecamatan.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <div class="field">
                <span class="p-float-label mt-6">
                    <Dropdown v-model="vAlamat$.kelurahan.$model" class="w-full" :options="kelurahanList" optionValue="name" optionLabel="name" placeholder="Pilih Kelurahan"
                    @blur="vAlamat$.kelurahan.$touch"/>
                    <label class="text-base">Kelurahan</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of vAlamat$.kelurahan.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <div class="field">
                <span class="p-float-label mt-6">
                    <InputText v-model="vAlamat$.kodePos.$model" type="number" class="w-full" placeholder="3XXXXX"
                    @blur="vAlamat$.kodePos.$touch"
                    />
                    <label class="text-base">Kode Pos</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of vAlamat$.kodePos.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <div class="field">
                <span class="p-float-label mt-6">
                    <InputText v-model="vAlamat$.linkGoogleMap.$model" type="text" class="w-full" placeholder="maps.google"
                    @blur="vAlamat$.linkGoogleMap.$touch"/>
                    <label class="text-base">Link Google Map</label>
                </span>
                <div class="flex-ml-2 mt-1" v-for="error of vAlamat$.linkGoogleMap.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
        </div>
        <template #footer>
            <button icon="pi" @click="isWantInputGuru = true; secondStepInputGuru = false" class="border rounded p-2 mx-2">
                Kembali
            </button>
            <button class="border rounded bg-ltDarkGreen text-white py-2 px-4 mx-2 hover:bg-ltLightGreen" label="Login" icon="pi" @click="toThirdDialog()" >
                Berikutnya
            </button>
        </template>
    </Dialog>
    <Dialog :modal="true" v-model:visible="thirdStepInputGuru" header="Riwayat Pendidikan" :breakpoints="{ '960px': '75vw', '640px': '100vw' }">
        <div class="flex-col justify-center card">
            <DataTable :value="riwayatPendidikanArray" responsiveLayout="scroll">
                <template #header>
                    <div class="flex justify-between">
                        <h5>
                            Riwayat Pendidikan
                        </h5>
                        <button class="bg-ltDarkGreen text-white rounded border p-1 px-2 hover:bg-ltLightGreen" @click="toAddRiwayatPendidikan()">
                            <i class="pi pi-plus"></i>
                        </button>
                    </div>
                </template>
                <template #empty>
                    Belum Ada Riwayat Pendidikan silahkan di input terlebih dahulu.
                </template>
                <Column header="Jenjang Pendidikan" field="jenjangPendidikan"/>
                <Column header="Nama Lembaga" field="namaLembaga"/>
                <Column header="Tahun Lulus" field="tahunLulus"/>
                <Column header="Jurusan" field="jurusan"/>
                <Column header="Gelar" field="gelar"/>
                <Column header="Action">
                    <template #body="{index}">
                        <button @click="deleteRiwayatPendidikan(index)" class="border rounded py-1 px-2 ml-1 bg-red-500 text-white ">
                            <font-awesome-icon icon="fa-solid fa-trash" />
                        </button>
                    </template>
                </Column>
            </DataTable>
        </div>
        <!-- Riwayat Pendidikan -->
        <Dialog :modal="true" v-model:visible="isWantToAddRiwayatPendidikan" header="Tambah Riwayat Pendidikan">
            <!-- Jenjang Pendidikan - DropDown -->
            <div class="field">
                <span class="p-float-label mt-6">
                    <Dropdown v-model="vRiwayatPendidikan$.jenjangPendidikan.$model" class="w-full" :options="pendidikanList" optionValue="value" optionLabel="name" placeholder="SLTA kah? "
                    @blur="vRiwayatPendidikan$.jenjangPendidikan.$touch"/>
                    <label class="text-base">Jenjang Pendidikan</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of vRiwayatPendidikan$.jenjangPendidikan.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <!-- Nama Lembaga InputText-->
            <div class="field">
                <span class="p-float-label mt-6">
                    <InputText v-model="vRiwayatPendidikan$.namaLembaga.$model" type="text" class="w-full" placeholder="Lan Tabur"
                    @blur="vRiwayatPendidikan$.namaLembaga.$touch"/>
                    <label class="text-base">Nama Lembaga</label>
                </span>
                <div class="flex ml-2 mt1-" v-for="error of vRiwayatPendidikan$.namaLembaga.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <!-- Tahun Lulus Number-->
            <div class="field">
                <span class="p-float-label mt-6">
                    <InputText v-model="vRiwayatPendidikan$.tahunLulus.$model" type="number" class="w-full" placeholder="19XX"
                    @blur="vRiwayatPendidikan$.tahunLulus.$touch"/>
                    <label class="text-base">Tahun Lulus</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of vRiwayatPendidikan$.tahunLulus.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <!-- Jurusan Input Text -->
            <div class="field">
                <span class="p-float-label mt-6">
                    <InputText v-model="vRiwayatPendidikan$.jurusan.$model" type="text" class="w-full" placeholder="Kosongkan jika tidak ada Jurusan"
                    @blur="vRiwayatPendidikan$.jurusan.$touch"/>
                    <label class="text-base">Jurusan</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of vRiwayatPendidikan$.jurusan.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <!-- Gelar Input Text -->
            <div class="field">
                <span class="p-float-label mt-6">
                    <InputText v-model="vRiwayatPendidikan$.gelar.$model" type="text" class="w-full" placeholder="Kosongkan jika tidak ada gelar"
                    @blur="vRiwayatPendidikan$.gelar.$touch"/>
                    <label class="text-base">Gelar</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of vRiwayatPendidikan$.gelar.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <template #footer>
                <button icon="pi" @click="isWantToAddRiwayatPendidikan = false" class="border rounded p-2 mx-2">
                    Kembali
                </button>
                <button class="border rounded bg-ltDarkGreen text-white py-2 px-4 mx-2 hover:bg-ltLightGreen" label="Login" icon="pi" @click="saveRiwayatPendidikan()" >
                    Berikutnya
                </button>
            </template>
        </Dialog>
        <template #footer>
            <button icon="pi" @click="thirdStepInputGuru = false; secondStepInputGuru = true" class="border rounded p-2 mx-2">
                Kembali
            </button>
            <button class="border rounded bg-ltDarkGreen text-white py-2 px-4 mx-2 hover:bg-ltLightGreen" label="Login" icon="pi" @click="toFourthDialog()" >
                Berikutnya
            </button>
        </template>
    </Dialog>
    <Dialog :modal="true" v-model:visible="fourthStepInputGuru" header="Data Guru">
        <div class="flex-col justify-center">
            <div class="field">
                <span class="p-float-label mt-6">
                    <Dropdown v-model="vDataGuru$.statusGuru.$model" class="w-full" :options="statusGuru" optionValue="value" optionLabel="name" placeholder="Status Guru"
                    @blur="vDataGuru$.statusGuru.$touch"/>
                    <label class="text-base">Status Guru</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of vDataGuru$.statusGuru.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <div class="field">
                <span class="p-float-label mt-6" v-if="formInputGuru.statusGuru !== 'Magang'">
                    <InputText v-model="vDataGuru$.nig.$model" type="number" class="w-full" placeholder="01XXXXXXXXXXXXXX(16 Digit)"
                    @blur="vDataGuru$.nig.$touch"/>
                    <label class="text-base">NIG</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of vDataGuru$.nig.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <div class="field" v-if="formInputGuru.statusGuru === 'GTM'">
                <span class="p-float-label mt-6"  >
                    <InputText v-model="vDataGuru$.nigy.$model" type="number" class="w-full" placeholder="muhammad@gmail.com"
                    @blur="vDataGuru$.nigy.$touch"/>
                    <label class="text-base">NIGY</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of vDataGuru$.nigy.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <div class="field">
                <span class="p-float-label mt-6">
                    <Calendar class="h-10 w-full text-sm" v-model="vDataGuru$.tanggalMasuk.$model" dateFormat="dd MM yy" placeholder="Masukkan tanggal masuk"
                    @blur="vDataGuru$.tanggalMasuk.$touch"/>
                    <label class="text-base">Tanggal Masuk</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of vDataGuru$.tanggalMasuk.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <div class="field">
                <div class="flex justify-between mt-6">
                    <label class="text-base">Mukim di Pesantren?</label>
                    <InputSwitch v-model="vDataGuru$.statusMukim.$model"/>
                </div>
                <div class="flex ml-2 mt-1" v-for="error of vDataGuru$.statusMukim.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
        </div>
        <template #footer>
            <button icon="pi" @click="thirdStepInputGuru = true; fourthStepInputGuru =false;" class="border rounded p-2 mx-2">
                Cancel
            </button>
            <button class="border rounded bg-ltDarkGreen text-white py-2 px-4 mx-2 hover:bg-ltLightGreen" label="Login" icon="pi" @click="inputGuru(formInputGuru)" >
                Simpan
            </button>
        </template>
    </Dialog>

</template>

<style lang="scss" scoped>
::v-deep(.p-paginator) {
    .p-paginator-current {
        margin-left: auto;
    }
}

::v-deep(.p-progressbar) {
    height: .5rem;
    background-color: #D8DADC;

    .p-progressbar-value {
        background-color: #607D8B;
    }
}

::v-deep(.p-datepicker) {
    min-width: 25rem;

    td {
        font-weight: 400;
    }
}

::v-deep(.p-datatable.p-datatable-customers) {
    .p-datatable-header {
        padding: 1rem;
        text-align: left;
        font-size: 1.5rem;
    }

    .p-paginator {
        padding: 1rem;
    }

    .p-datatable-thead > tr > th {
        text-align: left;
    }

    .p-datatable-tbody > tr > td {
        cursor: auto;
    }

    .p-dropdown-label:not(.p-placeholder) {
        text-transform: uppercase;
    }
}
</style>

<script setup>
import NavbarVue from '@/components/Navbar.vue';
import { FilterMatchMode, FilterOperator } from 'primevue/api'
import { ref, onMounted, reactive } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import baseErrorHandler from '@/api/errorHandler';
import { between, email, helpers, maxLength, minLength, numeric, required, requiredIf, url } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import moment from 'moment';
import { isArray } from '@vue/shared';
import { useToast } from 'primevue/usetoast';
import { countryCode } from '@/utils/coutryCode';

const canAddGuru = ref(false)

onMounted(async () => {
    if (store.state.role === 'ADMIN' || store.state.role === 'TU_PESANTREN') {
        console.log(store.state.role)
        canAddGuru.value = true
    }

    await axios.get('/v2/guru')
    .then(res => {
        guruList.value = res.data.data
        loading1.value = false
    })
    .catch(err => {
        baseErrorHandler(err, store, router)
    })

    fetch('https://www.emsifa.com/api-wilayah-indonesia/api/provinces.json')
    .then(response => response.json())
    .then(provinces => {
        provinceList.value = provinces
    });
})

const updateData = async () => {
    loading1.value = true
    await axios.get('/v2/guru')
    .then(res => {
        guruList.value = res.data.data
        loading1.value = false
    })
}

const monthName = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember']
const router = useRouter()
const store = useStore()
const toast = useToast()

const isLoading = ref(false)

const statusGuru = ref([
    {name: 'Guru Magang', value: 'Magang'},
    {name: 'Guru Tidak Tetap', value: 'GTT'},
    {name: 'Guru Tetap Yayasan', value: 'GTY'}
])

const genderList = ref([
    {name: 'Laki-Laki', value: 'M'},
    {name: 'Perempuan', value: 'F'},
])

const golonganDarahList = ref([
    {name: 'A', value: 'A'},
    {name: 'B', value: 'B'},
    {name: 'O', value: 'O'},
    {name: 'AB', value: 'AB'},
])

const statusPernikahanList = ref([
    {name: 'Menikah', value: 'Menikah'},
    {name: 'Belum Menikah', value: 'Single'},
    {name: 'Janda', value: 'Janda'},
    {name: 'Duda', value: 'Duda'},
])

const pendidikanList = ref([
    { name: 'Taman Kanak-Kanak / sederajat', value: 'TK'},
    { name: 'Sekolah Dasar / sederajat', value: 'SD'},
    { name: 'Sekolah Menegah Pertama / sederajat', value: 'SMP'},
    { name: 'Sekolah Menegah Atas / sederajat', value: 'SMA'},
    { name: 'Diploma / sederajat', value: 'Diploma'},
    { name: 'Strata 1', value: 'S1'},
    { name: 'Strata 2', value: 'S2'},
    { name: 'Strata 3', value: 'S3'},
])

const provinceList = ref(null)
const kotaList = ref(null)
const kecamatanList = ref(null)
const kelurahanList = ref(null)
const formInputGuru = reactive({
    riwayatPendidikan: [],
    image: '',
})

const formRiwayatPendidikan = reactive({
    statusMukim: true
})

const riwayatPendidikanArray = ref([])

const inputGuruRules = {
    nik : {
        required: helpers.withMessage('NIK tidak boleh kosong!', required),
        minlength: helpers.withMessage('Panjang NIK harus 16 Angka', minLength(16)),
        maxLength: helpers.withMessage('Panjang NIK tidak boleh lebih dari 16 Angka', maxLength(16))
    },
    nama: {
        required: helpers.withMessage('Nama Lengkap tidak boleh kosong', required),
        alpha: helpers.withMessage('Nama Lengkap hanya boleh terdiri dari huruf', helpers.regex(/^[a-zA-Z ]*$/))
    },
    email: {
        required: helpers.withMessage('Email tidak boleh kosong', required),
        email: helpers.withMessage('Format email tidak valid', email)
    },
    jenisKelamin: {
        required: helpers.withMessage('Jenis Kelamin tidak boleh kosong', required)
    },
    tempatLahir: {
        required: helpers.withMessage('Tempat Lahir tidak boleh kosong', required),
        alpha: helpers.withMessage('Tempat lahir hanya boleh terdiri dari huruf', helpers.regex(/^[a-zA-Z ]*$/))
    },
    tanggalLahir: {
        required: helpers.withMessage('Tanggal Lahir tidak boleh kosong', required)
    },
    golonganDarah: { },
    countryCode: {
        required: helpers.withMessage('Kode negara tidak boleh kosong', required)
    },
    nomorTelepon: {
        required: helpers.withMessage('Nomor Telepon Aktif tidak boleh kosong', required),
        numeric: helpers.withMessage('Nomor telepon hanaya boleh terdiri dari angka', numeric),
        min: helpers.withMessage('Panjang Nomor Telepon minimal 9 angka', minLength(9)),
        max: helpers.withMessage('Panjang Nomor Telepon maksimal 13 angka', maxLength(13))
    },
    statusPernikahan: {
        required: helpers.withMessage('Status Pernikahan tidak boleh kosong', required)
    },
    jumlahAnak: {
        required: helpers.withMessage('Jumlah anak tidak boleh kosong', requiredIf(formInputGuru.statusPernikahan != 'Single')) },
    namaAyah: {
        required: helpers.withMessage('Nama Ayah Kandung tidak boleh kosong', required),
        alpha: helpers.withMessage('Nama Ayah Kandung hanya boleh terdiri dari huruf ', helpers.regex(/^[a-zA-Z ]*$/))
    },
    namaIbu: {
        required: helpers.withMessage('Nama Ibu Kandung hanya tidak boleh kosong', required),
        alpha: helpers.withMessage('Nama Ibu hanya boleh terdiri dari angka', helpers.regex(/^[a-zA-Z ]*$/))
    },
    image: {
        required: helpers.withMessage('Foto Profil tidak boleh kosong', required),
    }
}

const inputAlamatGuruRules = {
    namaJalan: {
        required: helpers.withMessage('Nama Jalan tidak boleh kosong', required)
    },
    noRT: {
        required: helpers.withMessage('Nomor RT tidak boleh kosong', required),
        numeric: helpers.withMessage('Nomor RT harus terdiri dari Angka', numeric)
    },
    noRW: {
        required: helpers.withMessage('Nomor RW tidak boleh kosong', required),
        numeric: helpers.withMessage('Nomor RW harus terdiri dari Angka', numeric)
    },
    provinsi: {
        required: helpers.withMessage('Nama Provinsi tidak boleh kosong', required),
    },
    kota: {
        required: helpers.withMessage('Nama Kota tiadk boleh kosong', required)
    },
    kecamatan: {
        required: helpers.withMessage('Nama Kecamatan tidak boleh kosong', required)
    },
    kelurahan: {
        required: helpers.withMessage('Nama Kelurahan tidak boleh kosong', required),
    },
    kodePos: {
        required: helpers.withMessage('Nomor Kode Pos tidak boleh kosong', required),
        numeric: helpers.withMessage('Nomor Kode Pos harus terdiri dari angka', numeric),
        minLength: helpers.withMessage('Nomor Kode Pos harus terdiri dari 5 angka', minLength(5)),
        maxLength: helpers.withMessage('Nomor Kode Pos harus terdiri dari 5 angka', maxLength(5))
    },
    linkGoogleMap: {
        url: helpers.withMessage('Link Google Map tidak valid, mohon input ulang', url)
    }
}

const inputRiwayatPendidikanRules = {
    jenjangPendidikan: {
        required: helpers.withMessage('Jenjang Pendidikan tidak boleh kosong', required)
    },
    namaLembaga: {
        required: helpers.withMessage('Nama Lembaga tidak boleh kosong', required)
    },
    tahunLulus: {
        required: helpers.withMessage('Tahun Lulus tidak boleh kosong', required),
        numeric: helpers.withMessage('Tahun Lulus harus berupa angka', numeric),
        beetwen: helpers.withMessage(`Tahun Lulus harus berada di rentang 1900 hingga ${(moment(Date.now()).format('YYYY'))}`, between(1900, moment(Date.now()).format('YYYY')))
    },
    jurusan: {},
    gelar: {}
}

const inputDataGuruRules = {
    nig: {
        // required: helpers.withMessage('NIG tidak boleh kosong', required),
        requiredIf : helpers.withMessage('NIGY tidak boleh kosong', requiredIf(() => {
            return formInputGuru.statusGuru == 'GTT' || formInputGuru.statusGuru == "GTY"
        })),
        minLength: helpers.withMessage('NIG harus terdiri dari 14 digit angka', minLength(14)),
        maxLength: helpers.withMessage('NIG harus terdiri dari 14 digit angka', maxLength(14))
    },
    statusGuru: {
        required: helpers.withMessage('Status Guru tidak boleh kosong', required),
    },
    nigy: {
        // requiredIf : helpers.withMessage('NIGY tidak boleh kosong', requiredIf(() => {
        //     return formInputGuru.statusGuru == 'GTY'
        // })),
        minLength: helpers.withMessage('NIGY harus terdiri dari 14 digit angka', minLength(14)),
        maxLength: helpers.withMessage('NIGY harus terdiri dari 14 digit angka', maxLength(14))
    },
    tanggalMasuk: {
        required: helpers.withMessage('Tanggal Masuk tidak boleh kosong', required)
    },
    statusMukim: {
        required: helpers.withMessage('Status Mukim di Pesantren tidak boleh kosong', required)
    }
}

const deleteRiwayatPendidikan = (index) => {
    riwayatPendidikanArray.value.splice(index, 1)
}

const v$ = useVuelidate(inputGuruRules, formInputGuru)
const vAlamat$ = useVuelidate(inputAlamatGuruRules, formInputGuru)
const vRiwayatPendidikan$ = useVuelidate(inputRiwayatPendidikanRules, formRiwayatPendidikan)
const vDataGuru$ = useVuelidate(inputDataGuruRules, formInputGuru)

const guruList = ref(null)
const guruFilter = ref({
    'global' : { value: null, matchMode: FilterMatchMode.CONTAINS},
    'kodeGuru' : {value: null, matchMode: FilterMatchMode.CONTAINS},
    'nama' : {value: null, matchMode: FilterMatchMode.CONTAINS},
    'gender' : {value: null, matchMode: FilterMatchMode.CONTAINS},
    'email' : {value: null, matchMode: FilterMatchMode.CONTAINS},
    'ttl': {value: null, matchMode: FilterMatchMode.CONTAINS}
})

const fetchingKota = (id) => {
    fetch(`https://www.emsifa.com/api-wilayah-indonesia/api/regencies/${id}.json`)
    .then(response => response.json())
    .then(regencies => {
        kotaList.value = regencies
    });
}

const fetchingKecamatan = (id) => {
    fetch(`https://www.emsifa.com/api-wilayah-indonesia/api/districts/${id}.json`)
    .then(response => response.json())
    .then(districts => {
        kecamatanList.value = districts
    });
}

const fetchingKelurahan = (id) => {
    fetch(`https://www.emsifa.com/api-wilayah-indonesia/api/villages/${id}.json`)
    .then(response => response.json())
    .then(villages => kelurahanList.value = villages);
}

const isWantInputGuru = ref(false)
const secondStepInputGuru = ref(false)
const thirdStepInputGuru = ref(false)
const fourthStepInputGuru = ref(false)

const isWantToAddRiwayatPendidikan = ref(false)

const toAddRiwayatPendidikan = () => {
    formRiwayatPendidikan.jenjangPendidikan = null
    formRiwayatPendidikan.namaLembaga = null
    formRiwayatPendidikan.tahunLulus = null
    formRiwayatPendidikan.jurusan = null
    formRiwayatPendidikan.gelar = null
    vRiwayatPendidikan$.value.$reset()
    isWantToAddRiwayatPendidikan.value = true
}

const toSecondDialog = async () => {
    const isFormCorrect = await v$.value.$validate()
    console.log(isFormCorrect)
    if (isFormCorrect) {
        secondStepInputGuru.value = true;
        isWantInputGuru.value = false;
    }
}

const toThirdDialog = async() => {
    const isFormCorrect = await vAlamat$.value.$validate()
    if (isFormCorrect) {
        thirdStepInputGuru.value = true
        secondStepInputGuru.value = false
    }
}

const toFourthDialog = () => {
    formInputGuru.riwayatPendidikan = riwayatPendidikanArray.value
    thirdStepInputGuru.value = false 
    fourthStepInputGuru.value = true
}

const saveRiwayatPendidikan = async() => {
    const isFormCorrect = await vRiwayatPendidikan$.value.$validate()
    const data = {
        jenjangPendidikan: formRiwayatPendidikan.jenjangPendidikan,
        namaLembaga: formRiwayatPendidikan.namaLembaga,
        tahunLulus: formRiwayatPendidikan.tahunLulus,
        jurusan: formRiwayatPendidikan.jurusan,
        gelar: formRiwayatPendidikan.gelar,
    }
    if (isFormCorrect) {
        console.log(formRiwayatPendidikan)
        riwayatPendidikanArray.value.push(data)
        isWantToAddRiwayatPendidikan.value = false
    }
}

const initValueFormInputGuru = () => {
    riwayatPendidikanArray.value = [{}]
    formInputGuru.riwayatPendidikan = ''
    formInputGuru.image = '' 
    formInputGuru.nik = '' 
    formInputGuru.nama = '' 
    formInputGuru.email = '' 
    formInputGuru.jenisKelamin = '' 
    formInputGuru.tempatLahir = '' 
    formInputGuru.tanggalLahir = '' 
    formInputGuru.golonganDarah = '' 
    formInputGuru.countryCode = '' 
    formInputGuru.nomorTelepon = '' 
    formInputGuru.statusPernikahan = '' ,
    formInputGuru.jumlahAnak = '' ,
    formInputGuru.namaAyah = '' 
    formInputGuru.namaIbu = '' 
    formInputGuru.namaJalan = ''
    formInputGuru.noRT = ''
    formInputGuru.noRW = ''
    formInputGuru.provinsi = '' 
    formInputGuru.kota = '' 
    formInputGuru.kecamatan = '' 
    formInputGuru.kelurahan = '' 
    formInputGuru.kodePos = '' 
    formInputGuru.linkGoogleMap = '' 
    formInputGuru.statusGuru = '' 
    formInputGuru.nig = '' 
    formInputGuru.tanggalMasuk = '' 
    formInputGuru.statusMukim = '' 
}

const inputGuru = async() => {
    const isFormCorrect = await vDataGuru$.value.$validate()

    if (riwayatPendidikanArray.value === null || riwayatPendidikanArray.value.length < 3) {
        toast.add({
            severity: 'error',
            summary: 'Gagal Input Data!',
            detail: 'Riwayat pendidikan tidak boleh kosong! Minimal masukkan SD, SMP, dan SMA',
            life: '3000'
        })
        return
    }
    
    if(isFormCorrect) {
        // Showing Block Element 
        isLoading.value = true

        // Post data 
        const formData = new FormData()

        if(formInputGuru.nigy) {
            formData.append('nigy', formInputGuru.nigy)
        }

        if(formInputGuru.nig) {
            formData.append('nig', formInputGuru.nig)
        }

        if (formInputGuru.golonganDarah) {
            formData.append('golonganDarah', formInputGuru.golonganDarah)
        }

        if (formInputGuru.linkGoogleMap) {
            formData.append('linkGoogleMap', formInputGuru.linkGoogleMap)
        }

        console.log(formInputGuru)
        formData.append('image', formInputGuru.image)
        formData.append('email', formInputGuru.email)
        formData.append('jenisKelamin', formInputGuru.jenisKelamin)
        formData.append('jumlahAnak', formInputGuru.jumlahAnak)
        formData.append('kecamatan', formInputGuru.kecamatan.name)
        formData.append('kelurahan', formInputGuru.kelurahan)
        formData.append('kodePos', formInputGuru.kodePos)
        formData.append('kota', formInputGuru.kota.name)
        formData.append('nama', formInputGuru.nama)
        formData.append('namaAyah', formInputGuru.namaAyah)
        formData.append('namaIbu', formInputGuru.namaIbu)
        formData.append('namaJalan', formInputGuru.namaJalan)
        // formData.append('nig', formInputGuru.nig)
        formData.append('nik', formInputGuru.nik)
        formData.append('nomorRT', formInputGuru.noRT)
        formData.append('nomorRW', formInputGuru.noRW)
        formData.append('nomorTelepon', `${formInputGuru.countryCode}-${formInputGuru.nomorTelepon}`)
        formData.append('provinsi', formInputGuru.provinsi.name)
        formData.append('riwayatPendidikan', JSON.stringify(formInputGuru.riwayatPendidikan))
        formData.append('statusGuru', formInputGuru.statusGuru)
        formData.append('statusMukim', formInputGuru.statusMukim)
        formData.append('statusPernikahan', formInputGuru.statusPernikahan)
        formData.append('tanggalLahir', moment(formInputGuru.tanggalLahir).format('YYYY-MM-DD'))
        formData.append('tanggalMasuk', moment(formInputGuru.tanggalMasuk).format('YYYY-MM-DD'))
        formData.append('tempatLahir', formInputGuru.tempatLahir)

        console.log(formData)

        await axios.post('/v2/guru', formData)
        .then(res => {
            console.log(res)
            toast.add({
                severity: 'success',
                summary: 'Data berhasil di Input',
                detail: res.data.message,
                life: 3000
            })
            fourthStepInputGuru.value = false
            isLoading.value = false
            v$.value.$reset()
            vAlamat$.value.$reset()
            vDataGuru$.value.$reset()
            initValueFormInputGuru()
            updateData()
        })
        .catch (err => {
            isLoading.value = false
            if (isArray(err.response.data.error)) {
                const errorList = err.response.data.error
                // console.log(errorList)
                errorList.forEach(data => {
                    toast.add({
                        severity : 'error',
                        summary : "Input Data Murid Gagal",
                        detail : data.msg,
                        life: 3000
                    })
                })
            }
            else {
                toast.add({
                    severity : 'error',
                    summary : "Input Data Murid Gagal",
                    detail : err.response.data.error.msg,
                    life: 3000
                })
            }
            console.log(err.response.data.error)
        })
    }
}

const file = ref(null)

const uploadFile = async() => {
    // console.log(file.value)
    console.log(file.value.files)
    formInputGuru.image = file.value.files[0]
}

const recoveryGuru = async(nigs)=> {
    if (store.state.role !== 'ADMIN') {
        toast.add({
            severity: 'Error',
            summary: `Not Authorized`,
            message: 'Not Authorized',
            life: 3000
        })
    }
    
    await axios.put(`/v2/guru/nigs/${nigs}`)
    .then(res => {
        toast.add({
            severity: 'success',
            summary: 'Recovery Berhasil',
            detail: res.data.message,
            life: 3000
        })
    })
    .catch(err => {
        toast.add({
            severity: 'error',
            summary: 'Error Occured',
            detail: err.rensponse.data.message,
            life: 3000
        })
    })
}

// const customerService = ref(new CustomerService());


const filters1 = ref({
    'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
    'name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
    'country.name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
    'representative': {value: null, matchMode: FilterMatchMode.IN},
    'date': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
    'balance': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
    'status': {operator: FilterOperator.OR, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
    'activity': {value: null, matchMode: FilterMatchMode.BETWEEN},
    'verified': {value: null, matchMode: FilterMatchMode.EQUALS}
});

const loading1 = ref(true);


const clearFilter1 = () => {
    initFilters1();
};
const initFilters1 = () => {
    filters1.value = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'country.name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'representative': {value: null, matchMode: FilterMatchMode.IN},
        'date': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
        'balance': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
        'status': {operator: FilterOperator.OR, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
        'activity': {value: null, matchMode: FilterMatchMode.BETWEEN},
        'verified': {value: null, matchMode: FilterMatchMode.EQUALS}
    }
};

const countryCodeList = ref( countryCode )

</script>