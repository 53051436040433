<template>
    <NavbarVue/>
    <div class="flex mt-3 mx-2 justify-around items-center" v-if="detailHalaqoh.guruTahfidzDetail">
        <span class="text-xl sm:text-2xl">{{ $route.params.kode }}</span>
        <span class="text-xl sm:text-2xl">{{ detailHalaqoh.guruTahfidzDetail.pesantrenDetail.detail.guru.nama }}</span>
    </div>
    <!-- <div class="card px-5">
        <Chart type="line" :data="realChartData" :options="basicOptions" :responsive="true" />
    </div> -->
    <TabView class="px-2">
        <!-- <TabPanel header="Grafik"> -->
        <!-- </TabPanel> -->
        <TabPanel header="Sesi">
            <DataTable :value="detailHalaqoh.sesiHistory" responsive-layout="scroll" paginator="true"
            :rows="10"
            >
                <Column header="Waktu Setoran" style="min-width:12.5rem">
                    <template #body={data}>
                        {{ moment(data.waktu).locale('id').format('dddd, DD MMMM YYYY H:mm:ss')}}
                    </template>
                </Column>
                <Column header="Jam Tahfidz" style="min-width:10rem">
                    <template #body="{data}">
                        {{ data.jamTahfidz.namaJam }} - {{ data.jamTahfidz.tipe }}
                    </template>
                </Column>
                <Column header="Nama Santri" style="min-width:10rem">
                    <template #body="{data}">
                        {{ data.muridTahfidzDetail.pesantrenDetail.muridDetail.murid.nama }}
                    </template>
                </Column>
                <Column field="juz" header="Juz" >
                    <template #body="{data}">
                        <span v-if="data.awalSetoran.juz === data.akhirSetoran.juz">{{ data.awalSetoran.juz }}</span>
                        <span v-else>{{ data.awalSetoran.juz }} - {{ data.akhirSetoran.juz }}</span>
                    </template>
                </Column>
                <Column header="Jumlah Setoran" style="min-width:9rem">
                    <template #body="{data}">
                        <span v-if="data.jumlahBaris < 15"> {{ data.jumlahBaris }} baris </span>
                        <span v-else>{{ data.jumlahBaris }} baris / <br> {{ data.jumlahHalaman }} halaman</span>
                    </template>
                </Column>
                <Column header="Awal Setoran" style="min-width:14rem">
                    <template #body="{data}">
                        <div v-if="data.awalSetoran.nomorSurah != data.akhirSetoran.nomorSurah">
                            {{ data.awalSetoran.namaSurahEn }} : {{ data.awalSetoran.ayat }} - <br>
                            {{ data.akhirSetoran.namaSurahEn }} : {{ data.akhirSetoran.ayat }}
                        </div>
                        <div v-else>
                            {{ data.awalSetoran.namaSurahEn }} : {{ data.awalSetoran.ayat }} - {{ data.akhirSetoran.ayat }}
                        </div>
                    </template>
                </Column>
                <Column header="Nilai">
                    <template #body="{data}">
                        {{ (data.nilaiKelancaran + data.nilaiMakhroj + data.nilaiSifatHuruf + data.nilaiTajwid)/4 }}
                    </template>
                </Column>                                                                      
            </DataTable>
        </TabPanel>
        <TabPanel header="Guru">
            <div class="rounded text-center justify-center mx-2">
                <div class="flex justify-around mt-4 pt-3 mx-2 sm:mx-auto flex-row">
                    <img :src="detailHalaqoh.guruTahfidzDetail.pesantrenDetail.detail.guru.profileUrl" alt="" width="200" class=" rounded border">
                </div>
                <h1 class="text-3xl pt-5 w-full"><router-link :to="`/guru/${detailHalaqoh.guruTahfidzDetail.pesantrenDetail.detail.guru.nig}`">{{detailHalaqoh.guruTahfidzDetail.pesantrenDetail.detail.guru.nama}}</router-link></h1>
                <span class="text-base pt-2">{{detailHalaqoh.guruTahfidzDetail.pesantrenDetail.detail.guru.email}}</span>
                <hr class="mt-5">
                <hr class="mt-1">
                <div class="flex mx-auto justify-center">
                    <div class="text-left text-base grid grid-cols-2 mx-1 pt-5 pb-5">
                        <span class="">NIG</span> 
                        <span>{{detailHalaqoh.guruTahfidzDetail.pesantrenDetail.detail.guru.nig}}</span>
                        <span class="">Kode Guru</span>
                        <span>{{detailHalaqoh.guruTahfidzDetail.pesantrenDetail.detail.guru.kodeGuru}}</span>
                        <span>Jenis Kelamin </span>
                        <span>{{detailHalaqoh.guruTahfidzDetail.pesantrenDetail.detail.guru.jenisKelamin == 'M' ? 'Laki-Laki' : 'Perempuan'}}</span>
                        <span>Tempat, Tanggal Lahir</span>
                        <span>{{detailHalaqoh.guruTahfidzDetail.pesantrenDetail.detail.guru.tempatLahir}}, {{ moment(detailHalaqoh.guruTahfidzDetail.pesantrenDetail.detail.guru.tanggalLahir).locale('id').format('DD MMMM YYYY') }}</span>
                        <span>Tanggal Masuk</span>
                        <span>{{ moment(detailHalaqoh.guruTahfidzDetail.pesantrenDetail.detail.guru.tanggalMasuk).locale('id').format('DD MMMM YYYY') }}</span>
                    </div>
                </div>
            </div>
        </TabPanel>
        <TabPanel header="Murid">
            <DataTable :value="detailHalaqoh.muridTahfidzDetail" responsive-layout="scroll" 
            paginator="true" :rows="10"
            > 
                <Column field="pesantrenDetail.muridDetail.murid.nis" header="NIS"/>
                <Column header="Murid" style="min-width: 16rem;">
                    <template #body="{data}">
                        <router-link :to="`/murid/${data.pesantrenDetail.muridDetail.murid.nis}`" class="flex items-center">
                            <img :src="data.pesantrenDetail.muridDetail.murid.profileUrl" alt="" width="40" :class="flag">
                            <div class="flex-row px-2">
                            <p class="text-base">{{data.pesantrenDetail.muridDetail.murid.nama}}</p>
                            <p class="text-xs">NISN: {{data.pesantrenDetail.muridDetail.murid.nisn}}</p>
                            </div>
                        </router-link>
                    </template>
                </Column>
                <Column header="Setoran Terakhir" style="min-width:14rem">
                    <template #body="{data}">
                        <!-- {{ data.sesi[data.sesi.length - 1] }} -->
                        <div v-if="data.sesi[data.sesi.length - 1].awalSetoran.nomorSurah != data.sesi[data.sesi.length - 1].akhirSetoran.nomorSurah">
                            <!-- sama -->
                            {{ data.sesi[data.sesi.length - 1].awalSetoran.namaSurahEn }} : {{ data.sesi[data.sesi.length -1].awalSetoran.ayat }} -
                            {{ data.sesi[data.sesi.length - 1].akhirSetoran.namaSurahEn }} : {{ data.sesi[data.sesi.length - 1].akhirSetoran.ayat }} pada hari {{ moment(data.sesi[data.sesi.length -1].waktu).locale('id').format('dddd, DD MMMM YYYY') }}
                        </div>
                        <div v-else>
                            <!-- beda -->
                            {{ data.sesi[data.sesi.length - 1].awalSetoran.namaSurahEn }} : {{ data.sesi[data.sesi.length - 1].awalSetoran.ayat }} - {{ data.sesi[data.sesi.length - 1].akhirSetoran.ayat }} pada hari {{ moment(data.sesi[data.sesi.length -1].waktu).locale('id') }}
                        </div> 
                        
                    </template>
                </Column>
            </DataTable>
        </TabPanel>
        <TabPanel header="Status Target">
            <CustomStatusTahfidzHalaqoh/>
        </TabPanel>
    </TabView>
</template>
<script setup>
import NavbarVue from '@/components/Navbar.vue';
import { onBeforeMount, onMounted, ref, reactive } from 'vue';
import { useRoute } from 'vue-router';
// import Chart from 'primevue/chart'
import axios from 'axios';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import moment from 'moment';
import CustomStatusTahfidzHalaqoh from '@/components/CustomStatusTahfidzHalaqoh.vue';
import { useStore } from 'vuex';

const store = useStore()

onBeforeMount(async ()=> {
    // Get Surah Data
    await axios.get('/v2/surah')
    .then(res => {
        console.log(res)
        surahList.value = res.data.data
    })

    // Get Halaqoh data
    await axios.get(`/v2/halaqoh/detail/${route.params.kode}`)
    .then(res => {
        detailHalaqoh.value = res.data.data
        store.state.halaqoh.data = res.data.data
    })
    parsingSessionToChartData(detailHalaqoh.value.muridTahfidzDetail)
    console.log(chartData)
    parsingChartDataToBasicData((chartData))
    testingDataset.value = chartData[0]           
})

onMounted(async()=> {
})

// Variable 
const route = useRoute()
const detailHalaqoh = ref(null)
const surahList = ref(null)

// Function 
// const parsingSetoran = (surah, ayat) => {
//     return `${surahList.value[surah - 1].indo} : ${ayat}`
// }

const chartData = reactive([])

const testingDataset = ref(null)

const parsingSessionToChartData = async(sessionData) => {
    sessionData.forEach(murid => {
        let formattedData = {
            hafalan: [],
            murojah: []
        }
        formattedData['nama'] = murid.pesantrenDetail.muridDetail.murid.nama
        murid.sesi.forEach(sesi => {
            sesi.waktu = moment(sesi.waktu).locale('id').format('DD-MM-YYYY')
            let rekapSesi = {}
            rekapSesi['tanggal'] = sesi.waktu
            rekapSesi['halaman'] = sesi.halaman
            if(sesi.jamTahfidz.tipe === 'Hafalan') {
                let isTanggalSama = formattedData.hafalan.find(o => o.tanggal === rekapSesi.tanggal)
                if (isTanggalSama){
                    isTanggalSama.halaman += rekapSesi.halaman  
                }
                else formattedData.hafalan.push(rekapSesi)
            }
            else {
                let isTanggalSama = formattedData.murojah.find(o => o.tanggal === rekapSesi.tanggal)
                if (isTanggalSama) {
                    isTanggalSama.halaman += rekapSesi.halaman
                }
                else formattedData.murojah.push(rekapSesi)
            }   
        })
        chartData.push(formattedData)
    })
}

// const basicData =  ref(
//     {
//         datasets: [
//             {
//                 label: 'Hafalan',
//                 data: testingDataset,
//                 fill: false,
//                 borderColor: '#42A5F5',
//                 tension: .2,
//                 parsing: {
//                     xAxisKey: 'hafalan.tanggal',
//                     yAxisKey: 'hafalan.halaman',
//                 }
//             },
//         ]
//     }
// );

const realChartData = ref({ datasets: null})

const parsingChartDataToBasicData = (chartData)=> {
    let basicData = []
    chartData.forEach(data => {
        let hafalan = {
            borderColor: '#42A5F5',
            tension: .2,
            parsing: {
                xAxisKey: 'tanggal',
                yAxisKey: 'halaman',
            }
        }
        hafalan['label'] = data.nama
        hafalan['data'] = ref(data.hafalan)
        basicData.push(hafalan)
        
        let murojah = {
            borderColor: '#00FF00',
            tension: .2,
            parsing: {
                xAxisKey: 'tanggal',
                yAxisKey: 'halaman',
            }
        }
        murojah['label'] = data.nama
        murojah['data']  = ref(data.murojah)
        basicData.push(murojah)
    })
    console.log(basicData)

    realChartData.value.datasets = basicData
}

// const basicOptions = ref(
//     {
//         plugins: {
//             legend: {
//                 display: false,
//                 labels: {
//                     color: '#495057'
//                 }
//             },
//             title: {
//                 display: true,
//                 text: 'Diagram Setoran Halaqoh',
//                 font: {
//                     size: 24
//                 }
//             }
//         },
//         // parsing:{
//         //     xAxisKey: 'hafalan.tanggal',
//         //     yAxisKey: 'hafalan.halaman',
//         // },
//         scales: {
//             x: {
//                 ticks: {
//                     color: '#495057'
//                 },
//                 grid: {
//                     color: '#ebedef'
//                 }
//             },
//             y: {
//                 ticks: {
//                     color: '#495057'
//                 },
//                 grid: {
//                     color: '#ebedef'
//                 }
//             }
//         }
//     }
// );
</script>