<template>
    <BlockUI :blocked="isLoading" :full-screen="true"/>
    <Toast/>
    <Navbar/>
    <div class="px-2 sm:px-4 mt-4 mx-2">
        <div class="card shadow">
            <FullCalendar :options="option" :responsive="true" />
        </div>
    </div>
    <Dialog :modal="true" v-model:visible="isWantToAddReminder" header="Tambah Reminder">
        <span class="p-float-label mt-6 w-full">
            <InputText class="w-full " type="text" v-model.trim="vInputReminder$.nama.$model"/>
            <label class="text-base w-full">Nama Jadwal</label>
        </span>
        <div class="flex ml-2 mt-1" v-for="error of vInputReminder$.nama.$errors" :key="error.$uid">
            <small v-if="vInputReminder$.nama.$errors" class="p-error">{{ error.$message }}</small>
        </div>
        <span class="p-float-label mt-6 w-full">
            <Calendar class="w-full " :showIcon="true" selection-mode="range" :manualInput="false" v-model="vInputReminder$.rentangTanggal.$model"/>
            <label class="text-base w-full ">Rentang Tanggal</label>
        </span>
        <div class="flex ml-2 mt-1" v-for="error of vInputReminder$.rentangTanggal.$errors" :key="error.$uid">
            <small v-if="vInputReminder$.rentangTanggal.$errors" class="p-error">{{ error.$message }}</small>
        </div>
        <span class="p-float-label mt-6 w-full">
            <Calendar class="w-full " :time-only="true" v-model="vInputReminder$.jamMulai.$model" />
            <label class="text-base w-full ">Mulai Jadwal</label>
        </span>
        <div class="flex ml-2 mt-1" v-for="error of vInputReminder$.jamMulai.$errors" :key="error.$uid">
            <small v-if="vInputReminder$.jamMulai.$errors" class="p-error">{{ error.$message }}</small>
        </div>
        <span class="p-float-label mt-6 w-full">
            <Calendar class="w-full " :time-only="true" v-model="vInputReminder$.jamSelesai.$model"/>
            <label class="text-base w-full ">Selesai Jadwal</label>
        </span>
        <div class="flex ml-2 mt-1" v-for="error of vInputReminder$.jamSelesai.$errors" :key="error.$uid">
            <small v-if="vInputReminder$.jamSelesai.$errors" class="p-error">{{ error.$message }}</small>
        </div>
        <span class="p-float-label mt-6 w-full">
            <InputText class="w-full " type="text" v-model.trim="vInputReminder$.message.$model"/>
            <label class="text-base w-full">Pesan Tampil</label>
        </span>
        <div class="flex ml-2 mt-1" v-for="error of vInputReminder$.message.$errors" :key="error.$uid">
            <small v-if="vInputReminder$.message.$errors" class="p-error">{{ error.$message }}</small>
        </div>
        <template #footer>
            <button icon="pi" @click="isWantInputGuru = false" class="border rounded p-2 mx-2">
                Cancel
            </button>
            <button class="border rounded bg-ltDarkGreen text-white py-2 px-4 mx-2 hover:bg-ltLightGreen" label="Login" icon="pi" @click="inputBulkReminder() " >
                Simpan
            </button>
        </template>
    </Dialog>
</template>

<script setup>
import Navbar from '@/components/Navbar.vue';
import axios from 'axios';
// import moment from 'moment';
import '@fullcalendar/core';

import idLocale from '@fullcalendar/core/locales/id'
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list'
import { onMounted, ref, reactive } from 'vue';
import { helpers, required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import moment from 'moment';
import { useToast } from 'primevue/usetoast';

const reminder = ref(null)
const formInputReminder = reactive({})

const inputReminderRules = {
    nama: {
        required: helpers.withMessage('Nama Jadwal tidak boleh kosong', required),
    },
    message: {
        requierd: helpers.withMessage("Pesan Jam tidak boleh kosong", required)
    },
    rentangTanggal: {
        required: helpers.withMessage("Rentang Tanggall tidak boleh kosong!", required),
    },
    jamMulai: {
        required: helpers.withMessage("Jam Mulai tidak baleh kosong", required),
    },
    jamSelesai: {
        required: helpers.withMessage("Jam Selesai tidak boleh kosong", required),
    }
}

const vInputReminder$ = useVuelidate(inputReminderRules, formInputReminder)
const toast = useToast()

const isWantToAddReminder = ref(false)
const isLoading = ref(false)

const fecthJadwalAlarm = async () => {
    await axios.get('/v2/ltclock')
    .then(res => {
        reminder.value = res.data.data
        reminder.value.forEach(data => {
            data['title'] = data.nama
            data['start'] = data.waktuMulai
            data['end'] = data.waktuSelesai
        })
    })
}

onMounted(async () => {
    fecthJadwalAlarm()
})

const option = ref({
    plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin],
    // initialView: 'timeline',
    locale: idLocale,
    initialDate: Date.now(),
    events: reminder,
    firstDay: 0,
    contentHeight: 650,
    // duration: { minutes: 5},
    customButtons: {
        addReminder : {
            text: '+',
            click: function() {
                isWantToAddReminder.value = true
            }
        }
    },
    eventClick: function(info) {
        isWantToAddReminder.value = true
        console.log(info.dateStr)
        console.log(info.event)
        console.log(info.event.id)
        console.log('clicked')
    },
    // eventMouseEnter: function() {
    //     console.log('Ada mouse')
    // },
    headerToolbar: {
        left: 'prev,next',
        center: 'title',
        right: 'addReminder dayGridMonth,timeGridWeek,timeGridDay',
    },
    buttonText: {
        today: 'Hari ini',
        month : 'B',
        week : 'M',
        day : 'H'
    },
    views: {
        dayGridMonth: { 
            titleFormat: { 
                year: 'numeric', 
                month: 'long', 
            },
            // duration: { minutes: 4}
        },
        timeGridWeek: {
            titleFormat : {
                year : 'numeric',
                day : 'numeric',
                month : 'short',
            }
        }
    },
    editable: false, 
    selectable: true,
    selectMirror : true,
})

const initFormInputReminder = () => {
    formInputReminder.nama = "" 
    formInputReminder.rentangTanggal = "" 
    formInputReminder.jamMulai = "" 
    formInputReminder.jamSelesai = "" 
    formInputReminder.message = "" 

}
const inputBulkReminder = async() => {
    isLoading.value = true
    const isCorrect = await vInputReminder$.value.$validate()
    console.log(isCorrect)
    if (isCorrect) {
        const data = {
            tanggalMulai: moment(formInputReminder.rentangTanggal[0]).format('YYYY-MM-DD'),
            tanggalSelesai: moment(formInputReminder.rentangTanggal[1]).format('YYYY-MM-DD'),
            jamMulai: moment(formInputReminder.jamMulai).format('HH:mm'),
            jamSelesai: moment(formInputReminder.jamSelesai).format('HH:mm'),
            nama: formInputReminder.nama,
            message:  formInputReminder.message
        }
    
        console.log(data)
        await axios.post('/v2/ltclock', data)
        .then(res => {
            isWantToAddReminder.value = false
            console.log(res.data)
            fecthJadwalAlarm()
            toast.add({
                severity: 'success',
                summary: 'Jadwal berhasil dibuat',
                detail: res.data.message,
                life: 3000,
            })
            vInputReminder$.value.$reset()
            initFormInputReminder()
        })
        .catch(err => {
            console.log(err)
        })
        .finally(isLoading.value = false)
    }
    isLoading.value = false

}

</script>