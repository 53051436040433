<template>
    <NavbarVue></NavbarVue>
    <Toast/>
    <ConfirmDialog :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '50vw'}"></ConfirmDialog>
    <EditGuruVue :data="data.guru" v-if="isWantEditGuru" @close="isWantEditGuru = false"/>
    <div class="flex justify-between sm:justify-around items-center pt-2 mx-4 sm:mx-0">
        <h1 class="text-xl sm:text-2xl">{{ data.guru.nigs }}</h1>
        <!-- <h1 class="text-xl sm:text-2xl text-center px-2">{{ data.murid.nama }}</h1> -->
        <div class="hidden sm:block" v-if="canEditOrDelete">
            <button class="border rounded py-1 px-2 mx-2 bg-blue-500 text-white" @click="isWantEditGuru = true">
                <font-awesome-icon icon="fa-solid fa-pen-to-square" class="pr-2"/>
                Edit
            </button>
            <button @click="confirmDelete(data.guru)" class="border rounded py-1 px-2 mx-2 bg-red-500 text-white ">
                <font-awesome-icon icon="fa-solid fa-trash" class="pr-2"/>
                Delete
            </button>
        </div>
        <div class="sm:hidden flex" v-if="canEditOrDelete">
            <button class="border rounded py-1 px-2 mr-1 bg-blue-500 text-white" @click="isWantEditGuru = true">
                <font-awesome-icon icon="fa-solid fa-pen-to-square" />
            </button>
            <button @click="confirmDelete(data.guru)" class="border rounded py-1 px-2 ml-1 bg-red-500 text-white ">
                <font-awesome-icon icon="fa-solid fa-trash" />
            </button>
        </div>
    </div>
    <div class="rounded text-center justify-center mx-2" v-if="data.guru.nomorTelepon">
        <div class="flex justify-around mt-4 pt-3 mx-2 sm:mx-auto flex-row">
            <img :src="data.guru.profileUrl" alt="" width="200" class=" rounded border">
        </div>
        <h1 class="text-3xl pt-5">{{data.guru.nama}}</h1>
        <span class="text-base py-2">{{data.guru.email}}</span>

        <TabView class="mt-2">
            <TabPanel header="Informasi Dasar">
                <div class="flex mx-auto justify-center card">
                    <div class="text-left text-base grid grid-cols-2 px-5 pt-5 pb-5 border">
                        <span class="">NIG</span>
                        <span>{{data.guru.nig}}</span>
                        <span v-if="data.guru.statusGuru === 'GTY'">NIGY</span>
                        <span v-if="data.guru.statusGuru === 'GTY'">{{ data.guru.nigy }}</span>
                        <span>NIK</span>
                        <span>{{ data.guru.nik }}</span>
                        <span class="">Kode Guru</span>
                        <span>{{data.guru.kodeGuru}}</span>
                        <span>Status Guru</span>
                        <span>{{ data.guru.statusGuru === 'GTY' ? 'Guru Tetap' : 'Guru Tidak Tetap' }}</span>
                        <span>Jenis Kelamin </span>
                        <span>{{data.guru.jenisKelamin == 'M' ? 'Laki-Laki' : 'Perempuan'}}</span>
                        <span>Tempat, Tanggal Lahir</span>
                        <span>{{data.guru.tempatLahir}}, {{ moment(data.guru.tanggalLahir).locale('id').format('DD MMMM YYYY') }}</span>
                        <span>Tanggal Masuk</span>
                        <span>{{moment(data.guru.tanggalMasuk).locale('id').format('DD MMMM YYYY')}}</span>
                        <span>Golongan Darah</span>
                        <span>{{ data.guru.golonganDarah == null ? 'Tidak ada data' : data.guru.golonganDarah}}</span>
                        <span>Status Pernikahan</span>
                        <span>{{ data.guru.statusPernikahan == 'Single' ? 'Belum Menikah': data.guru.statusPernikahan }}</span>
                        <div v-if="data.guru.jumlahAnak > 0">
                            <span>Jumlah Anak</span>
                        </div>
                        <div v-if="data.guru.jumlahAnak > 0">
                            <span>{{ data.guru.jumlahAnak }}</span>
                        </div>
                        <span>Nomor Telepon Aktif</span>
                        <span class="text-green-700"><a :href="`https://wa.me/${data.guru.countryCode}${data.guru.nomorTelepon}`"><i class="pi pi-whatsapp"/> +{{ data.guru.countryCode }}-{{ data.guru.nomorTelepon}} </a></span>
                        <span>Nama Ayah</span>
                        <span>{{ data.guru.namaAyah }}</span>
                        <span>Nama Ibu</span>
                        <span>{{ data.guru.namaIbu }}</span>
                        <span>Alamat</span>
                        <span>{{ data.guru.namaJalan }}, RT {{ data.guru.nomorRT }}/RW {{ data.guru.nomorRW }} {{ data.guru.kelurahan }}, {{ data.guru.namaKecamatan }}, {{ data.guru.namaKota }}, {{ data.guru.namaProvinsi }}, {{ data.guru.kodePos }} </span>
                        <span v-if="data.guru.linkGoogleMap">Link Google Map</span>
                        <span v-if="data.guru.linkGoogleMap"><a :href="data.guru.linkGoogleMap">Google Maps</a></span>
                        <span>Tinggal di Pesantren?</span>
                        <span>{{ data.guru.statusMukim ? 'Ya' : 'Tidak' }}</span>
                    </div>
                </div>
            </TabPanel>
            <TabPanel header="Riwayat Pendidikan">
                <DataTable :value="data.guru.riwayatPendidikan" responsiveLayout="scroll">
                    <template #empty>
                        Tidak Ada Riwayat Pendidikan.
                    </template>
                    <Column header="Jenjang Pendidikan" field="jenjangPendidikan"/>
                    <Column header="Nama Lembaga" field="namaLembaga"/>
                    <Column header="Tahun Lulus" field="tahunLulus"/>
                    <Column header="Jurusan" field="jurusan"/>
                    <Column header="Gelar" field="gelar"/>
                </DataTable>
            </TabPanel>
        </TabView>
    </div>
    <Dialog :modal="true" v-model:visible="isWantEditGuru" header="Informasi Dasar">
        <div class="flex-col justify-center">
            <div class="field">
                <span class="p-float-label mt-6">
                    <InputText id="nik" type="number" v-model="v$.nik.$model" class="w-full" placeholder="167XXXXXXXXXXXXX (16 Digit)"
                    @blur="v$.nik.$touch"/>
                    <label for="nik" class="text-base">NIK</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of v$.nik.$errors" :key="error.$uid">
                    <small v-if="(v$.nik.$error)"  class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <div class="field">
                <span class="p-float-label mt-6">
                    <InputText v-model="v$.nama.$model" type="text" class="w-full" placeholder="Muhammad Agus"
                    @blur="v$.nama.$touch"/>
                    <label class="text-base">Nama Lengkap</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of v$.nama.$errors" :key="error.$uid">
                    <small v-if="(v$.nama.$error)"  class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <div class="field">
                <span class="p-float-label mt-6">
                    <InputText v-model="v$.email.$model" type="email" class="w-full" placeholder="muhammad@gmail.com"
                    @blur="v$.email.$touch"/>
                    <label class="text-base">Email</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of v$.email.$errors" :key="error.$uid">
                    <small v-if="v$.email.$error" class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <div class="field">
                <span class="p-float-label mt-6">
                    <Dropdown v-model="v$.jenisKelamin.$model" class="w-full" :options="genderList" optionValue="value" optionLabel="name" placeholder="Jenis Kelamin"
                    @blur="v$.jenisKelamin.$touch"/>
                    <label class="text-base">Jenis Kelamin</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of v$.jenisKelamin.$errors" :key="error.$uid">
                    <small v-if="v$.jenisKelamin.$errors" class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <div class="field">
                <span class="p-float-label mt-6">
                    <InputText v-model="v$.tempatLahir.$model" type="text" class="w-full" placeholder="Pagar Alam"
                    @blur="v$.tempatLahir.$touch"></InputText>
                    <label class="text-base">Tempat Lahir</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of v$.tempatLahir.$errors" :key="error.$uid">
                    <small class="p-error" v-if="v$.tempatLahir.$errors">{{ error.$message }}</small>
                </div>
            </div>
            <div class="field">
                <span class="p-float-label mt-6">
                    <Calendar class="h-10 w-full text-sm" v-model="v$.tanggalLahir.$model" dateFormat="dd MM yy" placeholder="Masukkan tanggal lahir"
                    @blur="v$.tanggalLahir.$touch"/>
                    <label class="text-base">Tanggal Lahir</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of v$.tanggalLahir.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <div class="field">
                <span class="p-float-label mt-6">
                    <Dropdown v-model="v$.golonganDarah.$model" class="w-full" :options="golonganDarahList" optionValue="value" optionLabel="name" placeholder="Golongan Darah"
                    @blur="v$.golonganDarah.$touch"/>
                    <label class="text-base">Golongan Darah</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of v$.golonganDarah.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>

            <div class="">
                <span class="p-float-label mt-6">
                    <Dropdown v-model="v$.countryCode.$model" class="w-full"
                    :options="countryCodeList" optionValue="phone_code" optionLabel="phone_code" placeholder="+62" op
                    filter="true"
                    @blur="v$.countryCode.$touch" >
                        <template #option="country">
                            <div class="flex justify-between">
                                <span>{{ country.option.country_name }}</span>
                                <span>+{{country.option.phone_code}}</span>
                            </div>
                        </template>
                    </Dropdown>
                    <label for="">Kode Negara</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of v$.countryCode.$errors" :key="error.$uid">
                    <small class="p-error">{{error.$message}}</small>
                </div>
            </div>
            
            <div class="field">
                <span class="p-float-label mt-6">
                    <InputText v-model="v$.nomorTelepon.$model" type="number" class="w-full" placeholder="08XXXXXXXXXX"
                    @blur="v$.nomorTelepon.$touch"/>
                    <label class="text-base">Nomor Telepon Aktif</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of v$.nomorTelepon.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>

            <div class="field">
                <span class="p-float-label mt-6">
                    <Dropdown v-model="v$.statusPernikahan.$model" class="w-full" :options="statusPernikahanList" optionValue="value" optionLabel="name" placeholder="Sudah nikah belum?" @change="data.guru.statusPernikahan == 'Single' ? data.guru.jumlahAnak = 0 : ''"
                    @blur="v$.statusPernikahan.$touch"/>
                    <label class="text-base">Status Pernikahan</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of v$.statusPernikahan.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>

            <div v-if="data.guru.statusPernikahan != 'Single'" class="field">
                <span class="p-float-label mt-6" >
                    <InputText v-model="v$.jumlahAnak.$model" type="number" class="w-full" placeholder="Jumlah Anak Kandung dan Tiri"
                    @blur="v$.jumlahAnak.$touch"/>
                    <label class="text-base">Jumlah Anak</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of v$.jumlahAnak.$errors" :key="error.$uid">
                    <span class="p-error">{{ error.$message }}</span>
                </div>
            </div>
                <!-- Orang Tua -->
            <div class="field">
                <span class="p-float-label mt-6">
                    <InputText v-model="v$.namaAyah.$model" type="text" class="w-full" placeholder="Usman" @blur="v$.namaAyah.$touch"/>
                    <label class="text-base">Nama Ayah Kandung</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of v$.namaAyah.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <div class="field">
                <span class="p-float-label mt-6">
                    <InputText v-model="v$.namaIbu.$model" type="text" class="w-full" placeholder="Yasniar"
                    @blur="v$.namaIbu.$touch"/>
                    <label class="text-base">Nama Ibu Kandung</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of v$.namaIbu.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>

                <!-- Foto Profil -->
            <label for="name" class="text-sm">
                Foto Profil
            </label>

            <input class="mb-5 mt-2 block w-full text-sm text-l border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none" id="file_input"
            type="file"
            ref="file"
            @change="uploadFile"
            />

            </div>
            <template #footer>
                <button icon="pi" @click="isWantInputGuru = false" class="border rounded p-2 mx-2">
                    Batal
                </button>
                <button class="border rounded bg-ltDarkGreen text-white py-2 px-4 mx-2 hover:bg-ltLightGreen" label="Login" icon="pi" @click="toSecondDialog() " >
                    Berikutnya
                </button>
            </template>
    </Dialog>
    <Dialog :modal="true" v-model:visible="secondStepEditGuru" header="Alamat Guru">
        <div class="flex-col justify-center">

            <!-- Alamat -->
            <div class="field">
                <span class="p-float-label mt-6">
                    <InputText v-model="vAlamat$.namaJalan.$model" type="text" class="w-full" placeholder="Yasniar"
                    @blur="vAlamat$.namaJalan.$touch"/>
                    <label class="text-base">Nama Jalan</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of vAlamat$.namaJalan.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <div class="field">
                <span class="p-float-label mt-6">
                    <InputText v-model="vAlamat$.nomorRT.$model" type="number" class="w-full" placeholder="12"
                    @blur="vAlamat$.nomorRT.$touch"/>
                    <label class="text-base">Nomor RT</label>
                </span>
                <div class="flex ml-2 mt-2" v-for="error of vAlamat$.nomorRT.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <div class="field">
                <span class="p-float-label mt-6">
                    <InputText v-model="vAlamat$.nomorRW.$model" type="number" class="w-full" placeholder="12"
                    @blur="vAlamat$.nomorRW.$touch"/>
                    <label class="text-base">Nomor RW</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of vAlamat$.nomorRW.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <div class="field">
                <span class="p-float-label mt-6">
                    <Dropdown v-model="vAlamat$.provinsi.$model" class="w-full" :options="provinceList" optionValue="" optionLabel="name" placeholder="Pilih Provinsi" @change="fetchingKota(data.guru.provinsi.id)"
                    @blur="vAlamat$.provinsi.$touch"/>
                    <label class="text-base">Provinsi</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of vAlamat$.provinsi.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <div class="field">
                <span class="p-float-label mt-6">
                    <Dropdown v-model="vAlamat$.kota.$model" class="w-full" :options="kotaList" optionValue="" optionLabel="name" placeholder="Pilih Kota" @change="fetchingKecamatan(data.guru.kota.id)"
                    @blur="vAlamat$.kota.$touch"
                    />
                    <label class="text-base">Kota</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of vAlamat$.kota.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <div class="field">
                <span class="p-float-label mt-6">
                    <Dropdown v-model="vAlamat$.kecamatan.$model" class="w-full" :options="kecamatanList" optionValue="" optionLabel="name" placeholder="Pilih Kecamatan" @change="fetchingKelurahan(data.guru.kecamatan.id)"
                    @blur="vAlamat$.kecamatan.$touch"/>
                    <label class="text-base">Kecamatan</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of vAlamat$.kecamatan.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <div class="field">
                <span class="p-float-label mt-6">
                    <Dropdown v-model="vAlamat$.kelurahan.$model" class="w-full" :options="kelurahanList" optionValue="name" optionLabel="name" placeholder="Pilih Kelurahan"
                    @blur="vAlamat$.kelurahan.$touch"/>
                    <label class="text-base">Kelurahan</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of vAlamat$.kelurahan.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <div class="field">
                <span class="p-float-label mt-6">
                    <InputText v-model="vAlamat$.kodePos.$model" type="number" class="w-full" placeholder="3XXXXX"
                    @blur="vAlamat$.kodePos.$touch"
                    />
                    <label class="text-base">Kode Pos</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of vAlamat$.kodePos.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <div class="field">
                <span class="p-float-label mt-6">
                    <InputText v-model="vAlamat$.linkGoogleMap.$model" type="text" class="w-full" placeholder="maps.google"
                    @blur="vAlamat$.linkGoogleMap.$touch"/>
                    <label class="text-base">Link Google Map</label>
                </span>
                <div class="flex-ml-2 mt-1" v-for="error of vAlamat$.linkGoogleMap.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
        </div>
        <template #footer>
            <button icon="pi" @click="isWantEditGuru = true; secondStepEditGuru = false" class="border rounded p-2 mx-2">
                Kembali
            </button>
            <button class="border rounded bg-ltDarkGreen text-white py-2 px-4 mx-2 hover:bg-ltLightGreen" label="Login" icon="pi" @click="toThirdDialog()" >
                Berikutnya
            </button>
        </template>
    </Dialog>
    <Dialog :modal="true" v-model:visible="thirdStepEditGuru" header="Data Guru">
        <div class="flex-col justify-center">
            <div class="field">
                <span class="p-float-label mt-6">
                    <Dropdown v-model="vDataGuru$.statusGuru.$model" class="w-full" :options="statusGuru" optionValue="value" optionLabel="name" placeholder="Status Guru" :disabled="$store.state.role === 'ADMIN' ? false : true"
                    @blur="vDataGuru$.statusGuru.$touch"/>
                    <label class="text-base">Status Guru</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of vDataGuru$.statusGuru.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <div class="field">
                <span class="p-float-label mt-6">
                    <InputText v-model="vDataGuru$.nig.$model" type="number" class="w-full" placeholder="01XXXXXXXXXXXXXX(16 Digit)"
                    @blur="vDataGuru$.nig.$touch"/>
                    <label class="text-base">NIG</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of vDataGuru$.nig.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <div class="field" v-if="data.guru.statusGuru === 'GTY'">
                <span class="p-float-label mt-6"  >
                    <InputText v-model="vDataGuru$.nigy.$model" type="number" class="w-full" placeholder="muhammad@gmail.com"
                    @blur="vDataGuru$.nigy.$touch"/>
                    <label class="text-base">NIGY</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of vDataGuru$.nigy.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <div class="field">
                <span class="p-float-label mt-6">
                    <Calendar class="h-10 w-full text-sm" v-model="vDataGuru$.tanggalMasuk.$model" dateFormat="dd MM yy" placeholder="Masukkan tanggal masuk"
                    @blur="vDataGuru$.tanggalMasuk.$touch"/>
                    <label class="text-base">Tanggal Masuk</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of vDataGuru$.tanggalMasuk.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <div class="field">
                <div class="flex justify-between mt-6">
                    <label class="text-base">Mukim di Pesantren?</label>
                    <InputSwitch v-model="vDataGuru$.statusMukim.$model"/>
                </div>
                <div class="flex ml-2 mt-1" v-for="error of vDataGuru$.statusMukim.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
        </div>
        <template #footer>
            <button icon="pi" @click="secondStepEditGuru = true; thirdStepEditGuru =false;" class="border rounded p-2 mx-2">
                Kembali
            </button>
            <button class="border rounded bg-ltDarkGreen text-white py-2 px-4 mx-2 hover:bg-ltLightGreen" label="Login" icon="pi" @click="updateGuru()" >
                Simpan
            </button>
        </template>
    </Dialog>

    <Dialog :modal="true" v-model:visible="isWantToEditRiwayatPendidikan" header="Riwayat Pendidikan">
        <div class="flex-col justify-center card">
            <DataTable :value="riwayatPendidikanArray" responsiveLayout="scroll">
                <template #header>
                    <div class="flex justify-between">
                        <h5>
                            Riwayat Pendidikan
                        </h5>
                        <button class="bg-ltDarkGreen text-white rounded border p-1 px-2 hover:bg-ltLightGreen" @click="toAddRiwayatPendidikan()">
                            <i class="pi pi-plus"></i>
                        </button>
                    </div>
                </template>
                <template #empty>
                    Belum Ada Riwayat Pendidikan silahkan di input terlebih dahulu.
                </template>
                <Column header="Jenjang Pendidikan" field="jenjangPendidikan"/>
                <Column header="Nama Lembaga" field="namaLembaga"/>
                <Column header="Tahun Lulus" field="tahunLulus"/>
                <Column header="Jurusan" field="jurusan"/>
                <Column header="Gelar" field="gelar"/>
                <Column header="Action">
                    <template #body="{index}">
                        <button @click="deleteRiwayatPendidikan(index)" class="border rounded py-1 px-2 ml-1 bg-red-500 text-white ">
                            <font-awesome-icon icon="fa-solid fa-trash" />
                        </button>
                    </template>
                </Column>
            </DataTable>
        </div>
        <!-- Riwayat Pendidikan -->
        <Dialog :modal="true" v-model:visible="isWantToAddRiwayatPendidikan" header="Tambah Riwayat Pendidikan">
            <!-- Jenjang Pendidikan - DropDown -->
            <div class="field">
                <span class="p-float-label mt-6">
                    <Dropdown v-model="vRiwayatPendidikan$.jenjangPendidikan.$model" class="w-full" :options="pendidikanList" optionValue="value" optionLabel="name" placeholder="SLTA kah? "
                    @blur="vRiwayatPendidikan$.jenjangPendidikan.$touch"/>
                    <label class="text-base">Jenjang Pendidikan</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of vRiwayatPendidikan$.jenjangPendidikan.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <!-- Nama Lembaga InputText-->
            <div class="field">
                <span class="p-float-label mt-6">
                    <InputText v-model="vRiwayatPendidikan$.namaLembaga.$model" type="text" class="w-full" placeholder="Lan Tabur"
                    @blur="vRiwayatPendidikan$.namaLembaga.$touch"/>
                    <label class="text-base">Nama Lembaga</label>
                </span>
                <div class="flex ml-2 mt1-" v-for="error of vRiwayatPendidikan$.namaLembaga.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <!-- Tahun Lulus Number-->
            <div class="field">
                <span class="p-float-label mt-6">
                    <InputText v-model="vRiwayatPendidikan$.tahunLulus.$model" type="number" class="w-full" placeholder="19XX"
                    @blur="vRiwayatPendidikan$.tahunLulus.$touch"/>
                    <label class="text-base">Tahun Lulus</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of vRiwayatPendidikan$.tahunLulus.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <!-- Jurusan Input Text -->
            <div class="field">
                <span class="p-float-label mt-6">
                    <InputText v-model="vRiwayatPendidikan$.jurusan.$model" type="text" class="w-full" placeholder="Kosongkan jika tidak ada Jurusan"
                    @blur="vRiwayatPendidikan$.jurusan.$touch"/>
                    <label class="text-base">Jurusan</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of vRiwayatPendidikan$.jurusan.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <!-- Gelar Input Text -->
            <div class="field">
                <span class="p-float-label mt-6">
                    <InputText v-model="vRiwayatPendidikan$.gelar.$model" type="text" class="w-full" placeholder="Kosongkan jika tidak ada gelar"
                    @blur="vRiwayatPendidikan$.gelar.$touch"/>
                    <label class="text-base">Gelar</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of vRiwayatPendidikan$.gelar.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <template #footer>
                <button icon="pi" @click="isWantToAddRiwayatPendidikan = false" class="border rounded p-2 mx-2">
                    Kembali
                </button>
                <button class="border rounded bg-ltDarkGreen text-white py-2 px-4 mx-2 hover:bg-ltLightGreen" label="Login" icon="pi" @click="saveRiwayatPendidikan()" >
                    Berikutnya
                </button>
            </template>
        </Dialog>
        <template #footer>
            <button icon="pi" @click="thirdStepInputGuru = false; secondStepInputGuru = true" class="border rounded p-2 mx-2">
                Kembali
            </button>
            <button class="border rounded bg-ltDarkGreen text-white py-2 px-4 mx-2 hover:bg-ltLightGreen" label="Login" icon="pi" @click="toFourthDialog()" >
                Berikutnya
            </button>
        </template>
    </Dialog>
</template>

<script setup>
import NavbarVue from '@/components/Navbar.vue';
import axios from 'axios';
import { onBeforeMount, reactive, ref  } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useConfirm } from 'primevue/useconfirm'
import { useToast } from 'primevue/usetoast'
import moment from 'moment';
import { isArray } from '@vue/shared';
import { helpers, minLength, maxLength, email, numeric, url } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { useStore } from 'vuex';
import { countryCode } from '@/utils/coutryCode';

const store = useStore()
const canEditOrDelete = ref(false)

let v$, vAlamat$, vDataGuru$

onBeforeMount( async () => {

    if (store.state.role === 'ADMIN' || store.state.role === 'TU_PESANTREN') {
        console.log(store.state.role)
        canEditOrDelete.value = true
    }

    await axios.get(`/v2/guru/nigs/${route.params.nigs}`)
    .then(res => {
        console.log(res.data)
        data.guru = res.data.data
        data.guru.tanggalLahir = new Date(data.guru.tanggalLahir)
        data.guru.tanggalMasuk = new Date(data.guru.tanggalMasuk)
        data.guru.countryCode = data.guru.nomorTelepon.split('-')[0]
        data.guru.nomorTelepon = data.guru.nomorTelepon.split('-')[1]
    })
    .catch(err => {
        console.log(err)
    })

    // Fetching Provinsi 
    await fetch('https://www.emsifa.com/api-wilayah-indonesia/api/provinces.json')
    .then(response => response.json())
    .then(provinces => {
        provinceList.value = provinces
        data.guru.namaProvinsi = data.guru.provinsi
        data.guru.provinsi = provinceList.value.find(object => object.name === data.guru.provinsi)
    });

    // Fetching Kota
    await fetch(`https://www.emsifa.com/api-wilayah-indonesia/api/regencies/${data.guru.provinsi.id}.json`)
    .then(response => response.json())
    .then(regencies => {
        kotaList.value = regencies
        data.guru.namaKota = data.guru.kota
        data.guru.kota = kotaList.value.find(object => object.name === data.guru.kota)
    });

    // Fetching Kecamatan
    await fetch(`https://www.emsifa.com/api-wilayah-indonesia/api/districts/${data.guru.kota.id}.json`)
    .then(response => response.json())
    .then(districts => {
        kecamatanList.value = districts
        data.guru.namaKecamatan = data.guru.kecamatan
        data.guru.kecamatan = kecamatanList.value.find(object => object.name === data.guru.kecamatan)
    });

    // Fetching Kelurahan
    await fetch(`https://www.emsifa.com/api-wilayah-indonesia/api/villages/${data.guru.kecamatan.id}.json`)
    .then(response => response.json())
    .then(villages => {
        kelurahanList.value = villages
        // data.guru.kelurahan = kelurahanList.value.find(object => object.name === data.guru.kelurahan)
    });

    // Validate data from Backend 
    const v = useVuelidate(updateGuruRules, data.guru)
    v$ = v
    const vAlamat = useVuelidate(updateAlamatGuruRules, data.guru)
    vAlamat$  = vAlamat
    const vDataGuru = useVuelidate(updateDataGuruRules, data.guru)
    vDataGuru$ = vDataGuru
})

const route = useRoute()
const router = useRouter()
const confirm = useConfirm()
const toast = useToast()

const countryCodeList = ref(countryCode)


const data = reactive({
    guru: {}
})

const provinceList = ref(null)
const kotaList = ref(null)
const kecamatanList = ref(null)
const kelurahanList = ref(null)

const statusGuru = ref([
    {name: 'Guru Magang', value: 'Magang'},
    {name: 'Guru Tidak Tetap', value: 'GTT'},
    {name: 'Guru Tetap Yayasan', value: 'GTY'}
])

const genderList = ref([
    {name: 'Laki-Laki', value: 'M'},
    {name: 'Perempuan', value: 'F'},
])

const golonganDarahList = ref([
    {name: 'A', value: 'A'},
    {name: 'B', value: 'B'},
    {name: 'O', value: 'O'},
    {name: 'AB', value: 'AB'},
])

const statusPernikahanList = ref([
    {name: 'Menikah', value: 'Menikah'},
    {name: 'Belum Menikah', value: 'Single'},
    {name: 'Janda', value: 'Janda'},
    {name: 'Duda', value: 'Duda'},
])

const fetchingKota = (id) => {
    fetch(`https://www.emsifa.com/api-wilayah-indonesia/api/regencies/${id}.json`)
    .then(response => response.json())
    .then(regencies => {
        kotaList.value = regencies
        data.guru.kota = null
    });
}

const fetchingKecamatan = (id) => {
    fetch(`https://www.emsifa.com/api-wilayah-indonesia/api/districts/${id}.json`)
    .then(response => response.json())
    .then(districts => {
        kecamatanList.value = districts
        data.guru.kecamatan = null
    });
}

const fetchingKelurahan = (id) => {
    fetch(`https://www.emsifa.com/api-wilayah-indonesia/api/villages/${id}.json`)
    .then(response => response.json())
    .then(villages => {
        kelurahanList.value = villages
        data.guru.kelurahan = null    
    });
}

const confirmDelete = (guru) => {
    console.log(guru)
    confirm.require({
        message: `Apakah kamu yakin ingin menghapus data ${guru.nama}?`,
        header: 'Delete Confirmation',
        icon: 'pi pi-info-circle',
        acceptClass: 'p-button-danger',
        accept: async () => {
            await axios.delete(`/v2/guru/nigs/${guru.nigs}`)
            .then(res => {
                console.log(res.data)
                toast.add({severity:'info', summary:'Confirmed', detail:'Data telah dihapus', life: 3000});
                router.push('/gurus')
            })
            .catch(err => {
                console.log(err);
                toast.add({severity:'error', summary:'Rejected', detail:'Error Occours', life: 3000});
            })
        },
        reject: () => {
            toast.add({severity:'error', summary:'Rejected', detail:'Data gagal dihapus', life: 3000});
        }
    });
}

const isWantEditGuru= ref(false)
const secondStepEditGuru = ref(false)
const thirdStepEditGuru = ref(false )


const toSecondDialog = async () => {
    const isFormCorrect = await v$.value.$validate()
    console.log(isFormCorrect)
    if (isFormCorrect) {
        secondStepEditGuru.value = true;
        isWantEditGuru.value = false;
    }
}

const toThirdDialog = async() => {
    const isFormCorrect = await vAlamat$.value.$validate()
    if (isFormCorrect) {
        thirdStepEditGuru.value = true
        secondStepEditGuru.value = false
    }
}

const updateGuruRules = {
    nik : {
        minlength: helpers.withMessage('Panjang NIK harus 16 Angka', minLength(16)),
        maxLength: helpers.withMessage('Panjang NIK tidak boleh lebih dari 16 Angka', maxLength(16))
    },
    nama: {
        alpha: helpers.withMessage('Nama Lengkap hanya boleh terdiri dari huruf', helpers.regex(/^[a-zA-Z ]*$/))
    },
    email: {
        email: helpers.withMessage('Format email tidak valid', email)
    },
    jenisKelamin: {
    },
    tempatLahir: {
        alpha: helpers.withMessage('Tempat lahir hanya boleh terdiri dari huruf', helpers.regex(/^[a-zA-Z ]*$/))
    },
    tanggalLahir: {
    },
    golonganDarah: { },
    countryCode: {},
    nomorTelepon: {
        numeric: helpers.withMessage('Nomor telepon hanya boleh terdiri dari angka', numeric),
        // min: helpers.withMessage('Panjang Nomor Telepon minimal 11 angka', minLength(11)),
        // max: helpers.withMessage('Panjang Nomor Telepon maksimal 13 angka', maxLength(13))
    },
    statusPernikahan: {
    },
    jumlahAnak: {
    },
    namaAyah: {
        alpha: helpers.withMessage('Nama Ayah Kandung hanya boleh terdiri dari huruf ', helpers.regex(/^[a-zA-Z ]*$/))
    },
    namaIbu: {
        alpha: helpers.withMessage('Nama Ibu hanya boleh terdiri dari angka', helpers.regex(/^[a-zA-Z ]*$/))
    },
}

const updateAlamatGuruRules = {
    namaJalan: {
    },
    nomorRT: {
        numeric: helpers.withMessage('Nomor RT harus terdiri dari Angka', numeric)
    },
    nomorRW: {
        numeric: helpers.withMessage('Nomor RW harus terdiri dari Angka', numeric)
    },
    provinsi: {
    },
    kota: {

    },
    kecamatan: {
    },
    kelurahan: {
    },
    kodePos: {
        numeric: helpers.withMessage('Nomor Kode Pos harus terdiri dari angka', numeric),
        minLength: helpers.withMessage('Nomor Kode Pos minimal terdiri dari 5 angka', minLength(5))
    },
    linkGoogleMap: {
        url: helpers.withMessage('Link Google Map tidak valid, mohon input ulang', url)
    }
}

const updateDataGuruRules = {
    nig: {
        minLength: helpers.withMessage('NIG harus terdiri dari 14 digit angka', minLength(14)),
        maxLength: helpers.withMessage('NIG harus terdiri dari 14 digit angka', maxLength(14))
    },
    statusGuru: {
    },
    nigy: {
        minLength: helpers.withMessage('NIGY harus terdiri dari 14 digit angka', minLength(14)),
        maxLength: helpers.withMessage('NIGY harus terdiri dari 14 digit angka', maxLength(14))
    },
    tanggalMasuk: {
    },
    statusMukim: {
    }
}

const file = ref(null)

const uploadFile = async () => {
	// console.log(file.value)
	console.log(file.value.files)
	data.guru.image = file.value.files[0]
}

const updateGuru = async() => {
    const isFormCorrect = await vDataGuru$.value.$validate()
    
    if(isFormCorrect) {
        // Post data 
        const formData = new FormData()

        if(data.guru.nigy) {
            formData.append('nigy', data.guru.nigy)
        }

        if (data.guru.golonganDarah) {
            formData.append('golonganDarah', data.guru.golonganDarah)
        }

        if (data.guru.linkGoogleMap) {
            formData.append('linkGoogleMap', data.guru.linkGoogleMap)
        }


        formData.append('image', data.guru.image)
        formData.append('email', data.guru.email)
        formData.append('jenisKelamin', data.guru.jenisKelamin)
        formData.append('jumlahAnak', data.guru.jumlahAnak)
        formData.append('kecamatan', data.guru.kecamatan.name)
        formData.append('kelurahan', data.guru.kelurahan)
        formData.append('kodePos', data.guru.kodePos)
        formData.append('kota', data.guru.kota.name)
        formData.append('nama', data.guru.nama)
        formData.append('namaAyah', data.guru.namaAyah)
        formData.append('namaIbu', data.guru.namaIbu)
        formData.append('namaJalan', data.guru.namaJalan)
        formData.append('nig', data.guru.nig)
        formData.append('nik', data.guru.nik)
        formData.append('nomorRT', data.guru.nomorRT)
        formData.append('nomorRW', data.guru.nomorRW)
        formData.append('nomorTelepon', `${data.guru.countryCode}-${data.guru.nomorTelepon}`)
        formData.append('provinsi', data.guru.provinsi.name)
        formData.append('statusGuru', data.guru.statusGuru)
        formData.append('statusMukim', data.guru.statusMukim)
        formData.append('statusPernikahan', data.guru.statusPernikahan)
        formData.append('tanggalLahir', moment(data.guru.tanggalLahir).format('YYYY-MM-DD'))
        formData.append('tanggalMasuk', moment(data.guru.tanggalMasuk).format('YYYY-MM-DD'))
        formData.append('tempatLahir', data.guru.tempatLahir)

        console.log(formData)

        await axios.patch(`/v2/guru/nigs/${route.params.nigs}`, formData)
        .then(async res => {
            console.log(res)
            let severity = 'error'
            let summary = 'Data gagal di Update'
            if (res.data.success) {
                severity = 'success'
                summary = 'Data berhasil di Update'
            }
            toast.add({
                severity: severity,
                summary: summary,
                detail: res.data.message,
                life: 3000
            })
            thirdStepEditGuru.value = false
            data.guru = res.data.data
            data.guru.tanggalLahir = new Date(data.guru.tanggalLahir)
            data.guru.tanggalMasuk = new Date(data.guru.tanggalMasuk)

            // Fetching Provinsi 
            await fetch('https://www.emsifa.com/api-wilayah-indonesia/api/provinces.json')
            .then(response => response.json())
            .then(provinces => {
                provinceList.value = provinces
                data.guru.namaProvinsi = data.guru.provinsi
                data.guru.provinsi = provinceList.value.find(object => object.name === data.guru.provinsi)
            });

            // Fetching Kota
            await fetch(`https://www.emsifa.com/api-wilayah-indonesia/api/regencies/${data.guru.provinsi.id}.json`)
            .then(response => response.json())
            .then(regencies => {
                kotaList.value = regencies
                data.guru.namaKota = data.guru.kota
                data.guru.kota = kotaList.value.find(object => object.name === data.guru.kota)
            });

            // Fetching Kecamatan
            await fetch(`https://www.emsifa.com/api-wilayah-indonesia/api/districts/${data.guru.kota.id}.json`)
            .then(response => response.json())
            .then(districts => {
                kecamatanList.value = districts
                data.guru.namaKecamatan = data.guru.kecamatan
                data.guru.kecamatan = kecamatanList.value.find(object => object.name === data.guru.kecamatan)
            });

            // Fetching Kelurahan
            await fetch(`https://www.emsifa.com/api-wilayah-indonesia/api/villages/${data.guru.kecamatan.id}.json`)
            .then(response => response.json())
            .then(villages => {
                kelurahanList.value = villages
                // data.guru.kelurahan = kelurahanList.value.find(object => object.name === data.guru.kelurahan)
            });
        })
        .catch (err => {
            if (isArray(err.response.data.error)) {
                const errorList = err.response.data.error
                // console.log(errorList)
                errorList.forEach(data => {
                    toast.add({
                        severity : 'error',
                        summary : "Input Data Murid Gagal",
                        detail : data.msg,
                        life: 3000
                    })
                })
            }
            else {
                toast.add({
                    severity : 'error',
                    summary : "Input Data Murid Gagal",
                    detail : err.response.data.msg,
                    life: 3000
                })
            }
            console.log(err.response.data.error)
        })
    }
}

</script>