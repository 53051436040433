<template>
    <NavbarVue/>
    <Toast/>
    <ConfirmDialog :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '50vw'}"/>
    <BlockUI :blocked="isLoading"/>
    <div class="card mx-5">
        <div class="card px-2">
            <TabView class="">
                <TabPanel v-if="store.state.role === 'ADMIN'">
                    <template #header>
                        <i class="pi pi-fw pi-users"></i>
                        <span>User</span>
                    </template>
                    <div class="card">
                        <DataTable responsive-layout="scroll" :value="userList"
                        :paginator="true" :rows="50"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[10,25,50]"
                        currentPageReportTemplate="Menampilkan halaman {first} dari {last} halaman dari {totalRecords} seluruh data"
                        >
                            <template #header>
                                User List
                            </template>
                            <!-- <Column field="id" header="No"/> -->
                            <Column field="username" header="Username"/>
                            <Column header="Last Login" style="min-width: 12rem;">
                                <template #body="{data}"> 
                                    {{ moment(data.lastLogin).locale('id').format('dddd, D MMMM YYYY, HH:mm:ss') }}
                                </template>
                            </Column>
                            <Column header="Default">
                                <template #body="{data}">
                                    <div class="w-full bg-red-600 p-1 rounded text-white text-center" v-if="data.isPasswordDefault">
                                        <span>DEFAULT</span>
                                    </div>
                                    <div class="w-full bg-green-600 p-1 rounded text-white text-center" v-else>
                                        <span>SECURE</span>
                                    </div>
                                </template>
                            </Column>
                            <Column header="Email Sent">
                                <template #body="{data}">
                                    <div v-if="data.isEmailSent === false" class="bg-red-600 p-1 rounded text-white text-center">
                                        <button @click="resendEmail(data.id)">Not Sent.</button>
                                    </div>
                                    <div v-else class="bg-green-600 p-1 rounded text-white text-center">
                                        <span >Sent</span>
                                    </div>
                                </template>
                            </Column>
                            <Column header="Deleted">
                                <template #body="{data}">
                                    <!-- {{ data.deleted }} -->
                                    <div v-if="data.deleted" class="bg-red-600 p-1 rounded text-white text-center">
                                        <span >DELETED</span>
                                    </div>
                                    <div v-else class="bg-green-600 p-1 rounded text-white text-center">
                                        <span >ACTIVE</span>
                                    </div>
                                </template>
                            </Column>
                            <Column header="Action" style="min-width: 12rem;">
                                <template #body="{data}">
                                    <button @click="resetPassword(data.username)" class="border button bg-red-900 rounded text-white p-1">Reset Password</button>
                                    <button @click="resendEmail(data.id)" class="border button bg-green-600 text-white rounded p-1">Resend Email</button>
                                </template>
                            </Column>
                        </DataTable>
                    </div>
                </TabPanel>
                <TabPanel>
                    <template #header>
                        <i class="pi pi-fw pi-history"></i>
                        <span>History</span>
                    </template>
                    <div class="card">
                        <DataTable responsive-layout="scroll" :value="eventList" :paginator="true" :rows="50"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[10,25,50]"
                        currentPageReportTemplate="Menampilkan halaman {first} dari {last} halaman dari {totalRecords} seluruh data"
                        >
                            <template #header>
                                <div class="flex justify-between">
                                    <div class="field col-12 md:col-4">
                                        <span class="p-float-label mt-6 sm:m-6">
                                            <InputNumber id="inputnumber" max="100" min="0" class="w-full" v-model="price" />
                                            <label for="inputnumber" class="text-base">Harga per Event</label>
                                        </span>
                                    </div>
                                    <span>Total {{ new Intl.NumberFormat('id', {style: 'currency', currency: 'IDR'}).format(data.unpaidEvent*price) }} Rupiah </span>
                                </div>
                            </template>
                            <Column field="target" header="Target"/>
                            <Column field="targetId" header="Target Id"/>
                            <Column header="Waktu">
                                <template #body="{data}">
                                    {{ moment(data.time).locale('id').format('ddd, DD-MM-YY H:mm:ss') }}
                                </template>
                            </Column>
                            <Column field="type" header="Tipe Event"/>
                            <Column field="actor.username" header="Username"/>
                            <Column field="paid" header="Paid" />
                        </DataTable>
                    </div>
                </TabPanel>
                <TabPanel v-if="store.state.role === 'ADMIN'">
                    <template #header>
                        <i class="pi pi-fw pi-user-edit"></i>
                        <span>Roles</span>
                    </template>
                    <!-- Table Role Sederhana aja  -->
                    <div class="card">
                        <DataTable responsiveLayout="scroll" :value="allUserRole" :paginator="true" :rows="50">
                            <template #header>
                                <div class="table-header text-white flex justify-end">
                                    <button class="bg-ltDarkGreen rounded border p-1 px-2 hover:bg-ltLightGreen" @click="isAddNewRole = true"> 
                                        <i class="pi pi-plus"></i>
                                    </button>
                                </div>
                            </template>
                            <Column field="user.username" header="Username"></Column>
                            <Column field="role" header="Role"></Column>
                            <Column header="Action">
                                <template #body="{data}">
                                    <button @click="deleteRole(data)" class="border rounded py-1 px-2 ml-1 bg-red-500 text-white ">
                                        <font-awesome-icon icon="fa-solid fa-trash" />
                                    </button>
                                </template>
                            </Column>
                        </DataTable>
                    </div>
                    <Dialog header="Assign new role" v-model:visible="isAddNewRole" :modal="true" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '50vw'}">
                        <div class="card">
                            <div class="flex flex-col justify-center">
                                <div class="w-full">
                                    <Dropdown v-model="form.username" class="w-full" optionValue="username" :options="data.users" optionLabel="username" placeholder="Pick Username" />
                                </div>
                                <div class="w-full mt-2">
                                    <Dropdown v-model="form.role" class="w-full" optionValue="nama" :options="data.availableRole" optionLabel="nama" placeholder="Pick Role to Append"/>
                                </div>
                            </div>
                        </div>
                        <template #footer>
                            <button icon="pi" @click="closeModal" class="border rounded p-2 mx-2">
                                Cancel
                            </button>
                            <button class="border rounded bg-ltDarkGreen text-white py-2 px-4 mx-2 hover:bg-ltLightGreen" label="Login" icon="pi" @click="addRole()" >
                                Simpan
                            </button>
                        </template>
                    </Dialog>
                </TabPanel>
            </TabView>
        </div>
    </div>
    <div class="container mx-auto">
        <!-- <span>{{data.users}}</span> -->
    </div>
</template>

<script setup>
import NavbarVue from '@/components/Navbar.vue';
import { ref, onBeforeMount, reactive } from 'vue';
import axios from 'axios';
// import { useStore } from 'vuex';
// import { useRouter } from 'vue-router';
import { useToast } from 'primevue/usetoast';
import moment from 'moment';
import { useConfirm } from 'primevue/useconfirm';
import { useStore } from 'vuex';

const isLoading = ref(false)
const store = useStore()
const price = ref(1000)

onBeforeMount(async () => {
    if (store.state.role === 'ADMIN') {
        await axios.get('/v2/user')
        .then(res => {
            console.log(res.data.data)
            data.users = res.data.data
            userList.value = res.data.data
        })
    
        // Patching all Username with signed role
        await axios.get('/v2/role')
        .then(res => {
            console.log(res.data.data)
            data.availableRole = res.data.data
        })
    
        // Patching All Role in Enum
        axios.get('/v2/role/all')
        .then(res => {
            console.log(res.data.data)
            allUserRole.value = res.data.data
        })
    }

    // Patching Event History
    await axios.get('/v2/event')
    .then(res => {  
        eventList.value = res.data.data
        data.paidEvent = res.data.paid 
        data.unpaidEvent = res.data.unpaid
        console.log(res.data.data)
    })

}) 

const data = reactive({
    users : {} ,
    availableRole : {},
    allUserRole : {},
    eventHistory: {},
    unpaidEvent: null,
    paidEvent: null,
})

const eventList = ref(null)


const allUserRole  = ref([])

// const store = useStore()
// const router = useRouter()
const isAddNewRole = ref(false)

// const availableRole = ref(null)

const toast = useToast()
const confirm = useConfirm()

const form = reactive({
    username : '',
    role : '',
})

const resetPassword = async(username) => {
    await axios.patch(`/v2/auth/reset/${username}`)
    .then(res => {
        toast.add({
            severity: 'success',
            summary: 'Reset Password berhasil',
            detail: res.data.message,
            life: 3000
        })
    })
    .catch(err => {
        console.log(err)
        if (err.response.data.success === false) {
            toast.add({
                severity: 'error',
                summary: 'Reset Password gagal',
                detail: err.response.data.message,
                life: 3000
            })
        }
    })
}

const addRole = async () => {
    await axios.post(`v2/role/addRole/${form.username}`, form)
    .then(async res => {
        if (res.data.success) {
            isAddNewRole.value = false
            await axios.get('/v2/role')
            .then(res => {
                console.log(res.data.data)
                data.availableRole = res.data.data
            })
            toast.add({
                severity : 'success',
                summary : `Role berhasil ditambahkan`,
                detail : res.data.message,
                life: 3000
            })
        }
    })
    .catch (err => {
        console.log(err)
        if (err.response.status === 409) {
            toast.add({
                severity : 'error',
                summary : `Role gagal ditambahkan`,
                detail : err.response.data.message,
                life: 3000
            })
        }
    })
}

const deleteRole = async(data) => {
    confirm.require({
        message: `Apakah kami yakin ingin menghapus role ${data.role} dari ${data.user.username}?`,
        header: 'Konfirmasi Penghapusan',
        icon: 'pi pi-info-circle',
        acceptClass: 'p-button-danger',
        accept: async() => {
            await axios.delete(`v2/role/delete/${data.user.username}/${data.role}`)
            .then(async res => {
                if (res.data.success) {
                    // isAddNewRole.value = false
                    await axios.get('/v2/role/all')
                    .then(res => {
                        console.log(res.data.data)
                        allUserRole.value = res.data.data
                    })
                    toast.add({
                        severity : 'success',
                        summary : `Role berhasil dihapus`,
                        detail : res.data.message,
                        life: 3000
                    })
                }
            })
            .catch (err => {
                console.log(err)
                if (err.response.status === 500) {
                    toast.add({
                        severity : 'error',
                        summary : `Role gagal dihapus`,
                        detail : err.response.data.message,
                        life: 3000
                    })
                }
            })
        }   

    })
}

const resendEmail = async (id) => {
    isLoading.value = true
    await axios.patch(`/v2/user/mail/${id}`)
    .then(res => {
        toast.add({
            severity: 'success',
            summary: 'Email berhasil dikirim',
            detail: res.data.message,
            life: 3000
        })
    })
    .catch(err => {
        toast.add({
            severity: 'error',
            summary: 'Email gagal terkirim',
            detail: err.response.data.message,
            life: 3000,
        })
    })
    .finally(isLoading.value = false)
}

const userList = ref(null)

</script>

<style scoped lang="scss">
::v-deep(.tabmenudemo-content) {
    padding: 2rem 1rem;
}
</style>