<template>
    <NavbarVue/>
    <ConfirmDialog :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '50vw'}"></ConfirmDialog>
    <Toast/>
    <div class="mt-2 p-2">
        <div class="flex justify-between px-2">
            <h1 class="text-2xl">Database Quran</h1>
        </div>
        <div class="px-2 sm:px-4 mt-4">
            <TabView>
                <TabPanel>
                    <template #header> 
                        Quran Madina
                    </template>
                    <div class="card shadow">
                        <DataTable :value="dataQuranMadina"
                        :lazy="true" 
                        responsive-layout="scroll" paginator="true" :rows="10"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[10,25,50]"
                        currentPageReportTemplate="Menampilkan halaman {first} dari {last} halaman dari {totalRecords} seluruh data"
                        >
                            <template #empty>
                                Data Kosong.
                            </template>
                            <ColumnGroup type="header">
                                <Row>
                                    <Column header="Id" rowspan="2"/>
                                    <Column header="Surah" colspan="3"/>
                                    <Column header="Baris" colspan="2"/>
                                    <Column field="ayat" header="Ayat" rowspan="2"/>
                                    <Column field="juz" header="Juz" rowspan="2"/>
                                </Row>
                                <Row>
                                    <Column field="nomorSurah" header="Nomor"/>
                                    <Column field="namaSurahAr"  header="Arab"/>
                                    <Column field="namaSurahEn" header="English"/>
                                    <Column field="mulaiBaris" header="Mulai"/>
                                    <Column field="akhirBaris" header="Akhir"/>
                                </Row>
                            </ColumnGroup>
                        </DataTable>
                    </div>
                </TabPanel>
                <TabPanel >
                    <template #header>
                        Seluruh data
                    </template>
                    <div class="card shadow">
                        <DataTable :value="dataQuran" v-model:expanded-rows="expandedRows" 
                        responsive-layout="scroll" paginator="true" :rows="10"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[10,25,50]"
                        currentPageReportTemplate="Menampilkan halaman {first} dari {last} halaman dari {totalRecords} seluruh data"
                        >
                            <Column :expander="true" header-style="width:3rem"/>
                            <Column header="Halaman" field="halaman"/>
                            <Column header="Juz">
                                <template #body="{data}">
                                    {{data.data[0].juz}}
                                </template>
                            </Column>
                            <template #expansion="listBaris">
                                <DataTable :value="listBaris.data.data" responsive-layout="scroll">
                                    <template #loading> Memuat Data ...</template> 
                                    <Column header="Surah">
                                        <template #body="{data}">
                                            {{ surahList[data.surah-1].indo }}
                                        </template>
                                    </Column>
                                    <Column field="ayat" header="Ayat" sortable/>
                                    <Column field="baris" header="Baris" sortable/>
                                </DataTable>
                            </template>
                        </DataTable>
                    </div>
                </TabPanel>
                <TabPanel >
                    <template #header>
                        Your Data
                    </template>
                    <div class="card shadow">
                        <DataTable :value="userQuranData" v-model:expanded-rows="expandedRows" 
                        responsive-layout="scroll" paginator="true" :rows="10"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[10,25,50]"
                        currentPageReportTemplate="Menampilkan halaman {first} dari {last} halaman dari {totalRecords} seluruh data"
                        >
                            <template #header>
                                <div class="flex justify-end">
                                    <button class="rounded border bg-ltDarkGreen px-2 py-1 text-white" @click="isWantInputQuran = true">
                                        <i class="pi pi-plus"/>
                                    </button>
                                </div>
                            </template>
                            <Column :expander="true" header-style="width:3rem"/>
                            <Column header="Halaman" field="halaman"/>
                            <Column header="Juz">
                                <template #body="{data}">
                                    {{data.data[0].juz}}
                                </template>
                            </Column>
                            <Column header="Action">
                                <template #body="{data}"> 
                                    <div class="flex">
                                        <button class="border rounded py-1 px-2 mr-1 bg-blue-500 text-white" @click="editOnePages(data.data)">
                                            <font-awesome-icon icon="fa-solid fa-pen-to-square" />
                                        </button>
                                        <button class="border rounded py-1 px-2 ml-1 bg-red-500 text-white " @click="deleteQuranOnePage(data.halaman)">
                                            <font-awesome-icon icon="fa-solid fa-trash" />
                                        </button>
                                    </div>
                                </template>
                            </Column>
                            <template #expansion="listBaris">
                                <DataTable :value="listBaris.data.data" responsive-layout="scroll">
                                    <template #loading> Memuat Data ...</template> 
                                    <Column header="Surah">
                                        <template #body="{data}">
                                            {{ surahList[data.surah-1].indo }}
                                        </template>
                                    </Column>
                                    <Column field="ayat" header="Ayat" sortable/>
                                    <Column field="juz" header="Juz"/>
                                    <Column field="baris" header="Baris" sortable/>
                                    <Column header="Action">
                                        <template #body="{data}">
                                            <div class="flex">
                                                <button class="border rounded py-1 px-2 mr-1 bg-blue-500 text-white"  @click="editOneBaris(data)" >
                                                    <font-awesome-icon icon="fa-solid fa-pen-to-square" />
                                                </button>
                                                <button class="border rounded py-1 px-2 ml-1 bg-red-500 text-white " @click="deleteQuranOneBaris(data)">
                                                    <font-awesome-icon icon="fa-solid fa-trash" />
                                                </button>
                                            </div>
                                        </template>
                                    </Column>
                                </DataTable>
                            </template>
                        </DataTable>
                    </div>
                </TabPanel>
                <TabPanel>
                    <template #header>
                        Cek Data
                    </template>
                    <DataTable :value="cekQuranData" responsive-layout="scroll" paginator="true" :rows="10"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[10,25,50]"
                        currentPageReportTemplate="Menampilkan halaman {first} dari {last} halaman dari {totalRecords} seluruh data"

                    >
                        <Column header="Surah:Ayat">
                            <template #body="{data}">
                                {{ surahList[data.surah -1].indo }} : {{ data.ayat }}
                            </template>
                        </Column>
                        <Column header="Halaman" field="halaman"/>
                        <Column header="Juz" field="juz"/>
                        <Column header="Baris" field="baris"/>
                        <Column header="Dibuat Oleh">
                            <template #body="{data}">
                                {{ data.createdBy.user.username }}
                            </template>
                        </Column>
                        <Column header="Action">
                            <template #body>
                                <button>Correct</button>
                                <button>Revision</button>
                            </template>
                        </Column>
                    </DataTable>
                </TabPanel>
            </TabView>
        </div>
    </div>
    <!-- Input Data Quran -->
    <Dialog v-model:visible="isWantInputQuran" header="Pilih Halaman dan Juz" :modal="true"> 
        <div class="flex-col justify-center">
            <span class="p-float-label mt-6 sm:m-6">
                <InputNumber id="inputnumber" min="0" max="30" class="w-full" v-model="tempData.juz" />
                <label class="text-base">Juz</label>
            </span>
            <span class="p-float-label mt-6 sm:m-6">
                <InputNumber id="inputnumber" min="0" max="604" class="w-full" v-model="tempData.halaman" />
                <label class="text-base">Halaman</label>
            </span>
        </div>
        <template #footer>
            <button @click="stepTwo = true; isWantInputQuran = false">Berikutnya</button>
        </template>
    </Dialog>
    <Dialog v-model:visible="stepTwo" header="Input Awal Surah dan Akhir Surah" :modal="true">
        <div class="mt-3" >Awal Halaman</div>
            <div class="flex">
                <span class="p-float-label mt-6 sm:m-6 w-1/2 mr-1">
                    <Dropdown id="dropdown" class="w-full" v-model="tempData.awalSurah" @change="tempData.akhirSurah=tempData.awalSurah" placeholder="Awal Surah"  :options="surahList" optionLabel="id">
                        <template #value="slotProps">
                            <!-- <span>{{surah.value.indo}}</span> -->
                            <div v-if="slotProps.value" class="text-end">
                                <span>{{slotProps.value.arabic}}</span>
                                <!-- <span>{{slotProps.value.indo}}</span> -->
                            </div>
                            <div v-else>
                                <span>{{ slotProps.placeholder }}</span>
                            </div>
                        </template>
                        <template #option="surah">
                            <div class="flex w-full justify-between">
                                <span>{{ surah.option.id }}</span>
                                <span>{{ surah.option.arabic }}</span>
                                <span>{{ surah.option.indo }}</span>

                            </div>
                        </template>
                    </Dropdown>
                    <label for="dropdown">Surah</label>
                </span>
                <span class="p-float-label mt-6 w-1/2 sm:m-6 ml-1">
                    <InputNumber id="inputnumber" :max="tempData.awalSurah == null ? 0 : tempData.awalSurah.ayat"  min="0" inputClass="w-full" v-model="tempData.awalAyat" />
                    <label for="inputnumber" class="text-base">Ayat</label>
                </span>
            </div>
            <div class="mt-3">Akhir Halaman</div>
            <div class="flex">
                <span class="p-float-label mt-6 sm:m-6 w-1/2 mr-1">
                    <Dropdown id="dropdown" class="w-full" v-model="tempData.akhirSurah" placeholder="Awal Surah"  :options="surahList" optionLabel="id">
                        <template #value="slotProps">
                            <!-- <span>{{surah.value.indo}}</span> -->
                            <div v-if="slotProps.value" class="text-end">
                                <span>{{slotProps.value.arabic}}</span>
                                <!-- <span>{{slotProps.value.indo}}</span> -->
                            </div>
                            <div v-else>
                                <span>{{ slotProps.placeholder }}</span>
                            </div>
                        </template>
                        <template #option="surah">
                            <div class="flex w-full justify-between">
                                <span>{{ surah.option.id }}</span>
                                <span>{{ surah.option.arabic }}</span>
                                <span>{{ surah.option.indo }}</span>

                            </div>
                        </template>
                    </Dropdown>
                    <label for="dropdown">Surah</label>
                </span>
                <span class="p-float-label mt-6 sm:m-6 w-1/2 ml-1">
                    <InputNumber id="inputnumber" inputClass="w-full" :min="tempData.awalAyat == 0 ? 0 : tempData.awalAyat" :max="tempData.akhirSurah == null ? 0 : tempData.akhirSurah.ayat" v-model="tempData.akhirAyat" />
                    <label for="inputnumber" class="text-base">Ayat</label>
                </span>
            </div>
            <template #footer>
                <button @click="isWantInputQuran = true; stepTwo = false">Sebelumnya</button>
                <button @click="twoToThree(tempData.halaman, tempData.juz, tempData.awalSurah, tempData.awalAyat, tempData.akhirSurah, tempData.akhirAyat)">Berikutnya</button>
            </template>
    </Dialog>
    <Dialog v-model:visible="stepThree" :modal="true" header="Input Baris Data" :breakpoints="{'960px': '75vw', '640px': '100vw'}">
        <!-- v-model:editingRows="editingRows" @row-edit-save="onRowEditSave" dataKey="id"  -->
        <div class="card">
            <DataTable :value="inputArray" editMode="cell" 
            @cell-edit-complete="onCellEditComplete" class="editable-cells-table"
            responsiveLayout="scroll" >
                <Column header="Halaman" field="halaman" style="width:20%">
                    <template #editor="{data, field}">
                        <InputNumber max="604" min="1" input-class="w-1/2" v-model="data[field]" autofocus/>
                    </template>
                </Column>
                <Column header="Juz" field="juz" style="min-width:7rem">
                    <template #editor="{data, field}">
                        <InputNumber max="30" min="1" input-class="w-1/2" v-model="data[field]" autofocus/>
                    </template>
                </Column>
                <Column header="Surah" field="surahDetail" style="min-width:16rem">
                    <template #body="{data}">
                        {{ data.surahDetail.indo }}
                    </template>
                    <template #editor="{data, field}" >
                        <Dropdown id="dropdown" class="w-full" v-model="data[field]" placeholder="Awal Surah"  :options="surahList" optionLabel="id">
                            <template #value="slotProps">
                                <!-- <span>{{surah.value.indo}}</span> -->
                                <div v-if="slotProps.value" class="text-end">
                                    <span>{{slotProps.value.arabic}}</span>
                                    <!-- <span>{{slotProps.value.indo}}</span> -->
                                </div>
                                <div v-else>
                                    <span>{{ slotProps.placeholder }}</span>
                                </div>
                            </template>
                            <template #option="surah">
                                <div class="flex w-full justify-between">
                                    <span>{{ surah.option.id }}</span>
                                    <span>{{ surah.option.arabic }}</span>
                                    <span>{{ surah.option.indo }}</span>
    
                                </div>
                            </template>
                        </Dropdown>
                    </template>
                </Column>
                <Column header="Ayat" field="ayat" style="min-width:7rem">
                    <template #editor="{data, field}">
                        <div class="w-fit">
                            <InputNumber :max="data.surah.ayat" min="1" input-class="w-1/2" v-model="data[field]" autofocus/>
                        </div>
                    </template>
                </Column>
                <Column header="Baris" field="baris" style="min-width:7rem">
                    <template #editor="{data, field}">
                        <InputNumber max="15" min="1" input-class="w-1/2" v-model="data[field]" autofocus/>
                    </template>
                </Column>
            </DataTable>
        </div>
        <template #footer>
            <button @click="stepThree = false; stepTwo=true">Sebelumnya</button>
            <button @click="createQuran">Simpan</button>
        </template>
    </Dialog>

    <!-- Edit One Pages Data Quran  -->
    <Dialog v-model:visible="isWantToEditOnePages" :modal="true" header="Edit Data Satu Halaman" :breakpoints="{'960px': '75vw', '640px': '100vw'}">
        <!-- v-model:editingRows="editingRows" @row-edit-save="onRowEditSave" dataKey="id"  -->
        <div class="card">
            <DataTable :value="editArray" editMode="cell" 
            @cell-edit-complete="onCellEditComplete" class="editable-cells-table"
            responsiveLayout="scroll" >
                <Column header="Halaman" field="halaman" style="width:20%">
                    <template #editor="{data, field}">
                        <InputNumber max="604" min="1" input-class="w-1/2" v-model="data[field]" autofocus/>
                    </template>
                </Column>
                <Column header="Juz" field="juz" style="min-width:7rem">
                    <template #editor="{data, field}">
                        <InputNumber max="30" min="1" input-class="w-1/2" v-model="data[field]" autofocus/>
                    </template>
                </Column>
                <Column header="Surah" field="surahDetail" style="min-width:16rem">
                    <template #body="{data}">
                        {{ surahList[data.surah-1].indo }}
                    </template>
                    <template #editor="{data, field}" >
                        <Dropdown id="dropdown" class="w-full" v-model="data[field]" placeholder="Awal Surah"  :options="surahList" optionLabel="id">
                            <template #value="slotProps">
                                <!-- <span>{{surah.value.indo}}</span> -->
                                <div v-if="slotProps.value" class="text-end">
                                    <span>{{slotProps.value.arabic}}</span>
                                    <!-- <span>{{slotProps.value.indo}}</span> -->
                                </div>
                                <div v-else>
                                    <span>{{ slotProps.placeholder }}</span>
                                </div>
                            </template>
                            <template #option="surah">
                                <div class="flex w-full justify-between">
                                    <span>{{ surah.option.id }}</span>
                                    <span>{{ surah.option.arabic }}</span>
                                    <span>{{ surah.option.indo }}</span>
    
                                </div>
                            </template>
                        </Dropdown>
                    </template>
                </Column>
                <Column header="Ayat" field="ayat" style="min-width:7rem">
                    <template #editor="{data, field}">
                        <div class="w-fit">
                            <InputNumber :max="data.surah.ayat" min="1" input-class="w-1/2" v-model="data[field]" autofocus/>
                        </div>
                    </template>
                </Column>
                <Column header="Baris" field="baris" style="min-width:7rem">
                    <template #editor="{data, field}">
                        <InputNumber max="15" min="1" input-class="w-1/2" v-model="data[field]" autofocus/>
                    </template>
                </Column>
            </DataTable>
        </div>
        <template #footer>
            <button @click="updateQuranOnePage">Simpan</button>
        </template>
    </Dialog>
    <!-- Edit 1 Baris Quran -->
    <Dialog v-model:visible="isWantToEditOneBaris" :modal="true" header="Edit Data per Baris" :breakpoints="{'960px': '75vw', '640px': '100vw'}">
        <div class="flex-col justify-center">
            <span class="p-float-label mt-6 sm:m-6 mr-1">
                <Dropdown id="dropdown" class="w-full" v-model="editBaris.surahDetail" @change="editBaris.surah=editBaris.surahDetail.id" placeholder="Awal Surah"  :options="surahList" optionLabel="id">
                    <template #value="slotProps">
                        <!-- <span>{{surah.value.indo}}</span> -->
                        <div v-if="slotProps.value" class="text-end">
                            <span>{{slotProps.value.arabic}}</span>
                            <!-- <span>{{slotProps.value.indo}}</span> -->
                        </div>
                        <div v-else>
                            <span>{{ slotProps.placeholder }}</span>
                        </div>
                    </template>
                    <template #option="surah">
                        <div class="flex w-full justify-between">
                            <span>{{ surah.option.id }}</span>
                            <span>{{ surah.option.arabic }}</span>
                            <span>{{ surah.option.indo }}</span>

                        </div>
                    </template>
                </Dropdown>
                <label for="dropdown">Surah</label>
            </span>
            
            <span class="p-float-label mt-6 sm:m-6">
                <InputNumber id="inputnumber" min="1" max="30" class="w-full" v-model="editBaris.ayat" />
                <label class="text-base">Ayat</label>
            </span>
            <span class="p-float-label mt-6 sm:m-6">
                <InputNumber id="inputnumber" min="1" max="30" class="w-full" v-model="editBaris.juz" />
                <label class="text-base">Juz</label>
            </span>
            <span class="p-float-label mt-6 sm:m-6">
                <InputNumber id="inputnumber" min="1" max="604" class="w-full" v-model="editBaris.halaman" />
                <label class="text-base">Halaman</label>
            </span>
            <span class="p-float-label mt-6 sm:m-6">
                <InputNumber id="inputnumber" min="1" max="15" class="w-full" v-model="editBaris.baris" />
                <label class="text-base">Baris</label>
            </span>
        </div>
        <template #footer>
            <button @click="updateQuranOneBaris">Simpan</button>
        </template>
    </Dialog>
</template>

<script>
import NavbarVue from '@/components/Navbar.vue';
import DataTable from 'primevue/datatable';
import Dropdown from 'primevue/dropdown';
import TabPanel from 'primevue/tabpanel';
import TabView from 'primevue/tabview';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup'
import Row from 'primevue/row'
import { onMounted, ref, reactive } from 'vue';
import Toast from 'primevue/toast';
import Dialog from 'primevue/dialog';
import ConfirmDialog from 'primevue/confirmdialog';
import InputNumber from 'primevue/inputnumber';
import axios from 'axios';
import { useToast } from 'primevue/usetoast';
import { useConfirm } from 'primevue/useconfirm';

// import { Vue, Vue2 } from 'vue-demi';
// import Login from './Login.vue';

export default {
    components : {
        NavbarVue,
        ConfirmDialog,
        ColumnGroup,
        Row,
        DataTable,
        TabPanel,
        Dropdown,
        Dialog,
        TabView,
        InputNumber,
        Toast,
        Column
    },
    setup() {
        onMounted(async() => {

            // Get Surah List
            await axios.get('/v2/surah')
            .then(res => {
                console.log(res)
                surahList.value = res.data.data
            })



            fetchAllQuranData()
        })

        const fetchAllQuranData = async() => {

            // Get Quran Madina Data 
            await axios.get('/v2/quran-madina')
            .then(res => {
                console.log(res)
                dataQuranMadina.value = res.data.data
            })

            // Get all Quran Data
            await axios.get('/v2/quran')
            .then(res => {
                console.log(res)
                dataQuran.value = groupingQuranData(res.data.data)
            })

            // Get Check Data 
            await axios.get('/v2/quran/cek')
            .then(res =>{
                cekQuranData.value = res.data.data
            })

            // Get Quran inputted by User 
                        // Get Check Data 
            await axios.get('/v2/quran/user')
            .then(res =>{
                userQuranData.value = groupingQuranData(res.data.data)
            })
        }

        const editOnePages = async (data) => {
            isWantToEditOnePages.value = true
            editArray.value = data
            editArray.value.forEach(data => {
                data.surahDetail = surahList.value[data.surah -1]
            })
        }

        const editOneBaris = async(data) => {
            isWantToEditOneBaris.value = true
            editBaris.value = data
            editBaris.value.surahDetail = surahList.value[data.surah -1]
        }

        const groupingQuranData = (listData) => {
            const groupData = listData.reduce((acc, curr) => {
                if (!acc[curr.halaman]) {
                    acc[curr.halaman] = []
                }
                acc[curr.halaman].push(curr)
                return acc
            }, {})

            const finalData = Object.entries(groupData).map(([halaman, data]) => ({ halaman, data }));
            console.log(finalData)
            console.log(typeof(finalData))
            return finalData
        }

        const toast = useToast()
        const confirm = useConfirm()

        const isWantInputQuran = ref(false)
        const stepTwo = ref(false)
        const stepThree = ref(false)
        const stepFinal = ref(false)
        const isWantToEditOnePages = ref(false)
        const isWantToEditOneBaris = ref(false)

        const surahList = ref(null)
        const dataQuran = ref(null)
        const dataQuranMadina = ref(null)
        const cekQuranData = ref(null)
        const userQuranData = ref(null)

        const editingRows = ref([])
        const expandedRows = ref([]);
        const tempData = reactive({
            nama: ''
        })
        const inputArray = ref(null)
        const editArray = ref(null)
        const editBaris = ref(null)

        const twoToThree = (halaman, juz, awalSurah, awalAyat, akhirSurah, akhirAyat) => {
            stepTwo.value = false
            stepThree.value = true
            console.log('atneuahtneshuatoehu')
            let generateData = []
            if (awalSurah.id === akhirSurah.id){
                let id=0
                for(let i=awalAyat; i<=akhirAyat; i++, id++){
                    let data = {
                        // id: id,s
                        halaman: halaman,
                        juz: juz,
                        surahDetail: awalSurah,
                        surah: awalSurah.id,
                        ayat: i,
                        baris: 1
                    }
                    generateData.push(data)
                }
            }
            else 
            // if(awalSurah.id !== akhirSurah.id) 
            {
                console.log('masuk sini')
                for (let i = awalSurah.id; i<= akhirSurah.id; i++) {
                    const surah = surahList.value[i-1]
                    console.log('pantek')
                    console.log(surah)
                    for(let j=1; j<=surah.ayat; j++){
                        let data = {
                            halaman: halaman,
                            juz: juz,
                            surahDetail: surah,
                            ayat: j,
                            baris: 1
                        }
                        generateData.push(data)
                    }
                }
            }
            console.log(awalSurah.id)
            console.log(akhirSurah.id)
            inputArray.value = generateData
        }

        const onRowEditSave = (event) => {
            let { newData, index } = event;
            inputArray.value[index] = newData;
        };

        const onCellEditComplete = (event) => {
            let { data, newValue, field } = event
            switch (field) {
                case 'surahDetail' : 
                    data[field] = newValue
                    data['surah'] = newValue.id
                break;
                default: 
                    if (newValue){
                        data[field] = newValue
                    }
                    else event.preventDefault()
                break;
            }
        }

        const createQuran = async() => {
            const data = inputArray.value
            delete data.surahDetail
            // console.log(inputArray)
            console.log(data)
            await axios.post('/v2/quran', {data: data})
            .then(res => {
                console.log(res.data.data)
                if (res.data.success) {
                    toast.add({
                        severity: 'success',
                        summary : "Data Quran berhasil dibuat",
                        detail : res.data.message,
                        life: 3000
                    })
                    fetchAllQuranData()
                    stepThree.value = false
                    inputArray.value = null
                }
                else {
                    toast.add({
                        severity: 'error',
                        summary : "Error",
                        detail : res.data.message,
                        life: 3000
                    })
                    
                }
            })
            .catch(err => {
                console.log(err.response)
            })
        }

        const updateQuranOnePage = async() => {
            await axios.patch('/v2/quran', {data: editArray.value})
            .then(res => {
                toast.add({
                    severity: 'success',
                    summary: 'Data berhasil di update',
                    detail: res.data.message,
                    life: 3000
                })
            })
            .catch(err => {
                toast.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: err.response.data.message,
                    life: 3000
                })
            })
            fetchAllQuranData()
        }

        const updateQuranOneBaris = async() => {
            await axios.patch(`/v2/quran/baris/${editBaris.value.id}`, editBaris.value)
            .then(res => {
                toast.add({
                    severity: 'success',
                    summary: 'Data berhasil di update',
                    detail: res.data.message,
                    life: 3000
                })
            })
            .catch(err => {
                toast.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: err.response.message,
                    life: 3000,
                })    
            })
            isWantToEditOneBaris.value = false
            fetchAllQuranData()
        }

        const deleteQuranOnePage = async(halaman) => {
            confirm.require({
                message: `Apakah kamu yakin ingin menghapus data halaman ${halaman}?`,
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: async () => {
                    await axios.delete(`v2/quran/halaman/${halaman}`)
                    .then(res => {
                        toast.add({
                            severity: 'success',
                            summary: 'Data berhasil dihapus',
                            detail: res.data.message,
                            life: 3000
                        })
                    })
                    .catch(err => {
                        toast.add({
                            severity: 'error',
                            summary: "Error",
                            detail: err.response.data,
                            life: 3000
                        })
                    })
        
                    fetchAllQuranData()
                },
            })
        }
        
        const deleteQuranOneBaris = async(data) => {
            confirm.require({
                message: `Apakah kamu yakin ingin menghapus data ${data.surahDetail.indo}:${data.ayat} halaman ${data.halaman} baris ke- ${data.baris}?`,
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: async () => {
                    await axios.delete(`/v2/quran/baris/${data.id}`)
                        .then(res => {
                        toast.add({
                            severity: 'success',
                            summary: 'Data berhasil dihapus',
                            detail: res.data.message,
                            life: 3000
                        })
                    })
                    .catch(err => {
                        toast.add({
                            severity: 'error',
                            summary: "Error",
                            detail: err.response.data,
                            life: 3000
                        })
                    })
        
                    fetchAllQuranData()
                },
            })

        }
        return {
            createQuran,
            updateQuranOnePage,
            deleteQuranOnePage,
            deleteQuranOneBaris,
            updateQuranOneBaris,
            onRowEditSave,
            onCellEditComplete,
            twoToThree,
            editOnePages,
            editOneBaris,
            expandedRows,
            isWantInputQuran,
            isWantToEditOnePages,
            isWantToEditOneBaris,
            tempData,
            editingRows,
            inputArray,
            editArray,
            editBaris,
            dataQuran,
            dataQuranMadina,
            userQuranData,
            cekQuranData,
            stepFinal,
            stepTwo,
            stepThree,
            surahList,
        }
    }
}
</script>