<template>
  <NavbarVue></NavbarVue>
  <h1 class="text-3xl text-center mt-1">Selamat Datang {{ user.nama }}</h1>
</template>

<script setup>
import NavbarVue from '@/components/Navbar.vue'
import { onBeforeMount, ref } from 'vue';

onBeforeMount(async() => {
  user.value = await JSON.parse(sessionStorage.getItem('user'))
  console.log(sessionStorage.getItem('user'))
  console.log(user.value)
})
const user = ref(null)
// const stringWelcome = `Selamat Datang ${user.value.nama}`
</script>
