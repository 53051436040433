<template>
    <NavbarVue/>
    <Toast/>
    <Dialog header="Ubah Password" v-model:visible="isChangePassword" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" :modal="true" >
        <div class="flex flex-col justify-items-center">
            <span class="p-float-label mt-7 mx-auto">
                <Password class="w-full" inputClass="w-full" panelClass="" id="currentPassword" v-model="data.currentPassword" :feedback="false" toggleMask=""/>
                <label for="currentPassword" class="text-base">Password Sekarang</label>
            </span>
            <span class="p-float-label mt-7 mx-auto">
                <Password class="w-full" id="newPassword" v-model="data.newPassword" toggleMask=""/> 
                <label for="newPassword" class="text-base">Password Baru</label>
            </span>
            <span class="p-float-label mt-7 mx-auto">
                <Password class="w-full" id="confirmNewPassword" v-model="data.confirmNewPassword" toggleMask="" :feedback="false"/> 
                <label for="confirmNewPassword">Konfirmasi Password Baru</label>
            </span>
        </div>
        <template #footer>
            <button class="border rounded p-2"> Cancel </button>
            <button class="border rounded bg-ltDarkGreen text-white py-2 px-2 mx-2 hover:bg-ltLightGreen" @click="changePassword"> Simpan </button>
        </template>
    </Dialog>
    
    <div class="p-2 text-center">
        <img :src="$store.state.user.profileUrl" alt="" width="200" class=" rounded border">
        {{ $store.state.user.nama }}
        {{ $store.state.user.jenisKelamin === 'M' ? "Laki-Laki"  : "Perempuan" }}
        {{ $store.state.user.statusGuru }}
        {{ $store.state.user.nig }}
        <!-- {{ $store.state.user }} -->
        <button @click="isChangePassword = true" class="button bordered bg-ltDarkGreen hover:bg-ltLightGreen text-white p-2 rounded">Ganti Kata Sandi</button>
    </div>
</template>

<script>
import NavbarVue from '@/components/Navbar.vue';
import axios from 'axios';
import Dialog from 'primevue/dialog'
import Toast from 'primevue/toast';
import { useToast } from 'primevue/usetoast';
import Password from 'primevue/password'
import { ref, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default {
    components: {
        NavbarVue,
        Toast,
        Dialog,
        Password
    },
    setup(){
        const isChangePassword = ref(false)
        const router = useRouter()
        const store = useStore()
        const closeChangePassword = () => {
            isChangePassword.value = false
        }

        const data = reactive({
            currentPassword : '',
            newPassword : '',
            confirmNewPassword : ''
        })

        const toast = useToast()

        // const error = ref()
        const changePassword = async () => {
            await axios.patch('/v2/auth/password', data)
            .then(res => {
                console.log(res.data)
                isChangePassword.value = false
                store.commit('setToken', '')
                store.commit('setLoggedIn', false)
                router.push('/login')
            })
            .catch(error => {
                if (error.response.status === 401) {
                    store.commit('setToken', '')
                    store.commit('setLoggedIn', false)
                    router.push('/login')
                }
                if(error.response.data){
                    const errorList = error.response.data
                    errorList.forEach(data => {
                        toast.add({
                            severity : 'error',
                            summary : "Gagal mengganti password",
                            detail : data.msg,
                            life: 3000
                        })
                    })
                }
                console.log(error)
            })
        }

        return {
            isChangePassword,
            closeChangePassword,
            changePassword,
            data
        }
    }
}
</script>