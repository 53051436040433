<template>
    <DataTable :value="datas.statusTarget" v-if="datas">
        <template #header>
            <div class="flex justify-between align-middle">
                <span class="p-float-label mr-1 w-50">
                    <Calendar v-model="form.date" inputId="birth_date" dateFormat="dd MM yy" selection-mode="range"  @update:model-value="fetchStatusHalaqoh()"/>
                    <label for="birth_date">Rentang Tanggal</label>
                </span>
                <span class="p-float-label ml-1 w-50">
                    <Dropdown class="w-full " v-model="form.tipeSesi" :options="tipeSesi" optionLabel="name" optionValue="value" placeholder="Pilih Tipe Sesi" @change="fetchStatusHalaqoh()"/>
                    <label class="text-base w-full">Tipe Sesi</label>
                </span>
            </div>
        </template>
        <template #empty>
            Belum ada status target silahkan pilih tanggal dan tipe sesi terlebih dahulu
        </template>
        <Column header="Nama" sortable>
            <template #body="{data}">
                {{ store.state.halaqoh.data.muridTahfidzDetail.find(murid => data.muridId === murid.pesantrenDetail.muridDetail.murid.id).pesantrenDetail.muridDetail.murid.nama }}
            </template>
        </Column>
        <Column header="Tipe Sesi">
            <template #body="{data}">
                {{ data.tipeSesi === "Murojah_Baru" ? "Murojah Hafalan Baru" : data.tipeSesi === "Hafalan" ? "Hafalan" : "Murojah Hafalan Lama" }}
            </template>
        </Column>
        <Column header="Total Setoran">
            <template #body="{data}">
                <span v-if="data.satuanTarget === 'Halaman'">{{ data._sum.jumlahHalaman }} Halaman</span>
                <span v-else-if="data.satuanTarget === 'Ayat'">{{ data._sum.jumlahAyat }} Ayat</span>
                <span v-else-if="data.satuanTarget === 'Baris'">{{ data._sum.jumlahBaris }} Baris</span>
                <span v-else-if="data.satuanTarget === 'Juz'">{{ data._sum.jumlahHalaman / 20 }} Juz</span>
                <span v-else>Tidak ada data Target.</span>
            </template>
        </Column>
        <Column header="Target">
            <template #body="{data}">
                <span v-if="data.satuanTarget === 'Halaman'">{{ data._avg.target * datas.rentangTarget  }} Halaman</span>
                <span v-else-if="data.satuanTarget === 'Ayat'">{{ data._avg.target * datas.rentangTarget }} Ayat</span>
                <span v-else-if="data.satuanTarget === 'Baris'">{{ data._avg.target * datas.rentangTarget }} Baris</span>
                <span v-else-if="data.satuanTarget === 'Juz'">{{ data._avg.target * datas.rentangTarget }} Juz</span>
                <span v-else>Tidak ada data Target.</span>
            </template>
        </Column>
        <Column header="Status">
            <template #body="{data}">
                <Chip v-if="data.satuanTarget === 'Halaman'">{{ data._avg.target * datas.rentangTarget > data._sum.jumlahHalaman ? 'Tidak Tuntas' : 'Tuntas' }}</Chip>
                <Chip v-else-if="data.satuanTarget === 'Ayat'">{{ data._avg.target * datas.rentangTarget > data._sum.jumlahAyat ? 'Tidak Tuntas' : 'Tuntas' }} </Chip>
                <Chip v-else-if="data.satuanTarget === 'Baris'">{{ data._avg.target * datas.rentangTarget > data._sum.jumlahBaris ? 'Tidak Tuntas' : 'Tuntas'}}</Chip>
                <Chip v-else-if="data.satuanTarget === 'Juz'">{{ data._avg.target * datas.rentangTarget > data._sum.jumlahHalaman / 20 ? 'Tidak Tuntas' : 'Tuntas'}}</Chip>
                <span v-else>Tidak ada data Target.</span>
            </template>
        </Column>
    </DataTable>
</template>
<script setup>
import { tipeSesiList } from '@/utils/reusableVar'
import axios from 'axios'
import moment from 'moment'
import { onBeforeMount,  reactive, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

const store = useStore()
const route = useRoute()
const tipeSesi = ref(tipeSesiList)

const form = reactive({
    date: [new Date(), new Date()],
    tipeSesi: 'Hafalan',
})

const datas = reactive({
    statusTarget: {},
    rentangTarget: 0,
})

const fetchStatusHalaqoh = async() => {
    const date = []
    date[0] = moment(form.date[0]).set({hour: 0, minute: 0, second: 0}).toDate()
    date[1] = moment(form.date[1]).set({ hour: 23, minute: 59, second: 59}).toDate()

    const param = {
        date: date,
        tipeSesi: form.tipeSesi
    }
    console.log(param)
    await axios.get(`/v2/halaqoh/target/${route.params.kode}`, {
        params: param
    })
    .then(async res => {
        datas.statusTarget = res.data.data
        datas.rentangTarget = res.data.days
    })
}

onBeforeMount(async () => {
    await fetchStatusHalaqoh()
})

</script>