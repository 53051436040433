<style>
    #menu-toggle:checked + #menu {
            display: block;
        }
</style>

<template>
<div class="antialiased bg-gray-400">
    <header class="lg:px-16 px-6 bg-ltLightGreen flex flex-wrap items-center lg:py-0 py-2">
        <div class="flex-1 flex justify-between items-center">
            <router-link to="/" class="flex flex-wrap w-max p-2.5">
                <img src="@/assets/logo.png" alt="" class="w-8 h-fit inline">
                <div class="flex flex-col px-3 ">
                    <h1>Lan Tabur</h1>
                    <h1>Management System</h1> 
                </div>
            </router-link>
        </div>

    <label for="menu-toggle" class="pointer-cursor lg:hidden block"><svg class="fill-current text-gray-900" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><title>menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path></svg></label>
    <input class="hidden" type="checkbox" id="menu-toggle" />

    <div class="hidden lg:flex lg:items-center lg:w-auto w-full" id="menu">
        <nav>
        <ul class="lg:flex items-center text-center justify-between text-base text-gray-700 pt-4 lg:pt-0">
            <li v-for="route in routeList.route" :key="route.namaRoute" :class="route.namaRoute === pathName ? 'bg-ltDarkGreen text-white rounded' : ''">
                <router-link :to="route.to" class="lg:p-4 py-3 px-0 block border-b-2 border-transparent hover:border-indigo-400" >
                    {{route.namaRoute}}
                </router-link>
            </li>
            <li>
                <router-link to="/login" v-if="isTokenAvail !== null"  @click="logOut" class="lg:p-4 py-3 px-0 block border-b-2 border-transparent hover:border-indigo-400" href="#">Log Out</router-link>
            </li>
        </ul>
        </nav>
        

    </div>

    </header>


</div>
</template>

<script>
import { onMounted, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { returnNavbarList } from '@/plugins/navbar';

export default {
    setup(){
        const store = useStore()
        const ro = useRoute()
        const router = useRouter()

        const pathName = ref(ro.name)
        const routeList = reactive({
            route : []
        })

        function logOut() {
            // store.commit('setToken', '')
            // store.commit('setRole', '')
            store.commit('setLoggedIn', false)
            // sessionStorage.clear()
            router.push('/login')
        }     

        const isTokenAvail = sessionStorage.getItem('token')

        onMounted(() => {
            if (pathName.value == 'Reset') routeList.route  = []
            else routeList.route = returnNavbarList(sessionStorage.getItem('role'))
        })

        return {
            store,
            pathName,
            routeList,
            isTokenAvail,
            logOut
        }   
    }
}
</script>