import LoginVue from '@/views/Login.vue'
import store from '@/store'
import { createRouter, createWebHistory, } from 'vue-router'
import DashboardVue from '../views/Dashboard.vue'
import GuruVue from '@/views/Guru.vue'
import MuridVue from '@/views/Murid.vue'
import UserVue from '@/views/User.vue'
import ParentVue from '@/views/Parent.vue'
import QuranVue from '@/views/Quran.vue'
import DetailMuridVue from '@/views/DetailMurid.vue'
import AdminVue from '@/views/Admin.vue'
import DetailGuruVue from '@/views/DetailGuru.vue'
import UnauthorizedVue from '@/views/Unauthorized.vue'
import TahfidzAdminVue from '@/views/TahfidzAdmin.vue'
import TahfidzGuruVue from '@/views/TahfidzGuru.vue'
import DetailJamTahfidzVue from '@/views/DetailJamTahfidz.vue'
import DetailHalaqohVue from '@/views/DetailHalaqoh.vue'
import ResetVue from '@/views/Reset.vue'
import FamilyVue from '@/views/Family.vue'
import DetailFamilyVue from '@/views/DetailFamily.vue'
import DetailParentVue from '@/views/DetailParent.vue'
import LTClockAdminVue from '@/views/LTClockAdmin.vue'
// import LandingPageVue from '@/views/LandingPage.vue'

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: DashboardVue,
    meta : {
      middleware : 'auth',
      title : 'Dashboard',
      allowed : ['Any']
    }
  },
  {
    path: '/gurus',
    name: 'Guru',
    component: GuruVue,
    meta : {
      middleware : 'auth',
      title : 'Guru',
      allowed : ['ADMIN', 'TU_PESANTREN']
    }
  },
  {
    path: '/murids',
    name: 'Murid',
    component: MuridVue,
    meta : {
      middleware : 'auth',
      title : 'Murid',
      allowed : ['ADMIN', 'TU_PESANTREN']
    }
  },
  {
    path: '/guru/:nigs',
    name: 'Guru Detail',
    children : [
      {
        path: '',
        component: DetailGuruVue,
        meta: {
          middleware: 'auth',
          title: 'Detail Guru',
          allowed : ['ADMIN', 'TU_PESANTREN']
        }
      }
    ],
    meta : {
      middleware : 'auth',
      title : 'Database Murid'
    }
  },
  {
    path: '/murid/:nis',
    name: 'Murid Detail',
    // component: MuridVue,
    children: [
      {
        path : '', 
        component : DetailMuridVue,
        meta : {
          middleware :'auth',
          title : 'Detail Murid',
          allowed : ['ADMIN', 'TU_PESANTREN', 'KEPALA_TAHFIDZ', 'GURU_TAHFIDZ']
        } 
      }
    ],
    meta : {
      middleware : 'auth',
      title : 'Database Murid',
      allowed : ['ADMIN', 'TU_PESANTREN']
    }
  },
  {
    path: '/family/:nif',
    name: 'Family Detail',
    children: [
      {
        path: '',
        component: DetailFamilyVue,
        meta: {
          middleware: 'auth',
          title: 'Detail Keluarga',
          allowed: ['ADMIN', 'TU_PESANTREN']
        }
      }
    ]
  },
  {
    path: '/tahfidz',
    name: 'Tahfidz',
    children : [
      {
        path : 'admin',
        component : TahfidzAdminVue,
        name: 'Kepala Tahfidz',
        meta : {
          middleware : 'auth',
          title: 'Kepala Tahfidz',
          allowed : ['ADMIN', 'KEPALA_TAHFIDZ']
        },
      },
      {
        path: 'jamTahfidz/:id',
        component: DetailJamTahfidzVue,
        meta: {
          middleware: 'auth',
          title: 'Detail Jam Tahfidz',
          allowed: ['ADMIN', 'KEPALA_TAHFIDZ']
        }
      },
      {
        path: 'halaqoh/:kode',
        component: DetailHalaqohVue,
        meta: {
          middleware: 'auth',
          title: 'Detail Halaqoh',
          allowed: ['ADMIN', 'KEPALA_TAHFIDZ']
        }
      },
      {
        path: 'guru',
        component : TahfidzGuruVue,
        name: 'Guru Tahfidz',
        meta: {
          middleware : 'auth',
          title: 'Guru Tahfidz',
          allowed : ['ADMIN', 'GURU_TAHFIDZ']
        }
      },
    ]
  },
  {
    path: '/parents',
    name: 'Parent',
    component: ParentVue,
    meta : {
      middleware : 'auth',
      title : 'Orang Tua',
      allowed: ['ADMIN', 'TU_PESANTREN']
    }
  },
  {
    path: '/parent/:nip',
    name: 'Parent Detail',
    children: [
      {
        path: '',
        component: DetailParentVue,
        meta: {
          middleware: 'auth',
          title: 'Detail Orang Tua',
          allowed: ['ADMIN', 'TU_PESANTREN']
        }
      }
    ]
  },
  {
    path: '/families',
    name: 'Family',
    component: FamilyVue,
    meta : {
      middleware : 'auth',
      title : 'Keluarga',
      allowed: ['ADMIN', 'TU_PESANTREN']
    }
  },
  {
    path: '/qurans',
    name: 'Quran',
    component: QuranVue,
    meta : {
      middleware : 'auth',
      title : `Al-Qur'an`,
      allowed : ['ADMIN', 'INPUT_QURAN']
    }
  },
  {
    path: '/user',
    name: 'User',
    component: UserVue,
    meta : {
      middleware : 'auth',
      title : 'User'
    }
  },
  {
    path: '/admin',
    name: 'Admin',
    component: AdminVue,
    meta : {
      middleware : 'auth',
      title : 'Admin',
      allowed : ['ADMIN', 'MUDI']
    }
  },
  {
    path: '/ltclock',
    name: 'LTClock Admin',
    component: LTClockAdminVue,
    meta : {
      middleware : 'auth',
      title : 'LTClock Admin',
      allowed : ['ADMIN', 'LTCLOCK_ADMIN']
    }
  },
  {
    path: '/403',
    name: 'Unauthorized',
    component : UnauthorizedVue,
    meta: {
      title : '403 Unauthorized Access'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginVue,
    meta : {
      // allowed : ['Any']
    }
  },
  {
    path: '/reset',
    name: 'Reset',
    component: ResetVue,
    meta : {
      // allowed : ['Any']
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: ()=> import('@/views/NotFound.vue'),
    meta: {
      title: '404 Not Found'
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  
})

router.beforeEach((to, _ , next) => {
  document.title = `${to.meta.title} - Lan Tabur Management System`
  console.log(to.name)

  // check if route exist or not 
  // const routeExist = router.options.routes.some(route => route.name === to.name)
  // if(routeExist)next()
  // else next('/404')


  // Securing Login if already Logged In 
  if (to.path == '/login'){
    if (sessionStorage.getItem('isLoggedIn') == 'true') {
      console.log('SesStorage : ')
      console.log(sessionStorage.getItem('isLoggedIn'))
      next('/')
    }
    // else next()
  }
  
  if (to.meta.middleware){
    const middleware = require(`@/middleware/${to.meta.middleware}`)
    if (middleware){
      middleware.default(next, store)
    }
  }
  else if (to.meta.allowed) {
    console.log(typeof(to.meta.allowed))
    console.log('yang ini array')
    console.log(typeof(['aeua', 'aoeuao']))

    // console.log(Array(to.meta.allowed).includes())
    if (to.meta.allowed.includes('Any')) next()
    else if (to.meta.allowed.includes(sessionStorage.getItem('role'))) next()
    else next('/403')
  } 
  else next()
})

export default router
