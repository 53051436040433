export const pendidikanList = [
{ name: 'Taman Kanak-Kanak / sederajat', value: 'TK' },
    { name: 'Sekolah Dasar / sederajat', value: 'SD' },
    { name: 'Sekolah Menegah Pertama / sederajat', value: 'SMP' },
    { name: 'Sekolah Menegah Atas / sederajat', value: 'SMA' },
    { name: 'Diploma / sederajat', value: 'Diploma' },
    { name: 'Strata 1', value: 'S1' },
    { name: 'Strata 2', value: 'S2' },
    { name: 'Strata 3', value: 'S3' },
]

export const jenisKelaminList = [       
    { name: 'Laki-Laki', value: 'M' },
    { name: 'Perempuan', value: 'F' },
]

export const golonganDarahList = [
    { name: 'A', value: 'A' },
    { name: 'B', value: 'B' },
    { name: 'O', value: 'O' },
    { name: 'AB', value: 'AB' },
]

export const satuanTargetList = [
    { name: 'Ayat', value: 'Ayat'},
    { name: 'Baris', value: 'Baris'},
    { name: 'Halaman', value: 'Halaman'},
    { name: 'Juz', value: 'Juz'},
]

export const tipeSesiList = [
    {name: 'Hafalan', value: 'Hafalan'},
    {name: 'Murojah Hafalan Baru', value: 'Murojah_Baru'},
    {name: 'Murojah Hafalan Lama', value: 'Murojah'},
]


