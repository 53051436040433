<template>
    <DataTable :value="store.state.guru.statusTarget" v-if="store.state.guru.halaqoh">
        <template #header>
            <div class="flex justify-between align-middle">
                <span class="p-float-label mr-1 w-50">
                    <Calendar v-model="form.date" inputId="birth_date" dateFormat="dd MM yy" selection-mode="range"  @update:model-value="store.commit('fetchStatusTarget', form)"/>
                    <label for="birth_date">Rentang Tanggal</label>
                </span>
                <span class="p-float-label ml-1 w-50">
                    <Dropdown class="w-full " v-model="form.tipeSesi" :options="tipeSesi" optionLabel="name" optionValue="value" placeholder="Pilih Tipe Sesi" @change="store.commit('fetchStatusTarget', form)"/>
                    <label class="text-base w-full">Tipe Sesi</label>
                </span>
            </div>
        </template>
        <template #empty>
            Belum ada status target silahkan pilih tanggal terselebih dahulu
        </template>
        <Column header="Nama" sortable>
            <template #body="{data}">
                {{ store.state.guru.halaqoh.find(murid => data.muridId === murid.pesantrenDetail.muridDetail.murid.id).pesantrenDetail.muridDetail.murid.nama }}
            </template>
        </Column>
        <Column header="Tipe Sesi">
            <template #body="{data}">
                {{ data.tipeSesi === "Murojah_Baru" ? "Murojah Hafalan Baru" : data.tipeSesi === "Hafalan" ? "Hafalan" : "Murojah Hafalan Lama" }}
            </template>
        </Column>
        <Column header="Total Setoran">
            <template #body="{data}">
                <span v-if="data.satuanTarget === 'Halaman'">{{ data._sum.jumlahHalaman }} Halaman</span>
                <span v-else-if="data.satuanTarget === 'Ayat'">{{ data._sum.jumlahAyat }} Ayat</span>
                <span v-else-if="data.satuanTarget === 'Baris'">{{ data._sum.jumlahBaris }} Baris</span>
                <span v-else-if="data.satuanTarget === 'Juz'">{{ data._sum.jumlahHalaman / 20 }} Juz</span>
                <span v-else>Tidak ada data Target.</span>
            </template>
        </Column>
        <Column header="Target">
            <template #body="{data}">
                <span v-if="data.satuanTarget === 'Halaman'">{{ data._avg.target * store.state.guru.rentangTarget  }} Halaman</span>
                <span v-else-if="data.satuanTarget === 'Ayat'">{{ data._avg.target * store.state.guru.rentangTarget }} Ayat</span>
                <span v-else-if="data.satuanTarget === 'Baris'">{{ data._avg.target * store.state.guru.rentangTarget }} Baris</span>
                <span v-else-if="data.satuanTarget === 'Juz'">{{ data._avg.target * store.state.guru.rentangTarget }} Juz</span>
                <span v-else>Tidak ada data Target.</span>
            </template>
        </Column>
        <Column header="Status">
            <template #body="{data}">
                <Chip v-if="data.satuanTarget === 'Halaman'">{{ data._avg.target * store.state.guru.rentangTarget > data._sum.jumlahHalaman ? 'Tidak Tuntas' : 'Tuntas' }}</Chip>
                <Chip v-else-if="data.satuanTarget === 'Ayat'">{{ data._avg.target * store.state.guru.rentangTarget > data._sum.jumlahAyat ? 'Tidak Tuntas' : 'Tuntas' }} </Chip>
                <Chip v-else-if="data.satuanTarget === 'Baris'">{{ data._avg.target * store.state.guru.rentangTarget > data._sum.jumlahBaris ? 'Tidak Tuntas' : 'Tuntas'}}</Chip>
                <Chip v-else-if="data.satuanTarget === 'Juz'">{{ data._avg.target * store.state.guru.rentangTarget > data._sum.jumlahHalaman / 20 ? 'Tidak Tuntas' : 'Tuntas'}}</Chip>
                <span v-else>Tidak ada data Target.</span>
            </template>
        </Column>
    </DataTable>
</template>
<script setup>
import { tipeSesiList } from '@/utils/reusableVar'
import { onBeforeMount,  reactive, ref } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const tipeSesi = ref(tipeSesiList)

const form = reactive({
    date: [new Date(), new Date()],
    tipeSesi: 'Hafalan',
})

onBeforeMount(async () => {
    await store.commit('fetchStatusTarget', form)
})

</script>