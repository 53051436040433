<template>
    <Navbar />
    <Toast />
    <ConfirmDialog :breakpoints="{ '960px': '75vw', '640px': '100vw' }" :style="{ width: '50vw' }"></ConfirmDialog>
    <BlockUI :blocked="isLoading" :full-screen="true" />
    <div class="flex justify-between sm:justify-around items-center pt-2 mx-4 sm:mx-0">
        <h1 class="text-xl sm:text-2xl">{{ $route.params.nip }}</h1>
        <div class="hidden sm:block" v-if="canEditOrDelete">
            <button class="border rounded py-1 px-2 mx-2 bg-blue-500 text-white" @click="isWantEditParent = true">
                <font-awesome-icon icon="fa-solid fa-pen-to-square" class="pr-2" />
                Edit
            </button>
            <button @click="confirmDelete()" class="border rounded py-1 px-2 mx-2 bg-red-500 text-white ">
                <font-awesome-icon icon="fa-solid fa-trash" class="pr-2" />
                Delete
            </button>
        </div>
        <div class="sm:hidden flex" v-if="canEditOrDelete">
            <button class="border rounded py-1 px-2 mr-1 bg-blue-500 text-white" @click="isWantEditParent = true">
                <font-awesome-icon icon="fa-solid fa-pen-to-square" />
            </button>
            <button @click="confirmDelete()" class="border rounded py-1 px-2 ml-1 bg-red-500 text-white ">
                <font-awesome-icon icon="fa-solid fa-trash" />
            </button>
        </div>
    </div>
    <div class="rounded text-center justify-center mx-2" v-if="data.parent !== null">
        <div class="flex justify-around mt-4 pt-3 mx-2 sm:mx-auto flex-row" v-if="data.parent.nama !== null">
            <img :src="data.parent.profileUrl" alt="" width="200" class=" rounded border">
        </div>
        <h1 class="text-3xl pt-5">{{ data.parent.nama }}</h1>
        <span class="text-base py-2 text-green-500">
            <a :href="`https://wa.me/${data.parent.countryCode}${data.parent.nomorTelepon}`">
                <i class="pi pi-whatsapp" /> +{{ data.parent.countryCode }}-{{ data.parent.nomorTelepon }}
            </a>
        </span>

        <TabView class="mt-2">
            <TabPanel header="Informasi Dasar">
                <div class="flex mx-auto justify-center card">
                    <div class="text-left text-base grid grid-cols-2 px-5 pt-5 pb-5 border">
                        <span class="">NIP</span>
                        <span>{{ data.parent.nip }}</span>
                        <span>NIK</span>
                        <span>{{ data.parent.nik }}</span>
                        <span>Jenis Kelamin </span>
                        <span>{{ data.parent.jenisKelamin == 'M' ? 'Laki-Laki' : 'Perempuan' }}</span>
                        <span>Tempat, Tanggal Lahir</span>
                        <span>{{ data.parent.tempatLahir }}, {{
                            moment(data.parent.tanggalLahir).locale('id').format('DD MMMM YYYY') }}</span>
                        <span>Tanggal Masuk</span>
                        <span>{{ moment(data.parent.tanggalMasuk).locale('id').format('DD MMMM YYYY') }}</span>
                        <span>Nomor Telepon Aktif</span>
                        <span class="text-green-700">
                            <a
                                :href="`https://wa.me/${data.parent.countryCode}${data.parent.nomorTelepon}`"><i
                                    class="pi pi-whatsapp" /> +{{ data.parent.countryCode }}-{{ data.parent.nomorTelepon }}
                            </a>
                        </span>
                        <span>Golongan Darah</span>
                        <span>{{data.parent.golonganDarah === null ? 'Belum ada data' : data.parent.golonganDarah}}</span>
                        <span>Alamat</span>
                        <span>{{ data.parent.namaJalan }}, RT {{ data.parent.nomorRT }}/RW {{ data.parent.nomorRW }} {{
                            data.parent.kelurahan
                        }},
                            {{ data.parent.namaKecamatan }}, {{ data.parent.namaKota }}, {{ data.parent.namaProvinsi }}, {{
                                data.parent.kodePos
                            }} </span>
                        <span v-if="data.parent.linkGoogleMap !== 'null'">Link Google Map</span>
                        <span v-if="data.parent.linkGoogleMap !== 'null'"><a :href="data.parent.linkGoogleMap">Google
                                Maps</a></span>
                    </div>
                </div>
            </TabPanel>
        </TabView>
    </div>
    <Dialog :modal="true" header="Masukkan Data Orang Tua" v-model:visible="isWantEditParent">
        <span class="p-float-label mt-6">
            <InputText type="number" v-model.trim="vInfo$.nik.$model" class="w-full"
                placeholder="167XXXXXXXXXXXXX (16 Digit)" @blur="vInfo$.nik.$touch" />
            <label for="nik" class="text-base">NIK</label>
        </span>
        <div class="flex ml-2 mt-1" v-for="error of vInfo$.nik.$errors" :key="error.$uid">
            <small class="p-error">{{ error.$message }}</small>
        </div>

        <span class="p-float-label mt-6">
            <InputText type="text" v-model.trim="vInfo$.nama.$model" class="w-full" placeholder="Muhammad Agus"
                @blur="vInfo$.nama.$touch" />
            <label for="nama" class="text-base">Nama Lengkap</label>
        </span>
        <div class="flex ml-2 mt-1" v-for="error of vInfo$.nama.$errors" :key="error.$uid">
            <small class="p-error">{{ error.$message }}</small>
        </div>

        <span class="p-float-label mt-6">
            <InputText type="email" v-model.trim="vInfo$.email.$model" class="w-full"
                placeholder="muhammadagus@lantabur.sch.id" @blur="vInfo$.email.$touch"
                :disabled="$store.state.role !== 'ADMIN' ? true : false" />
            <label for="Email" class="text-base">Email</label>
        </span>
        <div class="flex ml-2 mt-1" v-for="error of vInfo$.email.$errors" :key="error.$uid">
            <small class="p-error">{{ error.$message }}</small>
        </div>

        <span class="p-float-label mt-6">
            <Dropdown v-model="vInfo$.jenisKelamin.$model" class="w-full" :options="jenisKelamin" optionLabel="name"
                optionValue="value" @blur="vInfo$.jenisKelamin.$touch" />
            <label for="Gender" class="text-base">Jenis Kelamin</label>
        </span>
        <div class="flex ml-2 mt-1" v-for="error of vInfo$.jenisKelamin.$errors" :key="error.$uid">
            <small class="p-error">{{ error.$message }}</small>
        </div>

        <span class="p-float-label mt-6">
            <InputText type="text" v-model="vInfo$.tempatLahir.$model" class="w-full" placeholder="Pagar Alam"
                @blur="vInfo$.tempatLahir.$touch" />
            <label for="tempatLahir" class="text-base">Tempat Lahir</label>
        </span>
        <div class="flex mt-1 ml-2" v-for="error of vInfo$.tempatLahir.$errors" :key="error.$uid">
            <small class="p-error">{{ error.$message }}</small>
        </div>

        <span class="p-float-label mt-6">
            <Calendar v-model="vInfo$.tanggalLahir.$model" class="w-full" dateFormat="dd MM yy"
                placeholder="Kapan Lahirnya?" @blur="vInfo$.tanggalLahir.$touch" />
            <label for="tanggalLahir" class="text-base">Tanggal Lahir</label>
        </span>
        <div class="flex mt-1 ml-2" v-for="error of vInfo$.tanggalLahir.$errors" :key="error.$uid">
            <small class="p-error">{{ error.$message }}</small>
        </div>

        <span class="p-float-label mt-6">
            <Dropdown v-model="vInfo$.countryCode.$model" class="w-full" :options="countryCodeList" optionValue="phone_code"
                optionLabel="country_name" placeholder="+62" op filter="true" @blur="vInfo$.countryCode.$touch">
                <template #option="country">
                    <div class="flex justify-between">
                        <span>{{ country.option.country_name }}</span>
                        <span>+{{ country.option.phone_code }}</span>
                    </div>
                </template>
            </Dropdown>
            <label for="">Kode Negara</label>
        </span>
        <div class="flex ml-2 mt-1" v-for="error of vInfo$.countryCode.$errors" :key="error.$uid">
            <small class="p-error">{{ error.$message }}</small>
        </div>

        <span class="p-float-label mt-6">
            <InputText type="number" v-model="vInfo$.nomorTelepon.$model" class="w-full" placeholder="08XXXXXXXXXX"
                @blur="vInfo$.nomorTelepon.$touch" />
            <label for="nomorTelepon" class="text-base">Nomor Telepon Aktif</label>
        </span>
        <div class="flex ml-2 mt-1" v-for="error of vInfo$.nomorTelepon.$errors" :key="error.$uid">
            <small class="p-error">{{ error.$message }}</small>
        </div>

        <span class="p-float-label mt-6">
            <Dropdown v-model="vInfo$.agama.$model" class="w-full" :options="agamaList" optionLabel="name"
                optionValue="value" placeholder="Agama nya apa?" @blur="vInfo$.agama.$touch" />
            <label for="agama" class="text-base">Agama</label>
        </span>
        <div class="flex ml-2 mt-1" v-for="error of vInfo$.agama.$errors" :key="error.$uid">
            <small class="p-error">{{ error.$message }}</small>
        </div>

        <span class="p-float-label mt-6">
            <Dropdown v-model="vInfo$.pekerjaan.$model" class="w-full" :options="pekerjaanList" optionLabel="name"
                optionValue="name" placeholder="Kamu Dagang?" @blur="vInfo$.pekerjaan.$touch" />
            <label for="Pekerjaan" class="text-base">Pekerjaan</label>
        </span>
        <div class="flex ml-2 mt-1" v-for="error of vInfo$.pekerjaan.$errors" :key="error.$uid">
            <small class="p-error">{{ error.$message }}</small>
        </div>

        <span class="p-float-label mt-6">
            <Dropdown v-model="vInfo$.pendidikanTerakhir.$model" class="w-full" :options="pendidikanList" optionLabel="name"
                optionValue="value" placeholder="Terakhir sekolah apa?" @blur="vInfo$.pendidikanTerakhir.$touch" />
            <label for="Pendidikan Terakhir" class="text-base">Pendidikan Terakhir</label>
        </span>
        <div class="flex ml-2 mt-1" v-for="error of vInfo$.pendidikanTerakhir.$errors" :key="error.$uid">
            <small class="p-error">{{ error.$message }}</small>
        </div>

        <span class="p-float-label mt-6">
            <InputNumber v-model="vInfo$.penghasilanPerBulan.$model" locale="id" mode="currency" currency="IDR"
                min-fraction-digits="0" class="w-full" placeholder="Sebulan dapet uang berapa?"
                @blur="vInfo$.penghasilanPerBulan.$touch" />
            <label for="Penghasilan Per Bulan" class="text-base">Penghasilan Per Bulan</label>
        </span>
        <div class="flex ml-2 mt-1" v-for="error of vInfo$.penghasilanPerBulan.$errors" :key="error.$uid">
            <small class="p-error">{{ error.$message }}</small>
        </div>

        <span class="p-float-label mt-6">
            <Dropdown v-model="vInfo$.statusHidup.$model" class="w-full" :options="statusHidupList" optionLabel="name"
                optionValue="value" placeholder="Masih hidup engga?" @blur="vInfo$.statusHidup.$touch" />
            <label for="statusHidup" class="text-base">Status Hidup</label>
        </span>
        <div class="flex ml-2 mt-1" v-for="error of vInfo$.statusHidup.$errors" :key="error.$uid">
            <small class="p-error">{{ error.$message }}</small>
        </div>

        <span class="p-float-label mt-6">
            <InputText type="text" v-model="vInfo$.tahunMasuk.$model" class="w-full"
                placeholder="Tahun Bergabung ke Lan Tabur" @blur="vInfo$.tahunMasuk.$touch" />
            <label for="tahunMasuk" class="text-base">Tahun Masuk</label>
        </span>
        <div class="flex ml-2 mt-1" v-for="error of vInfo$.tahunMasuk.$errors" :key="error.$uid">
            <small class="p-error">{{ error.$message }}</small>
        </div>
        
        <span class="p-float-label mt-6">
            <Dropdown v-model="vInfo$.golonganDarah.$model" class="w-full" :options="golonganDarahList" optionValue="value"
                optionLabel="name" placeholder="Golongan Darah" @blur="vInfo$.golonganDarah.$touch" />
            <label class="text-base">Golongan Darah</label>
        </span>
        <div class="flex ml-2 mt-1" v-for="error of vInfo$.golonganDarah.$errors" :key="error.$uid">
            <small class="p-error">{{ error.$message }}</small>
        </div>

        <!-- Foto Profil -->
        <label for="name" class="text-sm">
            Foto Profil
        </label>

        <input
            class="mb-5 mt-2 block w-full text-sm text-l border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
            id="file_input" type="file" ref="file" @change="uploadFile" />
        <div class="flex ml-2 mt-1" v-for="error of vInfo$.image.$errors" :key="error.$uid">
            <small class="p-error">{{ error.$message }}</small>
        </div>

        <template #footer>
            <button icon="pi" @click="isWantEditParent = false" class="border rounded p-2 mx-2">
                Cancel
            </button>
            <button class="border rounded bg-ltDarkGreen text-white py-2 px-4 mx-2 hover:bg-ltLightGreen" icon="pi"
                @click="toUpdateAlamat()">
                Berikutnya
            </button>
        </template>
    </Dialog>
    <Dialog :modal="true" v-model:visible="isWantToUpdateAlamatParent" header="Alamat Orang Tua">
        <div class="flex-col justify-center">

            <!-- Alamat -->
            <div class="field">
                <span class="p-float-label mt-6">
                    <InputText v-model="vAlamat$.namaJalan.$model" type="text" class="w-full" placeholder="Yasniar"
                        @blur="vAlamat$.namaJalan.$touch" />
                    <label class="text-base">Nama Jalan</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of vAlamat$.namaJalan.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <div class="field">
                <span class="p-float-label mt-6">
                    <InputText v-model="vAlamat$.nomorRT.$model" type="number" class="w-full" placeholder="Yasniar"
                        @blur="vAlamat$.nomorRT.$touch" />
                    <label class="text-base">Nomor RT</label>
                </span>
                <div class="flex ml-2 mt-2" v-for="error of vAlamat$.nomorRT.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <div class="field">
                <span class="p-float-label mt-6">
                    <InputText v-model="vAlamat$.nomorRW.$model" type="number" class="w-full" placeholder="Yasniar"
                        @blur="vAlamat$.nomorRW.$touch" />
                    <label class="text-base">Nomor RW</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of vAlamat$.nomorRW.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <div class="field">
                <span class="p-float-label mt-6">
                    <Dropdown v-model="vAlamat$.provinsi.$model" class="w-full" :options="provinceList" optionValue=""
                        optionLabel="name" placeholder="Pilih Provinsi" @change="fetchingKota(data.parent.provinsi.id)"
                        @blur="vAlamat$.provinsi.$touch" />
                    <label class="text-base">Provinsi</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of vAlamat$.provinsi.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <div class="field">
                <span class="p-float-label mt-6">
                    <Dropdown v-model="vAlamat$.kota.$model" class="w-full" :options="kotaList" optionValue=""
                        optionLabel="name" placeholder="Pilih Kota" @change="fetchingKecamatan(data.parent.kota.id)"
                        @blur="vAlamat$.kota.$touch" />
                    <label class="text-base">Kota</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of vAlamat$.kota.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <div class="field">
                <span class="p-float-label mt-6">
                    <Dropdown v-model="vAlamat$.kecamatan.$model" class="w-full" :options="kecamatanList" optionValue=""
                        optionLabel="name" placeholder="Pilih Kecamatan"
                        @change="fetchingKelurahan(data.parent.kecamatan.id)" @blur="vAlamat$.kecamatan.$touch" />
                    <label class="text-base">Kecamatan</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of vAlamat$.kecamatan.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <div class="field">
                <span class="p-float-label mt-6">
                    <Dropdown v-model="vAlamat$.kelurahan.$model" class="w-full" :options="kelurahanList" optionValue="name"
                        optionLabel="name" placeholder="Pilih Kelurahan" @blur="vAlamat$.kelurahan.$touch" />
                    <label class="text-base">Kelurahan</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of vAlamat$.kelurahan.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <div class="field">
                <span class="p-float-label mt-6">
                    <InputText v-model="vAlamat$.kodePos.$model" type="number" class="w-full" placeholder="3XXXXX"
                        @blur="vAlamat$.kodePos.$touch" />
                    <label class="text-base">Kode Pos</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of vAlamat$.kodePos.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <div class="field">
                <span class="p-float-label mt-6">
                    <InputText v-model="vAlamat$.linkGoogleMap.$model" type="text" class="w-full" placeholder="maps.google"
                        @blur="vAlamat$.linkGoogleMap.$touch" />
                    <label class="text-base">Link Google Map</label>
                </span>
                <div class="flex-ml-2 mt-1" v-for="error of vAlamat$.linkGoogleMap.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
        </div>
        <template #footer>
            <button icon="pi" @click="isWantInputParent = true; secondStepInputParent = false"
                class="border rounded p-2 mx-2">
                Kembali
            </button>
            <button class="border rounded bg-ltDarkGreen text-white py-2 px-4 mx-2 hover:bg-ltLightGreen" label="Login"
                icon="pi" @click="updateParent()">
                Simpan
            </button>
        </template>
</Dialog>
</template>
<script setup>
import Navbar from '@/components/Navbar.vue';
import axios from 'axios';
import { onBeforeMount, ref, reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex'
import moment from 'moment'
import { countryCode } from '@/utils/coutryCode';
import { between, helpers } from '@vuelidate/validators';
import { minLength, maxLength, email, numeric, url } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import Toast from 'primevue/toast';
import { useToast } from 'primevue/usetoast';
import { isArray } from '@vue/shared';
import { useConfirm } from 'primevue/useconfirm';

const data = reactive({
    parent: null
})
const route = useRoute()
const toast = useToast()
const router = useRouter()
const store = useStore()
const confirm = useConfirm()

let vInfo$, vAlamat$

let counter = 0
onBeforeMount(async () => {
    if (store.state.role == 'ADMIN' || store.state.role === 'TU_PESANTREN') {
        canEditOrDelete.value = true
    }

    console.log(counter)
    counter++

    await axios.get(`/v2/parent/detail/${route.params.nip}`)
        .then(res => {
            data.parent = res.data.data
            data.parent.tanggalLahir = new Date(data.parent.tanggalLahir)
            data.parent.tahunMasuk = `20${data.parent.nip.slice(3, 5)}`
            if (data.parent.nomorTelepon.split('-').length < 1 ) {
                data.parent.countryCode = null
            }
            else {
                data.parent.countryCode = data.parent.nomorTelepon.split('-')[0]
                data.parent.nomorTelepon = data.parent.nomorTelepon.split('-')[1]
            }
        })
        .catch(err => {
            console.log(err)
        })


    
    // Fetching Province 
    await fetch('https://www.emsifa.com/api-wilayah-indonesia/api/provinces.json')
        .then(response => response.json())
        .then(provinces => {
            provinceList.value = provinces
            if(data.parent.provinsi !== null) {
                data.parent.namaProvinsi = data.parent.provinsi
                data.parent.provinsi = provinceList.value.find(object => object.name === data.parent.provinsi)
            }
        });

    console.log(data.parent.provinsi !== null)
    if ( data.parent.provinsi !== null){
        // Fetching Kota
        await fetch(`https://www.emsifa.com/api-wilayah-indonesia/api/regencies/${data.parent.provinsi.id}.json`)
            .then(response => response.json())
            .then(regencies => {
                kotaList.value = regencies
                data.parent.namaKota = data.parent.kota
                data.parent.kota = kotaList.value.find(object => object.name === data.parent.kota)
            });
    
        // Fetching Kecamatan
        await fetch(`https://www.emsifa.com/api-wilayah-indonesia/api/districts/${data.parent.kota.id}.json`)
            .then(response => response.json())
            .then(districts => {
                kecamatanList.value = districts
                data.parent.namaKecamatan = data.parent.kecamatan
                data.parent.kecamatan = kecamatanList.value.find(object => object.name === data.parent.kecamatan)
            });
    
        // Fetching Kelurahan
        await fetch(`https://www.emsifa.com/api-wilayah-indonesia/api/villages/${data.parent.kecamatan.id}.json`)
            .then(response => response.json())
            .then(villages => {
                kelurahanList.value = villages
                // data.guru.kelurahan = kelurahanList.value.find(object => object.name === data.guru.kelurahan)
            });
        
    }

    const v = useVuelidate(updateParentInfoRules, data.parent)
    vInfo$ = v
    const vAlamat = useVuelidate(updateAlamatParentRules, data.parent)
    vAlamat$ = vAlamat

})

const canEditOrDelete = ref(false)

const isWantEditParent = ref(false)
const isWantToUpdateAlamatParent = ref(false)
const isLoading = ref(false)

const countryCodeList = ref(countryCode)
const provinceList = ref(null)
const kotaList = ref(null)
const kecamatanList = ref(null)
const kelurahanList = ref(null)

const jenisKelamin = ref([
    { name: 'Laki-Laki', value: 'M' },
    { name: 'Perempuan', value: 'F' },
])

const agamaList = ref([
    { name: 'Islam', value: 'Islam' },
    { name: 'Kristen', value: 'Kristen' },
    { name: 'Buddha', value: 'Buddha' },
    { name: 'Hindu', value: 'Hindu' },
    { name: 'Kong Hu Chu', value: 'Kong_Hu_Chu' },
    { name: 'Katolik', value: 'Katolik' },
])

const pendidikanList = ref([
    { name: 'Tidak Sekolah', value: 'Tidak_Sekolah' },
    { name: 'TK', value: 'TK' },
    { name: 'SD/sederajat', value: 'SD' },
    { name: 'SMP/sederajat', value: 'SMP' },
    { name: 'SMA/sederajat', value: 'SMA' },
    { name: 'Diploma', value: 'Diploma' },
    { name: 'S1', value: 'S1' },
    { name: 'S2', value: 'S2' },
    { name: 'S3', value: 'S3' },
])

const statusHidupList = ref([
    { name: 'Hidup', value: 'HIDUP' },
    { name: 'Meninggal', value: 'MENINGGAL' },
])

const pekerjaanList = ref([
    { name: 'BELUM/TIDAK BEKERJA' },
    { name: 'PEGAWAI NEGERI SIPIL' },
    { name: 'TENTARA NASIONAL INDONESIA' },
    { name: 'KEPOLISIAN RI' },
    { name: 'KARYAWAN BUMN' },
    { name: 'KARYAWAN BUMD' },
    { name: 'ANGGOTA DPR-RI' },
    { name: 'ANGGOTA DPD' },
    { name: 'ANGGOTA BPK' },
    { name: 'PRESIDEN' },
    { name: 'WAKIL PRESIDEN' },
    { name: 'ANGGOTA MAHKAMAH KONSTITUSI' },
    { name: 'ANGGOTA KABINET/KEMENTERIAN' },
    { name: 'DUTA BESAR' },
    { name: 'GUBERNUR' },
    { name: 'WAKIL GUBERNUR' },
    { name: 'BUPATI' },
    { name: 'WAKIL BUPATI' },
    { name: 'WALIKOTA' },
    { name: 'WAKIL WALIKOTA' },
    { name: 'ANGGOTA DPRD PROVINSI' },
    { name: 'ANGGOTA DPRD KABUPATEN/KOTA' },
    { name: 'PENGACARA' },
    { name: 'NOTARIS' },
    { name: 'PENELITI' },
    { name: 'PERANGKAT DESA' },
    { name: 'KEPALA DESA' },
    { name: 'DOSEN' },
    { name: 'GURU' },
    { name: 'PERDAGANGAN' },
    { name: 'INDUSTRI' },
    { name: 'KONSTRUKSI' },
    { name: 'TRANSPORTASI' },
    { name: 'KARYAWAN SWASTA' },
    { name: 'KARYAWAN HONORER' },
    { name: 'BURUH HARIAN LEPAS' },
    { name: 'PEMBANTU RUMAH TANGGA' },
    { name: 'TUKANG CUKUR' },
    { name: 'TUKANG LISTRIK' },
    { name: 'TUKANG BATU' },
    { name: 'TUKANG KAYU' },
    { name: 'TUKANG SOL SEPATU' },
    { name: 'TUKANG LAS/PANDAI BESI' },
    { name: 'TUKANG JAHIT' },
    { name: 'TUKANG GIGI' },
    { name: 'PENATA RIAS' },
    { name: 'PENATA BUSANA' },
    { name: 'PENATA RAMBUT' },
    { name: 'MEKANIK' },
    { name: 'SENIMAN' },
    { name: 'TABIB' },
    { name: 'PARAJI' },
    { name: 'PERANCANG BUSANA' },
    { name: 'PENTERJEMAH' },
    { name: 'WARTAWAN' },
    { name: 'JURU MASAK' },
    { name: 'PROMOTOR ACARA' },
    { name: 'PILOT' },
    { name: 'ARSITEK' },
    { name: 'AKUNTAN' },
    { name: 'KONSULTAN' },
    { name: 'PENYIAR TELEVISI' },
    { name: 'PENYIAR RADIO' },
    { name: 'PELAUT' },
    { name: 'SOPIR' },
    { name: 'PIALANG' },
    { name: 'PARANORMAL' },
    { name: 'PEDAGANG' },
    { name: 'WIRASWASTA' },
    { name: 'PETANI/PEKEBUN' },
    { name: 'PETERNAK' },
    { name: 'BURUH TANI/PERKEBUNAN' },
    { name: 'BURUH PETERNAKAN' },
    { name: 'NELAYAN/PERIKANAN' },
    { name: 'BURUH NELAYAN/PERIKANAN' },
    { name: 'IMAM MESJID' },
    { name: 'PENDETA' },
    { name: 'PASTOR' },
    { name: 'USTADZ/MUBALIGH' },
    { name: 'BIARAWATI' },
    { name: 'PELAJAR/MAHASISWA' },
    { name: 'DOKTER' },
    { name: 'BIDAN' },
    { name: 'PERAWAT' },
    { name: 'APOTEKER' },
    { name: 'PSIKIATER/PSIKOLOG' },
    { name: 'PENSIUNAN' },
    { name: 'MENGURUS RUMAH TANGGA' },
    { name: 'LAINNYA' },

])

const golonganDarahList = ref([
	{ name: 'A', value: 'A' },
	{ name: 'B', value: 'B' },
	{ name: 'O', value: 'O' },
	{ name: 'AB', value: 'AB' },
])

const updateParentInfoRules = {
    nik: {
        minlength: helpers.withMessage('Panjang NIK harus 16 Angka', minLength(16)),
        maxLength: helpers.withMessage('Panjang NIK tidak boleh lebih dari 16 Angka', maxLength(16))
    },
    nama: {
        alpha: helpers.withMessage('Nama Lengkap hanya boleh terdiri dari huruf', helpers.regex(/^[a-zA-Z ]*$/))
    },
    email: {
        email: helpers.withMessage('Format email tidak valid', email)
    },
    agama: {

    },
    pekerjaan: {},
    pendidikanTerakhir: {},
    penghasilanPerBulan: {},
    tahunMasuk: {
        between: helpers.withMessage(`Tahun Masuk harus dari 2008 hingga ${moment(Date.now()).format("YYYY")}`, between(2008, moment(Date.now()).format('YYYY')))
    },
    image: {

    },
    statusHidup: {},
    jenisKelamin: {
    },
    tempatLahir: {
        alpha: helpers.withMessage('Tempat lahir hanya boleh terdiri dari huruf', helpers.regex(/^[a-zA-Z ]*$/))
    },
    tanggalLahir: {
    },
    countryCode: {},
    golonganDarah: {},
    nomorTelepon: {
        numeric: helpers.withMessage('Nomor telepon hanya boleh terdiri dari angka', numeric),
        // min: helpers.withMessage('Panjang Nomor Telepon minimal 11 angka', minLength(11)),
        // max: helpers.withMessage('Panjang Nomor Telepon maksimal 13 angka', maxLength(13))
    },
}

const updateAlamatParentRules = {
    namaJalan: {
    },
    nomorRT: {
        numeric: helpers.withMessage('Nomor RT harus terdiri dari Angka', numeric)
    },
    nomorRW: {
        numeric: helpers.withMessage('Nomor RW harus terdiri dari Angka', numeric)
    },
    provinsi: {
    },
    kota: {

    },
    kecamatan: {
    },
    kelurahan: {
    },
    kodePos: {
        numeric: helpers.withMessage('Nomor Kode Pos harus terdiri dari angka', numeric),
        minLength: helpers.withMessage('Nomor Kode Pos minimal terdiri dari 5 angka', minLength(5))
    },
    linkGoogleMap: {
        url: helpers.withMessage('Link Google Map tidak valid, mohon input ulang', url)
    }
}

const toUpdateAlamat = async () => {
    const isFormCorrect = await vInfo$.value.$validate()

    if (isFormCorrect) {
        isWantToUpdateAlamatParent.value = true
        isWantEditParent.value = false
    }
}

const fetchingKota = (id) => {
    fetch(`https://www.emsifa.com/api-wilayah-indonesia/api/regencies/${id}.json`)
        .then(response => response.json())
        .then(regencies => {
            kotaList.value = regencies
        });
}

const fetchingKecamatan = (id) => {
    fetch(`https://www.emsifa.com/api-wilayah-indonesia/api/districts/${id}.json`)
        .then(response => response.json())
        .then(districts => {
            kecamatanList.value = districts
        });
}

const fetchingKelurahan = (id) => {
    fetch(`https://www.emsifa.com/api-wilayah-indonesia/api/villages/${id}.json`)
        .then(response => response.json())
        .then(villages => kelurahanList.value = villages);
}

const updateParent = async () => {
    let isFormCorrect = await vAlamat$.value.$validate()

    if (isFormCorrect) {
        isLoading.value = true

        const form = new FormData()

        if (data.parent.golonganDarah) {
            form.append('golonganDarah', data.parent.golonganDarah)
        }

        if (data.parent.linkGoogleMap) {
            form.append('linkGoogleMap', data.parent.linkGoogleMap)
        }

        form.append('image', data.parent.image)
        form.append('nama', data.parent.nama)
        form.append('nik', data.parent.nik)
        form.append('jenisKelamin', data.parent.jenisKelamin)
        form.append('nomorTelepon', `${data.parent.countryCode}-${data.parent.nomorTelepon}`)
        form.append('pekerjaan', data.parent.pekerjaan)
        form.append('pendidikanTerakhir', data.parent.pendidikanTerakhir)
        form.append('penghasilanPerBulan', data.parent.penghasilanPerBulan)
        form.append('statusHidup', data.parent.statusHidup)
        form.append('tanggalLahir', moment(data.parent.tanggalLahir).format('YYYY-MM-DD'))
        form.append('tempatLahir', data.parent.tempatLahir)
        form.append('tahunMasuk', data.parent.tahunMasuk)
        form.append('agama', data.parent.agama)
        form.append('namaJalan', data.parent.namaJalan)
        form.append('nomorRT', data.parent.nomorRT)
        form.append('nomorRW', data.parent.nomorRW)
        form.append('provinsi', data.parent.provinsi.name)
        form.append('kota', data.parent.kota.name)
        form.append('kecamatan', data.parent.kecamatan.name)
        form.append('kelurahan', data.parent.kelurahan)
        form.append('kodePos', data.parent.kodePos)

        await axios.patch(`/v2/parent/detail/${route.params.nip}`, form)
            .then(() => {
                isLoading.value = false
                router.push('/parents')
            })
            .catch(err => {
                if (isArray(err.response.data.error)) {
                    const errorList = err.response.data.error
                    // console.log(errorList)
                    errorList.forEach(data => {
                        toast.add({
                            severity: 'error',
                            summary: "Input Data Murid Gagal",
                            detail: data.msg,
                            life: 3000
                        })
                    })
                }
                else {
                    toast.add({
                        severity: 'error',
                        summary: "Input Data Murid Gagal",
                        detail: err.response.data.message,
                        life: 3000
                    })
                }
                isLoading.value = false
                console.log(err.response.data.error)
            })
    }
}

const confirmDelete = () => {
    confirm.require({
        message: `Apakah kamu yakin ingin menghapus data ${data.parent.nama}?`,
        header: 'Konfirmasi Penghapusan',
        icon: 'pi pi-info-circle',
        acceptClass: 'p-button-danger',
        accept: async () => {
            await axios.delete(`/v2/parent/detail/${data.parent.nip}`)
                .then(() => {
                    router.push('/parents')
                })
                .catch(err => {
                    toast.add({
                        severity: 'error',
                        summary: 'Data Gagal dihapus',
                        detail: err.response.data.message,
                        life: 3000
                    })
                })
        }
    })
}
</script>