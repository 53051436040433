<template>
    <Navbar/>
    <Toast/>
    <BlockUI :blocked="isLoading" :full-screen="true"/>
    <ConfirmDialog :breakpoints="{ '960px': '75vw', '640px': '100vw' }" :style="{ width: '50vw' }"/>
    <div class="flex justify-between sm:justify-around items-center pt-2 mx-4 sm:mx-0" v-if="family !== null">
        <h1 class="text-xl sm:text-2xl">{{ $route.params.nif }}</h1>
        <!-- <h1 class="text-xl sm:text-2xl text-center px-2">{{ data.murid.nama }}</h1> -->
        <div class="hidden sm:block" v-if="canEditOrDelete">
            <button class="border rounded py-1 px-2 mx-2 bg-blue-500 text-white" @click="iWantToEditDetailFamily()">
                <font-awesome-icon icon="fa-solid fa-pen-to-square" class="pr-2"/>
                Edit
            </button>
            <button @click="confirmDelete()" class="border rounded py-1 px-2 mx-2 bg-red-500 text-white ">
                <font-awesome-icon icon="fa-solid fa-trash" class="pr-2"/>
                Delete
            </button>
        </div>
        <div class="sm:hidden flex" v-if="canEditOrDelete">
            <button class="border rounded py-1 px-2 mr-1 bg-blue-500 text-white" @click="iWantToEditDetailFamily()">
                <font-awesome-icon icon="fa-solid fa-pen-to-square" />
            </button>
            <button @click="confirmDelete()" class="border rounded py-1 px-2 ml-1 bg-red-500 text-white ">
                <font-awesome-icon icon="fa-solid fa-trash" />
            </button>
        </div>
    </div>
    <Card class="m-2" v-if="family">
        <template #content> 
            <DataTable
                :value="family.parentList"
                responsive-layout="scroll"
            >
                <template #header>
                    <div class="flex justify-between">
                        <span>Daftar Orang Tua</span> 
                        <button class="bg-ltDarkGreen rounded border p-1 px-2 hover:bg-ltLightGreen text-white" @click="iWantToAddParent()"> 
                            <i class="pi pi-plus"></i>
                        </button>
                    </div> 
                </template>
                <Column field="nip" header="NIP"/>
                <Column header="Nama">
                    <template #body="{data}" >
                        <div class="flex items-center">
                            <img :src="data.profileUrl" alt="" width="40">
                            <span class="ml-2">
                                {{ data.nama }}
                            </span>
                        </div>
                    </template>
                </Column>
                <Column header="Action">
                    <template #body="{data}">
                        <button @click="deleteParent(data)" class="border rounded py-1 px-2 ml-1 bg-red-500 text-white ">
                            <font-awesome-icon icon="fa-solid fa-trash"/>
                        </button>
                    </template>
                </Column>
            </DataTable>
            <DataTable 
                :value="family.muridList"
                responsive-layout="scroll"
            >
                <template #header> 
                    <div class="flex justify-between">
                        <span>Daftar Anak</span>
                        <button class="bg-ltDarkGreen rounded border p-1 px-2 hover:bg-ltLightGreen text-white" @click="iWantToAddAnak()"> 
                            <i class="pi pi-plus"></i>
                        </button>
                    </div>
                </template>
                <Column field="nis" header="NIS"/>
                <Column header="Nama">
                    <template #body="{data}">
                        <div class="flex items-center">
                            <img :src="data.profileUrl" alt="" width="40">
                            <span class="ml-2">{{ data.nama }}</span>
                        </div>
                    </template>
                </Column>
                <Column header="Action">
                    <template #body="{data}">
                        <button @click="deleteAnak(data)" class="border rounded py-1 px-2 ml-1 bg-red-500 text-white ">
                            <font-awesome-icon icon="fa-solid fa-trash"/>
                        </button>
                    </template>
                </Column>
            </DataTable>
        </template>
    </Card>
    <Dialog :modal="true" 
    v-model:visible="isWantToEditFamily" header="Edit Detail Keluarga">
        <span class="p-float-label mt-6">
            <InputText v-model.trim="vDetailFamily$.noKK.$model"
            class="w-full" placeholder="1672XXXXXXXXXXXX (16 Digit)"
            @blur="vDetailFamily$.noKK.$touch"/>
            <label for="nomorKK">Nomor Kartu Keluarga</label>
        </span>
        <div class="flex ml-2 mt-1" v-for="error of vDetailFamily$.noKK.$errors" :key="error.$uid">
            <small class="p-error">{{ error.$message }}</small>
        </div>

        <span class="p-float-label mt-6">
            <InputText v-model.trim="vDetailFamily$.tahunMasuk.$model"
            class="w-full" placeholder="Kapan Bergabung dengan keluarga Lan Tabur?"
            @blur="vDetailFamily$.tahunMasuk.$touch"/>
            <label for="tohunMasuk">Tahun Masuk</label>

        </span>
        <div class="flex ml-2 mt-1" v-for="error of vDetailFamily$.tahunMasuk.$errors" :key="error.$uid">
            <small class="p-error">{{ error.$message }}</small>
        </div>

        <template #footer> 
            <button icon="pi" @click="isWantToAddAnak = false" class="border rounded p-2 mx-2">
                Batal
            </button>
            <button class="border rounded bg-ltDarkGreen text-white py-2 px-4 mx-2 hover:bg-ltLightGreen" 
            label="Login" icon="pi" @click="updateDetailFamily() " >
                Simpan
            </button>
        </template>
    </Dialog>
    <Dialog :modal="true" 
    v-model:visible="isWantToAddAnak" header="Tambah Anak">
        <span class="p-float-label mt-6">
            <Dropdown v-model="vChild$.anak.$model"
            class="w-full" :filter="true" display="chip"
            :options="muridList" optionLabel="nama"
            @blur="vChild$.anak.$touch">
            <template #option="slotProps">
                <div class="flex">
                    <img :src="slotProps.option.profileUrl" alt="" width="30">
                    <div class="mx-3">
                        <p>{{ slotProps.option.nama }}</p>
                        <p class="text-xs">NIS: {{ slotProps.option.nis }}</p>
                    </div>
                </div>
            </template>
            </Dropdown>
            <label for="anak" class="text-base">Anak</label>
        </span>
        <template #footer> 
            <button icon="pi" @click="isWantToAddAnak = false" class="border rounded p-2 mx-2">
                Batal
            </button>
            <button class="border rounded bg-ltDarkGreen text-white py-2 px-4 mx-2 hover:bg-ltLightGreen" label="Login" icon="pi" @click="updateChild() " >
                Simpan
            </button>
        </template>
    </Dialog>
    <Dialog :modal="true"
    v-model:visible="isWantToAddParent" header="Tambah Orang Tua / Wali">
    <span class="p-float-label mt-6">
            <Dropdown v-model="vParent$.parent.$model"
            class="w-full" :filter="true" display="chip"
            :options="parentList" optionLabel="nama"
            @blur="vParent$.parent.$touch">
            <template #option="slotProps">
                <div class="flex">
                    <img :src="slotProps.option.profileUrl" alt="" width="30">
                    <div class="mx-3">
                        <p>{{ slotProps.option.nama }}</p>
                        <p class="text-xs">NIP: {{ slotProps.option.nis }}</p>
                    </div>
                </div>
            </template>
            </Dropdown>
            <label for="anak" class="text-base">Orang Tua</label>
        </span>
        <template #footer>
            <button icon="pi" @click="isWantToAddParent = false" class="border rounded p-2 mx-2">
                Batal
            </button>
            <button class="border rounded bg-ltDarkGreen text-white py-2 px-4 mx-2 hover:bg-ltLightGreen" label="Login" icon="pi" @click="updateParent() " >
                Simpan
            </button>
        </template>
    </Dialog>
    <Toast/>
</template>
<script setup>
import Navbar from '@/components/Navbar.vue';
import useVuelidate from '@vuelidate/core';
import { helpers, minLength, required, maxLength, between, numeric } from '@vuelidate/validators';
import axios from 'axios';
import { isArray } from '@vue/shared';
import { useToast } from 'primevue/usetoast';
import { onBeforeMount, ref, reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import moment from 'moment'
import { useConfirm } from 'primevue/useconfirm';

const route = useRoute()
const toast = useToast()
const store = useStore()
const router = useRouter()
const confirm = useConfirm()

const family = ref(null)
const isWantToAddParent = ref(false)
const isWantToAddAnak = ref(false)
const isWantToEditFamily = ref(false)
const isLoading = ref(false)

const parentList = ref(null)
const muridList = ref(null)

// const formParent = reactive({})
// const formAnak = reactive({})

// const parentRules = {
//     porent
// }

const canEditOrDelete = ref(false)

const fetchKeluargaDetail = async () => {
    await axios.get(`/v2/family/detail/${route.params.nif}`)
    .then(res => {
        family.value = res.data.data
    })
}

onBeforeMount(async () => {
    if (store.state.role === `ADMIN` || store.state.role === `TU_PESANTREN`) {
        canEditOrDelete.value = true
    }
    fetchKeluargaDetail()
})

const formEditDetailFamily = reactive({})

const editDetailFamilyRules = {
    noKK: {
        required: helpers.withMessage('Nomor Kartu Keluarga tidak boleh kosong', required),
        minLength: helpers.withMessage('Nomor Kartu Keluarga harus terdiri dari 16 digit', minLength(16)),
        maxLength: helpers.withMessage('Nomor Kartu Keluarga harus terdiri dari 16 digit', maxLength(16)),
        numeric: helpers.withMessage('Nomor Kartu Keluarga hanya boleh terdiri dari angka', numeric)
    },
    tahunMasuk: {
        required: helpers.withMessage('Tahun Masuk tidak boleh kosong', required),
        between: helpers.withMessage(`Tahun Masuk harus dari 2008 hingga ${moment(Date.now()).format('YYYY')}`, between(2008, moment(Date.now()).format('YYYY')))
    }
}

const vDetailFamily$ = useVuelidate(editDetailFamilyRules, formEditDetailFamily)

const iWantToEditDetailFamily = async () => {
    isWantToEditFamily.value = true
    formEditDetailFamily.noKK = family.value.noKK
    formEditDetailFamily.tahunMasuk = `20${family.value.nif.slice(0,2)}`
}

const updateDetailFamily = async () => {
    const isFormCorrect = await vDetailFamily$.value.$validate()

    if (isFormCorrect) {

        isLoading.value = true
        await axios.patch(`/v2/family/detail/${route.params.nif}`, formEditDetailFamily)
        .then(res => {
            toast.add({
                severity: 'success',
                detail: `Data berhasil diperbaharui`,
                summary: res.data.message,
                life: 3000
            })
            isWantToEditFamily.value = false
            isLoading.value = false
            router.push('/families')
        })
        .catch(err => {
            isLoading.value = false
            if (isArray(err.response.data.error)) {
                const errorList = err.response.data.error
                // console.log(errorList)
                errorList.forEach(data => {
                    toast.add({
                        severity : 'error',
                        summary : "Input Data Murid Gagal",
                        detail : data.msg,
                        life: 3000
                    })
                })
            }
            else {
                toast.add({
                    severity : 'error',
                    summary : "Input Data Murid Gagal",
                    detail : err.response.data.msg,
                    life: 3000
                })
            }
                console.log(err.response.data.error)
        })
    } 

}

const iWantToAddParent = async() => {
    isWantToAddParent.value = true
    await axios.get('/v2/parent/dropdown')
    .then(res => {
        parentList.value = res.data.data
    })

}

const iWantToAddAnak = async() => {
    isWantToAddAnak.value = true
    await axios.get('/v2/murid/dropdown/family')
    .then(res => {
        muridList.value = res.data.data
    })
}

const formUpdateChild = ref({
    nif: route.params.nif
})

const formUpdateChildRules = {
    anak: {
        required: helpers.withMessage('Anak tidak boleh kosong', required)
    }
}

const vChild$ = useVuelidate(formUpdateChildRules, formUpdateChild)

const formUpdateParent = ref({
    nif: route.params.nif
})

const formUpdateParentRules = {
    parent : {
        required: helpers.withMessage('Parent tidak boleh kosong', required)
    }
}

const vParent$ = useVuelidate(formUpdateParentRules, formUpdateParent)

const updateParent = async() => {
    let data = {
        nif: formUpdateParent.value.nif,
        nip: formUpdateParent.value.parent.nip
    }
    await axios.patch('/v2/family/add-parent', data)
    .then(res => {
        toast.add({
            severity: 'success',
            summary: 'Data Keluarga berhasil di Update',
            detail: res.data.message,
            life:3000
        })
        isWantToAddParent.value = false
        fetchKeluargaDetail()
    })
    .catch(err => {
        toast.add({
            severity: 'error',
            summary: 'Error',
            detail: err.response.data.message,
            life: 3000
        })
    })
}

const deleteParent = async(data) => {
    confirm.require({
        message: `Apakah kamu yakin ingin menghaput ${data.nama} dari keluarga dengan NIF ${route.params.nif} ?`,
        header: 'Konfirmasi Penghapusan',
        icon: 'pi pi-info-circle',
        acceptClass: 'p-button-danger',
        accept: async () => {
            toast.add({
                severity: 'success',
                summary: `${data.nama} berhasil dihapus`,
                // detail: res.data.message,
                life: 3000
            })
        }
    })
}

const deleteAnak = async(data) => {
    console.log(data)
    let detail = {
        nif: route.params.nif,
        nis: data.nis
    }
    confirm.require({
        message: `Apakah kamu yakin ingin menghaput ${data.nama} dari keluarga dengan NIF ${route.params.nif} ?`,
        header: 'Konfirmasi Penghapusan',
        icon: 'pi pi-info-circle',
        acceptClass: 'p-button-danger',
        accept: async () => {
            console.log(detail)
            await axios.delete('/v2/family/add-child', detail) 
            .then(res => {
                toast.add({
                    severity: 'success',
                    summary: `${data.nama} berhasil dihapus`,
                    detail: res.data.message,
                    life: 3000
                })
                fetchKeluargaDetail()
            })
            .catch( err => {
                toast.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: err.response.data.message,
                    life:3000
                })
            })
        }
    })
}

const updateChild = async() => {
    let data = {
        nif: formUpdateChild.value.nif,
        nis: formUpdateChild.value.anak.nis
    }
    await axios.patch('/v2/family/add-child', data)
    .thes(res => {
        toast.add({
            severity: 'success',
            summary: 'Data Keluarga Berhasil di Update',
            detail: res.data.message,
            life: 3000
        })
        isWantToAddAnak.value = false
        fetchKeluargaDetail()
    })
    .catch(err => {
        toast.add({
            severity: 'error',
            summary: 'Error',
            detail: err.response.data.message,
            life: 3000
        })
    })

}

const confirmDelete = () => {
    confirm.require({
        message: `Apakah kamu yakin ingin menghapus data ${family.value.nif}`,
        header: `Konfirmasi Penghapusan`,
        icon: 'pi pi-info-circle',
        acceptClass: 'p-button-danger',
        accept: async() => {
            await axios.delete(`/v2/family/detail/${family.value.nif}`)
            .then(() => {
                router.push('/families')
            })
            .catch(err => {
                toast.add({
                    severity: 'error',
                    summary: 'Data Gagal Dihapus',
                    detail: err.respons.data.message,
                    life: 3000
                })
            })
        }
        
    })
}
</script>