<template>
    <Card class="p-2 m-0">
        <template #title>
            <div class="flex justify-between align-middle mt-4">
                <span class="p-float-label mr-1 w-auto">
                    <Calendar class="w-auto" v-model="form.date" inputId="birth_date" dateFormat="dd MM yy" selection-mode="range"  @update:model-value="fetchStatusTarget()"/>
                    <label for="birth_date">Rentang Tanggal</label>
                </span>
            </div>
        </template>
        <template #header>
            <div class="text-lg font-semibold">
                <span v-if="moment(form.date[0]).isSame(moment(form.date[1]))">
                    Status Pencapaian Target <br> {{ moment(form.date[0]).format('DD MMMM YYYY') }}
                </span>
                <span v-else >
                    Status Pencapaian Target <br> {{ moment(form.date[0]).format('DD MMMM YYYY') }} - {{ moment(form.date[1]).format('DD MMMM YYYY') }}
                </span>
            </div>
        </template>
        <template #content v-if="data.statusMurid.detail !== null">
            <div v-if="data.statusMurid.detail.length === 0">
                Tidak ada data tercatat
            </div>
            <div class="flex justify-between" v-else>
                <div class="flex flex-col justify-center items-center" >
                    <div v-if="data.status.hafalan !== undefined">
                        <Knob 
                        v-model="data.status.hafalan.jumlah" 
                        :value-template="valueTemplate(data.status.hafalan.jumlah, data.status.hafalan.jumlahTarget)" :min="0" 
                        :value-color="data.status.hafalan.jumlah < data.status.hafalan.jumlahTarget ? 'red' : 'green'"
                        :max="data.status.hafalan.jumlahTarget < data.status.hafalan.jumlah ? data.status.hafalan.jumlah : data.status.hafalan.jumlahTarget" 
                        readonly/>
                        <span>Hafalan</span>
                    </div>
                    <span v-else> Tidak ada data Hafalan.</span>
                </div>
                <div class="flex flex-col justify-center items-center" >
                    <div v-if="data.status.murojahBaru !== undefined">
                        <Knob v-model="data.status.murojahBaru.jumlah" 
                        :value-template="valueTemplate(data.status.murojahBaru.jumlah, data.status.murojahBaru.jumlahTarget)" :min="0" 
                        :value-color="data.status.murojahBaru.jumlah < data.status.murojahBaru.jumlahTarget ? 'red' : 'green'"
                        :max="data.status.murojahBaru.jumlahTarget < data.status.murojahBaru.jumlah ? data.status.murojahBaru.jumlah : data.status.murojahBaru.jumlahTarget" 
                        readonly/>
                        <span>Murojah Hafalan Baru</span>
                    </div>
                    <span v-else>Tidak ada data Murojah Hafalan Baru</span>
                </div>
                <div class="flex flex-col justify-center items-center" >
                    <div v-if="data.status.murojah !== undefined">
                        <Knob v-model="data.status.murojah.jumlah" 
                        :value-template="valueTemplate(data.status.murojah.jumlah, data.status.murojah.jumlahTarget)" 
                        :value-color="data.status.murojah.jumlah < data.status.murojah.jumlahTarget ? 'red' : 'green'"
                        :min="0" 
                        :max="data.status.murojah.jumlahTarget < data.status.murojah.jumlah ? data.status.murojah.jumlah : data.status.murojah.jumlahTarget" 
                        readonly/>
                        <span>Murojah Hafalan Lama</span>
                    </div>
                    <span v-else>Tidak ada data Murojah Hafalan Lama</span>
                </div>

            </div>
        </template>
    </Card>
</template>

<script setup>
import axios from 'axios';
import moment from 'moment';
import { onMounted, reactive, ref } from 'vue';
import { useRoute } from 'vue-router';

const data = reactive ({
    statusMurid: {
        detail: [],
    },
    status: {
        hafalan: {},
        murojah: {},
        murojahBaru: {},
    }
})
const statusMurid = ref(null)
const route = useRoute()

const valueTemplate = (value, max) => {
    return `${value}/${max}`
}

const form = reactive({
    date: [new Date(), new Date()],
    tipeSesi: 'Hafalan',
})

const fetchStatusTarget = async() => {
    const date = []
    date[0] = moment(form.date[0]).set({hour: 0, minute: 0, second: 0}).toDate()
    date[1] = moment(form.date[1]).set({ hour: 23, minute: 59, second: 59}).toDate()

    const param = {
        date: date,
        tipeSesi: form.tipeSesi
    }
    await axios.get(`/v2/murid/tahfidz/target/${route.params.nis}`, {
        params: param
    })
    .then(res => {
        statusMurid.value = res.data.data
        data.statusMurid = res.data.data
        
        data.status = {
            hafalan: undefined,
            murojah: undefined,
            murojahBaru: undefined,
        }

        data.status.hafalan = data.statusMurid.detail.find(query => query.tipeSesi === 'Hafalan')
        switch (data.status.hafalan.satuanTarget) {
            case 'Halaman': 
                data.status.hafalan.jumlah = data.status.hafalan._sum.jumlahHalaman
                data.status.hafalan.jumlahTarget = data.status.hafalan.target * data.statusMurid.days
                break;
            case 'Ayat': 
                data.status.hafalan.jumlah = data.status.hafalan._sum.jumlahAyat
                data.status.hafalan.jumlahTarget = data.status.hafalan.target * data.statusMurid.days
                break;
            case 'Baris': 
                data.status.hafalan.jumlah = data.status.hafalan._sum.jumlahBaris
                data.status.hafalan.jumlahTarget = data.status.hafalan.target * data.statusMurid.days
                break;
            case 'Juz': 
                data.status.hafalan.jumlah = data.status.hafalan._sum.jumlahHalaman / 20
                data.status.hafalan.jumlahTarget = data.status.hafalan.target * data.statusMurid.days
                break;
        }

        data.status.murojah = data.statusMurid.detail.find(query => query.tipeSesi === 'Murojah')
        switch (data.status.murojah.satuanTarget) {
            case 'Halaman': 
                data.status.murojah.jumlah = data.status.murojah._sum.jumlahHalaman
                data.status.murojah.jumlahTarget = data.status.murojah.target * data.statusMurid.days
                break;
            case 'Ayat': 
                data.status.murojah.jumlah = data.status.murojah._sum.jumlahAyat
                data.status.murojah.jumlahTarget = data.status.murojah.target * data.statusMurid.days
                break;
            case 'Baris': 
                data.status.murojah.jumlah = data.status.murojah._sum.jumlahBaris
                data.status.murojah.jumlahTarget = data.status.murojah.target * data.statusMurid.days
                break;
            case 'Juz': 
                data.status.murojah.jumlah = data.status.murojah._sum.jumlahHalaman / 20
                data.status.murojah.jumlahTarget = data.status.murojah.target * data.statusMurid.days
                break;
        }
        
        data.status.murojahBaru = data.statusMurid.detail.find(query => query.tipeSesi === 'Murojah_Baru')
        switch (data.status.murojahBaru.satuanTarget) {
            case 'Halaman': 
                data.status.murojahBaru.jumlah = data.status.murojahBaru._sum.jumlahHalaman
                data.status.murojahBaru.jumlahTarget = data.status.murojahBaru.target * data.statusMurid.days
                break;
            case 'Ayat': 
                data.status.murojahBaru.jumlah = data.status.murojahBaru._sum.jumlahAyat
                data.status.murojahBaru.jumlahTarget = data.status.murojahBaru.target * data.statusMurid.days
                break;
            case 'Baris': 
                data.status.murojahBaru.jumlah = data.status.murojahBaru._sum.jumlahBaris
                data.status.murojahBaru.jumlahTarget = data.status.murojahBaru.target * data.statusMurid.days
                break;
            case 'Juz': 
                data.status.murojahBaru.jumlah = data.status.murojahBaru._sum.jumlahHalaman / 20
                data.status.murojahBarujumlahTarget = data.status.murojahBaru.target * data.statusMurid.days
                break;
        }
    })
    .catch(err => {
        console.log(err)
    })
}

onMounted(async () => {
    await fetchStatusTarget()
})

</script>