<template>
	<Toast />
	<NavbarVue />
	<BlockUI :blocked="isLoading" :full-screen="true" />
	<div class="mt-2 p-2 text-center">
		<div class="flex justify-between px-2">
			<h1 class="text-2xl">Database Murid</h1>
			<div class="" v-if="$store.state.role === 'ADMIN' || $store.state.role === 'TU_PESANTREN'">
				<span>{{ totalBanat }} data Banat</span><br>
				<span>{{ totalBanin }} data Banin</span>
			</div>
			<button class="rounded border bg-ltDarkGreen px-2 text-white" @click="isWantInputMurid = true"
				v-if="canAddMurid">Create</button>
			<!-- <Button icon="pi pi-plus" @click="isWantInputMurid = true"/>   -->
		</div>
		<div class="px-2 sm:px-4 mt-4">
			<div class="card shadow">
				<DataTable :value="muridList" :paginator="true" class="p-datatable-customers" showGridlines :rows="10"
					dataKey="id" v-model:filters="muridFilter" filterDisplay="menu" :loading="loading"
					paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[10,25,50]"
					currentPageReportTemplate="Menampilkan data ke-{first} hingga {last} data dari {totalRecords} seluruh data"
					responsiveLayout="scroll" :globalFilterFields="['nama', 'nis']">
					<template #header>
						<div class="flex justify-between">
							<div class="hidden sm:block">
								<Button type="button" icon="pi pi-filter-slash" label="Clear"
									class="p-button-outlined hidden sm:block" @click="clearFilter" />
							</div>
							<span class="p-input-icon-left">
								<i class="pi pi-search" />
								<InputText v-model="muridFilter['global'].value" placeholder="Keyword Pencarian" />
							</span>
							<div class="hidden sm:block">
								<Button icon="pi pi-refresh" @click="updateData" />
							</div>
						</div>
					</template>
					<template #empty>
						Tidak ada data Murid.
					</template>
					<template #loading>
						Memuat data Murid.
					</template>
					<Column field="nis" header="NIS" style="min-width: 12-rem; ">
						<template #body="{ data }">
							{{ data.nis }}
						</template>
						<template #filter="{ filterModel }">
							<InputText type="text" v-model="filterModel.value" class="p-column-filter"
								placeholder="Cari berdasarkan NIS" />
						</template>
					</Column>
					<Column field="nama" header="Nama" style="min-width: 24rem; ">
						<template #body="{ data }">
							<router-link :to="`/murid/${data.nis}`" class="flex items-center">
								<img :src="data.profileUrl" alt="" width="40" :class="flag">
								<div class="flex-row px-2">
									<p class="text-base">{{ data.nama }}</p>
									<p class="text-xs">NISN: {{ data.nisn }}</p>
									<p class="text-xs">NIK: {{ data.nik }}</p>
								</div>
							</router-link>
						</template>
						<template #filter="{ filterModel }">
							<InputText type="text" v-model="filterModel.value" class="p-column-filter"
								placeholder="Cari berdasarkan Nama / NIS / NISM" />
						</template>
					</Column>
					<Column field="gender" header="Jenis Kelamin" style="min-width: 12-rem; ">
						<template #body="{ data }">
							{{ data.jenisKelamin == 'M' ? 'Laki-Laki' : 'Perempuan' }}
						</template>
						<template #filter="{ filterModel }">
							<InputText type="text" v-model="filterModel.value" class="p-column-filter"
								placeholder="Cari berdasarkan Gender" />
						</template>
					</Column>
					<Column field="ttl" header="Tempat, Tanggal Lahir" style="min-width: 16rem; ">
						<template #body="{ data }">
							{{ data.tempatLahir }}, {{ new Date(data.tanggalLahir).getDate() }} {{
						monthName[new
							Date(data.tanggalLahir).getMonth()]}} {{ new Date(data.tanggalLahir).getFullYear() }}
						</template>
						<template #filter="{ filterModel }">
							<InputText type="text" v-model="filterModel.value" class="p-column-filter"
								placeholder="Cari berdasarkan TTL" />
						</template>
					</Column>
					<Column style="min-width: 12rem; " header="Action" v-if="$store.state.role === 'ADMIN'">
						<template #body="{ data }">
							<button v-if="data.deleted == true"
								class="px-2 rounded p-1 bordered bg-ltDarkGreen hover:bg-ltLightGreen text-white"
								@click="recoveryMurid(data.nis)">Recovery</button>
							<span v-else>No Action needed.</span>
						</template>
					</Column>
					<Column style="min-width: 12rem; " header="Action"
						v-if="$store.state.role === 'KEPALA_TAHFIDZ' || $store.state.role === 'ADMIN' || $store.state.role === 'KEPALA_TAHFIDZ_PUTRA' || $store.state.role === 'KEPALA_TAHFIDZ_PUTRI'">
						<template #body="{ data }">
							<button v-if="!data.detail.user.isGuru"
								class="px-2 rounded p-1 bordered bg-ltDarkGreen hover:bg-ltLightGreen text-white"
								@click="iWantToPromoteMuridToGuruTahfidz(data)">Set Guru Tahfidz</button>
							<span v-else>No Action needed.</span>
						</template>
					</Column>
				</DataTable>
			</div>
		</div>
	</div>
	<!-- <InputMurid v-if="isWantInputMurid" @close="isWantInputMurid = false"/> -->
	<ConfirmDialog :breakpoints="{ '960px': '75vw', '640px': '100vw' }" :style="{ width: '50vw' }"></ConfirmDialog>

	<!-- Dialog for Input 4Murid -->
	<Dialog :modal="true" header="Masukkan Data Murid" v-model:visible="isWantInputMurid"
		:breakpoints="{ '960px': '75vw', '640px': '100vw' }">
		<div class="flex-col justify-center">
			<div class="field">
				<span class="p-float-label mt-6 ">
					<InputText type="number" v-model.trim="v$.nik.$model" class="w-full"
						placeholder="167XXXXXXXXXXXXX (16 Digit)" @blur="v$.nik.$touch" />
					<label class="text-base">NIK</label>
				</span>
				<div class="flex ml-2 mt-1" v-for="error of v$.nik.$errors" :key="error.$uid">
					<small class="p-error">{{ error.$message }}</small>
				</div>
			</div>
			<div class="field">
				<span class="p-float-label mt-6 ">
					<InputText type="text" v-model.trim="v$.nama.$model" class="w-full" placeholder="Muhammad Agus"
						@blur="v$.nama.$touch" />
					<label class="text-base">Nama Lengkap</label>
				</span>
				<div class="flex ml-2 mt-1" v-for="error of v$.nama.$errors" :key="error.$uid">
					<small class="p-error">{{ error.$message }}</small>
				</div>
			</div>
			<span class="p-float-label mt-6 ">
				<Dropdown v-model="v$.jenisKelamin.$model" class="w-full" :options="jenisKelamin" optionLabel="view"
					optionValue="code" @blur="v$.jenisKelamin.$touch" />
				<label class="text-base">Jenis Kelamin</label>
			</span>
			<div class="flex ml-2 mt-1" v-for="error of v$.jenisKelamin.$errors" :key="error.$uid">
				<small class="p-error">{{ error.$message }}</small>
			</div>

			<span class="p-float-label mt-6 ">
				<InputText type="text" v-model="v$.tempatLahir.$model" class="w-full" placeholder="Pagar Alam"
					@blur="v$.tempatLahir.$touch" />
				<label class="text-base">Tempat Lahir</label>
			</span>
			<div class="flex ml-2 mt-1" v-for="error of v$.tempatLahir.$errors" :key="error.$uid">
				<small class="p-error">{{ error.$message }}</small>
			</div>

			<span class="p-float-label mt-6 ">
				<Calendar v-model="v$.tanggalLahir.$model" class="w-full" dateFormat="dd MM yy"
					placeholder="Lahirnya kapan?" @blur="v$.tanggalLahir.$touch" />
				<label class="text-base">Tanggal Lahir</label>
			</span>
			<div class="flex ml-2 mt-1" v-for="error of v$.tanggalLahir.$errors" :key="error.$uid">
				<small class="p-error">{{ error.$message }}</small>
			</div>

			<span class="p-float-label mt-6 ">
				<InputText type="text" v-model="v$.anakKe.$model" class="w-full" placeholder="Kamu anak ke berapa?"
					@blur="v$.anakKe.$touch" />
				<label class="text-base">Anak Ke</label>
			</span>
			<div class="flex ml-2 mt-1" v-for="error of v$.anakKe.$errors" :key="error.$uid">
				<small class="p-error">{{ error.$message }}</small>
			</div>


			<span class="p-float-label mt-6 ">
				<InputText type="text" v-model="v$.jumlahSaudaraKandung.$model" class="w-full"
					placeholder="Berapa Saudara Kandung kamu?" @blur="v$.jumlahSaudaraKandung.$touch" />
				<label class="text-base">Jumlah Saudara Kandung</label>
			</span>
			<div class="flex ml-2 mt-1" v-for="error of v$.jumlahSaudaraKandung.$errors" :key="error.$uid">
				<small class="p-error">{{ error.$message }}</small>
			</div>

			<span class="p-float-label mt-6 ">
				<InputText type="text" v-model="v$.jumlahSaudaraTiri.$model" class="w-full"
					placeholder="Berapa Saudara Tirinya?" @blur="v$.jumlahSaudaraTiri.$touch" />
				<label class="text-base">Jumlah Saudara Tiri</label>
			</span>
			<div class="flex ml-2 mt-1" v-for="error of v$.jumlahSaudaraTiri.$errors" :key="error.$uid">
				<small class="p-error">{{ error.$message }}</small>
			</div>

			<span class="p-float-label mt-6 ">
				<InputText type="text" v-model="v$.jumlahSaudaraAngkat.$model" class="w-full"
					placeholder="Berapa Saudara Angkatnya?" @blur="v$.jumlahSaudaraAngkat.$touch" />
				<label class="text-base">Jumlah Saudara Angkat</label>
			</span>
			<div class="flex ml-2 mt-1" v-for="error of v$.jumlahSaudaraAngkat.$errors" :key="error.$uid">
				<small class="p-error">{{ error.$message }}</small>
			</div>

			<span class="p-float-label mt-6">
				<Dropdown v-model="v$.golonganDarah.$model" class="w-full" :options="golonganDarahList"
					optionValue="value" optionLabel="name" placeholder="Golongan Darah"
					@blur="v$.golonganDarah.$touch" />
				<label class="text-base">Golongan Darah</label>
			</span>
			<div class="flex ml-2 mt-1" v-for="error of v$.golonganDarah.$errors" :key="error.$uid">
				<small class="p-error">{{ error.$message }}</small>
			</div>

			<!-- Foto Profil -->
			<label for="name" class="text-sm mt-1">
				Foto Profil
			</label>

			<input
				class="mb-5 mt-2 block w-full text-sm text-l border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
				id="file_input" type="file" ref="file" @change="uploadFile" />
			<div class="flex-ml-2 mt-1" v-for="error of v$.image.$errors" :key="error.$uid">
				<small class="p-error">{{ error.$message }}</small>
			</div>

			<span class="p-float-label mt-6 ">
				<InputText type="text" v-model="v$.nisn.$model" class="w-full" placeholder="XXXXXXXXXX (10 digit)"
					@blur="v$.nisn.$touch" />
				<label class="text-base">NISN</label>
			</span>
			<div class="flex ml-2 mt-1" v-for="error of v$.nisn.$errors" :key="error.$uid">
				<small class="p-error">{{ error.$message }}</small>
			</div>

			<span class="p-float-label mt-6 ">
				<InputText type="text" v-model="v$.tahunMasuk.$model" placeholder="Bukan Tahun masuk ke pesantren"
					class="w-full" @blur="v$.tahunMasuk.$touch" />
				<label class="text-base">Tahun Masuk</label>
			</span>
			<div class="flex ml-2 mt-1" v-for="error of v$.tahunMasuk.$errors" :key="error.$uid">
				<small class="p-error">{{ error.$message }}</small>
			</div>

			<span class="p-float-label mt-6 ">
				<Dropdown v-model="v$.firstLembaga.$model" class="w-full" :options="lembagaLantaburList"
					optionLabel="name" optionValue="value" placeholder="Pertama kali masuk ke?"
					@blur="v$.firstLembaga.$touch" />
				<label class="text-base">Di Lan Tabur Sejak</label>
			</span>
			<div class="flex ml-2 mt-1" v-for="error of v$.firstLembaga.$errors" :key="error.$uid">
				<small class="p-error">{{ error.$message }}</small>
			</div>
		</div>

		<template #footer>
			<button icon="pi" @click="isWantInputMurid = false" class="border rounded p-2 mx-2">
				Cancel
			</button>
			<button class="border rounded bg-ltDarkGreen text-white py-2 px-4 mx-2 hover:bg-ltLightGreen" icon="pi"
				@click="inputMurid(data)">
				Simpan
			</button>
		</template>
	</Dialog>
	<!-- Dialog for Promoting Sannti as Guru Tahfidz -->
	<Dialog :modal="true" header="Promote Murid sebagai Guru Tahfidz"
		v-model:visible="isWantToPromoteMuridToGuruTahfidz">
		<!-- Email -->
		<span class="p-float-label mt-6">
			<InputText v-model.trim="vPromoteGuruTahfidz$.email.$model" placeholder="muhammad@gmail.com" class="w-full"
				@blur="vPromoteGuruTahfidz$.email.$touch" />
			<label for="email">Email</label>
		</span>
		<div class="flex ml-2 mt-1" v-for="error of vPromoteGuruTahfidz$.email.$errors" :key="error.$uid">
			<small class="p-error">{{ error.$message }}</small>
		</div>
		<!-- Kode Negara -->
		<span class="p-float-label mt-6">
			<Dropdown v-model="vPromoteGuruTahfidz$.countryCode.$model" class="w-full" :options="countryCodeList"
				optionValue="phone_code" optionLabel="country_name" placeholder="+62" filter="true"
				@blur="vPromoteGuruTahfidz$.countryCode.$touch">
				<template #option="country">
					<div class="flex justify-between">
						<span>{{ country.option.country_name }}</span>
						<span>+{{ country.option.phone_code }}</span>
					</div>
				</template>
			</Dropdown>
			<label for="">Kode Negara</label>
		</span>
		<div class="flex ml-2 mt-1" v-for="error of vPromoteGuruTahfidz$.countryCode.$errors" :key="error.$uid">
			<small class="p-error">{{ error.$message }}</small>
		</div>
		<!-- Nomor Telepon Aktif -->
		<span class="p-float-label mt-6">
			<InputText v-model.trim="vPromoteGuruTahfidz$.nomorHp.$model" placeholder="08XXXXXXXXXX (12 digit)"
				class="w-full" @blur="vPromoteGuruTahfidz$.nomorHp.$touch" />
			<label for="nomorHP">Nomor Telepon Aktif</label>
		</span>
		<div class="flex ml-2 mt-1" v-for="error of vPromoteGuruTahfidz$.nomorHp.$errors" :key="error.$uid">
			<small class="p-error">{{ error.$message }}</small>
		</div>
		<!-- Nama Ayah -->
		<span class="p-float-label mt-6">
			<InputText v-model.trim="vPromoteGuruTahfidz$.namaAyah.$model" placeholder="Usman" class="w-full"
				@blur="vPromoteGuruTahfidz$.namaAyah.$touch" />
			<label for="namaAyah">Nama Ayah</label>
		</span>
		<div class="flex ml-2 mt-1" v-for="error of vPromoteGuruTahfidz$.namaAyah.$errors" :key="error.$uid">
			<small class="p-error">{{ error.$message }}</small>
		</div>
		<!-- Nama Ibu -->
		<span class="p-float-label mt-6">
			<InputText v-model.trim="vPromoteGuruTahfidz$.namaIbu.$model" placeholder="Yasniar" class="w-full"
				@blur="vPromoteGuruTahfidz$.namaIbu.$touch" />
			<label for="namaIbu">Nama Ibu</label>
		</span>
		<div class="flex ml-2 mt-1" v-for="error of vPromoteGuruTahfidz$.namaIbu.$errors" :key="error.$uid">
			<small class="p-error">{{ error.$message }}</small>
		</div>
		<template #footer>
			<button icon="pi" @click="isWantInputMurid = false" class="border rounded p-2 mx-2">
				Cancel
			</button>
			<button class="border rounded bg-ltDarkGreen text-white py-2 px-4 mx-2 hover:bg-ltLightGreen" icon="pi"
				@click="toSecondStepPromoteMurid()">
				Berikutnya
			</button>
		</template>
	</Dialog>
	<Dialog :modal="true" v-model:visible="secondStepPromoteMurid" header="Riwayat Pendidikan" :breakpoints="{ '960px': '75vw', '640px': '100vw' }">
		<div class="flex-col justify-center card">
			<DataTable :value="riwayatPendidikanArray" responsiveLayout="scroll">
				<template #header>
					<div class="flex justify-between">
						<h5>
							Riwayat Pendidikan
						</h5>
						<button class="bg-ltDarkGreen text-white rounded border p-1 px-2 hover:bg-ltLightGreen"
							@click="toAddRiwayatPendidikan()">
							<i class="pi pi-plus"></i>
						</button>
					</div>
				</template>
				<template #empty>
					Belum Ada Riwayat Pendidikan silahkan di input terlebih dahulu.
				</template>
				<Column header="Jenjang Pendidikan" field="jenjangPendidikan" />
				<Column header="Nama Lembaga" field="namaLembaga" />
				<Column header="Tahun Lulus" field="tahunLulus" />
				<Column header="Jurusan" field="jurusan" />
				<Column header="Gelar" field="gelar" />
				<Column header="Action">
					<template #body="{ index }">
						<button @click="deleteRiwayatPendidikan(index)"
							class="border rounded py-1 px-2 ml-1 bg-red-500 text-white ">
							<font-awesome-icon icon="fa-solid fa-trash" />
						</button>
					</template>
				</Column>
			</DataTable>
		</div>
		<!-- Riwayat Pendidikan -->
		<Dialog :modal="true" v-model:visible="isWantToAddRiwayatPendidikan" header="Tambah Riwayat Pendidikan" >
			<!-- Jenjang Pendidikan - DropDown -->
			<div class="field">
				<span class="p-float-label mt-6">
					<Dropdown v-model="vRiwayatPendidikan$.jenjangPendidikan.$model" class="w-full"
						:options="pendidikanList" optionValue="value" optionLabel="name" placeholder="SLTA kah? "
						@blur="vRiwayatPendidikan$.jenjangPendidikan.$touch" />
					<label class="text-base">Jenjang Pendidikan</label>
				</span>
				<div class="flex ml-2 mt-1" v-for="error of vRiwayatPendidikan$.jenjangPendidikan.$errors"
					:key="error.$uid">
					<small class="p-error">{{ error.$message }}</small>
				</div>
			</div>
			<!-- Nama Lembaga InputText-->
			<div class="field">
				<span class="p-float-label mt-6">
					<InputText v-model="vRiwayatPendidikan$.namaLembaga.$model" type="text" class="w-full"
						placeholder="Lan Tabur" @blur="vRiwayatPendidikan$.namaLembaga.$touch" />
					<label class="text-base">Nama Lembaga</label>
				</span>
				<div class="flex ml-2 mt1-" v-for="error of vRiwayatPendidikan$.namaLembaga.$errors" :key="error.$uid">
					<small class="p-error">{{ error.$message }}</small>
				</div>
			</div>
			<!-- Tahun Lulus Number-->
			<div class="field">
				<span class="p-float-label mt-6">
					<InputText v-model="vRiwayatPendidikan$.tahunLulus.$model" type="number" class="w-full"
						placeholder="19XX" @blur="vRiwayatPendidikan$.tahunLulus.$touch" />
					<label class="text-base">Tahun Lulus</label>
				</span>
				<div class="flex ml-2 mt-1" v-for="error of vRiwayatPendidikan$.tahunLulus.$errors" :key="error.$uid">
					<small class="p-error">{{ error.$message }}</small>
				</div>
			</div>
			<!-- Jurusan Input Text -->
			<div class="field">
				<span class="p-float-label mt-6">
					<InputText v-model="vRiwayatPendidikan$.jurusan.$model" type="text" class="w-full"
						placeholder="Kosongkan jika tidak ada Jurusan" @blur="vRiwayatPendidikan$.jurusan.$touch" />
					<label class="text-base">Jurusan</label>
				</span>
				<div class="flex ml-2 mt-1" v-for="error of vRiwayatPendidikan$.jurusan.$errors" :key="error.$uid">
					<small class="p-error">{{ error.$message }}</small>
				</div>
			</div>
			<!-- Gelar Input Text -->
			<div class="field">
				<span class="p-float-label mt-6">
					<InputText v-model="vRiwayatPendidikan$.gelar.$model" type="text" class="w-full"
						placeholder="Kosongkan jika tidak ada gelar" @blur="vRiwayatPendidikan$.gelar.$touch" />
					<label class="text-base">Gelar</label>
				</span>
				<div class="flex ml-2 mt-1" v-for="error of vRiwayatPendidikan$.gelar.$errors" :key="error.$uid">
					<small class="p-error">{{ error.$message }}</small>
				</div>
			</div>
			<template #footer>
				<button icon="pi" @click="isWantToAddRiwayatPendidikan = false" class="border rounded p-2 mx-2">
					Kembali
				</button>
				<button class="border rounded bg-ltDarkGreen text-white py-2 px-4 mx-2 hover:bg-ltLightGreen"
					label="Login" icon="pi" @click="saveRiwayatPendidikan()">
					Berikutnya
				</button>
			</template>
		</Dialog>
		<template #footer>
			<button icon="pi" @click="isWantToPromoteMuridToGuruTahfidz = true; secondStepPromoteMurid = false;"
				class="border rounded p-2 mx-2">
				Kembali
			</button>
			<button class="border rounded bg-ltDarkGreen text-white py-2 px-4 mx-2 hover:bg-ltLightGreen" label="Login"
				icon="pi" @click="promoteMuridToGuruTahfidz()">
				Simpan
			</button>
		</template>
	</Dialog>
</template>

<script setup>
import NavbarVue from '@/components/Navbar.vue';


import { onMounted, ref, reactive } from 'vue';
import axios from '@/api/axios';
// import { useStore } from 'vuex';
// import { useVuelidate } from '@vuelidate/core'
import { required, minLength, maxLength, numeric, helpers, between, minValue, email } from '@vuelidate/validators'
// import { useRouter } from 'vue-router';
// import { useConfirm } from 'primevue/useconfirm'
import { useToast } from 'primevue/usetoast'

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Dialog from 'primevue/dialog';
import { FilterMatchMode, ToastSeverity } from 'primevue/api';
import moment from 'moment';
import useVuelidate from '@vuelidate/core';
import { isArray } from '@vue/shared';
import { useStore } from 'vuex';
import { countryCode } from '@/utils/coutryCode';


const store = useStore()
const isWantInputMurid = ref(false)
const isWantToPromoteMuridToGuruTahfidz = ref(false)

const secondStepPromoteMurid = ref(false)

const formPromoteMuridAsGuruTahfidz = reactive({})
const riwayatPendidikanArray = ref([])

const formInputMurid = reactive({})
const isLoading = ref(false)
// const confirm = useConfirm()
const toast = useToast()

const canAddMurid = ref(false)

const countryCodeList = ref(countryCode)

const iWantToPromoteMuridToGuruTahfidz = async (data) => {
	isWantToPromoteMuridToGuruTahfidz.value = true
	formPromoteMuridAsGuruTahfidz.nis = data.nis
}


const promoteMuridAsGuruTahfidzRules = {
	email: {
		required: helpers.withMessage('Email tidak boleh kosong', required),
		email: helpers.withMessage('Format email tidak valid', email)
	},
	countryCode: {
		// required: helpers.withMessage('Kode Negara tidak boleh kosong', required)
	},
	nomorHp: {
		// required: helpers.withMessage('Nemer Telepon tidak boleh kosong', required),
		numeric: helpers.withMessage('Nomor Telepon harus terdiri dari angka', numeric),
	},
	namaAyah: {
		required: helpers.withMessage('Nama Ayah tidak boleh kosong', required),
	},
	namaIbu: {
		required: helpers.withMessage('Nama Ibu tidak boleh kosong', required)
	}
}


const formRiwayatPendidikan = reactive({
	statusMukim: true
})

const inputRiwayatPendidikanRules = {
	jenjangPendidikan: {
		required: helpers.withMessage('Jenjang Pendidikan tidak boleh kosong', required)
	},
	namaLembaga: {
		required: helpers.withMessage('Nama Lembaga tidak boleh kosong', required)
	},
	tahunLulus: {
		required: helpers.withMessage('Tahun Lulus tidak boleh kosong', required),
		numeric: helpers.withMessage('Tahun Lulus harus berupa angka', numeric),
		beetwen: helpers.withMessage(`Tahun Lulus harus berada di rentang 1900 hingga ${(moment(Date.now()).format('YYYY'))}`, between(1900, moment(Date.now()).format('YYYY')))
	},
	jurusan: {},
	gelar: {}
}

const vPromoteGuruTahfidz$ = useVuelidate(promoteMuridAsGuruTahfidzRules, formPromoteMuridAsGuruTahfidz)
const vRiwayatPendidikan$ = useVuelidate(inputRiwayatPendidikanRules, formRiwayatPendidikan)

const isWantToAddRiwayatPendidikan = ref(false)

const pendidikanList = ref([
	{ name: 'Taman Kanak-Kanak / sederajat', value: 'TK' },
	{ name: 'Sekolah Dasar / sederajat', value: 'SD' },
	{ name: 'Sekolah Menegah Pertama / sederajat', value: 'SMP' },
	{ name: 'Sekolah Menegah Atas / sederajat', value: 'SMA' },
	{ name: 'Diploma / sederajat', value: 'Diploma' },
	{ name: 'Strata 1', value: 'S1' },
	{ name: 'Strata 2', value: 'S2' },
	{ name: 'Strata 3', value: 'S3' },
])

const toAddRiwayatPendidikan = () => {
	formRiwayatPendidikan.jenjangPendidikan = null
	formRiwayatPendidikan.namaLembaga = null
	formRiwayatPendidikan.tahunLulus = null
	formRiwayatPendidikan.jurusan = null
	formRiwayatPendidikan.gelar = null
	vRiwayatPendidikan$.value.$reset()
	isWantToAddRiwayatPendidikan.value = true
}

const toSecondStepPromoteMurid = async () => {
	const isValid = await vPromoteGuruTahfidz$.value.$validate()

	if (isValid) {
		secondStepPromoteMurid.value = true
		isWantToPromoteMuridToGuruTahfidz.value = false
	}
}

const saveRiwayatPendidikan = async () => {
	const isFormCorrect = await vRiwayatPendidikan$.value.$validate()
	const data = {
		jenjangPendidikan: formRiwayatPendidikan.jenjangPendidikan,
		namaLembaga: formRiwayatPendidikan.namaLembaga,
		tahunLulus: formRiwayatPendidikan.tahunLulus,
		jurusan: formRiwayatPendidikan.jurusan,
		gelar: formRiwayatPendidikan.gelar,
	}
	if (isFormCorrect) {
		console.log(formRiwayatPendidikan)
		riwayatPendidikanArray.value.push(data)
		isWantToAddRiwayatPendidikan.value = false
	}
}

const initValuePromoteMurid = () => {
	formPromoteMuridAsGuruTahfidz.email = null
	formPromoteMuridAsGuruTahfidz.countryCode = null
	formPromoteMuridAsGuruTahfidz.nomorHp = null
	formPromoteMuridAsGuruTahfidz.namaAyah = null
	formPromoteMuridAsGuruTahfidz.namaIbu = null
	riwayatPendidikanArray.value = []
}

const promoteMuridToGuruTahfidz = async () => {
	const isFormCorrect = await vPromoteGuruTahfidz$.value.$validate()

	if (riwayatPendidikanArray.value === null || riwayatPendidikanArray.value.length < 1) {
		toast.add({
			severity: ToastSeverity.ERROR,
			detail: 'Riwayat Pendidikan tidak boleh kosong! Minimal Input Data TK atau SD! Jangan pemalas input data!',
			summary: 'Gagal Promote Murid jadi Guru Tahfidz',
			life: 3000
		})
		return
	}

	if (isFormCorrect) {
		isLoading.value = true

		const form = {
			email: formPromoteMuridAsGuruTahfidz.email,
			namaAyah: formPromoteMuridAsGuruTahfidz.namaAyah,
			namaIbu: formPromoteMuridAsGuruTahfidz.namaIbu,
			riwayatPendidikan: JSON.stringify(riwayatPendidikanArray.value)
		}

		console.log(formPromoteMuridAsGuruTahfidz.nomorTelepon)

		if (formPromoteMuridAsGuruTahfidz.nomorTelepon !== undefined) {
			form.nomorTelepon = `${formPromoteMuridAsGuruTahfidz.countryCode}-${formPromoteMuridAsGuruTahfidz.nomorHp}`
		}

		await axios.post(`/v2/murid/guru-tahfidz/${formPromoteMuridAsGuruTahfidz.nis}`, form)
			.then(res => {
				toast.add({
					severity: ToastSeverity.SUCCESS,
					summary: 'Murid berhasil di jadikan Guru Tahfidz',
					detail: res.data.message,
					life: 3000
				})
				secondStepPromoteMurid.value = false
				isLoading.value = false
				initValuePromoteMurid()
				updateData()
			})
			.catch(err => {
				isLoading.value = false
				if (isArray(err.response.data.error)) {
					const errorList = err.response.data.error
					// console.log(errorList)
					errorList.forEach(data => {
						toast.add({
							severity: 'error',
							summary: "Murid Gagal dinaikkan menjadi gur tahfidz",
							detail: data.msg,
							life: 3000
						})
					})
				}
				else {
					toast.add({
						severity: 'error',
						summary: "Murid Gagal dinaikkan menjadi gur tahfidz",
						detail: err.response.data.message,
						life: 3000
					})
				}
				console.log(err.response.data.error)
			})
	}
}

const inputMuridRules = {
	nisn: {
		required: helpers.withMessage('NISN tidak boleh kosong', required),
		minLength: helpers.withMessage('NISN harus terdiri dari 10 digit angka', minLength(10)),
		maxLength: helpers.withMessage('NISN harus terdiri dari 10 digit angka', maxLength(10)),
	},
	nik: {
		required: helpers.withMessage('NIK tidak boleh kosong', required),
		minLength: helpers.withMessage('NIK harus terdiri dari 16 digit angka', minLength(16)),
		maxLength: helpers.withMessage('NIK harus terdiri dari 16 digit angka', maxLength(16)),
		numeric: helpers.withMessage('NIK hanya boleh terdiri dari angka', numeric)
	},
	nama: {
		required: helpers.withMessage('Nama tidak boleh kosong', required),
		alpha: helpers.withMessage('Nama hanya boleh terdiri dari huruf dan spasi', helpers.regex(/^[a-zA-Z ]*$/)),
		// mustUpperFirst: helpers.withMessage('Setiap Kalimat harus diawali dengan Huruf Besar dan diikuti dengan huru kecil', 
		// value => helpers.regex(/^[A-Z][a-z]+\s[A-Z][a-z]+\s[A-Z][a-z]+$/).test(value))
	},
	firstLembaga: {
		required: helpers.withMessage('Pertama kali bergabung di lantabur tidak boleh kosong', required)
	},
	jenisKelamin: {
		required: helpers.withMessage('Jenis Kelamin tidak boleh kosong', required)
	},
	tempatLahir: {
		required: helpers.withMessage('Tempat Lahir tidak boleh kosong', required)
	},
	tanggalLahir: {
		required: helpers.withMessage('Tanggal Lahir tidak boleh kosong', required),
	},
	anakKe: {
		required: helpers.withMessage('Urutan Anak tidak boleh kosong', required),
		numeric: helpers.withMessage('Urutan Anak harus berupa angka', numeric),
		minValue: helpers.withMessage('Minimal urutan anak adalah 1', minValue(1))
	},
	jumlahSaudaraKandung: {
		required: helpers.withMessage('Jumlah Saudara Kandung tidak boleh kosong', required),
		numeric: helpers.withMessage('Jumlah Saudara Kandung harus berupa angka', numeric),
	},
	jumlahSaudaraTiri: {
		required: helpers.withMessage('Jumlah Saudara Tiri tidak boleh kosong, jika tidak ada isi dengan 0', required),
		numeric: helpers.withMessage('Jumlah Saudara Tiri harus berupa Angka', numeric)
	},
	jumlahSaudaraAngkat: {
		required: helpers.withMessage('Jumlah Saudara Angkat tidak boleh kosong, jika tidak ada isi dengan 0', required),
		numeric: helpers.withMessage('Jumlah Saudara Angkat harus berupa Angka', numeric)
	},
	golonganDarah: {

	},
	tahunMasuk: {
		required: helpers.withMessage('Tahun Masuk tidak boleh kosong', required),
		beetwen: helpers.withMessage(`Tahun Lulus harus berada di rentang 2008 hingga ${(moment(Date.now()).format('YYYY'))}`, between(2008, moment(Date.now()).format('YYYY')))
	},
	image: {
		required: helpers.withMessage('Foto Profil tidak boleh kosong', required)
	}
}

const file = ref(null)

const uploadFile = async () => {
	// console.log(file.value)
	console.log(file.value.files)
	formInputMurid.image = file.value.files[0]
}

const initInputMurid = () => {
	formInputMurid.nik = ''
	formInputMurid.nama = ''
	formInputMurid.jenisKelamin = ''
	formInputMurid.tempatLahir = ''
	formInputMurid.tanggalLahir = ''
	formInputMurid.anakKe = ''
	formInputMurid.jumlahSaudaraKandung = ''
	formInputMurid.jumlahSaudaraTiri = ''
	formInputMurid.jumlahSaudaraAngkat = ''
	formInputMurid.golonganDarah = ''
	formInputMurid.nisn = ''
	formInputMurid.tahunMasuk = ''
	formInputMurid.firstLembaga = ''
}

const v$ = useVuelidate(inputMuridRules, formInputMurid)

const inputMurid = async () => {

	const isValid = await v$.value.$validate()
	if (!isValid) return
	const formData = new FormData()

	if (formInputMurid.golonganDarah) {
		formData.append('golonganDarah', formInputMurid.golonganDarah)
	}

	formData.append('jenisKelamin', formInputMurid.jenisKelamin)
	formData.append('nisn', formInputMurid.nisn)
	formData.append('image', formInputMurid.image)
	formData.append('nama', formInputMurid.nama)
	formData.append('tempatLahir', formInputMurid.tempatLahir)
	formData.append('tanggalLahir', moment(formInputMurid.tanggalLahir).format('YYYY-MM-DD'))
	formData.append('tahunMasuk', formInputMurid.tahunMasuk)
	formData.append('anakKe', formInputMurid.anakKe)
	formData.append('jumlahSaudaraKandung', formInputMurid.jumlahSaudaraKandung)
	formData.append('jumlahSaudaraTiri', formInputMurid.jumlahSaudaraTiri)
	formData.append('jumlahSaudaraAngkat', formInputMurid.jumlahSaudaraAngkat)
	formData.append('firstLembaga', formInputMurid.firstLembaga)
	formData.append('nik', formInputMurid.nik)

	isLoading.value = true
	await axios.post('/v2/murid', formData)
		.then(res => {
			console.log(res.data)
			toast.add({
				severity: 'success',
				summary: 'Data berhasil di Input',
				detail: res.data.message,
				life: 3000
			})
			isLoading.value = false
			isWantInputMurid.value = false
			v$.value.$reset()
			initInputMurid()
			updateData()
		})
		.catch(err => {
			if (isArray(err.response.data.error)) {
				const errorList = err.response.data.error
				// console.log(errorList)
				errorList.forEach(data => {
					toast.add({
						severity: 'error',
						summary: "Input Data Murid Gagal",
						detail: data.msg,
						life: 3000
					})
				})
			}
			else {
				toast.add({
					severity: 'error',
					summary: "Input Data Murid Gagal",
					detail: err.response.data.error.msg,
					life: 3000
				})
			}
			console.log(err.response.data.error)
		})

}

const monthName = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember']

const muridList = ref(null)
const muridFilter = ref({
	'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
	'nis': { value: null, matchMode: FilterMatchMode.CONTAINS },
	'nama': { value: null, matchMode: FilterMatchMode.CONTAINS },
	'gender': { value: null, matchMode: FilterMatchMode.CONTAINS },
	'ttl': { value: null, matchMode: FilterMatchMode.CONTAINS },
})

const clearFilter = () => {
	initFilter()
}

const initFilter = () => {
	muridFilter.value = {
		'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
		'nis': { value: null, matchMode: FilterMatchMode.CONTAINS },
		'nama': { value: null, matchMode: FilterMatchMode.CONTAINS },
		'gender': { value: null, matchMode: FilterMatchMode.CONTAINS },
		'ttl': { value: null, matchMode: FilterMatchMode.CONTAINS },
	}
}
const loading = ref(true);

const jenisKelamin = ref([
	{ code: 'M', view: 'Laki-Laki' },
	{ code: 'F', view: 'Perempuan' },
])

const lembagaLantaburList = ref([
	{ name: 'TKIT', value: 'TK' },
	{ name: 'SDIT', value: 'SD' },
	{ name: 'SMPIT / MTs', value: 'SMP' },
	{ name: 'MA / SMKIT', value: 'SMA' },
])

const golonganDarahList = ref([
	{ name: 'A', value: 'A' },
	{ name: 'B', value: 'B' },
	{ name: 'O', value: 'O' },
	{ name: 'AB', value: 'AB' },
])

const totalBanat = ref(null)
const totalBanin = ref(null)

onMounted(async () => {
	await axios.get('/v2/murid',)
		.then(res => {
			muridList.value = res.data.data
			loading.value = false
			const banat = muridList.value.filter(murid => murid.jenisKelamin === 'F')
			totalBanat.value = banat.length
			const banin = muridList.value.filter(murid => murid.jenisKelamin === 'M')
			totalBanin.value = banin.length
		})

	if (store.state.role === 'ADMIN' || store.state.role === 'TU_PESANTREN') {
		console.log(store.state.role)
		canAddMurid.value = true
	}
})

const updateData = async () => {
	loading.value = true
	await axios.get('/v2/murid')
		.then(res => {
			muridList.value = res.data.data
			loading.value = false
			const banat = muridList.value.filter(murid => murid.jenisKelamin === 'F')
			totalBanat.value = banat.length
			const banin = muridList.value.filter(murid => murid.jenisKelamin === 'M')
			totalBanin.value = banin.length
		})
}

const recoveryMurid = async (nis) => {
	if (store.state.role !== 'ADMIN') {
		toast.add({
			severity: 'Error',
			summary: `Not Authorized`,
			message: 'Not Authorized',
			life: 3000
		})
	}

	await axios.put(`/v2/murid/detail/${nis}`)
		.then(res => {
			console.log(res.data.message)
			toast.add({
				severity: 'success',
				summary: 'Recovery Berhasil',
				detail: res.data.message,
				life: 3000
			})
			updateData()
		})
		.catch(err => {
			toast.add({
				severity: 'error',
				summary: 'Error Occured',
				detail: err.rensponse.data.message,
				life: 3000
			})
		})
}
</script>

<style lang="scss" scoped>
::v-deep(.p-paginator) {
	.p-paginator-current {
		margin-left: auto;
	}
}

::v-deep(.p-progressbar) {
	height: .5rem;
	background-color: #D8DADC;

	.p-progressbar-value {
		background-color: #607D8B;
	}
}

::v-deep(.p-datepicker) {
	min-width: 25rem;

	td {
		font-weight: 400;
	}
}

::v-deep(.p-datatable.p-datatable-customers) {
	.p-datatable-header {
		padding: 1rem;
		text-align: left;
		font-size: 1.5rem;
	}

	.p-paginator {
		padding: 1rem;
	}

	.p-datatable-thead>tr>th {
		text-align: left;
	}

	.p-datatable-tbody>tr>td {
		cursor: auto;
	}

	.p-dropdown-label:not(.p-placeholder) {
		text-transform: uppercase;
	}
}
</style>