<template>
    <Navbar />
    <Toast />
    <BlockUI :blocked="isLoading" :full-screen="true" />
    <div class="mt-2 p-2">
        <div class="flex justify-between px-2">
            <h1 class="text-2xl">Database Orang Tua</h1>

            <button class="rounded border bg-ltDarkGreen px-2 py-1 text-white" @click="toggleMenu"
                v-if="canAddParent">Tambah Data Orang Tua</button>
            <Menu ref="menu" :model="itemCreateParent" :popup="true" />
        </div>
        <div class="px-2 sm:px-4 mt-4">
            <div class="card shadow">
                <DataTable :value="parentList" :paginator="true" class="p-datatable-customers" showGridlines :rows="10"
                    dataKey="id" v-model:filters="parentFilter" filterDisplay="menu" :loading="loading"
                    responsiveLayout="scroll" :globalFilterFields="['nama', 'nis']">
                    <template #header>
                        <div class="flex justify-between">
                            <div class="hidden sm:block">
                                <Button type="button" icon="pi pi-filter-slash" label="Clear"
                                    class="p-button-outlined hidden sm:block" @click="clearFilter" />
                            </div>
                            <span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="parentFilter['global'].value" placeholder="Keyword Pencarian" />
                            </span>
                            <div class="hidden sm:block">
                                <Button icon="pi pi-refresh" @click="updateData" />
                            </div>
                        </div>
                    </template>
                    <template #empty>
                        Tidak ada data Orang Tua.
                    </template>
                    <template #loading>
                        Memuat data Orang Tua.
                    </template>
                    <Column field="nip" header="NIP">
                    </Column>
                    <Column header="Nama" style="min-width: 16rem;">
                        <template #body="{ data }">
                            <div class="flex items-center">
                                <img :src="data.profileUrl" alt="" width="40">
                                <div class="flex-row px-2">
                                    <p class="text-base">
                                        <router-link :to="`/parent/${data.nip}`">
                                            {{ data.nama }}
                                        </router-link>
                                    </p>
                                    <p class="text-xs">NIK: {{ data.nik }}</p>
                                </div>
                            </div>
                        </template>
                    </Column>
                    <Column header="Email" style="min-width: 12rem;">
                        <template #body="{ data }">
                            <span v-if="data.email !== null">{{ data.email }}</span>
                            <button @click="isWantUpdateEmail = true; formUpdateEmail.nip = data.nip;"
                                v-else-if="(data.statusHidup === 'HIDUP' && data.email === null)" class="bordered bg-ltDarkGreen text-white
                                 hover:bg-ltLightGreen py-1 px-2 rounded">Set Email</button>
                            <span v-else> No Action Needed</span>
                        </template>
                    </Column>
                    <Column header="No Telepon">
                        <template #body="{ data }">
                            <a :href="`https://wa.me/${data.nomorTelepon}`">{{ data.nomorTelepon }}</a>
                        </template>
                    </Column>
                    <Column field="jenisKelamin" header="Jenis Kelamin" style="min-width: 8rem; ">
                        <template #body="{ data }">
                            {{ data.jenisKelamin == 'M' ? 'Laki-Laki' : 'Perempuan' }}
                        </template>
                    </Column>
                    <Column field="statusHidup" header="Status Hidup" />
                    <Column header="Action" v-if="$store.state.role === `ADMIN`">
						<template #body="{ data }">
							<button v-if="data.deleted == true"
								class="px-2 rounded p-1 bordered bg-ltDarkGreen hover:bg-ltLightGreen text-white"
								@click="recoveryParent(data.nip)">Recovery</button>
							<span v-else>No Action needed.</span>
						</template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>
    <Dialog :modal="true" header="Update Email dan kirim informasi login" v-model:visible="isWantUpdateEmail">
        <span class="p-float-label mt-6">
            <InputText type="email" v-model="vUpdateEmail$.email.$model" class="w-full"
                placeholder="muhammad@lantabur.sch.id" @blur="vUpdateEmail$.email.$touch" />
            <label for="email">Email</label>
        </span>
        <div class="flex ml-2 mt-1" v-for="error of vUpdateEmail$.email.$errors" :key="error.$uid">
            <small class="p-error">{{ error.$message }}</small>
        </div>
        <template #footer>
            <button icon="pi" @click="isWantUpdateEmail = false" class="border rounded p-2 mx-2">
                Cancel
            </button>
            <button class="border rounded bg-ltDarkGreen text-white py-2 px-4 mx-2 hover:bg-ltLightGreen" icon="pi"
                @click="updateEmail(formUpdateEmail.nip)">
                Simpan
            </button>
        </template>
    </Dialog>
    <Dialog :modal="true" header="Masukkan Data Orang Tua" v-model:visible="isWantInputParent">
        <span class="p-float-label mt-6">
            <InputText type="number" v-model.trim="v$.nik.$model" class="w-full" placeholder="167XXXXXXXXXXXXX (16 Digit)"
                @blur="v$.nik.$touch" />
            <label for="nik" class="text-base">NIK</label>
        </span>
        <div class="flex ml-2 mt-1" v-for="error of v$.nik.$errors" :key="error.$uid">
            <small class="p-error">{{ error.$message }}</small>
        </div>

        <span class="p-float-label mt-6">
            <InputText type="text" v-model.trim="v$.nama.$model" class="w-full" placeholder="Muhammad Agus"
                @blur="v$.nama.$touch" />
            <label for="nama" class="text-base">Nama Lengkap</label>
        </span>
        <div class="flex ml-2 mt-1" v-for="error of v$.nama.$errors" :key="error.$uid">
            <small class="p-error">{{ error.$message }}</small>
        </div>

        <!-- <span class="p-float-label mt-6">
                <InputText type="email" v-model.trim="v$.email.$model"
                class="w-full" placeholder="muhammadagus@lantabur.sch.id"
                @blur="v$.email.$touch"/>
                <label for="Email" class="text-base">Email</label>
            </span>
            <div class="flex ml-2 mt-1" v-for="error of v$.email.$errors" :key="error.$uid">
                <small class="p-error">{{ error.$message }}</small>
            </div> -->

        <span class="p-float-label mt-6">
            <Dropdown v-model="v$.jenisKelamin.$model" class="w-full" :options="jenisKelamin" optionLabel="name"
                optionValue="value" @blur="v$.jenisKelamin.$touch" />
            <label for="Gender" class="text-base">Jenis Kelamin</label>
        </span>
        <div class="flex ml-2 mt-1" v-for="error of v$.jenisKelamin.$errors" :key="error.$uid">
            <small class="p-error">{{ error.$message }}</small>
        </div>

        <span class="p-float-label mt-6">
            <InputText type="text" v-model="v$.tempatLahir.$model" class="w-full" placeholder="Pagar Alam"
                @blur="v$.tempatLahir.$touch" />
            <label for="tempatLahir" class="text-base">Tempat Lahir</label>
        </span>
        <div class="flex mt-1 ml-2" v-for="error of v$.tempatLahir.$errors" :key="error.$uid">
            <small class="p-error">{{ error.$message }}</small>
        </div>

        <span class="p-float-label mt-6">
            <Calendar v-model="v$.tanggalLahir.$model" class="w-full" dateFormat="dd MM yy" placeholder="Kapan Lahirnya?"
                @blur="v$.tanggalLahir.$touch" />
            <label for="tanggalLahir" class="text-base">Tanggal Lahir</label>
        </span>
        <div class="flex mt-1 ml-2" v-for="error of v$.tanggalLahir.$errors" :key="error.$uid">
            <small class="p-error">{{ error.$message }}</small>
        </div>

        <span class="p-float-label mt-6">
            <Dropdown v-model="v$.countryCode.$model" class="w-full" :options="countryCodeList" optionValue="phone_code"
                optionLabel="country_name" placeholder="+62" op filter="true" @blur="v$.countryCode.$touch">
                <template #option="country">
                    <div class="flex justify-between">
                        <span>{{ country.option.country_name }}</span>
                        <span>+{{ country.option.phone_code }}</span>
                    </div>
                </template>
            </Dropdown>
            <label for="">Kode Negara</label>
        </span>
        <div class="flex ml-2 mt-1" v-for="error of v$.countryCode.$errors" :key="error.$uid">
            <small class="p-error">{{ error.$message }}</small>
        </div>

        <span class="p-float-label mt-6">
            <InputText type="number" v-model="v$.nomorTelepon.$model" class="w-full" placeholder="08XXXXXXXXXX"
                @blur="v$.nomorTelepon.$touch" />
            <label for="nomorTelepon" class="text-base">Nomor Telepon Aktif</label>
        </span>
        <div class="flex ml-2 mt-1" v-for="error of v$.nomorTelepon.$errors" :key="error.$uid">
            <small class="p-error">{{ error.$message }}</small>
        </div>

        <span class="p-float-label mt-6">
            <Dropdown v-model="v$.agama.$model" class="w-full" :options="agamaList" optionLabel="name" optionValue="value"
                placeholder="Agama nya apa?" @blur="v$.agama.$touch" />
            <label for="agama" class="text-base">Agama</label>
        </span>
        <div class="flex ml-2 mt-1" v-for="error of v$.agama.$errors" :key="error.$uid">
            <small class="p-error">{{ error.$message }}</small>
        </div>

        <span class="p-float-label mt-6">
            <Dropdown v-model="v$.pekerjaan.$model" class="w-full" :options="pekerjaanList" optionLabel="name"
                optionValue="name" placeholder="Kamu Dagang?" @blur="v$.pekerjaan.$touch" />
            <label for="Pekerjaan" class="text-base">Pekerjaan</label>
        </span>
        <div class="flex ml-2 mt-1" v-for="error of v$.pekerjaan.$errors" :key="error.$uid">
            <small class="p-error">{{ error.$message }}</small>
        </div>

        <span class="p-float-label mt-6">
            <Dropdown v-model="v$.pendidikanTerakhir.$model" class="w-full" :options="pendidikanList" optionLabel="name"
                optionValue="value" placeholder="Terakhir sekolah apa?" @blur="v$.pendidikanTerakhir.$touch" />
            <label for="Pendidikan Terakhir" class="text-base">Pendidikan Terakhir</label>
        </span>
        <div class="flex ml-2 mt-1" v-for="error of v$.pendidikanTerakhir.$errors" :key="error.$uid">
            <small class="p-error">{{ error.$message }}</small>
        </div>

        <span class="p-float-label mt-6">
            <InputNumber v-model="v$.penghasilanPerBulan.$model" locale="id" mode="currency" currency="IDR"
                min-fraction-digits="0" class="w-full" placeholder="Sebulan dapet uang berapa?"
                @blur="v$.penghasilanPerBulan.$touch" />
            <label for="Penghasilan Per Bulan" class="text-base">Penghasilan Per Bulan</label>
        </span>
        <div class="flex ml-2 mt-1" v-for="error of v$.penghasilanPerBulan.$errors" :key="error.$uid">
            <small class="p-error">{{ error.$message }}</small>
        </div>

        <span class="p-float-label mt-6">
            <Dropdown v-model="v$.statusHidup.$model" class="w-full" :options="statusHidupList" optionLabel="name"
                optionValue="value" placeholder="Masih hidup engga?" @blur="v$.statusHidup.$touch" />
            <label for="statusHidup" class="text-base">Status Hidup</label>
        </span>
        <div class="flex ml-2 mt-1" v-for="error of v$.statusHidup.$errors" :key="error.$uid">
            <small class="p-error">{{ error.$message }}</small>
        </div>

        <span class="p-float-label mt-6">
            <InputText type="text" v-model="v$.tahunMasuk.$model" class="w-full" placeholder="Tahun Bergabung ke Lan Tabur"
                @blur="v$.tahunMasuk.$touch" />
            <label for="tahunMasuk" class="text-base">Tahun Masuk</label>
        </span>
        <div class="flex ml-2 mt-1" v-for="error of v$.tahunMasuk.$errors" :key="error.$uid">
            <small class="p-error">{{ error.$message }}</small>
        </div>

        <span class="p-float-label mt-6">
            <Dropdown v-model="v$.golonganDarah.$model" class="w-full" :options="golonganDarahList" optionValue="value"
                optionLabel="name" placeholder="Golongan Darah" @blur="v$.golonganDarah.$touch" />
            <label class="text-base">Golongan Darah</label>
        </span>
        <div class="flex ml-2 mt-1" v-for="error of v$.golonganDarah.$errors" :key="error.$uid">
            <small class="p-error">{{ error.$message }}</small>
        </div>

        <!-- Foto Profil -->
        <label for="name" class="text-sm">
            Foto Profil
        </label>

        <input
            class="mb-5 mt-2 block w-full text-sm text-l border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
            id="file_input" type="file" ref="file" @change="uploadFile" />
        <div class="flex ml-2 mt-1" v-for="error of v$.image.$errors" :key="error.$uid">
            <small class="p-error">{{ error.$message }}</small>
        </div>

        <template #footer>
            <button icon="pi" @click="isWantInputParent = false" class="border rounded p-2 mx-2">
                Cancel
            </button>
            <button class="border rounded bg-ltDarkGreen text-white py-2 px-4 mx-2 hover:bg-ltLightGreen" icon="pi"
                @click="toInputAlamat()">
                Berikutnya
            </button>
        </template>
    </Dialog>
    <Dialog :modal="true" v-model:visible="secondStepInputParent" header="Alamat Orang Tua">
        <div class="flex-col justify-center">

            <!-- Alamat -->
            <div class="field">
                <span class="p-float-label mt-6">
                    <InputText v-model="vAlamat$.namaJalan.$model" type="text" class="w-full" placeholder="Yasniar"
                        @blur="vAlamat$.namaJalan.$touch" />
                    <label class="text-base">Nama Jalan</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of vAlamat$.namaJalan.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <div class="field">
                <span class="p-float-label mt-6">
                    <InputText v-model="vAlamat$.noRT.$model" type="number" class="w-full" placeholder="Yasniar"
                        @blur="vAlamat$.noRT.$touch" />
                    <label class="text-base">Nomor RT</label>
                </span>
                <div class="flex ml-2 mt-2" v-for="error of vAlamat$.noRT.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <div class="field">
                <span class="p-float-label mt-6">
                    <InputText v-model="vAlamat$.noRW.$model" type="number" class="w-full" placeholder="Yasniar"
                        @blur="vAlamat$.noRW.$touch" />
                    <label class="text-base">Nomor RW</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of vAlamat$.noRW.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <div class="field">
                <span class="p-float-label mt-6">
                    <Dropdown v-model="vAlamat$.provinsi.$model" class="w-full" :options="provinceList" optionValue=""
                        optionLabel="name" placeholder="Pilih Provinsi" @change="fetchingKota(formInputParent.provinsi.id)"
                        @blur="vAlamat$.provinsi.$touch" />
                    <label class="text-base">Provinsi</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of vAlamat$.provinsi.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <div class="field">
                <span class="p-float-label mt-6">
                    <Dropdown v-model="vAlamat$.kota.$model" class="w-full" :options="kotaList" optionValue=""
                        optionLabel="name" placeholder="Pilih Kota" @change="fetchingKecamatan(formInputParent.kota.id)"
                        @blur="vAlamat$.kota.$touch" />
                    <label class="text-base">Kota</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of vAlamat$.kota.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <div class="field">
                <span class="p-float-label mt-6">
                    <Dropdown v-model="vAlamat$.kecamatan.$model" class="w-full" :options="kecamatanList" optionValue=""
                        optionLabel="name" placeholder="Pilih Kecamatan"
                        @change="fetchingKelurahan(formInputParent.kecamatan.id)" @blur="vAlamat$.kecamatan.$touch" />
                    <label class="text-base">Kecamatan</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of vAlamat$.kecamatan.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <div class="field">
                <span class="p-float-label mt-6">
                    <Dropdown v-model="vAlamat$.kelurahan.$model" class="w-full" :options="kelurahanList" optionValue="name"
                        optionLabel="name" placeholder="Pilih Kelurahan" @blur="vAlamat$.kelurahan.$touch" />
                    <label class="text-base">Kelurahan</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of vAlamat$.kelurahan.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <div class="field">
                <span class="p-float-label mt-6">
                    <InputText v-model="vAlamat$.kodePos.$model" type="number" class="w-full" placeholder="3XXXXX"
                        @blur="vAlamat$.kodePos.$touch" />
                    <label class="text-base">Kode Pos</label>
                </span>
                <div class="flex ml-2 mt-1" v-for="error of vAlamat$.kodePos.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
            <div class="field">
                <span class="p-float-label mt-6">
                    <InputText v-model="vAlamat$.linkGoogleMap.$model" type="text" class="w-full" placeholder="maps.google"
                        @blur="vAlamat$.linkGoogleMap.$touch" />
                    <label class="text-base">Link Google Map</label>
                </span>
                <div class="flex-ml-2 mt-1" v-for="error of vAlamat$.linkGoogleMap.$errors" :key="error.$uid">
                    <small class="p-error">{{ error.$message }}</small>
                </div>
            </div>
        </div>
        <template #footer>
            <button icon="pi" @click="isWantInputParent = true; secondStepInputParent = false"
                class="border rounded p-2 mx-2">
                Kembali
            </button>
            <button class="border rounded bg-ltDarkGreen text-white py-2 px-4 mx-2 hover:bg-ltLightGreen" label="Login"
                icon="pi" @click="inputParent()">
                Simpan
            </button>
        </template>
</Dialog>
</template>

<script setup>
import Navbar from '@/components/Navbar.vue';
import { FilterMatchMode, } from 'primevue/api'
import { ref, onMounted, reactive } from 'vue';
import axios from 'axios';
import { between, email, helpers, maxLength, minLength, numeric, url, required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { isArray } from '@vue/shared';
import moment from 'moment';
import { useToast } from 'primevue/usetoast';
import { useStore } from 'vuex';
import { countryCode } from '@/utils/coutryCode';

const store = useStore()
const canAddParent = ref(false)

const parentList = ref(null)

const fecthAllData = async () => {
    await axios.get('/v2/parent')
        .then(res => {
            console.log('Ada response')
            console.log(res)
            parentList.value = res.data.data
            isLoading.value = false
        })
        .catch(err => {
            console.log('error')
            console.log(err)
        })
}

onMounted(async () => {
    if (store.state.role === 'ADMIN' || store.state.role === 'TU_PESANTREN') {
        console.log(store.state.role)
        canAddParent.value = true
    }

    fetch('https://www.emsifa.com/api-wilayah-indonesia/api/provinces.json')
        .then(response => response.json())
        .then(provinces => {
            provinceList.value = provinces
        });

    fecthAllData()
})


const toast = useToast()
const isLoading = ref(false)
const isWantInputParent = ref(false)
const isWantAppendGuruToParent = ref(false)
const isWantUpdateEmail = ref(false)
// const isLoading = ref(false)

const secondStepInputParent = ref(false)

const provinceList = ref(null)
const kotaList = ref(null)
const kecamatanList = ref(null)
const kelurahanList = ref(null)

const formInputParent = reactive({})

const inputParentRule = {
    nik: {
        required: helpers.withMessage('NIK tidak boleh kosong', required),
        minLength: helpers.withMessage("Panjang NIK harus 16 digit angka", minLength(16)),
        maxLength: helpers.withMessage("Panjang NIK harus 16 digit angka", maxLength(16))
    },
    nama: {
        required: helpers.withMessage('Nama Lengkat tidak boleh kosong', required),
        alpha: helpers.withMessage('Nama hanya boleh diisi oleh huruf dan spasi', helpers.regex(/^[a-zA-Z ]*$/))
    },
    jenisKelamin: {
        required: helpers.withMessage('Jenis Kelamin tidak boleh kosong', required)
    },
    countryCode: {
        required: helpers.withMessage('Kode Negara tidak boleh kosong', required)
    },
    nomorTelepon: {
        required: helpers.withMessage('Nomor Telepon tidak boleh kosong', required),
        minLength: helpers.withMessage('Nomor Telepon minimal 9 digit angka', minLength(9)),
        maxLength: helpers.withMessage('Nomor Telepon maksimal 13 digit angka', maxLength(13)),
        numeric: helpers.withMessage('Nomor Telepon harus berupa angka', numeric)
    },
    pekerjaan: {
        required: helpers.withMessage('Pekerjaan tidak boleh kosong', required)
    },
    pendidikanTerakhir: {
        required: helpers.withMessage('Pendidikan Terakhir tidak boleh kosong', required)
    },
    penghasilanPerBulan: {
        required: helpers.withMessage('Penghasilan Per Bulan tidak boleh kosong', required)
    },
    statusHidup: {
        required: helpers.withMessage('Status Hidup tidak boleh kosong', required)
    },
    tanggalLahir: {
        required: helpers.withMessage('Tanggal lahir tidak boleh kosong', required)
    },
    tempatLahir: {
        required: helpers.withMessage('Tempat Lahir tidak boleh kosong', required)
    },
    tahunMasuk: {
        required: helpers.withMessage('Tahun Masuk tidak boleh kosong', required),
        betwen: helpers.withMessage(`Tahun Masuk harus berada di rentang 2008 hingga ${moment(Date.now()).format('YYYY')}`, between(2008, moment(Date.now()).format('YYYY')))
    },
    agama: {
        required: helpers.withMessage('Agama tidak boleh kosong', required),
    },
    golonganDarah: {
    },
    image: {
        required: helpers.withMessage('Foto Profil Orang Tua tidak boleh kosong!', required)
    }
}

const inputAlamatParentRules = {
    namaJalan: {
        required: helpers.withMessage('Nama Jalan tidak boleh kosong', required)
    },
    noRT: {
        required: helpers.withMessage('Nomor RT tidak boleh kosong', required),
        numeric: helpers.withMessage('Nomor RT harus terdiri dari Angka', numeric)
    },
    noRW: {
        required: helpers.withMessage('Nomor RW tidak boleh kosong', required),
        numeric: helpers.withMessage('Nomor RW harus terdiri dari Angka', numeric)
    },
    provinsi: {
        required: helpers.withMessage('Nama Provinsi tidak boleh kosong', required),
    },
    kota: {
        required: helpers.withMessage('Nama Kota tiadk boleh kosong', required)
    },
    kecamatan: {
        required: helpers.withMessage('Nama Kecamatan tidak boleh kosong', required)
    },
    kelurahan: {
        required: helpers.withMessage('Nama Kelurahan tidak boleh kosong', required),
    },
    kodePos: {
        required: helpers.withMessage('Nomor Kode Pos tidak boleh kosong', required),
        numeric: helpers.withMessage('Nomor Kode Pos harus terdiri dari angka', numeric),
        minLength: helpers.withMessage('Nomor Kode Pos harus terdiri dari 5 angka', minLength(5)),
        maxLength: helpers.withMessage('Nomor Kode Pos harus terdiri dari 5 angka', maxLength(5))
    },
    linkGoogleMap: {
        url: helpers.withMessage('Link Google Map tidak valid, mohon input ulang', url)
    }
}

const v$ = useVuelidate(inputParentRule, formInputParent)
const vAlamat$ = useVuelidate(inputAlamatParentRules, formInputParent)

const file = ref(null)

const fetchingKota = (id) => {
    fetch(`https://www.emsifa.com/api-wilayah-indonesia/api/regencies/${id}.json`)
        .then(response => response.json())
        .then(regencies => {
            kotaList.value = regencies
        });
}

const fetchingKecamatan = (id) => {
    fetch(`https://www.emsifa.com/api-wilayah-indonesia/api/districts/${id}.json`)
        .then(response => response.json())
        .then(districts => {
            kecamatanList.value = districts
        });
}

const fetchingKelurahan = (id) => {
    fetch(`https://www.emsifa.com/api-wilayah-indonesia/api/villages/${id}.json`)
        .then(response => response.json())
        .then(villages => kelurahanList.value = villages);
}

const itemCreateParent = ref([
    {
        label: 'Orang Tua Biasa',
        command: () => {
            isWantInputParent.value = true
        }
    },
    {
        label: 'Orang Tua Rangkap Guru',
        command: () => {
            isWantAppendGuruToParent.value = true
        }
    }
])

const menu = ref()

const toggleMenu = (event) => {
    menu.value.toggle(event)
}

const uploadFile = async () => {
    // console.log(file.value)
    console.log(file.value.files)
    formInputParent.image = file.value.files[0]
}

const jenisKelamin = ref([
    { name: 'Laki-Laki', value: 'M' },
    { name: 'Perempuan', value: 'F' },
])

const agamaList = ref([
    { name: 'Islam', value: 'Islam' },
    { name: 'Kristen', value: 'Kristen' },
    { name: 'Buddha', value: 'Buddha' },
    { name: 'Hindu', value: 'Hindu' },
    { name: 'Kong Hu Chu', value: 'Kong_Hu_Chu' },
    { name: 'Katolik', value: 'Katolik' },
])

const pendidikanList = ref([
    { name: 'Tidak Sekolah', value: 'Tidak_Sekolah' },
    { name: 'TK', value: 'TK' },
    { name: 'SD/sederajat', value: 'SD' },
    { name: 'SMP/sederajat', value: 'SMP' },
    { name: 'SMA/sederajat', value: 'SMA' },
    { name: 'Diploma', value: 'Diploma' },
    { name: 'S1', value: 'S1' },
    { name: 'S2', value: 'S2' },
    { name: 'S3', value: 'S3' },
])

const statusHidupList = ref([
    { name: 'Hidup', value: 'HIDUP' },
    { name: 'Meninggal', value: 'MENINGGAL' },
])


const golonganDarahList = ref([
	{ name: 'A', value: 'A' },
	{ name: 'B', value: 'B' },
	{ name: 'O', value: 'O' },
	{ name: 'AB', value: 'AB' },
])

const pekerjaanList = ref([
    { name: 'BELUM/TIDAK BEKERJA' },
    { name: 'PEGAWAI NEGERI SIPIL' },
    { name: 'TENTARA NASIONAL INDONESIA' },
    { name: 'KEPOLISIAN RI' },
    { name: 'KARYAWAN BUMN' },
    { name: 'KARYAWAN BUMD' },
    { name: 'ANGGOTA DPR-RI' },
    { name: 'ANGGOTA DPD' },
    { name: 'ANGGOTA BPK' },
    { name: 'PRESIDEN' },
    { name: 'WAKIL PRESIDEN' },
    { name: 'ANGGOTA MAHKAMAH KONSTITUSI' },
    { name: 'ANGGOTA KABINET/KEMENTERIAN' },
    { name: 'DUTA BESAR' },
    { name: 'GUBERNUR' },
    { name: 'WAKIL GUBERNUR' },
    { name: 'BUPATI' },
    { name: 'WAKIL BUPATI' },
    { name: 'WALIKOTA' },
    { name: 'WAKIL WALIKOTA' },
    { name: 'ANGGOTA DPRD PROVINSI' },
    { name: 'ANGGOTA DPRD KABUPATEN/KOTA' },
    { name: 'PENGACARA' },
    { name: 'NOTARIS' },
    { name: 'PENELITI' },
    { name: 'PERANGKAT DESA' },
    { name: 'KEPALA DESA' },
    { name: 'DOSEN' },
    { name: 'GURU' },
    { name: 'PERDAGANGAN' },
    { name: 'INDUSTRI' },
    { name: 'KONSTRUKSI' },
    { name: 'TRANSPORTASI' },
    { name: 'KARYAWAN SWASTA' },
    { name: 'KARYAWAN HONORER' },
    { name: 'BURUH HARIAN LEPAS' },
    { name: 'PEMBANTU RUMAH TANGGA' },
    { name: 'TUKANG CUKUR' },
    { name: 'TUKANG LISTRIK' },
    { name: 'TUKANG BATU' },
    { name: 'TUKANG KAYU' },
    { name: 'TUKANG SOL SEPATU' },
    { name: 'TUKANG LAS/PANDAI BESI' },
    { name: 'TUKANG JAHIT' },
    { name: 'TUKANG GIGI' },
    { name: 'PENATA RIAS' },
    { name: 'PENATA BUSANA' },
    { name: 'PENATA RAMBUT' },
    { name: 'MEKANIK' },
    { name: 'SENIMAN' },
    { name: 'TABIB' },
    { name: 'PARAJI' },
    { name: 'PERANCANG BUSANA' },
    { name: 'PENTERJEMAH' },
    { name: 'WARTAWAN' },
    { name: 'JURU MASAK' },
    { name: 'PROMOTOR ACARA' },
    { name: 'PILOT' },
    { name: 'ARSITEK' },
    { name: 'AKUNTAN' },
    { name: 'KONSULTAN' },
    { name: 'PENYIAR TELEVISI' },
    { name: 'PENYIAR RADIO' },
    { name: 'PELAUT' },
    { name: 'SOPIR' },
    { name: 'PIALANG' },
    { name: 'PARANORMAL' },
    { name: 'PEDAGANG' },
    { name: 'WIRASWASTA' },
    { name: 'PETANI/PEKEBUN' },
    { name: 'PETERNAK' },
    { name: 'BURUH TANI/PERKEBUNAN' },
    { name: 'BURUH PETERNAKAN' },
    { name: 'NELAYAN/PERIKANAN' },
    { name: 'BURUH NELAYAN/PERIKANAN' },
    { name: 'IMAM MESJID' },
    { name: 'PENDETA' },
    { name: 'PASTOR' },
    { name: 'USTADZ/MUBALIGH' },
    { name: 'BIARAWATI' },
    { name: 'PELAJAR/MAHASISWA' },
    { name: 'DOKTER' },
    { name: 'BIDAN' },
    { name: 'PERAWAT' },
    { name: 'APOTEKER' },
    { name: 'PSIKIATER/PSIKOLOG' },
    { name: 'PENSIUNAN' },
    { name: 'MENGURUS RUMAH TANGGA' },
    { name: 'LAINNYA' },

])

const countryCodeList = ref(countryCode)

const toInputAlamat = async () => {
    const isFormCorrect = await v$.value.$validate()

    if (isFormCorrect) {
        secondStepInputParent.value = true
        isWantInputParent.value = false
    }
}

const initFormInputParent = () => {
    formInputParent.nik = null
    formInputParent.nama = null
    formInputParent.jenisKelamin = null
    formInputParent.tempatLahir = null
    formInputParent.tanggalLahir = null
    formInputParent.countryCode = null
    formInputParent.nomorTelepon = null
    formInputParent.agama = null
    formInputParent.pekerjaan = null
    formInputParent.pendidikanTerakhir = null
    formInputParent.penghasilanPerBulan = null
    formInputParent.statusHidup = null
    formInputParent.tahunMasuk = null
    formInputParent.image = null
    formInputParent.namaJalan = null
    formInputParent.noRT = null
    formInputParent.noRW = null
    formInputParent.provinsi = null
    formInputParent.kota = null
    formInputParent.province_id = null
    formInputParent.kecamatan = null
    formInputParent.kelurahan = null
    formInputParent.kodePos = null
    formInputParent.linkGoogleMap = null
    formInputParent.golonganDarah = null

}

const inputParent = async () => {
    let isFormCorrect = await vAlamat$.value.$validate()

    if (isFormCorrect) {
        isLoading.value = true

        const form = new FormData()

        if (formInputParent.golonganDarah) {
            form.append('golonganDarah', formInputParent.golonganDarah)
        }

        if (formInputParent.linkGoogleMap) {
            form.append('linkGoogleMap', formInputParent.linkGoogleMap)
        }

        form.append('image', formInputParent.image)
        form.append('nik', formInputParent.nik)
        form.append('nama', formInputParent.nama)
        form.append('jenisKelamin', formInputParent.jenisKelamin)
        form.append('nomorTelepon', `${formInputParent.countryCode}-${formInputParent.nomorTelepon}`)
        form.append('pekerjaan', formInputParent.pekerjaan)
        form.append('pendidikanTerakhir', formInputParent.pendidikanTerakhir)
        form.append('penghasilanPerBulan', formInputParent.penghasilanPerBulan)
        form.append('statusHidup', formInputParent.statusHidup)
        form.append('tanggalLahir', moment(formInputParent.tanggalLahir).format('YYYY-MM-DD'))
        form.append('tempatLahir', formInputParent.tempatLahir)
        form.append('tahunMasuk', formInputParent.tahunMasuk)
        form.append('agama', formInputParent.agama)
        form.append('namaJalan', formInputParent.namaJalan)
        form.append('nomorRT', formInputParent.noRT)
        form.append('nomorRW', formInputParent.noRW),
        form.append('provinsi', formInputParent.provinsi.name)
        form.append('kota', formInputParent.kota.name)
        form.append('kecamatan', formInputParent.kecamatan.name)
        form.append('kelurahan', formInputParent.kelurahan)
        form.append('kodePos', formInputParent.kodePos)

        await axios.post('/v2/parent', form)
            .then(res => {
                console.log(res)
                toast.add({
                    severity: 'success',
                    summary: 'Data berhasil di Input',
                    detail: res.data.message,
                    life: 3000
                })
                formInputParent.value = null
                secondStepInputParent.value = false
                isLoading.value = false
                initFormInputParent()
                v$.value.$reset()
                vAlamat$.value.$reset()
                fecthAllData()
            })
            .catch(err => {
                if (isArray(err.response.data.error)) {
                    const errorList = err.response.data.error
                    // console.log(errorList)
                    errorList.forEach(data => {
                        toast.add({
                            severity: 'error',
                            summary: "Input Data Murid Gagal",
                            detail: data.msg,
                            life: 3000
                        })
                    })
                }
                else {
                    toast.add({
                        severity: 'error',
                        summary: "Input Data Murid Gagal",
                        detail: err.response.data.error.msg,
                        life: 3000
                    })
                }
                isLoading.value = false
                console.log(err.response.data.error)
            })
    }
}

const updateEmailRules = {
    email: {
        required: helpers.withMessage('Email tidak boleh kosong', required),
        isEmail: helpers.withMessage('Format Email tidak valid silahkan input ulang', email)
    }
}
const formUpdateEmail = reactive({})

const vUpdateEmail$ = useVuelidate(updateEmailRules, formUpdateEmail)

const updateEmail = async (nif) => {
    const isFormValid = await vUpdateEmail$.value.$validate()
    if (isFormValid) {
        isLoading.value = true
        await axios.patch(`/v2/parent/set-email/${nif}`, formUpdateEmail)
            .then(res => {
                toast.add({
                    severity: 'success',
                    summary: 'Update Email berhasil',
                    detail: res.data.message,
                    life: 3000
                })
                isWantUpdateEmail.value = false
            })
            .catch(err => {
                if (isArray(err.response.data.error)) {
                    const errorList = err.response.data.error
                    // console.log(errorList)
                    errorList.forEach(data => {
                        toast.add({
                            severity: 'error',
                            summary: "Update Data Gagal",
                            detail: data.msg,
                            life: 3000
                        })
                    })
                }
                else {
                    toast.add({
                        severity: 'error',
                        summary: "Update Data Gagal",
                        detail: err.response.data.error.msg,
                        life: 3000
                    })
                }
                console.log(err.response.data.error)
                formUpdateEmail.nif = ''
                formUpdateEmail.email = '0'
                fecthAllData()
            })
            .finally(() => {
                isLoading.value = false
            })
    }
}

const recoveryParent = async (nip) => {
	if (store.state.role !== 'ADMIN') {
		toast.add({
			severity: 'Error',
			summary: `Not Authorized`,
			message: 'Not Authorized',
			life: 3000
		})
	}
    isLoading.value = true
	await axios.put(`/v2/parent/detail/${nip}`)
		.then(res => {
			console.log(res.data.message)
			toast.add({
				severity: 'success',
				summary: 'Recovery Berhasil',
				detail: res.data.message,
				life: 3000
			})
			fecthAllData()
		})
		.catch(err => {
			toast.add({
				severity: 'error',
				summary: 'Error Occured',
				detail: err.rensponse.data.message,
				life: 3000
			})
		})
    isLoading.value = false
}

const parentFilter = ref({
    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
    'kodeGuru': { value: null, matchMode: FilterMatchMode.CONTAINS },
    'nama': { value: null, matchMode: FilterMatchMode.CONTAINS },
    'gender': { value: null, matchMode: FilterMatchMode.CONTAINS },
    'email': { value: null, matchMode: FilterMatchMode.CONTAINS },
    'ttl': { value: null, matchMode: FilterMatchMode.CONTAINS }
})
</script>