<template>
    <Toast/>
    <ConfirmDialog :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '50vw'}"></ConfirmDialog>
    <BlockUI :full-screen="true" :blocked="isLoading"/>
    <Navbar/>
    <div class="card p-2">
        <TabView>
            <TabPanel>
                <template #header>
                    Jadwal
                </template>
                <FullCalendar :options="option" :responsive="true" />
                <!-- <span class="text-2xl text-center sm:hidden">Tampilan Kalendar Jadwal akan lebih baik apabila di akses di layar besar.</span> -->
                <Dialog :modal="true" v-model:visible="isWantAddJamTahfidz" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '50vw'}" header="Tambah Jam Tahfidz">
                    <div class="flex flex-wrap justify-center sm:mx-5">
                        <span class="p-float-label mt-5 mb-2 w-full">
                            <InputText class="w-full" type="text" v-model.trim="namaSesi"/>
                            <label class="text-base w-full">Nama Sesi</label>
                        </span>
                        <span class="p-float-label mt-5 mb-2 w-full">
                            <Dropdown class="w-full " v-model="tipeSesi" :options="tipeSesiList" optionLabel="name" optionValue="value" placeholder="Pilih Tipe Sesi"/>
                            <label class="text-base w-full">Tipe Sesi</label>
                        </span>
                        <span class="p-float-label mt-5 mb-2 w-full">
                            <Calendar class="w-full " placeholder="pilih tanggal yang sama untuk input 1 hari" :showIcon="true" selection-mode="range" :manualInput="false" v-model="date"/>
                            <label class="text-base w-full ">Rentang Tanggal </label>
                        </span>
                        <span class="p-float-label mt-5 mb-2 w-full">
                            <Calendar class="w-full " :time-only="true" v-model="waktuMulai" />
                            <label class="text-base w-full ">Jam Mulai</label>
                        </span>
                        <span class="p-float-label mt-5 mb-2 w-full">
                            <Calendar class="w-full " :time-only="true" v-model="waktuSelesai"/>
                            <label class="text-base w-full ">Jam Selesai</label>
                        </span>
                    </div>
                    <template #footer>
                        <button icon="pi" @click="isWantAddJamTahfidz = false" class="border rounded p-2 mx-2">
                            Cancel
                        </button>
                        <button class="border rounded bg-ltDarkGreen text-white py-2 px-4 mx-2 hover:bg-ltLightGreen" label="Login" icon="pi" @click="inputJamTahfidz" >
                            Simpan
                        </button>
                    </template>
                </Dialog>
                <Dialog :modal="true" v-model:visible="isWantEditJamTahfidz" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '50vw'}" >
                    <template #header>
                        <button @click="deleteJamTahfidz(editedJamTahfidzValue)" class="border rounded py-1 px-2 ml-1 bg-red-500 text-white ">
                            <font-awesome-icon icon="fa-solid fa-trash" />
                        </button>
                        <h5 class="p-dialog-title">Edit Jam Tahfidz</h5>
                    </template>
                    <div class="flex flex-wrap justify-center sm:mx-5">
                        <span class="p-float-label mt-5 mb-2 w-full">
                            <InputText class="w-full " type="text" v-model.trim="editedJamTahfidzValue.namaJam"/>
                            <label class="text-base w-full">Nama Sesi</label>
                        </span>
                        <span class="p-float-label mt-5 mb-2 w-full">
                            <Dropdown class="w-full " v-model="editedJamTahfidzValue.tipe" :options="tipeSesiList" optionLabel="name" optionValue="value" placeholder="Pilih Tipe Sesi"/>
                            <label class="text-base w-full">Tipe Sesi</label>
                        </span>
                        <span class="p-float-label mt-5 mb-2 w-full">
                            <Calendar class="w-full " :showIcon="true" selection-mode="range" :manualInput="false" v-model="editedDate"/>
                            <label class="text-base w-full ">Rentang Tanggal</label>
                        </span>
                        <span class="p-float-label mt-5 mb-2 w-full">
                            <Calendar class="w-full " :time-only="true" v-model="editedDate[0]" />
                            <label class="text-base w-full ">Mulai Jam Tahfidz</label>
                        </span>
                        <span class="p-float-label mt-5 mb-2 w-full">
                            <Calendar class="w-full " :time-only="true" v-model="editedDate[1]"/>
                            <label class="text-base w-full ">Selesai Jam Tahfidz</label>
                        </span>
                        
                    </div>
                    <template #footer>
                        <div class="flex justify-between">
                            <div>
                                <router-link :to="`/tahfidz/jamTahfidz/${editedJamTahfidzValue.id}`" class=" border rounded bg-ltDarkGreen text-white hover:bg-ltLightGreen py-2 px-4">Lihat Detail</router-link>
                            </div>
                            <div>
                                <button icon="pi" @click="isWantEditJamTahfidz = false" class="border rounded p-2 mx-2">
                                    Cancel
                                </button>
                                <button class="border rounded bg-ltDarkGreen text-white py-2 px-4 mx-2 hover:bg-ltLightGreen" label="Login" icon="pi" @click="inputJamTahfidz" >
                                    Simpan
                                </button>
                            </div>
                        </div>
                    </template>
                </Dialog>
            </TabPanel>
            <TabPanel>
                <template #header>
                    Halaqoh
                </template>
                <DataTable :value="halaqohList" :paginator="true" :rows="10" v-model:expanded-rows="expandedRows"
                v-model:filters="filter" :globalFilterFields="['kode', 'guruTahfidzDetail.pesantrenDetail.detail.guru.nama']"
                    dataKey="kode"
                    responsiveLayout="scroll">
                    <template #header>
                        <div class="flex justify-between items-center">
                            <span class="text-xl hidden sm:block">Daftar Halaqoh</span>
                            <span class="p-input-icon-left w-100">
                                <i class="pi pi-search" />
                                <InputText v-model="filter['global'].value" placeholder="Cari Berdasarkan Kode Halaqoh / Nama Guru" />
                            </span>
                            <button class="border rounded bg-ltDarkGreen text-white py-2 px-4 mx-2 hover:bg-ltLightGreen hidden sm:block" 
                            @click="iWantToAddHalaqoh"> Tambah Halaqoh</button>
                            <button class="border rounded bg-ltDarkGreen text-white py-2 px-2 hover:bg-ltLightGreen sm:hidden" 
                            @click="iWantToAddHalaqoh"><i class="pi pi-plus"/></button>
                        </div>
                    </template>
                    <template #empty>
                        Tidak ada data Halaqoh.
                    </template>
                    <template #loading>
                        Memuat data Halaqoh, Mohon Tunggu.
                    </template>
                    <ColumnGroup type="header">
                        <Row>
                            <Column :rowspan="3"/>
                            <Column header="Kode Halaqoh" :rowspan="3"/>
                            <Column header="Kode Guru" :rowspan="3" />
                            <Column header="Nama Guru" :rowspan="3" sortable/>
                            <Column header="Status" :rowspan="3" />
                            <Column header="Jumlah Santri" :rowspan="3" style="min-width: 7rem;" />
                            <Column :header="stringPekanan" :colspan="6"/>
                        </Row>
                        <Row>
                            <Column header="Target Kosong" :rowspan="2" style="min-width: 7rem;"/>
                            <Column header="Hafalan" :colspan="2"/>
                            <Column header="Murojah Hafalan Baru" :colspan="2"/>
                            <Column header="Murojah Hafalan Lama" :colspan="2" />
                        </Row>
                        <Row>
                            <Column header="Tuntas" style="min-width: 8rem;" />
                            <Column header="Tidak Tuntas"  style="min-width: 8rem;"/>
                            <Column header="Tuntas" style="min-width: 8rem;" />
                            <Column header="Tidak Tuntas"  style="min-width: 8rem;"/>
                            <Column header="Tuntas" style="min-width: 8rem;" />
                            <Column header="Tidak Tuntas"  style="min-width: 8rem;"/>
                        </Row>
                        
                    </ColumnGroup>
                    <Column :expander="true" headerStyle="widht: 2rem"/>
                    <Column header="Kode Halaqoh">
                        <template #body="{data}">
                            <router-link :to="`/tahfidz/halaqoh/${data.kode}`"> {{ data.kode }}</router-link>
                        </template>
                    </Column>
                    <Column field="guruTahfidzDetail.pesantrenDetail.detail.guru.kodeGuru" header="Kode Guru" style="min-width: 8rem;"/>
                    <Column field="guruTahfidzDetail.pesantrenDetail.detail.guru.nama" header="Nama Guru" style="min-width: 24rem;" sortable>
                        <template #body="{data}">
                            <div class="flex items-center">
                                <img :src="data.guruTahfidzDetail.pesantrenDetail.detail.guru.profileUrl" alt="" width="40" class="rounded-full">
                                <div class="flex-row px-2">
                                    <router-link :to="`/tahfidz/halaqoh/${data.kode}`" class="image-text text-base">{{data.guruTahfidzDetail.pesantrenDetail.detail.guru.nama}}</router-link>
                                    <p class="text-xs" v-if="data.guruTahfidzDetail.pesantrenDetail.detail.guru.nig !== null">NIG:{{data.guruTahfidzDetail.pesantrenDetail.detail.guru.nig}}</p>
                                    <p class="text-xs">NIGS:{{data.guruTahfidzDetail.pesantrenDetail.detail.guru.nigs}}</p>
                                </div>
                            </div>
                        </template>
                    </Column>
                    <Column field="closed" header="Status">
                        <template #body="{data}">
                            <div v-if="data.closed" class="bg-red-600 p-1 rounded text-white text-center">
                                <span >CLOSED</span>
                            </div>
                            <div v-else class="bg-green-600 p-1 rounded text-white text-center">
                                <span >OPEN</span>
                            </div>
                        </template>
                    </Column>
                    <Column header="Jumlah Santri">
                        <template #body="{data}" >
                            {{ data.muridTahfidzDetail.length }} Santri
                        </template>
                    </Column>
                    <Column header="Target Kosong">
                        <template #body="{data}">
                            <span v-if="data.targetKosong">
                                {{ data.targetKosong }} Target
                            </span>
                            <span v-else>
                                No Data.
                            </span>
                        </template>
                    </Column>
                    <Column header="Hafalan Tuntas">
                        <template #body="{data}">
                            <span v-if="data.targetTuntas" :class="data.targetTuntas.hafalan < data.muridTahfidzDetail.length ? 'text-red-600' : 'text-green-500'">
                                {{ data.targetTuntas.hafalan }} Target
                            </span>
                            <span v-else>
                                No Data.
                            </span>
                        </template>
                    </Column>
                    <Column header="Hafalan Tidak Tuntas">
                        <template #body="{data}">
                            <span v-if="data.targetFail" :class="data.targetFail.hafalan > 0 ? 'text-red-600' : ''">
                                {{ data.targetFail.hafalan }} Target
                            </span>
                            <span v-else> 
                                No Data.
                            </span>
                        </template>
                    </Column>
                    <Column header="Murojah Hafalan Baru Tuntas">
                        <template #body="{data}">
                            <span v-if="data.targetTuntas" :class="data.targetTuntas.murojahBaru < data.muridTahfidzDetail.length ? 'text-red-600' : 'text-green-500'">
                                {{ data.targetTuntas.murojahBaru }} Target
                            </span>
                            <span v-else> 
                                No Data.
                            </span>
                        </template>
                    </Column>
                    <Column header="Murojah Hafalan Baru Tidak Tuntas">
                        <template #body="{data}">
                            <span v-if="data.targetFail" :class="data.targetFail.murojahBaru > 0 ? 'text-red-600' : ''">
                                {{ data.targetFail.murojahBaru }} Target
                            </span>
                            <span v-else>
                                No Data.
                            </span>
                        </template>
                    </Column>
                    <Column header="Murojah Hafalan Lama Tuntas">
                        <template #body="{data}">
                            <span v-if="data.targetTuntas" class="text-red" :class="data.targetTuntas.murojah < data.muridTahfidzDetail.length ? 'text-red-600' : 'text-green-500'">
                                {{ data.targetTuntas.murojah }} Target
                            </span>
                            <span v-else>
                                No Data.
                            </span>
                        </template>
                    </Column>
                    <Column header="Murojah Hafalan Lama Tidak Tuntas">
                        <template #body="{data}">
                            <span v-if="data.targetFail" :class="data.targetFail.murojah > 0 ? 'text-red-600' : ''">
                                {{ data.targetFail.murojah }} Target
                            </span>
                            <span v-else>
                                No Data.
                            </span>
                        </template>
                    </Column>
                    <Column header="Action" v-if="store.state.role === 'ADMIN' || store.state.role === 'KEPALA_TAHFIDZ' || store.state.role==='KEPALA_TAHFIDZ_PUTRA' || store.state.role === 'KEPALA_TAHFIDZ_PUTRI'">
                        <template #body="{data}">
                            <div class="flex">
                                <button class="border rounded py-1 px-2 mr-1 bg-blue-500 text-white" @click="iWantToEditHalaqoh(data)">
                                    <font-awesome-icon icon="fa-solid fa-pen-to-square" />
                                </button>
                                <button v-if="data.closed === false" @click="closeHalaqoh(data)" class="border rounded py-1 px-2 mx-1 bg-yellow-400 text-white ">
                                    <font-awesome-icon icon="fa-solid fa-lock" />
                                </button>
                                <button v-else-if="data.closed === true" @click="uncloseHalaqoh(data)" class="border rounded py-1 px-2 mx-1 bg-yellow-400 text-white ">
                                    <font-awesome-icon icon="fa-solid fa-lock-open" />
                                </button>
                                <button v-if="data.deleted == false" @click="deleteHalaqoh(data)" class="border rounded py-1 px-2 ml-1 bg-red-500 text-white ">
                                    <font-awesome-icon icon="fa-solid fa-trash" />
                                </button>
                                <button v-if="data.deleted == true && $store.state.role == 'ADMIN'" @click="recoveryHalaqoh(data)" class="border rounded py-1 px-2 ml-1 bg-red-500 text-white ">
                                    <font-awesome-icon icon="fa-solid fa-trash-can-arrow-up" />
                                </button>
                            </div>
                        </template>
                    </Column>
                    <template #expansion="listMurid">
                        <DataTable :value="listMurid.data.muridTahfidzDetail" responsive-layout="scroll">
                            <Column field="pesantrenDetail.muridDetail.murid.nis" header="NIS"></Column>
                            <Column field="pesantrenDetail.muridDetail.murid.nama" header="Nama Santri">
                                <template #body="{data}">
                                    <router-link :to="`/murid/${data.pesantrenDetail.muridDetail.murid.nis}`" class="flex items-center">
                                        <img :src="data.pesantrenDetail.muridDetail.murid.profileUrl" alt="" width="40" :class="flag" class="rounded-full">
                                        <div class="flex-row px-2">
                                            <p class="text-base">{{data.pesantrenDetail.muridDetail.murid.nama}}</p>
                                            <p class="text-xs">NISN: {{data.pesantrenDetail.muridDetail.murid.nisn}}</p>
                                        </div>
                                    </router-link>
                                </template>
                            </Column>
                            <Column header="Target Hafalan" >
                                <template #body="{data}">
                                    <span v-if="data.pesantrenDetail.tahfidzDetail.target.length > 0">
                                        {{ data.pesantrenDetail.tahfidzDetail.target.find(target => target.type === 'Hafalan').jumlah }} {{ data.pesantrenDetail.tahfidzDetail.target.find(target => target.type === 'Hafalan').satuan }}
                                    </span>
                                    <span v-else>No Data.</span>
                                </template>
                            </Column>
                            <Column header="Target Murojah Hafalan Baru">
                                <template #body="{data}">
                                    <span v-if="data.pesantrenDetail.tahfidzDetail.target.length > 0">
                                        {{ data.pesantrenDetail.tahfidzDetail.target.find(target => target.type === 'Murojah_Baru').jumlah }} {{ data.pesantrenDetail.tahfidzDetail.target.find(target => target.type === 'Murojah_Baru').satuan }}
                                    </span>
                                    <span v-else>No Data.</span>
                                </template>
                            </Column>
                            <Column header="Target Murojah Hafalan Lama">
                                <template #body="{data}">
                                    <span v-if="data.pesantrenDetail.tahfidzDetail.target.length > 0">
                                        {{ data.pesantrenDetail.tahfidzDetail.target.find(target => target.type === 'Murojah').jumlah }} {{ data.pesantrenDetail.tahfidzDetail.target.find(target => target.type === 'Murojah').satuan }}
                                    </span>
                                    <span v-else>No Data.</span>
                                </template>
                            </Column>
                        </DataTable>
                    </template>
                </DataTable>
                <Dialog :modal="true" v-model:visible="isWantAddHalaqoh" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '50vw'}" header="Tambah Halaqoh">
                    <div class="flex flex-wrap justify-center sm:mx-5">
                        <span class="p-float-label w-full mt-6">
                            <Dropdown class="w-full" editable="true"  v-model="guruHalaqoh" :options="listGuru" optionLabel="nama" :filter="true">
                                <template #option="guru">
                                    <div class="flex flex-row justify-between w-full">
                                        <!-- <img :src="guru.option.profileUrl" class="rounded-full h-12"/> -->
                                        <span>{{ guru.option.nama }}</span>
                                        <span>{{ guru.option.kodeGuru }}</span>
                                    </div>
                                </template>
                            </Dropdown>
                            <label class="text-base w-full">Nama Guru</label>
                        </span>
                        <span class="p-float-label w-full mt-6">
                            <MultiSelect class="w-full" :filter="true" v-model="selectedMurid" :options="listMurid" optionLabel="nama">
                                <template #option="murid">
                                    <div class="flex flex-row justify-between w-full">
                                        <!-- <img :src="murid.option.profileUrl" class="rounded-full h-12"/> -->
                                        <span>{{ murid.option.nama }}</span>
                                        <span>{{ murid.option.nis }}</span>
                                    </div>
                                </template>
                            </MultiSelect>
                            <label class="text-base w-full">Pilih Santri</label>
                        </span>
                    </div>
                    <template #footer>
                        <button icon="pi" @click="isWantAddHalaqoh = false" class="border rounded p-2 mx-2">
                            Cancel
                        </button>
                        <button class="border rounded bg-ltDarkGreen text-white py-2 px-4 mx-2 hover:bg-ltLightGreen" label="Login" icon="pi" @click="inputHalaqoh" >
                            Simpan
                        </button>
                    </template>
                </Dialog>

                <Dialog :modal="true" v-model:visible="isWantEditHalaqoh" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '50vw'}" header="Edit Halaqoh">
                        <span class="p-float-label w-full mt-6 mb-2">
                            <Dropdown class="w-full" editable="true" disabled  v-model="formEditHalaqoh.guru" :options="listGuru" optionLabel="nama">
                                <template #option="guru">
                                    <div class="flex flex-row justify-between w-full">
                                        <!-- <img :src="guru.option.profileUrl" class="rounded-full h-12"/> -->
                                        <span>{{ guru.option.nama }}</span>
                                        <span>{{ guru.option.kodeGuru }}</span>
                                    </div>
                                </template>
                            </Dropdown>
                            <label class="text-base w-full">Nama Guru</label>
                        </span>
                        <DataTable :value="formEditHalaqoh.muridList" header="Daftar Murid" responsive-layout="scroll">
                            <template #header> 
                                <div class="flex justify-between">
                                    <span>Daftar Murid</span>
                                    <button @click="iWantToAddMurid">Tambah Murid</button>
                                </div>
                            </template>
                            <Column field="pesantrenDetail.muridDetail.murid.nis" header="NIS"/>
                            <Column header="Nama">
                                <template #body="{data}">  
                                    <div class="flex items-center">
                                        <img :src="data.pesantrenDetail.muridDetail.murid.profileUrl" alt="" width="40">
                                        <div class="flex-row px-2">
                                            <p class="image-text text-base">{{ data.pesantrenDetail.muridDetail.murid.nama }}</p>
                                            <p class="text-xs">NISN : {{ data.pesantrenDetail.muridDetail.murid.nisn }}</p>
                                        </div>
                                    </div>
                                </template>
                            </Column>
                            <Column header="Action">
                                <template #body="{data}"> 
                                    <button v-if="$store.state.role == 'ADMIN' || 'KEPALA_TAHFIDZ'" @click="deleteMuridFromHalaqoh(data)" class="border rounded py-1 px-2 ml-1 bg-red-500 text-white ">
                                        <font-awesome-icon icon="fa-solid fa-trash" />
                                    </button>
                                </template>
                            </Column>
                        </DataTable>
                    <template #footer>
                        <button icon="pi" @click="isWantEditHalaqoh = false" class="border rounded p-2 mx-2">
                            Cancel
                        </button>
                        <button class="border rounded bg-ltDarkGreen text-white py-2 px-4 mx-2 hover:bg-ltLightGreen" label="Login" icon="pi" @click="updateHalaqoh(data)" >
                            Simpan
                        </button>
                    </template>
                </Dialog>
            </TabPanel>
            <TabPanel>
                <template #header>
                    Sesi
                </template>
                <DataTable :value="sessionList" responsive-layout="scroll" paginator="true" :rows="10"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[10,25,50]"
                currentPageReportTemplate="Menampilkan halaman {first} dari {last} halaman dari {totalRecords} seluruh data"
                >
                    <template #empty> 
                        Tidak ada data Sesi.
                    </template>
                    <Column header="Waktu" sortable style="min-width: 15rem;">
                        <template #body="{data}">
                            {{ moment(data.waktu).locale('id').format('dddd DD MMMM YYYY H:mm:ss') }}
                        </template>
                    </Column>
                    <Column header="Jam Tahfidz" style="min-width: 10rem;">
                        <template  #body="{data}">
                            <rounter-link :to="`/tahfidz/jamTahfidz/${data.jamTahfidz.id}`">
                                {{ data.jamTahfidz.namaJam }}
                            </rounter-link>
                        </template>
                    </Column>
                    <Column header="Tipe Sesi" field="tipeSesi"/>
                    <!-- <Column header="Kode Halaqoh" field="halaqoh.kode"/> -->
                    <Column header="Halaqoh" style="min-width: 16rem;">
                        <template #body="{data}">
                            <!-- {{ data.guruTahfidzDetail.pesantrenDetail.detail.guru.kodeGuru }}  -->
                            {{ data.halaqoh.kode }} <br>
                            {{ data.guruTahfidzDetail.pesantrenDetail.detail.guru.nama }}
                        </template>
                    </Column>
                    <Column header="Santri" style="min-width: 16rem;">
                        <template #body="{data}">
                            {{ data.muridTahfidzDetail.pesantrenDetail.muridDetail.murid.nama }}<br>
                            {{ data.muridTahfidzDetail.pesantrenDetail.muridDetail.murid.nis }}
                        </template>
                    </Column>
                    <Column header="Setoran" style="min-width: 16rem;">
                        <template #body="{data}">
                            <div v-if="data.awalSetoran.nomorSurah != data.akhirSetoran.nomorSurah">
                                {{ data.awalSetoran.namaSurahEn }} : {{ data.awalSetoran.ayat }} - <br> {{ data.akhirSetoran.namaSurahEn }} : {{ data.akhirSetoran.ayat }}
                            </div>
                            <div v-else>
                                {{ data.awalSetoran.namaSurahEn }} : {{ data.awalSetoran.ayat }} - {{ data.akhirSetoran.ayat }}
                            </div>
                        </template>
                    </Column>
                    <Column header="Juz">
                        <template #body="{data}">
                            <span v-if="data.awalSetoran.juz === data.akhirSetoran.juz"> {{ data.awalSetoran.juz }}</span>
                            <span v-else>{{ data.awalSetoran.juz }} - {{ data.akhirSetoran.juz }}</span>
                        </template>
                    </Column>
                    <Column field="halaman" header="Jumlah Setoran" style="min-width: 9rem;">
                        <template #body="{data}">
                            <span v-if="data.jumlahBaris < 15">{{ data.jumlahBaris }} Baris</span>
                            <span v-else>{{ data.jumlahBaris }} Baris /<br> {{ data.jumlahHalaman }} Halaman</span>
                        </template>
                    </Column>
                    <Column header="Nilai"> 
                        <template #body="{data}">
                            {{ (data.nilaiKelancaran + data.nilaiMakhroj + data.nilaiSifatHuruf + data.nilaiTajwid)/4 }}
                        </template>
                    </Column>
                    <Column header="Action" v-if="$store.state.role === 'ADMIN'">
                        <template #body="{data}">
                            <span v-if="data.deleted == true"> Recovery </span>
                            <span v-else> No Action Needed.</span>
                        </template>
                    </Column>
                </DataTable>
            </TabPanel>
        </TabView>
    </div>
<AddMuridToHalaqoh/>
</template>
<script setup>

import Navbar from '@/components/Navbar.vue';

import '@fullcalendar/core';

import idLocale from '@fullcalendar/core/locales/id'
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { ref, onMounted, reactive } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useToast } from 'primevue/usetoast';
import moment from 'moment';
import axios from 'axios';
import { useConfirm } from 'primevue/useconfirm';
import AddMuridToHalaqoh from '@/components/AddMuridToHalaqoh.vue';
import { FilterMatchMode } from 'primevue/api';

const store = useStore()

const jadwal = ref()

const filter = ref({
    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
})

const today = moment()

const stringPekanan = ref(`Status Target ${moment(today.toISOString()).startOf('week').subtract(1, 'days').locale('id').format('DD MMMM YYYY')} - ${today.locale('id').format('DD MMMM YYYY')}`)

onMounted(async ()=> {
    fetchJamTahfidzData()

    await axios.get('/v2/surah')
    .then(res => {
        console.log(res)
        surahList.value = res.data.data
    })

    const today = moment()
    let endDays = today.startOf('week').subtract(1, 'days')
    console.log(endDays.format('dddd, DD MMMM YYYY'))

    fetchHalaqohData()
    fetchAllSessionData()
})

const surahList = ref(null)

const fetchJamTahfidzData = async() => {
    await axios.get('v2/jamTahfidz')
    .then(res => {
        jadwal.value = res.data.data
        jadwal.value.forEach(data => {
            // console.log(data.tipe)
            if (data.tipe === 'Murojah') {
                // console.log('Murojah nih')
                data['color'] = 'blue'
            }
            else {
                // console.log('Bukan Murojah nih')
                data['color'] = 'green'
            }
            data['title'] = data.namaJam,
            data['start'] = data.waktuMulai
            data['end'] = data.waktuSelesai
        })
    })
}

const iWantToAddMurid = async() => {
    store.state.halaqoh.editHalaqoh.kode = formEditHalaqoh.kode
    store.state.isWantToAddMuridToHalaqoh = true
}

const fetchHalaqohData = async () => {
    await axios.get('/v2/halaqoh')
    .then(res => {  
        halaqohList.value = res.data.data
    })
}

const fetchAllSessionData = async()=> {
    await axios.get('/v2/sesi/')
    .then(res => {
        sessionList.value = res.data.data
    })
}

const halaqohList = ref(null)
const sessionList = ref(null)
const expandedRows = ref([])

// const store = useStore()
const router = useRouter()
const toast = useToast()
const confirm = useConfirm()

// const today = Date.now()
const tipeSesiList = ref([
    {name: 'Hafalan', value: 'Hafalan'},
    {name: 'Murojah Hafalan Lama', value: 'Murojah'},
    {name: 'Murojah Hafalan Baru', value: 'Murojah_Baru'},
])
// const event = ref(jadwal.value.
//     }))

const isWantAddJamTahfidz = ref(false)
const isWantEditJamTahfidz = ref(false)
const isWantAddHalaqoh = ref(false)
const isLoading = ref(false)

const editedJamTahfidzValue = ref(null)

const option = ref({
    plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
    locale: idLocale,
    initialDate: Date.now(),
    events: jadwal,
    firstDay: 5,
    contentHeight: 650,
    customButtons: {
        addJamTahfidz : {
            text: '+',
            click: function() {
                isWantAddJamTahfidz.value = true
            }
        }
    },
    eventClick: function(info) {
        isWantEditJamTahfidz.value = true
        editedJamTahfidzValue.value = jadwal.value.find(data => data.id == info.event.id )
        editedDate.value = [new Date(editedJamTahfidzValue.value.waktuMulai), new Date(editedJamTahfidzValue.value.waktuSelesai)]
        console.log(info.dateStr)
        console.log(info.event)
        console.log(info.event.id)
        console.log('clicked')
    },
    // eventMouseEnter: function() {
    //     console.log('Ada mouse')
    // },
    headerToolbar: {
        left: 'prev,next',
        center: 'title',
        right: 'addJamTahfidz dayGridMonth,timeGridWeek,timeGridDay',
    },
    buttonText: {
        today: 'Hari ini',
        month : 'B',
        week : 'M',
        day : 'H'
    },
    views: {
        dayGridMonth: { 
            titleFormat: { 
                year: 'numeric', 
                month: 'long', 
            },
        },
        timeGridWeek: {
            titleFormat : {
                year : 'numeric',
                day : 'numeric',
                month : 'short',
            }
        }
    },
    editable: false, 
    selectable: true,
    selectMirror : true,
})

const tipeSesi = ref(null)
const waktuMulai = ref(null)
const waktuSelesai = ref(null)
const date = ref(null)
const editedDate = ref(null)
const namaSesi = ref(null)

const inputJamTahfidz = async () => {
    isLoading.value = true
    const form ={
        tanggalMulai: moment(date.value[0]).format('YYYY-MM-DD'),
        tanggalSelesai: moment(date.value[1]).format('YYYY-MM-DD'),
        jamMulai: moment(waktuMulai.value).format('HH:mm'),
        jamSelesai: moment(waktuSelesai.value).format('HH:mm'),
        tipeSesi: tipeSesi.value,
        nama: namaSesi.value
    }
    console.log(form)
    await axios.post('/v2/jamTahfidz', form)
    .then(res=> {
        isWantAddJamTahfidz.value = false
        console.log(res.data)
        fetchJamTahfidzData()
    })
    .catch(err => {
        console.log(err)
    })
    .finally (isLoading.value = false)
}

const deleteJamTahfidz = async (jamTahfidz) => {
    confirm.require({
        message: `Apakah kamu yakin ingin menghapus data ${jamTahfidz.namaJam} pada hari ${moment(jamTahfidz.waktuMulai).locale('id').format('dddd, DD MMMM YYYY')}?`,
        header: 'Delete Confirmation',
        icon: 'pi pi-info-circle',
        acceptClass: 'p-button-danger',
        accept: async () => {
            isLoading.value = true
            await axios.delete(`/v2/jamTahfidz/id/${jamTahfidz.id}`)
            .then(res => {
                console.log(res.data)
                router.push('/tahfidz/admin')
                toast.add({
                    severity : 'success',
                    summary : "Jam Tahfidz telah dihapus",
                    detail : res.data.message,
                    life: 3000
                });
                isWantEditJamTahfidz.value = false
                fetchJamTahfidzData()
            })
            .catch(error => {
                console.log(error.response);
                toast.add({
                    severity:'error', 
                    summary:'Jam Tahfidz gagal dihapus', 
                    detail: error.response.data.message, 
                    life: 3000
                });
            })
            .finally(isLoading.value = false)
        },
        reject: () => {
            // toast.add({severity:'error', summary:'Rejected', detail:'Data gagal dihapus', life: 3000});
            isWantEditJamTahfidz.value = false
        }
    });
}

const listGuru = ref(null)
const listMurid = ref(null)
const guruHalaqoh = ref()
const selectedMurid = ref()
const editedKodeHalaqoh = ref()

const iWantToAddHalaqoh = async () => {
    await axios.get('/v2/guru/nama')
    .then(res => {
        console.log(res.data)
        listGuru.value = res.data.data
    })
    .catch(err => {
        console.log(err)
        toast.add({
            severity: 'error',
            message: err.response
        })
    })

    await axios.get('v2/murid/nama')
    .then(res => {
        console.log(res.data)
        listMurid.value = res.data.data
    })
    .catch(err => {
        console.log(err)
        toast.add({
            severity: 'error',
            message: err.response
        })
    })

    isWantAddHalaqoh.value = true
}

const deleteMuridFromHalaqoh = async (data) => {
    confirm.require({
        message: `Apakah kamu yakin ingin menghapus ${data.pesantrenDetail.muridDetail.murid.nama} dari halaqoh nya Ust. ${formEditHalaqoh.guru.nama}?`,
        header: 'Delete Confirmation',
        icon: 'pi pi-info-circle',
        acceptClass: 'p-button-danger',
        accept: async () => {
            isLoading.value = true
            await axios.delete(`/v2/halaqoh/delete/${formEditHalaqoh.kode}/${data.pesantrenDetail.muridDetail.murid.id}`)
            .then(res => {
                console.log(res.data)
                toast.add({
                    severity : 'success',
                    summary : `Halaqoh berhasil di Update`,
                    detail : res.data.message,
                    life: 3000
                });
                fetchHalaqohData()
                isWantEditHalaqoh.value = false
            })
            .catch(error => {
                console.log(error.response);
                toast.add({
                    severity:'error', 
                    summary:'Halaqoh gagal di update', 
                    detail: error.response.data.message, 
                    life: 3000
                });
            })
            .finally(() => {
                isLoading.value = false
            })
        },
        reject: () => {
            // toast.add({severity:'error', summary:'Rejected', detail:'Data gagal dihapus', life: 3000});
            // isWantEditJamTahfidz.value = false
        }
    });
}
const formEditHalaqoh = reactive({})

const iWantToEditHalaqoh = async (data) => {
    await axios.get('/v2/guru/nama')
    .then(res => {
        listGuru.value = res.data.data
    })

    await axios.get('v2/murid/nama')
    .then(res => {
        listMurid.value = res.data.data
    })

    isWantEditHalaqoh.value = true
    formEditHalaqoh.kode = data.kode
    formEditHalaqoh.guru= listGuru.value.find(guru => guru.nigs === data.guruTahfidzDetail.pesantrenDetail.detail.guru.nigs)
    formEditHalaqoh.muridList = data.muridTahfidzDetail
    editedKodeHalaqoh.value = data.kode
    console.log(listGuru.value.filter(guru => guru.nigs === data.guruTahfidzDetail.pesantrenDetail.detail.guru.nigs))
    editedGuruHalaqoh.value = listGuru.value.filter(guru => guru.nigs === data.guruTahfidzDetail.pesantrenDetail.detail.guru.nigs)
    // editedSelectedMurid.value = data.muridTahfidzDetail.map(data => {
    //     console.log(data.muridDetails.murid)
    //     return data.muridDetails.murid
    // })

}

const inputHalaqoh = async () => {
    isLoading.value = true
    const form = {
        kodeGuru : guruHalaqoh.value.kodeGuru,
        listMurid : selectedMurid.value
    }
    await axios.post('/v2/halaqoh', form )
    .then(res => {
        toast.add({
            severity : 'success',
            summary : "Input Halaqoh Berhasil",
            detail : res.data.message,
            life: 3000
        })
        isWantAddHalaqoh.value = false
        fetchHalaqohData()
    })
    .catch(err => {
        console.log(err)
    })
    .finally(isLoading.value = false)
}

const updateHalaqoh = async () => {
    const form = {
        kodeGuru : editedGuruHalaqoh.value.kodeGuru,
        listMurid : editedSelectedMurid.value
    }
    await axios.patch(`/v2/halaqoh/detail/${editedKodeHalaqoh.value}`, form )
    .then(res => {
        toast.add({
            severity : 'success',
            summary : "Input Halaqoh Berhasil",
            detail : res.data.message,
            life: 3000
        })
        isWantAddHalaqoh.value = false
    })
    .catch(err => {
        console.log(err)
        toast.add({
            severity : 'error',
            summary : "Input Halaqoh Berhasil",
            detail : err.response,
            life: 3000
        })
    })
}

const closeHalaqoh = async(data) => {
    confirm.require({
        message: `Apakah kamu yakin ingin menutup halaqoh ${data.kode}?`,
        header: 'Konfirmasi Penutupan Halaqoh',
        icon: 'pi pi-info-circle',
        acceptClass: 'p-button-danger',
        accept: async () => {
            await axios.put(`/v2/halaqoh/close/${data.kode}`)
            .then(res => {
                console.log(res.data)
                toast.add({
                    severity: 'success',
                    summary: 'Halaqoh berhasil ditutup',
                    detail: res.data.message,
                    life: 3000
                })
                fetchHalaqohData()
            })
            .catch(err => {
                console.log(err.response)
                toast.add({
                    severity: 'error',
                    summary: 'error occured',
                    detail: err.response,
                    life: 3000
                })
            })
        },
        reject: () => {
            // toast.add({severity:'error', summary:'Rejected', detail:'Data gagal dihapus', life: 3000});
            // isWantEditJamTahfidz.value = false
        }
    });

}

const uncloseHalaqoh = async(data) => {
    await axios.put(`/v2/halaqoh/open/${data.kode}`)
    .then(res => {
        console.log(res.data)
        toast.add({
            severity: 'success',
            summary: 'Halaqoh berhasil dibuka',
            detail: res.data.message,
            life: 3000
        })
        fetchHalaqohData()
    })
    .catch(err => {
        console.log(err.response)
        toast.add({
            severity: 'error',
            summary: 'error occured',
            detail: err.response,
            life: 3000
        })
    })
}

const deleteHalaqoh = async (data) => {
    confirm.require({
        message: `Apakah kamu yakin ingin menghapus halaqoh ${data.kode}?`,
        header: 'Konfirmasi Penghapusan Halaqoh',
        icon: 'pi pi-info-circle',
        acceptClass: 'p-button-danger',
        accept: async () => {
            await axios.delete(`/v2/halaqoh/detail/${data.kode}`)
            .then(res => {
                console.log(res.data)
                toast.add({
                    severity: 'success',
                    summary: 'Halaqoh berhasil dihapus',
                    detail: res.data.message,
                    life: 3000
                })
                fetchHalaqohData()
            })
            .catch(err => {
                console.log(err.response)
                toast.add({
                    severity: 'error',
                    summary: 'Error Occured',
                    detail: err.response,
                    life: 3000
                })
            })
        },
        reject: () => {
            // toast.add({severity:'error', summary:'Rejected', detail:'Data gagal dihapus', life: 3000});
            // isWantEditJamTahfidz.value = false
        }
    });
    
}

const recoveryHalaqoh = async (data) => {
    await axios.put(`/v2/halaqoh/detail/${data.kode}`)
    .then(res => {
        console.log(res.data)
        toast.add({
            severity: 'success',
            summary: 'Halaqoh berhasil direcovery',
            detail: res.data.message,
            life: 3000
        })
        fetchHalaqohData()
    })
    .catch(err => {
        console.log(err.response)
        toast.add({
            severity: 'error',
            summary: 'Halaqoh berhasil dihapus',
            detail: err.response,
            life: 3000
        })
    })
}

const editedGuruHalaqoh = ref(null)
const editedSelectedMurid = ref(null)
const isWantEditHalaqoh = ref(false)

// const editHalaqoh = async(data) => {
//     isWantEditHalaqoh.value = true
//     console.log(data)
//     editedGuruHalaqoh.value = data.guruTahfidzDetail.guruDetails.guru
// }
</script>