import axios from 'axios'
import store from '@/store'
import router from '@/router'

axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.proxy = false
axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')

axios.interceptors.response.use((response) => {
    return response
}, (error) => {
    // console.log(error.response)
    if(error.response.status === 403 || error.response.status === 401) {
        // console.log('Token is Expired')
        store.commit('setToken', '')
        store.commit('setLoggedIn', false)
        router.push('/login')
    }
    else return Promise.reject(error)
})


export default axios