export const returnNavbarList = (role) => {
    var route = [
        { namaRoute : 'Dashboard', to: '/'},
    ]
    if (role == 'ADMIN') {
        route.push(
            { namaRoute: 'Murid', to: '/murids' },
            { namaRoute: 'Guru', to: '/gurus' },
            { namaRoute: 'Orang Tua', to: '/parents'},
            { namaRoute: 'Family', to: '/families'},
            { namaRoute: 'Tahfidz', to: '/tahfidz/admin'},
            { namaRoute: 'Admin', to: '/admin' },
            { namaRoute: 'LTClock', to: '/ltclock'}
            )
    }
    else if (role === 'ROLE_DIBAWAH_ADMIN' || role === 'MUDIRUL_MAAHAD') {
        route.push(
            { namaRoute: 'Murid', to: '/murids' },
            { namaRoute: 'Guru', to: '/gurus' },
            { namaRoute: 'Orang Tua', to: '/parents'},
            { namaRoute: 'Family', to: '/families'},
            { namaRoute: 'Tahfidz', to: '/tahfidz/admin'},
            { namaRoute: 'Admin', to: '/admin' },
        )
    }
    else if (role == 'GURU') {
        route.push(
            // { namaRoute: 'Murid', to: '/murids' },
            // { namaRoute: 'Guru', to: '/gurus' }
            )
    }
    else if (role == 'GURU_TAHFIDZ') {
        route.push(
            { namaRoute: 'Tahfidz', to: '/tahfidz/guru' },
            // { namaRoute: 'Guru', to: '/gurus' }
            )
        }
    else if (role == 'KEPALA_TAHFIDZ' || role == 'KEPALA_TAHFIDZ_PUTRI' || role == 'KEPALA_TAHFIDZ_PUTRA') {
        route.push(
            { namaRoute: 'Murid', to: '/murids' },
            { namaRoute: 'Tahfidz', to: '/tahfidz/admin' },
            // { namaRoute: 'Guru', to: '/gurus' }
        )
    }
    else if(role == 'TU_PESANTREN') {
        route.push(
            { namaRoute: 'Murid', to: '/murids'},
            { namaRoute: 'Guru', to: '/gurus' },
            { namaRoute: 'Orang Tua', to: '/parents'},
            { namaRoute: 'Family', to: '/families'},
        )
    }
    else if(role == 'INPUT_QURAN') {
        route.push(
            { namaRoute: 'Quran', to: '/qurans'},
            // { namaRoute: 'Guru', to: '/gurus' },
        )
    }
    else if (role == 'LTCLOCK_ADMIN') {
        route.push(
            { namaRoute: 'LTClock', to: '/ltclock'}
        )
    }

    route.push({ namaRoute: 'User', to: '/user' })
    // console.log(route)
    return route
}