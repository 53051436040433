<template>
    <Dialog v-model:visible="store.state.isWantToAddMuridToHalaqoh" :modal="true" header="Tambah Murid" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '50vw'}">
        <div class="flex-col justify-center">
            <span class="p-float-label mt-6">
                <Dropdown id="dropdown" class="w-full" 
                    v-model="v$.murid.$model" 
                    placeholder="Nama Santri"  
                    :options="store.state.muridList" :filter="true" optionLabel="nama"
                    @blur="v$.murid.$touch">
                    <template #value="slotProps">
                        <div v-if="slotProps.value">
                            <span>{{slotProps.value.nama}}</span>
                        </div>
                        <div v-else>
                            <span>{{ slotProps.placeholder }}</span>
                        </div>
                    </template>
                    <template #option="murid">
                        <div class="flex flex-row justify-between w-full">
                            <!-- <img :src="murid.option.profileUrl" class="rounded-full h-12"/> -->
                            <span>{{ murid.option.nama }}</span>
                            <span>{{ murid.option.nis }}</span>
                        </div>
                    </template>
                </Dropdown>
                <label for="dropdown">Nama Santri</label>
            </span>
            <div class="flex ml-2 mt-1" v-for="error of v$.murid.$errors" :key="error.$uid">
                <small class="p-error">{{ error.$message }}</small>
            </div>
        </div>
        <template #footer>
            <button icon="pi" @click="store.state.isWantToAddMuridToHalaqoh = false" class="border rounded p-2 mx-2">
                Cancel
            </button>
            <button class="border rounded bg-ltDarkGreen text-white py-2 px-4 mx-2 hover:bg-ltLightGreen" label="Login" icon="pi" @click="inputMuridToHalaqoh" >
                Simpan
            </button>
        </template>
    </Dialog>
</template>

<script setup>
import useVuelidate from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import axios from 'axios';
import { useToast } from 'primevue/usetoast';
import { onMounted,} from 'vue';
import { useStore } from 'vuex';


const store = useStore()
const toast = useToast()

const rules = {
    murid: {
        required: helpers.withMessage('Murid tidak boleh kosong', required)
    }
}

const v$ = useVuelidate(rules, store.state.halaqoh.editHalaqoh)

const inputMuridToHalaqoh = async () => {
    const isFormCorrect = await v$.value.$validate()

    if (isFormCorrect) {
        store.state.isLoading = true

        await axios.patch(`/v2/halaqoh/add/${store.state.halaqoh.editHalaqoh.kode}/${store.state.halaqoh.editHalaqoh.murid.id}`)
        .then(res => {
            toast.add({
                severity: 'success',
                summary: 'Penambahan Data Murid Berhasil',
                detail: res.data.message,
                life: 3000,
            })
        })
        .catch(err => {
            toast.add({
                severity: 'error',
                summary: 'Data gagal dimasukkan',
                detail: err.response.data.message,
                life: 3000
            })
        })
        .finally(() => {
            store.state.isLoading = false
        })
    }
}

onMounted(() => {
    store.commit('fetchMuridList')
})

</script>