<template> 
    <NavbarVue></NavbarVue>
    <Toast/>
    <ConfirmDialog :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '50vw'}"></ConfirmDialog>
    <div class="flex justify-between sm:justify-around items-center pt-2 mx-4 sm:mx-0">
        <h1 class="text-xl sm:text-2xl">{{ data.murid.nis }}</h1>
        <!-- <h1 class="text-xl sm:text-2xl text-center px-2">{{ data.murid.nama }}</h1> -->
        <div v-if="canEditOrDelete">
            <div class="hidden sm:block">
                <button class="border rounded py-1 px-2 mx-2 bg-blue-500 text-white" @click="isWantEditMurid = true">
                    <font-awesome-icon icon="fa-solid fa-pen-to-square" class="pr-2"/>
                    Edit
                </button>
                <button @click="confirmDelete(data.murid)" class="border rounded py-1 px-2 mx-2 bg-red-500 text-white ">
                    <font-awesome-icon icon="fa-solid fa-trash" class="pr-2"/>
                    Delete
                </button>
            </div>
            <div class="sm:hidden flex">
                <button class="border rounded py-1 px-2 mr-1 bg-blue-500 text-white" @click="isWantEditMurid = true">
                    <font-awesome-icon icon="fa-solid fa-pen-to-square" />
                </button>
                <button @click="confirmDelete(data.murid)" class="border rounded py-1 px-2 ml-1 bg-red-500 text-white ">
                    <font-awesome-icon icon="fa-solid fa-trash" />
                </button>
            </div>
        </div>
    </div>
    <div class="rounded text-center justify-center mx-2">
        <div class="flex justify-around mt-4 pt-3 mx-2 sm:mx-auto flex-row">
            <img :src="data.murid.profileUrl" alt="" width="200" class=" rounded border">
        </div>
        <h1 class="text-3xl pt-5">{{data.murid.nama}}</h1>
        <hr class="mt-5">
        <hr class="mt-1">
        <div class="card mx-auto justify-center">
            <TabView>
                <TabPanel header="Biodata">
                    <div class="text-left text-base grid grid-cols-2 mx-5 pt-5 pb-5">
                        <span class="">NIK</span> 
                        <span>{{data.murid.nik}}</span>
                        <span class="">NISN</span>
                        <span>{{data.murid.nisn}}</span>
                        <span>Jenis Kelamin </span>
                        <span>{{data.murid.jenisKelamin == 'M' ? 'Laki-Laki' : 'Perempuan'}}</span>
                        <span>Tempat, Tanggal Lahir</span>
                        <span>{{data.murid.tempatLahir}}, {{new Date(data.murid.tanggalLahir).getDate()}} {{monthName[new Date(data.murid.tanggalLahir).getMonth()]}} {{new Date(data.murid.tanggalLahir).getFullYear()}}</span>
                        <span>Golongan Darah</span>
                        <span>{{data.murid.golonganDarah === null ? 'Belum ada data' : data.murid.golonganDarah}}</span>
                        <span class="col-span-2" v-if="data.murid.jumlahSaudaraTiri === 0 && data.murid.jumlahSaudaraAngkat === 0">
                            Anak ke {{data.murid.anakKe}} dari {{data.murid.jumlahSaudaraKandung}} saudara kandung
                        </span>
                        <span class="col-span-2" v-else-if="data.murid.jumlahSaudaraAngkat !== 0 && data.murid.jumlahSaudaraTiri !== 0">
                            Anak ke {{data.murid.anakKe}} dari {{data.murid.jumlahSaudaraKandung}} saudara kandung,{{data.murid.jumlahSaudaraAngkat}} saudara angkat, dan {{data.murid.jumlahSaudaraTiri}} saudara tiri.
                        </span>
                        <span class="col-span-2" v-else-if="data.murid.jumlahSaudaraTiri !== 0">
                            Anak ke {{data.murid.anakKe}} dari {{data.murid.jumlahSaudaraKandung}} saudara kandung dan {{data.murid.jumlahSaudaraTiri}} saudara tiri
                        </span>
                        <span class="col-span-2" v-else-if="data.murid.jumlahSaudaraAngkat !== 0">
                            Anak ke {{data.murid.anakKe}} dari {{data.murid.jumlahSaudaraKandung}} saudara kandung dan {{data.murid.jumlahSaudaraAngkat}} saudara angkat
                        </span>
                    </div>
                </TabPanel>
                <TabPanel header="Tahfidz">
                    <div class="px-2 sm:px-4 mt-4">
                        <div class="card shadow">
                            <div class="" v-if="data.murid.detail !== null">
                                <span> Target Hafalan: {{ targetHafalan }}</span><br/>
                                <span> Target Murojah Hafalan Lama: {{ targetMurojahLama }}</span><br>
                                <span> Target Murojah Hafalan Baru: {{ targetMurojahBaru }}</span>
                            </div>
                            <DataTable :value="sesiList" responsiveLayout="scroll" :paginator="true" :rows="10">
                                <template #empty>
                                    Tidak ada data Setoran. 
                                </template>
                                <Column sortable="true" header="Waktu Setoran" style="min-width:12.5rem">
                                    <template #body={data}>
                                        {{ moment(data.waktu).locale('id').format('dddd, DD MMMM YYYY HH:MM') }}
                                    </template>
                                </Column>
                                <Column field="halaqoh.kode" header="Kode Halaqoh">
                                    <template #body="{data}">
                                        {{ data.halaqoh.kode }} <br> {{ data.guruTahfidzDetail.pesantrenDetail.detail.guru.nama }}
                                    </template>
                                </Column>
                                <!-- <Column header="Guru">
                                    <template #body="{data}">
                                        {{ data.guruTahfidzDetail.pesantrenDetail.detail.guru.kodeGuru }} - }
                                    </template>
                                </Column> -->
                                <Column header="Jam Tahfidz" style="min-width:10rem">
                                    <template #body="{data}">
                                        {{ data.jamTahfidz.namaJam }} - {{ data.jamTahfidz.tipe === 'Murojah_Baru' ? 'Murojah Hafalan Baru' : data.jamTahfidz.tipe === 'Murojah' ? 'Murojah Hafalan Lama' : data.jamTahfidz.tipe  }}
                                    </template>
                                </Column>
                                <Column header="Awal Setoran" style="min-width:14rem">
                                    <template #body="{data}">
                                        <div v-if="data.awalSetoran.nomorSurah != data.akhirSetoran.nomorSurah">
                                            {{ data.awalSetoran.namaSurahEn }} : {{ data.awalSetoran.ayat }} - <br>
                                            {{ data.akhirSetoran.namaSurahEn }} : {{ data.akhirSetoran.ayat }}
                                        </div>
                                        <div v-else>
                                            {{ data.awalSetoran.namaSurahEn}} : {{ data.awalSetoran.ayat }} - {{ data.akhirSetoran.ayat }}
                                        </div>
                                    </template>
                                </Column>
                                <Column field="juz" header="Juz">
                                    <template #body="{data}">
                                        <span v-if="data.awalSetoran.juz === data.akhirSetoran.juz">{{ data.awalSetoran.juz }}</span>
                                        <span v-else>{{ data.awalSetoran.juz }} - {{ data.akhirSetoran.juz }}</span>
                                    </template>
                                </Column>
                                <Column field="halaman" header="Jumlah Setoran" style="min-width: 10rem;">
                                    <template #body="{data}">
                                        <span v-if="data.jumlahBaris < 15">{{ data.jumlahBaris }} baris</span>
                                        <span v-else> {{ data.jumlahBaris }} baris / <br> {{ data.jumlahHalaman }} halaman</span>
                                    </template>
                                </Column>
                                <Column header="Nilai">
                                    <template #body="{data}">
                                        {{ (data.nilaiKelancaran + data.nilaiMakhroj + data.nilaiSifatHuruf + data.nilaiTajwid)/4 }}
                                    </template>
                                </Column>
                            </DataTable>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel header="Pencapaian Target">
                    <CustomStatusTahfidzDetailMurid/>
                </TabPanel>
            </TabView>
        </div>
    </div>

    <!-- Dialog for Input 4Murid -->
    <Dialog :modal="true" header="Masukkan Data Murid" v-model:visible="isWantEditMurid" :breakpoints="{'960px': '75vw', '640px': '100vw'}">
        <div class="flex-col justify-center">
            <div class="field">
            <span class="p-float-label mt-6 ">
                <InputText type="number" v-model.trim="v$.nik.$model" class="w-full" placeholder="167XXXXXXXXXXXXX (16 Digit)"
                @blur="v$.nik.$touch"/>
                <label class="text-base">NIK</label>
            </span>
            <div class="flex ml-2 mt-1" v-for="error of v$.nik.$errors" :key="error.$uid">
                <small class="p-error">{{ error.$message }}</small>
            </div>
            </div>
            <div class="field">
            <span class="p-float-label mt-6 ">
                <InputText type="text" v-model.trim="v$.nama.$model" class="w-full" placeholder="Muhammad Agus"
                @blur="v$.nama.$touch"/>
                <label class="text-base">Nama Lengkap</label>
            </span>
            <div class="flex ml-2 mt-1" v-for="error of v$.nama.$errors" :key="error.$uid">
                <small class="p-error">{{ error.$message }}</small>
            </div>
            </div>
            <span class="p-float-label mt-6 ">
            <Dropdown v-model="v$.jenisKelamin.$model" class="w-full" :options="jenisKelamin" optionLabel="view" optionValue="code"
            @blur="v$.jenisKelamin.$touch"/>
            <label class="text-base">Jenis Kelamin</label>
            </span>
            <div class="flex ml-2 mt-1" v-for="error of v$.jenisKelamin.$errors" :key="error.$uid">
            <small class="p-error">{{ error.$message }}</small>
            </div>

            <span class="p-float-label mt-6 ">
            <InputText type="text" v-model="v$.tempatLahir.$model" class="w-full"
            placeholder="Pagar Alam"
            @blur="v$.tempatLahir.$touch"/>
            <label class="text-base">Tempat Lahir</label>
            </span>
            <div class="flex ml-2 mt-1" v-for="error of v$.tempatLahir.$errors" :key="error.$uid">
            <small class="p-error">{{ error.$message }}</small>
            </div>

            <span class="p-float-label mt-6 ">
            <Calendar v-model="v$.tanggalLahir.$model" class="w-full" dateFormat="dd MM yy"
            placeholder="Lahirnya kapan?"
            @blur="v$.tanggalLahir.$touch"
            />
            <label class="text-base">Tanggal Lahir</label>
            </span>
            <div class="flex ml-2 mt-1" v-for="error of v$.tanggalLahir.$errors" :key="error.$uid">
            <small class="p-error">{{ error.$message }}</small>
            </div>

            <span class="p-float-label mt-6 ">
            <InputText type="text" v-model="v$.anakKe.$model" class="w-full"
            placeholder="Kamu anak ke berapa?"
            @blur="v$.anakKe.$touch"
            />
            <label class="text-base">Anak Ke</label>
            </span>
            <div class="flex ml-2 mt-1" v-for="error of v$.anakKe.$errors" :key="error.$uid">
            <small class="p-error">{{ error.$message }}</small>
            </div>


            <span class="p-float-label mt-6 ">
            <InputText type="text" v-model="v$.jumlahSaudaraKandung.$model" class="w-full"
            placeholder="Berapa Saudara Kandung kamu?"
            @blur="v$.jumlahSaudaraKandung.$touch"
            />
            <label class="text-base">Jumlah Saudara Kandung</label>
            </span>
            <div class="flex ml-2 mt-1" v-for="error of v$.jumlahSaudaraKandung.$errors" :key="error.$uid">
            <small class="p-error">{{ error.$message }}</small>
            </div>

            <span class="p-float-label mt-6 ">
            <InputText type="text" v-model="v$.jumlahSaudaraTiri.$model" class="w-full"
            placeholder="Berapa Saudara Tirinya?"
            @blur="v$.jumlahSaudaraTiri.$touch"
            />
            <label class="text-base">Jumlah Saudara Tiri</label>
            </span>
            <div class="flex ml-2 mt-1" v-for="error of v$.jumlahSaudaraTiri.$errors" :key="error.$uid">
            <small class="p-error">{{ error.$message }}</small>
            </div>

            <span class="p-float-label mt-6 ">
            <InputText type="text" v-model="v$.jumlahSaudaraAngkat.$model" class="w-full"
            placeholder="Berapa Saudara Angkatnya?"
            @blur="v$.jumlahSaudaraAngkat.$touch"
            />
            <label class="text-base">Jumlah Saudara Angkat</label>
            </span>
            <div class="flex ml-2 mt-1" v-for="error of v$.jumlahSaudaraAngkat.$errors" :key="error.$uid">
            <small class="p-error">{{ error.$message }}</small>
            </div>

            <span class="p-float-label mt-6">
                <Dropdown v-model="v$.golonganDarah.$model" class="w-full" :options="golonganDarahList" optionValue="value" optionLabel="name" placeholder="Golongan Darah"
                @blur="v$.golonganDarah.$touch"/>
                <label class="text-base">Golongan Darah</label>
            </span>
            <div class="flex ml-2 mt-1" v-for="error of v$.golonganDarah.$errors" :key="error.$uid">
                <small class="p-error">{{ error.$message }}</small>
            </div>

            <!-- Foto Profil -->
            <label for="name" class="text-sm mt-1">
                Foto Profil
            </label>

            <input class="mb-5 mt-2 block w-full text-sm text-l border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none" id="file_input"
            type="file"
            ref="file"
            @change="uploadFile"
            />

            <span class="p-float-label mt-6 ">
            <InputText type="text" v-model="v$.nisn.$model" class="w-full" placeholder="XXXXXXXXXX (10 digit)"
            @blur="v$.nisn.$touch"/>
            <label class="text-base">NISN</label>
            </span>
            <div class="flex ml-2 mt-1" v-for="error of v$.nisn.$errors" :key="error.$uid">
            <small class="p-error">{{ error.$message }}</small>
            </div>

            <span class="p-float-label mt-6 ">
            <InputText type="text" v-model="v$.tahunMasuk.$model" placeholder="Bukan Tahun masuk ke pesantren" class="w-full" @blur="v$.tahunMasuk.$touch"/>
            <label class="text-base">Tahun Masuk</label>
            </span>
            <div class="flex ml-2 mt-1" v-for="error of v$.tahunMasuk.$errors" :key="error.$uid">
            <small class="p-error">{{ error.$message }}</small>
            </div>

            <span class="p-float-label mt-6 ">
            <Dropdown v-model="v$.firstLembaga.$model" class="w-full" :options="lembagaLantaburList" optionLabel="name" optionValue="value"
            placeholder="Pertama kali masuk ke?"
            @blur="v$.firstLembaga.$touch"/>
            <label class="text-base">Di Lan Tabur Sejak</label>
            </span>
            <div class="flex ml-2 mt-1" v-for="error of v$.firstLembaga.$errors" :key="error.$uid">
            <small class="p-error">{{ error.$message }}</small>
            </div>
        </div>
        
        <template #footer>
            <button icon="pi" @click="isWantEditMurid= false" class="border rounded p-2 mx-2">
                Cancel
            </button>
            <button class="border rounded bg-ltDarkGreen text-white py-2 px-4 mx-2 hover:bg-ltLightGreen" icon="pi" @click="updateMurid(data)" >
                Simpan
            </button>
        </template>
    </Dialog>
</template>

<script setup>
import NavbarVue from '@/components/Navbar.vue';
import axios from 'axios';
import { onBeforeMount, reactive, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useConfirm } from 'primevue/useconfirm'
import { isArray } from '@vue/shared';
import { useToast } from 'primevue/usetoast'
import { useStore } from 'vuex';
import moment from 'moment';
import { helpers, minValue, numeric, minLength,maxLength, between  } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import CustomStatusTahfidzDetailMurid from '@/components/CustomStatusTahfidzDetailMurid.vue';

let v$

onBeforeMount(async ()=> {
    await axios.get(`/v2/murid/detail/${route.params.nis}`)
    .then(res => {
        console.log(res.data)
        data.murid = res.data.data
        data.murid.tanggalLahir = new Date(data.murid.tanggalLahir)
        data.murid.tahunMasuk = `20${route.params.nis.slice(3,5)}`
        data.murid.tahunLulus = `20${route.params.nis.slice(0,2)}`
        
        const hafalan = data.murid.detail.pesantrenDetail.tahfidzDetail.target.filter(target => target.type === 'Hafalan')[0]
        console.log(hafalan)
        targetHafalan.value = `${hafalan.jumlah} ${hafalan.satuan}` 
        
        const murojah = data.murid.detail.pesantrenDetail.tahfidzDetail.target.filter(target => target.type === 'Murojah')[0]
        targetMurojahLama.value = `${murojah.jumlah} ${murojah.satuan}`
        
        const murojahBaru = data.murid.detail.pesantrenDetail.tahfidzDetail.target.filter(target => target.type === 'Murojah_Baru')[0]
        targetMurojahBaru.value = `${murojahBaru.jumlah} ${murojahBaru.satuan}`

        const lamaDiLanTabur = parseInt(data.murid.tahunLulus) - parseInt(data.murid.tahunMasuk)
        console.log(lamaDiLanTabur)
        switch (lamaDiLanTabur) {
            case 14 : 
                data.murid.firstLembaga = 'TK'
                break
            case 12 : 
                data.murid.firstLembaga = 'SD'
                break
            case 6: 
                data.murid.firstLembaga = 'SMP'
                break
            case 3:
                data.murid.firstLembaga = 'SMA'
                break
        }
        sesiList.value = res.data.data.detail.pesantrenDetail.tahfidzDetail.sesi
    })
    .catch(err => {
        console.log(err)
    })

    console.log(store.state.role)
    if (store.state.role == 'ADMIN' || store.state.role == 'TU_PESANTREN'){
        canEditOrDelete.value = true
    }

    const v = useVuelidate(editMuridRules, data.murid)
    v$ = v
})

const targetHafalan = ref('Tidak ada data')
const targetMurojahLama = ref('Tidak ada data')
const targetMurojahBaru = ref('Tidak ada data')

const jenisKelamin = ref([
    { code: 'M', view: 'Laki-Laki'},
    { code: 'F', view: 'Perempuan'},
])

const lembagaLantaburList = ref([
    { name: 'TKIT', value: 'TK'},
    { name: 'SDIT', value: 'SD'},
    { name: 'SMPIT / MTs', value: 'SMP'},
    { name: 'SMKIT / MA', value: 'SMA'},
])


const golonganDarahList = ref([
    {name: 'A', value: 'A'},
    {name: 'B', value: 'B'},
    {name: 'O', value: 'O'},
    {name: 'AB', value: 'AB'},
])

const file = ref(null)

const uploadFile = async () => {
	// console.log(file.value)
	console.log(file.value.files)
	data.murid.image = file.value.files[0]
}

const router = useRouter()
const route = useRoute()
const store = useStore()
const confirm = useConfirm()
const toast = useToast()

const isWantEditMurid = ref(false)

const canEditOrDelete = ref(false)

const monthName = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember']

const data = reactive({
    murid: {}
})

const sesiList = ref(null)

const editMuridRules = {
    nisn: {
        minLength: helpers.withMessage('NISN harus terdiri dari 16 digit angka',minLength(10) ),
        maxLength: helpers.withMessage('NISN harus terdiri dari 16 digit angka',maxLength(10) ),
    },
    nik: {
        minLength: helpers.withMessage('NIK harus terdiri dari 16 digit angka',minLength(16) ),
        maxLength: helpers.withMessage('NIK harus terdiri dari 16 digit angka',maxLength(16) ),
        numeric: helpers.withMessage('NIK hanya boleh terdiri dari angka', numeric)
    },
    nama: {
        alpha: helpers.withMessage('Nama hanya boleh terdiri dari huruf dan spasi', helpers.regex(/^[a-zA-Z ]*$/))
    },
    firstLembaga: {

    },
    jenisKelamin: {

    },
    tempatLahir: {

    },
    tanggalLahir: {

    },
    anakKe : {

        numeric: helpers.withMessage('Urutan Anak harus berupa angka', numeric),
        minValue: helpers.withMessage('Minimal urutan anak adalah 1', minValue(1))
    },
    jumlahSaudaraKandung: {

        numeric: helpers.withMessage('Jumlah Saudara Kandung harus berupa angka', numeric),
    },
    jumlahSaudaraTiri: {

        numeric: helpers.withMessage('Jumlah Saudara Tiri harus berupa Angka', numeric)
    },
    jumlahSaudaraAngkat: {

        numeric: helpers.withMessage('Jumlah Saudara Angkat harus berupa Angka', numeric)
    },
    golonganDarah: {

    },
    tahunMasuk: {

        beetwen: helpers.withMessage(`Tahun Lulus harus berada di rentang 2008 hingga ${(moment(Date.now()).format('YYYY'))}`, between(2008, moment(Date.now()).format('YYYY')))
    }
}

const confirmDelete = (murid) => {
    console.log(murid)
    confirm.require({
        message: `Apakah kamu yakin ingin menghapus data ${murid.nama}?`,
        header: 'Delete Confirmation',
        icon: 'pi pi-info-circle',
        acceptClass: 'p-button-danger',
        accept: async () => {
            await axios.delete(`/v2/murid/detail/${murid.nis}`)
            .then(res => {
                console.log(res.data)
                router.push('/murids')
                toast.add({severity:'info', summary:'Confirmed', detail:'Data telah dihapus', life: 3000});
            })
            .catch(err => {
                console.log(err);
                toast.add({severity:'error', summary:'Rejected', detail:'Error Occours', life: 3000});
            })
        },
        reject: () => {
            toast.add({severity:'error', summary:'Rejected', detail:'Data gagal dihapus', life: 3000});
        }
    });
}

async function updateMurid() {
    data.murid.nism = String(data.murid.nism)
    data.murid.nisn = String(data.murid.nisn)
    const formData = new FormData()
    if (data.murid.golonganDarah != '') formData.append('golonganDarah', data.murid.golonganDarah)

    // if (data.muridgolonganDarah == null)

    const tanggalLahir = moment(data.murid.tanggalLahir).format('YYYY-MM-DD')

    formData.append('image', data.murid.image)
    formData.append('nama', data.murid.nama)
    formData.append('nisn', data.murid.nisn)
    formData.append('jenisKelamin', data.murid.jenisKelamin)
    formData.append('tahunMasuk', data.murid.tahunMasuk)
    formData.append('tempatLahir', data.murid.tempatLahir)
    formData.append('tanggalLahir', tanggalLahir)
    formData.append('tahunLulus', data.murid.tahunLulus)
    formData.append('anakKe', data.murid.anakKe)
    formData.append('jumlahSaudaraKandung', data.murid.jumlahSaudaraKandung)
    formData.append('jumlahSaudaraTiri', data.murid.jumlahSaudaraTiri)
    formData.append('jumlahSaudaraAngkat', data.murid.jumlahSaudaraAngkat)
    formData.append('firstLembaga', data.murid.firstLembaga)
    formData.append('nik', data.murid.nik)

    console.log(formData)
    // console.log(form)

    await axios.patch(`/v2/murid/detail/${route.params.nis}`, formData)
    .then(res => {
        console.log(res.data)
        close()
        router.push(`/murids`)
    })
    .catch (err => {
        console.timeLog(err.response)
        if (isArray(err.response.data.error)) {
            const errorList = err.response.data.error
            errorList.forEach(data => {
                toast.add({
                    severity : 'error',
                    summary : "Edit Data Murid Gagal",
                    detail : data.msg,
                    life: 3000
                })
            })
        }
        else {
            toast.add({
                severity : 'error',
                summary : "Edit Data Murid Gagal",
                detail : err.response.data.error.msg,
                life: 3000
            })
        }
        console.log(err.response)
    })
    }
</script>