<template>
    <Navbar/>
    <Toast/>
    <ConfirmDialog/>
    <BlockUI :blocked="isLoading" :full-screen="true" />
    <BlockUI :blocked="store.state.isLoading" :full-screen="true" />
    <div class="card mx-auto p-2">

        <TabView>
            <TabPanel>
                <template #header>
                    Jadwal
                </template>
                
                <FullCalendar :options="option" :responsive="true"/>
            </TabPanel>
            <TabPanel>
                <template #header>
                    Sesi
                </template>

                <DataTable :value="sessionList" responsive-layout="scroll" paginator="true" :rows="10"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[10,25,50]"
                currentPageReportTemplate="Menampilkan halaman {first} dari {last} halaman dari {totalRecords} seluruh data"
                >
                    <template #header>
                        <button class="rounded border bg-ltDarkGreen p-2 text-white" @click="store.state.isWantToAddSession = true">Tambah Sesi</button>
                    </template>
                    <template #empty>
                        Tidak ada data Sesi.
                    </template>
                    <Column header="Waktu Setoran" style="min-width:12.5rem">
                        <template #body={data}>
                            {{ moment(data.waktu).locale('id'). format('dddd, DD MMMM YYYY H:mm:ss')}}
                        </template>
                    </Column>
                    <Column header="Jam Tahfidz" style="min-width:10rem">
                        <template #body="{data}">
                            {{ data.jamTahfidz.namaJam }}
                        </template>
                    </Column>
                    <Column header="Tipe Sesi" style="min-width: 10rem;">
                        <template #body="{data}">
                            <span v-if="data.tipeSesi === 'Hafalan'">Hafalan</span>
                            <span v-else-if="data.tipeSesi === 'Murojah_Baru'">Murojah Hafalan Baru</span>
                            <span v-else>Murojah Hafalan Lama</span>
                            <!-- {{ data.tipeSesi }} -->
                        </template>                    
                    </Column>
                    <Column header="Nama Santri" style="min-width:10rem">
                        <template #body="{data}">
                            {{ data.muridTahfidzDetail.pesantrenDetail.muridDetail.murid.nama }}
                        </template>
                    </Column>
                    <Column header="Juz" >
                        <template #body="{data}">
                            <span v-if="data.awalSetoran.juz !== data.akhirSetoran.juz">{{ data.awalSetoran.juz }} - {{ data.akhirSetoran.juz }}</span>
                            <span v-else>{{ data.awalSetoran.juz }}</span>
                        </template>
                    </Column>
                    <Column header="Jumlah Setoran" style="min-width:15rem">
                        <template #body="{data}">
                            <span> {{ data.jumlahBaris }} baris /</span>
                            <span>{{ data.jumlahHalaman }} halaman</span>
                        </template>
                    </Column>
                    <Column header="Awal Setoran" style="min-width:14rem">
                        <template #body="{data}">
                            <div v-if="data.awalSetoran.nomorSurah != data.akhirSetoran.nomorSurah">
                                {{ data.awalSetoran.namaSurahEn }} : {{ data.awalSetoran.ayat }} - {{ data.akhirSetoran.namaSurahEn }} : {{ data.akhirSetoran.ayat }}
                            </div>
                            <div v-else>
                                {{ data.awalSetoran.namaSurahEn }} : {{ data.awalSetoran.ayat }} - {{ data.akhirSetoran.ayat }}
                            </div>
                        </template>
                    </Column>
                    <Column header="Nilai">
                        <template #body="{data}">
                            {{ (data.nilaiKelancaran + data.nilaiMakhroj + data.nilaiSifatHuruf + data.nilaiTajwid)/4 }}
                        </template>
                    </Column>
                    <Column>
                        <template #body="{data}">
                            <div class="flex">
                                <button class="border rounded py-1 px-2 mr-1 bg-blue-500 text-white" @click="iWantToEditSession(data)">
                                    <font-awesome-icon icon="fa-solid fa-pen-to-square" />
                                </button>
                                <button @click="deleteSesi(data)" class="border rounded py-1 px-2 ml-1 bg-red-500 text-white ">
                                    <font-awesome-icon icon="fa-solid fa-trash" />
                                </button>
                            </div>
                        </template>
                    </Column>
                </DataTable>
                <Dialog :modal="true" v-model:visible="store.state.isWantToAddSession" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '50vw'}" header="Tambah Sesi">
                    <div class="flex-col justify-center">
                        <span class="p-float-label mt-6">
                            <Dropdown id="dropdown" class="w-full" v-model="vInputSesi$.murid.$model" placeholder="Nama Santri"  
                                :options="store.state.guru.halaqoh" optionLabel="id"
                                @change="santriOnChanged"
                                @blur="vInputSesi$.murid.$touch">
                                <template #value="slotProps">
                                    <!-- <span>{{surah.value.indo}}</span> -->
                                    <div v-if="slotProps.value">
                                        <span>{{slotProps.value.pesantrenDetail.muridDetail.murid.nama}}</span>
                                        <!-- <span>{{slotProps.value.indo}}</span> -->
                                    </div>
                                    <div v-else>
                                        <span>{{ slotProps.placeholder }}</span>
                                    </div>
                                </template>
                                <template #option="murid">
                                    <div class="flex w-full justify-between">
                                        <!-- <img :src="murid.option.muridDetails.profileUrl" alt=""> -->
                                        <span>{{ murid.option.pesantrenDetail.muridDetail.murid.nama }}</span>
                                        <span>{{ murid.option.pesantrenDetail.muridDetail.murid.nis }}</span>
                                    </div>
                                </template>
                            </Dropdown>
                            <label for="dropdown">Nama Santri</label>
                        </span>
                        <div class="flex ml-2 mt-1" v-for="error of vInputSesi$.murid.$errors" :key="error.$uid">
                            <small class="p-error">{{ error.$message }}</small>
                        </div>

                        <span class="p-float-label mt-6">
                            <Dropdown id="dropdown" class="w-full" v-model="vInputSesi$.sesi.$model" placeholder="Pilih sesi"  
                            :options="jadwalTahfidz" optionLabel="id"
                            @change="jamTahfidzOnChanged"
                            
                            @blur="vInputSesi$.sesi.$touch">
                                <template #value="slotProps">
                                    <!-- <span>{{surah.value.indo}}</span> -->
                                    <div v-if="slotProps.value">
                                        <span>{{slotProps.value.namaJam}}</span>
                                        <!-- <span>{{slotProps.value.indo}}</span> -->
                                    </div>
                                    <div v-else>
                                        <span>{{ slotProps.placeholder }}</span>
                                    </div>
                                </template>
                                <template #option="surah">
                                    <div class="flex w-full justify-between">
                                        <span>{{ surah.option.namaJam }}</span>
                                        <span>{{ new Date(surah.option.waktuMulai).toLocaleTimeString([], { hour12: false, hour: "2-digit", minute: "2-digit" }) }} - {{ new Date(surah.option.waktuSelesai).toLocaleTimeString([], { hour12: false, hour: "2-digit", minute: "2-digit" }) }}</span>
                                    </div>
                                </template>
                            </Dropdown>
                            <label for="dropdown">Sesi</label>
                        </span>
                        <div class="flex ml-2 mt-1" v-for="error of vInputSesi$.sesi.$errors" :key="error.$uid">
                            <small class="p-error">{{ error.$message }}</small>
                        </div>

                        <span class="p-float-label mt-5 mb-2 w-full">
                            <Dropdown class="w-full " v-model="vInputSesi$.tipeSesi.$model" @change="santriOnChanged" :options="tipeSesi" optionLabel="name" optionValue="value" placeholder="Pilih Tipe Sesi"/>
                            <label class="text-base w-full">Tipe Sesi</label>
                        </span>
                        <div class="flex ml-2 mt-1" v-for="error of vInputSesi$.tipeSesi.$errors" :key="error.$uid">
                            <small class="p-error">{{ error.$message }}</small>
                        </div>

                        <Fieldset legend="Awal Setoran" class="mt-2">
                            <div class="flex">
                                <div class="mt-2 w-1/2 mr-1">
                                    <span class="p-float-label">
                                        <Dropdown id="dropdown" class="w-full" v-model="vInputSesi$.awalSurah.$model" @change="vInputSesi$.akhirSurah.$model=vInputSesi$.awalSurah.$model" placeholder="Awal Surah"  
                                        :options="surahList" optionLabel="id"
                                        @blur="vInputSesi$.awalSurah.$touch">
                                            <template #value="slotProps">
                                                <!-- <span>{{surah.value.indo}}</span> -->
                                                <div v-if="slotProps.value" class="text-end">
                                                    <span>{{slotProps.value.arabic}}</span>
                                                    <!-- <span>{{slotProps.value.indo}}</span> -->
                                                </div>
                                                <div v-else>
                                                    <span>{{ slotProps.placeholder }}</span>
                                                </div>
                                            </template>
                                            <template #option="surah">
                                                <div class="flex w-full justify-between">
                                                    <span>{{ surah.option.id }}</span>
                                                    <span>{{ surah.option.arabic }}</span>
                                                    <span>{{ surah.option.indo }}</span>
        
                                                </div>
                                            </template>
                                        </Dropdown>
                                        <label for="dropdown">Surah</label>
                                    </span>
                                    <div class="flex ml-1 mt-1" v-for="error of vInputSesi$.awalSurah.$errors" :key="error.$uid">
                                        <small class="p-error">{{ error.$message }}</small>
                                    </div>
                                </div>
                                <div class="mt-2 w-1/2 ml-1">
                                    <span class="p-float-label">
                                        <InputNumber id="inputnumber" :max="formSesi.awalSurah == null ? 0 : formSesi.awalSurah.ayat"  min="0" inputClass="w-full" v-model="vInputSesi$.awalAyat.$model" 
                                        @blur="vInputSesi$.awalAyat.$touch"/>
                                        <label for="inputnumber" class="text-base">Ayat</label>
                                    </span>
                                    <div class="flex ml-2 mt-1" v-for="error of vInputSesi$.awalAyat.$errors" :key="error.$uid">
                                        <small class="p-error">{{ error.$message }}</small>
                                    </div>
                                </div>
                            </div>
                        </Fieldset>
                        <Fieldset legend="Akhir Setoran" class="mt-2" >
                            <div class="flex">
                                <div class="mt-2 w-1/2 mr-1">
                                    <span class="p-float-label">
                                        <Dropdown id="dropdown" class="w-full" v-model="vInputSesi$.akhirSurah.$model" placeholder="Awal Surah"  
                                        :options="surahList" optionLabel="id"
                                        @blur="vInputSesi$.akhirSurah.$touch">
                                            <template #value="slotProps">
                                                <!-- <span>{{surah.value.indo}}</span> -->
                                                <div v-if="slotProps.value" class="text-end">
                                                    <span>{{slotProps.value.arabic}}</span>
                                                    <!-- <span>{{slotProps.value.indo}}</span> -->
                                                </div>
                                                <div v-else>
                                                    <span>{{ slotProps.placeholder }}</span>
                                                </div>
                                            </template>
                                            <template #option="surah">
                                                <div class="flex w-full justify-between">
                                                    <span>{{ surah.option.id }}</span>
                                                    <span>{{ surah.option.arabic }}</span>
                                                    <span>{{ surah.option.indo }}</span>
        
                                                </div>
                                            </template>
                                        </Dropdown>
                                        <label for="dropdown">Surah</label>
                                    </span>
                                    <div class="flex ml-1 mt-1" v-for="error of vInputSesi$.akhirSurah.$errors" :key="error.$uid">
                                        <small class="p-error">{{ error.$message }}</small>
                                    </div>
                                </div>
                                <div class="mt-2 w-1/2 ml-1">
                                    <span class="p-float-label">
                                        <!-- :min="formSesi.awalAyat == 0 && formSesi.awalSurah.id === formSesi.akhirSurah.id ? 0 : formSesi.awalAyat"  -->
                                        <InputNumber id="inputnumber" inputClass="w-full" 
                                        :min="formSesi.awalSurah && formSesi.awalSurah.id !== formSesi.akhirSurah.id ? 0 : formSesi.awalAyat"
                                        :max="formSesi.akhirSurah == null ? 0 : formSesi.akhirSurah.ayat" 
                                        v-model="vInputSesi$.akhirAyat.$model" 
                                        @blur="vInputSesi$.akhirAyat.$touch"/>
                                        <label for="inputnumber" class="text-base">Ayat</label>
                                    </span>
                                    <div class="flex ml-1 mt-1" v-for="error of vInputSesi$.akhirAyat.$errors" :key="error.$uid">
                                        <small class="p-error">{{ error.$message }}</small>
                                    </div>
                                </div>
                            </div>
                        </Fieldset>

                        <Fieldset legend="Nilai" class="mt-2">
                            <div>
                                <div class="field col-12 md:col-4">
                                    <span class="p-float-label mt-2">
                                        <InputNumber id="inputnumber" max="100" min="0" class="w-full" v-model="vInputSesi$.nilaiTajwid.$model" 
                                        @blur="vInputSesi$.nilaiTajwid.$touch"/>
                                        <label for="inputnumber" class="text-base">Tajwid</label>
                                    </span>
                                    <div class="flex ml-1 mt-s1" v-for="error of vInputSesi$.nilaiTajwid.$errors" :key="error.$uid">
                                        <small class="p-error">
                                            {{ error.$message }}
                                        </small>
                                    </div>
                                </div>
                                <div class="field col-12 md:col-4">
                                    <span class="p-float-label mt-6">
                                        <InputNumber id="inputnumber" max="100" min="0" class="w-full" v-model="vInputSesi$.nilaiKelancaran.$model"
                                        @blur="vInputSesi$.nilaiKelancaran.$touch" />
                                        <label for="inputnumber" class="text-base">Kelancaran</label>
                                    </span>
                                    <div class="flex ml-2 mt-1" v-for="error of vInputSesi$.nilaiKelancaran.$errors" :key="error.$uid">
                                        <small class="p-error">{{ error.$message }}</small>
                                    </div>
                                </div>
                                <div class="field col-12 md:col-4">
                                    <span class="p-float-label mt-6 ">
                                        <InputNumber id="inputnumber" max="100" min="0" class="w-full" v-model="vInputSesi$.nilaiMakhroj.$model" 
                                        @blur="vInputSesi$.nilaiMakhroj.$touch"/>
                                        <label for="inputnumber" class="text-base">Makhroj</label>
                                    </span>
                                    <div class="flex ml-2 mt-1" v-for="error of vInputSesi$.nilaiMakhroj.$errors" :key="error.$uid">
                                        <small class="p-error">{{ error.$message }}</small>
                                    </div>
                                </div>
                                <div class="field col-12 md:col-4">
                                    <span class="p-float-label mt-6 ">
                                        <InputNumber id="inputnumber" max="100" min="0" class="w-full" v-model="vInputSesi$.nilaiSifatHuruf.$model" 
                                        @blur="vInputSesi$.nilaiSifatHuruf.$touch"/>
                                        <label for="inputnumber" class="text-base">Sifat Huruf</label>
                                    </span>
                                    <div class="flex ml-2 mt-1" v-for="error of vInputSesi$.nilaiSifatHuruf.$errors" :key="error.$uid">
                                        <small class="p-error">{{ error.$message }}</small>
                                    </div>
                                </div>
                            </div>
                            
                        </Fieldset>
                    </div>
                    <template #footer>
                            <button icon="pi" @click="isWantToAddSession = false" class="border rounded p-2 mx-2">
                                Cancel
                            </button>
                            <button class="border rounded bg-ltDarkGreen text-white py-2 px-4 mx-2 hover:bg-ltLightGreen" label="Login" icon="pi" @click="inputSesi(formSesi)" >
                                Simpan
                            </button>
                        </template>
                </Dialog>

                <Dialog :modal="true" v-model:visible="isWantToEditSession" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '50vw'}" style="webkit-scrollbar {display: none;}" header="Edit Sesi">
                    <div class="flex-col justify-center">
                        <span class="p-float-label mt-6 sm:m-6">
                            <Dropdown id="dropdown" class="w-full" v-model="editSesi.murid" placeholder="Nama Santri"  :options="store.state.guru.halaqoh" optionLabel="id">
                                <template #value="slotProps">
                                    <!-- <span>{{surah.value.indo}}</span> -->
                                    <div v-if="slotProps.value">
                                        <span>{{slotProps.value.pesantrenDetail.muridDetail.murid.nama}}</span>
                                        <!-- <span>{{slotProps.value.indo}}</span> -->
                                    </div>
                                    <div v-else>
                                        <span>{{ slotProps.placeholder }}</span>
                                    </div>
                                </template>
                                <template #option="murid">
                                    <div class="flex w-full justify-between">
                                        <!-- <img :src="murid.option.muridDetails.profileUrl" alt=""> -->
                                        <span>{{ murid.option.pesantrenDetail.muridDetail.murid.nama }}</span>
                                        <span>{{ murid.option.pesantrenDetail.muridDetail.murid.nis }}</span>
                                    </div>
                                </template>
                            </Dropdown>
                            <label for="dropdown">Nama Santri</label>
                        </span>
                        <span class="p-float-label mt-6 sm:m-6">
                            <Dropdown id="dropdown" class="w-full" v-model="editSesi.sesi" placeholder="Pilih sesi"  :options="[editSesi.sesi]" optionLabel="id" >
                                <template #value="slotProps">
                                    <!-- <span>{{surah.value.indo}}</span> -->
                                    <div v-if="slotProps.value">
                                        <span>{{slotProps.value.namaJam}}</span>
                                        <!-- <span>{{slotProps.value.indo}}</span> -->
                                    </div>
                                    <div v-else>
                                        <span>{{ slotProps.placeholder }}</span>
                                    </div>
                                </template>
                                <template #option="surah">
                                    <div class="flex w-full justify-between">
                                        <span>{{ surah.option.namaJam }}</span>
                                        <span>{{ new Date(surah.option.waktuMulai).toLocaleTimeString([], { hour12: false, hour: "2-digit", minute: "2-digit" }) }} - {{ new Date(surah.option.waktuSelesai).toLocaleTimeString([], { hour12: false, hour: "2-digit", minute: "2-digit" }) }}</span>
                                    </div>
                                </template>
                            </Dropdown>
                            <label for="dropdown">Sesi</label>
                        </span>

                        <span class="p-float-label mt-5 mb-2 w-full">
                            <Dropdown class="w-full " v-model="editSesi.tipeSesi" :options="tipeSesi" optionLabel="name" optionValue="value" placeholder="Pilih Tipe Sesi"/>
                            <label class="text-base w-full">Tipe Sesi</label>
                        </span>
                        <div class="flex ml-2 mt-1" v-for="error of vInputSesi$.tipeSesi.$errors" :key="error.$uid">
                            <small class="p-error">{{ error.$message }}</small>
                        </div>

                        <Fieldset legend="Awal Setoran" class="mt-2">
                            <div class="flex">
                                <span class="p-float-label mt-6 sm:m-6 w-1/2 mr-1">
                                    <Dropdown id="dropdown" class="w-full" v-model="editSesi.awalSurah" @change="editSesi.akhirSurah=editSesi.awalSurah" placeholder="Awal Surah"  :options="surahList" optionLabel="id">
                                        <template #value="slotProps">
                                            <!-- <span>{{surah.value.indo}}</span> -->
                                            <div v-if="slotProps.value" class="text-end">
                                                <span>{{slotProps.value.arabic}}</span>
                                                <!-- <span>{{slotProps.value.indo}}</span> -->
                                            </div>
                                            <div v-else>
                                                <span>{{ slotProps.placeholder }}</span>
                                            </div>
                                        </template>
                                        <template #option="surah">
                                            <div class="flex w-full justify-between">
                                                <span>{{ surah.option.id }}</span>
                                                <span>{{ surah.option.arabic }}</span>
                                                <span>{{ surah.option.indo }}</span>
    
                                            </div>
                                        </template>
                                    </Dropdown>
                                    <label for="dropdown">Surah</label>
                                </span>
                                <span class="p-float-label mt-6 w-1/2 sm:m-6 ml-1">
                                    <InputNumber id="inputnumber" :max="editSesi.awalSurah == null ? 0 : editSesi.awalSurah.ayat"  min="0" inputClass="w-full" v-model="editSesi.awalAyat" />
                                    <label for="inputnumber" class="text-base">Ayat</label>
                                </span>
                            </div>
                        </Fieldset>
                        <Fieldset legend="Akhir Setoran" class="mt-2" >

                            <div class="flex">
                                <span class="p-float-label mt-6 sm:m-6 w-1/2 mr-1">
                                    <Dropdown id="dropdown" class="w-full" v-model="editSesi.akhirSurah" placeholder="Awal Surah"  :options="surahList" optionLabel="id">
                                        <template #value="slotProps">
                                            <!-- <span>{{surah.value.indo}}</span> -->
                                            <div v-if="slotProps.value" class="text-end">
                                                <span>{{slotProps.value.arabic}}</span>
                                                <!-- <span>{{slotProps.value.indo}}</span> -->
                                            </div>
                                            <div v-else>
                                                <span>{{ slotProps.placeholder }}</span>
                                            </div>
                                        </template>
                                        <template #option="surah">
                                            <div class="flex w-full justify-between">
                                                <span>{{ surah.option.id }}</span>
                                                <span>{{ surah.option.arabic }}</span>
                                                <span>{{ surah.option.indo }}</span>
    
                                            </div>
                                        </template>
                                    </Dropdown>
                                    <label for="dropdown">Surah</label>
                                </span>
                                <span class="p-float-label mt-6 sm:m-6 w-1/2 ml-1">
                                    <InputNumber id="inputnumber" inputClass="w-full" 
                                    :min="editSesi.awalSurah && editSesi.awalSurah.id !== editSesi.akhirSurah.id ? 0 : editSesi.awalAyat"
                                    :max="editSesi.akhirSurah == null ? 0 : editSesi.akhirSurah.ayat" v-model="editSesi.akhirAyat" />
                                    <label for="inputnumber" class="text-base">Ayat</label>
                                </span>
                            </div>
                        </Fieldset>

                        <Fieldset legend="Nilai" class="mt-2">

                            <div>
                                <div class="field col-12 md:col-4">
                                    <span class="p-float-label mt-6 sm:m-6">
                                        <InputNumber id="inputnumber" max="100" min="0" class="w-full" v-model="editSesi.nilaiTajwid" />
                                        <label for="inputnumber" class="text-base">Tajwid</label>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-4">
                                    <span class="p-float-label mt-6 sm:m-6">
                                        <InputNumber id="inputnumber" max="100" min="0" class="w-full" v-model="editSesi.nilaiKelancaran" />
                                        <label for="inputnumber" class="text-base">Kelancaran</label>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-4">
                                    <span class="p-float-label mt-6 sm:m-6 ">
                                        <InputNumber id="inputnumber" max="100" min="0" class="w-full" v-model="editSesi.nilaiMakhroj" />
                                        <label for="inputnumber" class="text-base">Makhroj</label>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-4">
                                    <span class="p-float-label mt-6  sm:m-6">
                                        <InputNumber id="inputnumber" max="100" min="0" class="w-full" v-model="editSesi.nilaiSifatHuruf" />
                                        <label for="inputnumber" class="text-base">Sifat Huruf</label>
                                    </span>
                                </div>
                            </div>
                        </Fieldset>
                    </div>
                    <template #footer>
                            <button icon="pi" @click="isWantToEditSession = false" class="border rounded p-2 mx-2">
                                Cancel
                            </button>
                            <button class="border rounded bg-ltDarkGreen text-white py-2 px-4 mx-2 hover:bg-ltLightGreen" label="Login" icon="pi" @click="editSession(editSesi)" >
                                Simpan
                            </button>
                        </template>
                </Dialog>
            </TabPanel>
            <TabPanel>
                <template #header>
                    Daftar Murid
                </template>

                <DataTable
                :paginator="true"
                :value="store.state.guru.halaqoh"
                :rows="10"
                responsive-layout="scroll"
                v-model:expanded-rows="expand"
                >   
                    <ColumnGroup type="header">
                        <Row>
                            <Column :rowspan="2"/>
                            <Column header="NIS" :rowspan="2"/>
                            <Column header="Nama" :rowspan="2"/>
                            <!-- <Column header="Status" :rowspan="2"/> -->
                            <Column header="Target" :colspan="3"/>
                        </Row>
                        <Row>
                            <Column header="Hafalan" style="min-width: 10rem;"/>
                            <Column header="Murojah Hafalan Baru" style="min-width: 10rem;"/>
                            <Column header="Murojah Hafalan Lama" style="min-width: 10rem;"/>
                        </Row>
                    </ColumnGroup>
                    <Column :expander="true" headerStyle="widht: 2rem"/>
                    <Column field="pesantrenDetail.muridDetail.murid.nis" header="NIS" />
                    <Column field="pesantrenDetail.muridDetail.murid.nama" header="Nama" style="min-width: 20rem;">
                        <template #body="{data}">
                            <router-link :to="`/murid/${data.pesantrenDetail.muridDetail.murid.nis}`"  class="flex items-center">
                                <img :src="data.pesantrenDetail.muridDetail.murid.profileUrl" width="40" class="rounded-full"/>
                                <div class="flex-row px-2">
                                    <p class="text-base">{{data.pesantrenDetail.muridDetail.murid.nama}}</p>
                                </div>
                            </router-link>
                        </template>
                    </Column>

                    
                    <Column header="Target Hafalan" >
                        <template #body="{data}">
                            <span v-if="data.pesantrenDetail.tahfidzDetail.target.length > 0">
                                {{ data.pesantrenDetail.tahfidzDetail.target.find(target => target.type === 'Hafalan').jumlah }} {{ data.pesantrenDetail.tahfidzDetail.target.find(target => target.type === 'Hafalan').satuan }}
                            </span>
                            <span v-else>No Data.</span>
                        </template>
                    </Column>
                    <Column header="Target Murojah Hafalan Baru">
                        <template #body="{data}">
                            <span v-if="data.pesantrenDetail.tahfidzDetail.target.length > 0">
                                {{ data.pesantrenDetail.tahfidzDetail.target.find(target => target.type === 'Murojah_Baru').jumlah }} {{ data.pesantrenDetail.tahfidzDetail.target.find(target => target.type === 'Murojah_Baru').satuan }}
                            </span>
                            <span v-else>No Data.</span>
                        </template>
                    </Column>
                    <Column header="Target Murojah Hafalan Lama">
                        <template #body="{data}">
                            <span v-if="data.pesantrenDetail.tahfidzDetail.target.length > 0">
                                {{ data.pesantrenDetail.tahfidzDetail.target.find(target => target.type === 'Murojah').jumlah }} {{ data.pesantrenDetail.tahfidzDetail.target.find(target => target.type === 'Murojah').satuan }}
                            </span>
                            <span v-else>No Data.</span>
                        </template>
                    </Column>

                    <Column>
                        <template #body="{data}">
                            <button @click="iWantToEditTarget(data.pesantrenDetail.tahfidzDetail.target, data.pesantrenDetail.muridDetail.murid.id)"
                            v-if="data.pesantrenDetail.tahfidzDetail.target.length > 0">
                                <font-awesome-icon icon="fa-solid fa-pen-to-square" />
                            </button>
                            <button @click="iWantToAddTarget(data.pesantrenDetail.muridDetail.murid.id)"
                            v-else-if="data.pesantrenDetail.tahfidzDetail.target.length === 0">
                                <font-awesome-icon icon="fa-solid fa-plus" />
                            </button>
                            
                        </template>
                    </Column>
                    
                    <template #expansion="sessionList">
                        <DataTable :value="sessionList.data.pesantrenDetail.tahfidzDetail.sesi" responsive-layout="scroll">
                            <template #empty> 
                                Tidak ada data setoran.
                            </template>
                            <Column header="Waktu Setoran">
                                <template #body="{data}">
                                    {{ moment(data.waktu).locale('id').format('dddd, DD MMMM YYYY H:mm:ss')  }}
                                </template>
                            </Column>
                            <Column header="Jam Tahfidz">
                                <template #body="{data}">
                                    {{ data.jamTahfidz.tipe }}
                                </template>
                            </Column>
                            <Column header="Jumlah Setoran">
                                <template #body="{data}">
                                    <span> {{ data.jumlahBaris }} baris /</span>
                                    <span>{{ data.jumlahHalaman }} halaman</span>
                                </template>
                            </Column>
                            <Column header="Awal Setoran" style="min-width:14rem">
                                <template #body="{data}">
                                    <div v-if="data.awalSetoran.nomorSurah != data.akhirSetoran.nomorSurah">
                                        {{ data.awalSetoran.namaSurahEn }} : {{ data.awalSetoran.ayat }} - {{ data.akhirSetoran.namaSurahEn }} : {{ data.akhirSetoran.ayat }}
                                    </div>
                                    <div v-else>
                                        {{ data.awalSetoran.namaSurahEn }} : {{ data.awalSetoran.ayat }} - {{ data.akhirSetoran.ayat }}
                                    </div>
                                </template>
                            </Column>
                            <Column header="Juz">
                                <template #body="{data}">
                                    <span v-if="data.awalSetoran.juz !== data.akhirSetoran.juz">{{ data.awalSetoran.juz }} - {{ data.akhirSetoran.juz }}</span>
                                    <span v-else>{{ data.awalSetoran.juz }}</span>
                                </template>
                            </Column>
                            <Column header="Nilai">
                                <template #body="{data}">
                                    {{ (data.nilaiKelancaran + data.nilaiMakhroj + data.nilaiSifatHuruf + data.nilaiTajwid)/4 }}
                                </template>
                            </Column>
                        </DataTable>
                    </template>
                </DataTable>
            </TabPanel>
            <TabPanel>
                <template #header>Target</template>
                <CustomStatusMurid/>
            </TabPanel>
        </TabView>
    </div>

    <EditTarget/>
    <InputTarget/>
    <StatusMurid/>
</template>
<script setup>
import Navbar from '@/components/Navbar.vue';
import { onMounted, ref, reactive } from 'vue'
import { isArray } from '@vue/shared';
import axios from '@/api/axios';
import FullCalendar from '@fullcalendar/vue3';
import idLocale from '@fullcalendar/core/locales/id'
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast'
import { useRouter } from 'vue-router';
import moment from 'moment';
import { helpers, required } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core';
import Column from 'primevue/column';
import InputTarget from '@/components/InputTarget.vue';
import EditTarget from '@/components/EditTarget.vue'
import { useStore } from 'vuex';
import { tipeSesiList } from '@/utils/reusableVar'
import StatusMurid from '@/components/StatusMurid.vue';
import CustomStatusMurid from '@/components/CustomStatusMurid.vue';

const jadwal = ref()

const store = useStore()

const iWantToAddTarget = (data) => {
    store.commit('mutateInputTargetDialog', true)
    store.state.inputTarget.muridId = data
}

const iWantToEditTarget = (data, muridId) => {
    store.state.isWantToEditTarget = true

    store.state.editTarget.muridId = muridId

    const targetHafalan = data.find(target => target.type === 'Hafalan')
    const targetMurojah = data.find(target => target.type === 'Murojah')
    const targetMurojahBaru = data.find(target => target.type === 'Murojah_Baru')
    

    store.state.editTarget.targetHafalan = targetHafalan.jumlah
    store.state.editTarget.satuanHafalan = targetHafalan.satuan
    store.state.editTarget.targetMurojah = targetMurojah.jumlah
    store.state.editTarget.satuanMurojah = targetMurojah.satuan
    store.state.editTarget.targetMurojahBaru = targetMurojahBaru.jumlah
    store.state.editTarget.satuanMurojahBaru = targetMurojahBaru.satuan
}

const fetchAllData = async ()=> {
    await axios.get('/v2/halaqoh/guru')
    .then(res => {
        console.log(res)
        if (res.data.success == false) {
            confirm.require({
                message: res.data.message,
                header: 'Halaqoh Error',
                icon: 'pi pi-exclamation-triangle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    router.push('/')
                },
                reject: () => {
                    router.push('/')
                }
            });
        }
        formSesi.kodeHalaqoh = res.data.data.isGuru.pesantrenDetail.tahfidzDetail.halaqohList[0].kode
        formSesi.halaqohId = res.data.data.isGuru.pesantrenDetail.tahfidzDetail.halaqohList[0].id
        // halaqoh.value = res.data.data.isGuru.pesantrenDetail.tahfidzDetail.halaqohList[0].muridTahfidzDetail
        sessionList.value = res.data.data.isGuru.pesantrenDetail.tahfidzDetail.halaqohList[0].sesiHistory
    })
    store.commit('fetchTahfidzGuruDetail')
}

onMounted(async() => {
    // console.log(appStore)

    toast.add({
        severity: 'info',
        summary: 'Update Baru',
        detail: 'Atur target untuk mendapatkan status tuntas pada bagian target.',
        closable: true,
        life: 10000,
    })

    await axios.get('/v2/surah')
    .then(res => {
        console.log(res)
        surahList.value = res.data.data
    })  
    console.log('Fetching Jam Tahfidz nih')
    console.log(store.state.token)

    await axios.get('v2/jamTahfidz')
    .then(res => {
        jadwal.value = res.data.data
        jadwal.value.forEach(data => {
            if (data.tipe === 'Murojah') {
                data['color'] = 'brown'
            }
            else if (data.tipe === 'Murojah_Baru') {
                data['color'] = 'purple'
            }
            else data['color'] = 'green'
            data['title'] = data.namaJam,
            data['start'] = data.waktuMulai
            data['end'] = data.waktuSelesai
        })
    })

    await fetchAllData()
    store.commit('fetchTahfidzGuruDetail')

    console.log(surahList.value[0])

    const begin = new Date(Date.now())
    console.log(begin)
    const end = new Date(begin.setDate(begin.getDate() + 1))
    console.log(end)

    const today = new Date()
    // console.log(today)

    const param = {
        day: today.getDate(),
        month: today.getMonth()+1,
        year: today.getFullYear()
    }

    await axios.get(`/v2/jamTahfidz/date`, { params: param })
    .then (res => {
        console.log(res)
        jadwalTahfidz.value = res.data.data
    })
})

const expand = ref([])
// const halaqoh = ref(null)
const sessionList = ref(null)
const surahList = ref(null)
const jadwalTahfidz = ref(null)
const formSesi = reactive({})

// const satuanJumlahSetoran = ref([
//     {name: 'Halaman', code: 'H'}, 
//     {name: 'Baris', code: 'B'}
// ])
const toast = useToast()
const confirm = useConfirm()
const router = useRouter()

const option = ref({
    plugins: [dayGridPlugin,  timeGridPlugin, interactionPlugin],
    locale: idLocale,
    initialDate: Date.now(),
    events: jadwal,
    initialView: 'timeGridDay',
    contentHeight: 650,

    firstDay: 5,
    eventClick: function(info) {
        // const store = useStore()
        console.log(info)
        console.log(info.event)
        console.log(info.event.id)
        store.state.isWantToCheckStatusMurid = true
        store.state.jamTahfidzDetails = sessionList.value.filter(sesi => {
            console.log(sesi.jamTahfidz.id)
            console.log(info.event.id)
            console.log(sesi.jamTahfidz.id === info.event.id)
            console.log(`____________________`)
            return sesi.jamTahfidz.id === info.event.id
        })
        // store.commit('fetchJamTahfidzDetailsByHalaqoh', {
        //     halaqohId: formSesi.halaqohId,
        //     jamTahfidzId: info.event.id
        // })
        
    },
    headerToolbar: {
        left: 'prev',
        center: 'title',
        right: 'next',
    },
    buttonText: {
        today: 'Hari ini',
        month : 'Bulanan',
        week : 'Mingguan',
        day : 'Harian'
    },
    mobileHeader: {
        left: '',
        center: '',
        right: ''
    },
    windowResize: (arg) =>{
        console.log(arg.view.type)
    },
    views: {
        dayGridMonth: {
            titleFormat: {
                year: 'numeric',
                month: 'short',
            },
        },
        timeGridWeek: {
            titleFormat : {
                year : 'numeric',
                day : 'numeric',
                month : 'short',
            }
        },
        timeGridDay: {
            titleFormat : {
                // year : 'numeric',
                day : 'numeric',
                month : 'short',
            }
        }
    },
    editable: false,
    selectable: true,
    selectMirror : true,
})

// const maxAwalSurah = (formSesi) => {
//     if (formSesi.awalSurah === null){
//         console.log('Null bang')
//         return 0
//     }
//     else {
//         console.log('kaga null bang')
//         return formSesi.awalSurah.ayat
//     }
// }


const isWantToAddSession = ref(false)

const inputSesiRules = {
    akhirAyat: {
        required: helpers.withMessage('Akhir Ayat tidak boleh kosong', required),
    },
    akhirSurah: {
        required: helpers.withMessage('Akhir Surah tidak boleh kosong', required)
    },
    awalAyat: {
        required: helpers.withMessage('Awal Ayat tidak boleh kosong', required)
    },
    awalSurah: {
        required: helpers.withMessage('Awal Surah tidak boleh kosong', required)
    },
    murid: {
        required: helpers.withMessage('Murid tidak boleh kosong', required)
    },
    nilaiKelancaran: {
        required: helpers.withMessage('Nilai Kelancaran tidak boleh kosong', required)
    },
    nilaiMakhroj: {
        required: helpers.withMessage('Nilai Makhroj tidak boleh kosong', required)
    },
    nilaiSifatHuruf: {
        required: helpers.withMessage('Nilai Sifat Huruf tidak boleh kosong', required)
    },
    nilaiTajwid: {
        required: helpers.withMessage('Nilai Tajwid tidak boleh kosong', required)
    },
    sesi: {
        required: helpers.withMessage('Jam Tahfidz tidak boleh kosong', required)
    },
    tipeSesi: {
        required: helpers.withMessage("Tipe Sesi tidak boleh kosong", required)
    }
}

const tipeSesi = ref(tipeSesiList)

const vInputSesi$ = useVuelidate(inputSesiRules, formSesi, { $scope: false, })

const initValueFormInputSesi = () => {
    formSesi.akhirAyat = null    
    formSesi.akhirSurah = null    
    formSesi.awalAyat = null  
    formSesi.awalSurah = null   
    formSesi.murid = null 
    formSesi.tipeSesi = null
    formSesi.nilaiKelancaran = null 
    formSesi.nilaiMakhroj = null 
    formSesi.nilaiSifatHuruf = null
    formSesi.nilaiTajwid = null 
    formSesi.sesi = null
    vInputSesi$.value.$reset()
}

const jamTahfidzOnChanged = () => {
    formSesi.tipeSesi = formSesi.sesi.tipe
    santriOnChanged()

}

const santriOnChanged = () => {
    console.log('changed')
    if (formSesi.tipeSesi !== undefined && formSesi.murid !== undefined) {
        console.log(formSesi.murid !== undefined)
        let latestSession = null
        formSesi.murid.pesantrenDetail.tahfidzDetail.sesi.forEach(sesi => {
            if(sesi.tipeSesi === formSesi.tipeSesi && (!latestSession || moment(sesi.waktu).isAfter(moment(latestSession.waktu)))) {
                latestSession = sesi
            }
        })
        formSesi.lastestSession = latestSession
        if (latestSession !== null) {
            formSesi.awalSetoran = latestSession.akhirSetoran
            formSesi.awalSurah = surahList.value.find(surah => surah.id === (latestSession.akhirSetoran.nomorSurah))

            if(latestSession.jamTahfidz.id === formSesi.sesi.id) {
                toast.add({
                    severity: 'warn',
                    detail: 'Santri ini sudah setoran pada jam tahfidz yang dipilih',
                    summary: 'Peringatan Duplikasi Data Sesi',
                    life: 3000
                })
            }

            if(latestSession.akhirSetoran.nomorSurah === 114) {
                formSesi.awalSurah = surahList.value.find(surah => surah.id === (1))
                formSesi.awalAyat = 1
            }
            else if (latestSession.akhirSetoran.ayat + 1 > formSesi.awalSurah.ayat) {
                formSesi.awalSurah = surahList.value.find(surah => surah.id === (latestSession.akhirSetoran.nomorSurah + 1))
                formSesi.awalAyat = 1
            }
            else {
                formSesi.awalAyat = latestSession.akhirSetoran.ayat + 1
            }
            formSesi.akhirSurah = formSesi.awalSurah
        }
        else {
            formSesi.awalSetoran = null
            formSesi.awalSurah = null
            formSesi.awalAyat = null
            formSesi.akhirSurah = null
        }
    }
    
}

const inputSesi = async(formSesi) => {

    const isFormCorrect = await vInputSesi$.value.$validate()

    if (isFormCorrect) {
        isLoading.value = true
    
        const target = formSesi.murid.pesantrenDetail.tahfidzDetail.target.find(target => target.type === formSesi.tipeSesi)

        const data = {
            akhirAyat: formSesi.akhirAyat,
            akhirSurah: formSesi.akhirSurah.id,
            awalAyat: formSesi.awalAyat,
            target: target !== undefined ? target.jumlah : undefined,
            satuanTarget: target !== undefined ? target.satuan : undefined,
            awalSurah: formSesi.awalSurah.id,
            nilaiKelancaran: formSesi.nilaiKelancaran,
            nilaiMakhroj: formSesi.nilaiMakhroj,
            nilaiSifatHuruf: formSesi.nilaiSifatHuruf,
            nilaiTajwid: formSesi.nilaiTajwid,
            nis: formSesi.murid.pesantrenDetail.muridDetail.murid.nis,
            kodeHalaqoh: formSesi.kodeHalaqoh,
            jamTahfidzId: formSesi.sesi.id,
            tipeSesi: formSesi.tipeSesi
        }

        await axios.post('/v2/sesi/', data)
        .then(res => {
            console.log(res.data.data)
            console.log(res.data.message)
            toast.add({
                severity : 'success',
                summary : "Sesi berhasil dibuat",
                detail : res.data.message,
                life: 3000
            })

            if (data.target === undefined) {
                toast.add({
                    severity : 'warn',
                    summary : "Target Belum di atur",
                    detail : 'Silahkan atur target pada tab Halaqoh, agar bisa di data pencapain murid halaqohnya. Terima Kasih',
                    life: 10000
                })
            }
            isWantToAddSession.value = false
            
            formSesi = {}
            initValueFormInputSesi()
            fetchAllData()
        })
        .catch(err => {
    
            if (isArray(err.response.data.error)) {
                const errorList = err.response.data.error
                // console.log(errorList)
                errorList.forEach(data => {
                    toast.add({
                        severity : 'error',
                        summary : "Input Data Murid Gagal",
                        detail : data.msg,
                        life: 3000
                    })
                })
            }
            else {
                toast.add({
                    severity : 'error',
                    summary : "Input Data Murid Gagal",
                    detail : err.response.data.error.msg,
                    life: 3000
                })
            }
            console.log(err.response.data.error)
        })
        isLoading.value = false

    }
}

const isWantToEditSession = ref(false)
const isLoading = ref(false)
const editSesi = reactive({})

const iWantToEditSession = async(data) => {
    isWantToEditSession.value = true
    console.log(data)
    editSesi.murid = store.state.guru.halaqoh.find(murid => murid.pesantrenDetail.muridDetail.murid.nis == data.muridTahfidzDetail.pesantrenDetail.muridDetail.murid.nis)
    editSesi.sesi = data.jamTahfidz
    editSesi.juz = data.juz 
    editSesi.awalSurah = surahList.value.find(surah => surah.id == data.awalSetoran.nomorSurah)
    editSesi.awalAyat = data.awalSetoran.ayat
    editSesi.akhirSurah = surahList.value.find(surah => surah.id === data.akhirSetoran.nomorSurah)
    editSesi.akhirAyat = data.akhirSetoran.ayat
    editSesi.halaman = data.halaman
    editSesi.jumlahSetoran = data.halaman
    editSesi.satuan = 'H'
    editSesi.nilaiKelancaran = data.nilaiKelancaran
    editSesi.nilaiTajwid = data.nilaiTajwid
    editSesi.nilaiMakhroj = data.nilaiMakhroj
    editSesi.nilaiSifatHuruf = data.nilaiSifatHuruf
    editSesi.kodeHalaqoh = formSesi.kodeHalaqoh
    editSesi.id = data.id
    editSesi.tipeSesi = data.tipeSesi
} 

const editSession = async(editSesi) => {
    isLoading.value = true
    const data = {
        akhirAyat: editSesi.akhirAyat,
        akhirSurah: editSesi.akhirSurah.id,
        awalAyat: editSesi.awalAyat,
        awalSurah: editSesi.awalSurah.id,
        halaman: editSesi.halaman,
        juz: editSesi.juz,
        nilaiKelancaran: editSesi.nilaiKelancaran,
        nilaiMakhroj: editSesi.nilaiMakhroj,
        nilaiSifatHuruf: editSesi.nilaiSifatHuruf,
        nilaiTajwid: editSesi.nilaiTajwid,
        nis: editSesi.murid.pesantrenDetail.muridDetail.murid.nis,
        kodeHalaqoh: editSesi.kodeHalaqoh,
        jamTahfidzId: editSesi.sesi.id,
        tipeSesi: editSesi.tipeSesi
    }   

    await axios.patch(`/v2/sesi/${editSesi.id}`, data)
    .then(res => {
        toast.add({
            severity : 'success',
            summary : "Sesi berhasil diedit",
            detail : res.data.message,
            life: 3000
        })
        fetchAllData()
        isWantToEditSession.value = false
    })
    .catch(err => {
        if (isArray(err.response.data.error)) {
            const errorList = err.response.data.error
            // console.log(errorList)
            errorList.forEach(data => {
                toast.add({
                    severity : 'error',
                    summary : "Input Data Murid Gagal",
                    detail : data.msg,
                    life: 3000
                })
            })
        }
        else {
            toast.add({
                severity : 'error',
                summary : "Input Data Murid Gagal",
                detail : err.response.data.error.msg,
                life: 3000
            })
        }
        console.log(err.response.data.error)
    })
    console.log(data)
    isLoading.value = false
}

const deleteSesi = async(data) => {
    console.log(data)
    confirm.require({
        message: `Apakah kamu yakin ingin menghapus setoran ${data.muridTahfidzDetail.pesantrenDetail.muridDetail.murid.nama} pada ${data.jamTahfidz.namaJam} di tanggal ${moment(data.jamTahfidz.waktuMulai).locale('id').format('DD MMMM YYYY')}?`,
        header: 'Konfirmasi Hapus Sesi',
        icon: 'pi pi-info-circle',
        acceptClass: 'p-button-danger',
        accept: async () => {
            await axios.delete(`/v2/sesi/${data.id}`)
            .then(async res => {
                toast.add({
                    severity : 'success',
                    summary : "Sesi berhasil dihapus",
                    detail : res.data.message,
                    life: 3000
                })
                await fetchAllData()
                store.commit(`fetchTahfidzGuruDetail`)
            })
        },
        reject: () => {
            // toast.add({severity:'error', summary:'Rejected', detail:'Data gagal dihapus', life: 3000});
            // isWantEditJamTahfidz.value = false
        }
    });
}


</script>