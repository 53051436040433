export const countryCode =     [
    {
        "E164": "93",
        "phone_code": "93",
        "country_name": "Afghanistan"
    },
    {
        "E164": "355",
        "phone_code": "355",
        "country_name": "Albania"
    },
    {
        "E164": "213",
        "phone_code": "213",
        "country_name": "Algeria"
    },
    {
        "E164": "1",
        "phone_code": "1-684",
        "country_name": "American Samoa"
    },
    {
        "E164": "376",
        "phone_code": "376",
        "country_name": "Andorra"
    },
    {
        "E164": "244",
        "phone_code": "244",
        "country_name": "Angola"
    },
    {
        "E164": "1",
        "phone_code": "1-264",
        "country_name": "Anguilla"
    },
    {
        "E164": "672",
        "phone_code": "672",
        "country_name": "Antarctica"
    },
    {
        "E164": "1",
        "phone_code": "1-268",
        "country_name": "Antigua and Barbuda"
    },
    {
        "E164": "54",
        "phone_code": "54",
        "country_name": "Argentina"
    },
    {
        "E164": "374",
        "phone_code": "374",
        "country_name": "Armenia"
    },
    {
        "E164": "297",
        "phone_code": "297",
        "country_name": "Aruba"
    },
    {
        "E164": "61",
        "phone_code": "61",
        "country_name": "Australia"
    },
    {
        "E164": "43",
        "phone_code": "43",
        "country_name": "Austria"
    },
    {
        "E164": "994",
        "phone_code": "994",
        "country_name": "Azerbaijan"
    },
    {
        "E164": "1",
        "phone_code": "1-242",
        "country_name": "Bahamas"
    },
    {
        "E164": "973",
        "phone_code": "973",
        "country_name": "Bahrain"
    },
    {
        "E164": "880",
        "phone_code": "880",
        "country_name": "Bangladesh"
    },
    {
        "E164": "1",
        "phone_code": "1-246",
        "country_name": "Barbados"
    },
    {
        "E164": "375",
        "phone_code": "375",
        "country_name": "Belarus"
    },
    {
        "E164": "32",
        "phone_code": "32",
        "country_name": "Belgium"
    },
    {
        "E164": "501",
        "phone_code": "501",
        "country_name": "Belize"
    },
    {
        "E164": "229",
        "phone_code": "229",
        "country_name": "Benin"
    },
    {
        "E164": "1",
        "phone_code": "1-441",
        "country_name": "Bermuda"
    },
    {
        "E164": "975",
        "phone_code": "975",
        "country_name": "Bhutan"
    },
    {
        "E164": "591",
        "phone_code": "591",
        "country_name": "Bolivia"
    },
    {
        "E164": "387",
        "phone_code": "387",
        "country_name": "Bosnia and Herzegovina"
    },
    {
        "E164": "267",
        "phone_code": "267",
        "country_name": "Botswana"
    },
    {
        "E164": "55",
        "phone_code": "55",
        "country_name": "Brazil"
    },
    {
        "E164": "246",
        "phone_code": "246",
        "country_name": "British Indian Ocean Territory"
    },
    {
        "E164": "1",
        "phone_code": "1-284",
        "country_name": "British Virgin Islands"
    },
    {
        "E164": "673",
        "phone_code": "673",
        "country_name": "Brunei"
    },
    {
        "E164": "359",
        "phone_code": "359",
        "country_name": "Bulgaria"
    },
    {
        "E164": "226",
        "phone_code": "226",
        "country_name": "Burkina Faso"
    },
    {
        "E164": "257",
        "phone_code": "257",
        "country_name": "Burundi"
    },
    {
        "E164": "855",
        "phone_code": "855",
        "country_name": "Cambodia"
    },
    {
        "E164": "237",
        "phone_code": "237",
        "country_name": "Cameroon"
    },
    {
        "E164": "1",
        "phone_code": "1",
        "country_name": "Canada"
    },
    {
        "E164": "238",
        "phone_code": "238",
        "country_name": "Cape Verde"
    },
    {
        "E164": "1",
        "phone_code": "1-345",
        "country_name": "Cayman Islands"
    },
    {
        "E164": "236",
        "phone_code": "236",
        "country_name": "Central African Republic"
    },
    {
        "E164": "235",
        "phone_code": "235",
        "country_name": "Chad"
    },
    {
        "E164": "56",
        "phone_code": "56",
        "country_name": "Chile"
    },
    {
        "E164": "86",
        "phone_code": "86",
        "country_name": "China"
    },
    {
        "E164": "61",
        "phone_code": "61",
        "country_name": "Christmas Island"
    },
    {
        "E164": "61",
        "phone_code": "61",
        "country_name": "Cocos Islands"
    },
    {
        "E164": "57",
        "phone_code": "57",
        "country_name": "Colombia"
    },
    {
        "E164": "269",
        "phone_code": "269",
        "country_name": "Comoros"
    },
    {
        "E164": "682",
        "phone_code": "682",
        "country_name": "Cook Islands"
    },
    {
        "E164": "506",
        "phone_code": "506",
        "country_name": "Costa Rica"
    },
    {
        "E164": "385",
        "phone_code": "385",
        "country_name": "Croatia"
    },
    {
        "E164": "53",
        "phone_code": "53",
        "country_name": "Cuba"
    },
    {
        "E164": "599",
        "phone_code": "599",
        "country_name": "Curacao"
    },
    {
        "E164": "357",
        "phone_code": "357",
        "country_name": "Cyprus"
    },
    {
        "E164": "420",
        "phone_code": "420",
        "country_name": "Czech Republic"
    },
    {
        "E164": "243",
        "phone_code": "243",
        "country_name": "Democratic Republic of the Congo"
    },
    {
        "E164": "45",
        "phone_code": "45",
        "country_name": "Denmark"
    },
    {
        "E164": "253",
        "phone_code": "253",
        "country_name": "Djibouti"
    },
    {
        "E164": "1",
        "phone_code": "1-767",
        "country_name": "Dominica"
    },
    {
        "E164": "1",
        "phone_code": "1-809, 1-829, 1-849",
        "country_name": "Dominican Republic"
    },
    {
        "E164": "670",
        "phone_code": "670",
        "country_name": "East Timor"
    },
    {
        "E164": "593",
        "phone_code": "593",
        "country_name": "Ecuador"
    },
    {
        "E164": "20",
        "phone_code": "20",
        "country_name": "Egypt"
    },
    {
        "E164": "503",
        "phone_code": "503",
        "country_name": "El Salvador"
    },
    {
        "E164": "240",
        "phone_code": "240",
        "country_name": "Equatorial Guinea"
    },
    {
        "E164": "291",
        "phone_code": "291",
        "country_name": "Eritrea"
    },
    {
        "E164": "372",
        "phone_code": "372",
        "country_name": "Estonia"
    },
    {
        "E164": "251",
        "phone_code": "251",
        "country_name": "Ethiopia"
    },
    {
        "E164": "500",
        "phone_code": "500",
        "country_name": "Falkland Islands"
    },
    {
        "E164": "298",
        "phone_code": "298",
        "country_name": "Faroe Islands"
    },
    {
        "E164": "679",
        "phone_code": "679",
        "country_name": "Fiji"
    },
    {
        "E164": "358",
        "phone_code": "358",
        "country_name": "Finland"
    },
    {
        "E164": "33",
        "phone_code": "33",
        "country_name": "France"
    },
    {
        "E164": "689",
        "phone_code": "689",
        "country_name": "French Polynesia"
    },
    {
        "E164": "241",
        "phone_code": "241",
        "country_name": "Gabon"
    },
    {
        "E164": "220",
        "phone_code": "220",
        "country_name": "Gambia"
    },
    {
        "E164": "995",
        "phone_code": "995",
        "country_name": "Georgia"
    },
    {
        "E164": "49",
        "phone_code": "49",
        "country_name": "Germany"
    },
    {
        "E164": "233",
        "phone_code": "233",
        "country_name": "Ghana"
    },
    {
        "E164": "350",
        "phone_code": "350",
        "country_name": "Gibraltar"
    },
    {
        "E164": "30",
        "phone_code": "30",
        "country_name": "Greece"
    },
    {
        "E164": "299",
        "phone_code": "299",
        "country_name": "Greenland"
    },
    {
        "E164": "1",
        "phone_code": "1-473",
        "country_name": "Grenada"
    },
    {
        "E164": "1",
        "phone_code": "1-671",
        "country_name": "Guam"
    },
    {
        "E164": "502",
        "phone_code": "502",
        "country_name": "Guatemala"
    },
    {
        "E164": "44",
        "phone_code": "44-1481",
        "country_name": "Guernsey"
    },
    {
        "E164": "224",
        "phone_code": "224",
        "country_name": "Guinea"
    },
    {
        "E164": "245",
        "phone_code": "245",
        "country_name": "Guinea-Bissau"
    },
    {
        "E164": "592",
        "phone_code": "592",
        "country_name": "Guyana"
    },
    {
        "E164": "509",
        "phone_code": "509",
        "country_name": "Haiti"
    },
    {
        "E164": "504",
        "phone_code": "504",
        "country_name": "Honduras"
    },
    {
        "E164": "852",
        "phone_code": "852",
        "country_name": "Hong Kong"
    },
    {
        "E164": "36",
        "phone_code": "36",
        "country_name": "Hungary"
    },
    {
        "E164": "354",
        "phone_code": "354",
        "country_name": "Iceland"
    },
    {
        "E164": "91",
        "phone_code": "91",
        "country_name": "India"
    },
    {
        "E164": "62",
        "phone_code": "62",
        "country_name": "Indonesia"
    },
    {
        "E164": "98",
        "phone_code": "98",
        "country_name": "Iran"
    },
    {
        "E164": "964",
        "phone_code": "964",
        "country_name": "Iraq"
    },
    {
        "E164": "353",
        "phone_code": "353",
        "country_name": "Ireland"
    },
    {
        "E164": "44",
        "phone_code": "44-1624",
        "country_name": "Isle of Man"
    },
    {
        "E164": "972",
        "phone_code": "972",
        "country_name": "Israel"
    },
    {
        "E164": "39",
        "phone_code": "39",
        "country_name": "Italy"
    },
    {
        "E164": "225",
        "phone_code": "225",
        "country_name": "Ivory Coast"
    },
    {
        "E164": "1",
        "phone_code": "1-876",
        "country_name": "Jamaica"
    },
    {
        "E164": "81",
        "phone_code": "81",
        "country_name": "Japan"
    },
    {
        "E164": "44",
        "phone_code": "44-1534",
        "country_name": "Jersey"
    },
    {
        "E164": "962",
        "phone_code": "962",
        "country_name": "Jordan"
    },
    {
        "E164": "7",
        "phone_code": "7",
        "country_name": "Kazakhstan"
    },
    {
        "E164": "254",
        "phone_code": "254",
        "country_name": "Kenya"
    },
    {
        "E164": "686",
        "phone_code": "686",
        "country_name": "Kiribati"
    },
    {
        "E164": "383",
        "phone_code": "383",
        "country_name": "Kosovo"
    },
    {
        "E164": "965",
        "phone_code": "965",
        "country_name": "Kuwait"
    },
    {
        "E164": "996",
        "phone_code": "996",
        "country_name": "Kyrgyzstan"
    },
    {
        "E164": "856",
        "phone_code": "856",
        "country_name": "Laos"
    },
    {
        "E164": "371",
        "phone_code": "371",
        "country_name": "Latvia"
    },
    {
        "E164": "961",
        "phone_code": "961",
        "country_name": "Lebanon"
    },
    {
        "E164": "266",
        "phone_code": "266",
        "country_name": "Lesotho"
    },
    {
        "E164": "231",
        "phone_code": "231",
        "country_name": "Liberia"
    },
    {
        "E164": "218",
        "phone_code": "218",
        "country_name": "Libya"
    },
    {
        "E164": "423",
        "phone_code": "423",
        "country_name": "Liechtenstein"
    },
    {
        "E164": "370",
        "phone_code": "370",
        "country_name": "Lithuania"
    },
    {
        "E164": "352",
        "phone_code": "352",
        "country_name": "Luxembourg"
    },
    {
        "E164": "853",
        "phone_code": "853",
        "country_name": "Macau"
    },
    {
        "E164": "389",
        "phone_code": "389",
        "country_name": "Macedonia"
    },
    {
        "E164": "261",
        "phone_code": "261",
        "country_name": "Madagascar"
    },
    {
        "E164": "265",
        "phone_code": "265",
        "country_name": "Malawi"
    },
    {
        "E164": "60",
        "phone_code": "60",
        "country_name": "Malaysia"
    },
    {
        "E164": "960",
        "phone_code": "960",
        "country_name": "Maldives"
    },
    {
        "E164": "223",
        "phone_code": "223",
        "country_name": "Mali"
    },
    {
        "E164": "356",
        "phone_code": "356",
        "country_name": "Malta"
    },
    {
        "E164": "692",
        "phone_code": "692",
        "country_name": "Marshall Islands"
    },
    {
        "E164": "222",
        "phone_code": "222",
        "country_name": "Mauritania"
    },
    {
        "E164": "230",
        "phone_code": "230",
        "country_name": "Mauritius"
    },
    {
        "E164": "262",
        "phone_code": "262",
        "country_name": "Mayotte"
    },
    {
        "E164": "52",
        "phone_code": "52",
        "country_name": "Mexico"
    },
    {
        "E164": "691",
        "phone_code": "691",
        "country_name": "Micronesia"
    },
    {
        "E164": "373",
        "phone_code": "373",
        "country_name": "Moldova"
    },
    {
        "E164": "377",
        "phone_code": "377",
        "country_name": "Monaco"
    },
    {
        "E164": "976",
        "phone_code": "976",
        "country_name": "Mongolia"
    },
    {
        "E164": "382",
        "phone_code": "382",
        "country_name": "Montenegro"
    },
    {
        "E164": "1",
        "phone_code": "1-664",
        "country_name": "Montserrat"
    },
    {
        "E164": "212",
        "phone_code": "212",
        "country_name": "Morocco"
    },
    {
        "E164": "258",
        "phone_code": "258",
        "country_name": "Mozambique"
    },
    {
        "E164": "95",
        "phone_code": "95",
        "country_name": "Myanmar"
    },
    {
        "E164": "264",
        "phone_code": "264",
        "country_name": "Namibia"
    },
    {
        "E164": "674",
        "phone_code": "674",
        "country_name": "Nauru"
    },
    {
        "E164": "977",
        "phone_code": "977",
        "country_name": "Nepal"
    },
    {
        "E164": "31",
        "phone_code": "31",
        "country_name": "Netherlands"
    },
    {
        "E164": "599",
        "phone_code": "599",
        "country_name": "Netherlands Antilles"
    },
    {
        "E164": "687",
        "phone_code": "687",
        "country_name": "New Caledonia"
    },
    {
        "E164": "64",
        "phone_code": "64",
        "country_name": "New Zealand"
    },
    {
        "E164": "505",
        "phone_code": "505",
        "country_name": "Nicaragua"
    },
    {
        "E164": "227",
        "phone_code": "227",
        "country_name": "Niger"
    },
    {
        "E164": "234",
        "phone_code": "234",
        "country_name": "Nigeria"
    },
    {
        "E164": "683",
        "phone_code": "683",
        "country_name": "Niue"
    },
    {
        "E164": "850",
        "phone_code": "850",
        "country_name": "North Korea"
    },
    {
        "E164": "1",
        "phone_code": "1-670",
        "country_name": "Northern Mariana Islands"
    },
    {
        "E164": "47",
        "phone_code": "47",
        "country_name": "Norway"
    },
    {
        "E164": "968",
        "phone_code": "968",
        "country_name": "Oman"
    },
    {
        "E164": "92",
        "phone_code": "92",
        "country_name": "Pakistan"
    },
    {
        "E164": "680",
        "phone_code": "680",
        "country_name": "Palau"
    },
    {
        "E164": "970",
        "phone_code": "970",
        "country_name": "Palestine"
    },
    {
        "E164": "507",
        "phone_code": "507",
        "country_name": "Panama"
    },
    {
        "E164": "675",
        "phone_code": "675",
        "country_name": "Papua New Guinea"
    },
    {
        "E164": "595",
        "phone_code": "595",
        "country_name": "Paraguay"
    },
    {
        "E164": "51",
        "phone_code": "51",
        "country_name": "Peru"
    },
    {
        "E164": "63",
        "phone_code": "63",
        "country_name": "Philippines"
    },
    {
        "E164": "64",
        "phone_code": "64",
        "country_name": "Pitcairn"
    },
    {
        "E164": "48",
        "phone_code": "48",
        "country_name": "Poland"
    },
    {
        "E164": "351",
        "phone_code": "351",
        "country_name": "Portugal"
    },
    {
        "E164": "1",
        "phone_code": "1-787, 1-939",
        "country_name": "Puerto Rico"
    },
    {
        "E164": "974",
        "phone_code": "974",
        "country_name": "Qatar"
    },
    {
        "E164": "242",
        "phone_code": "242",
        "country_name": "Republic of the Congo"
    },
    {
        "E164": "262",
        "phone_code": "262",
        "country_name": "Reunion"
    },
    {
        "E164": "40",
        "phone_code": "40",
        "country_name": "Romania"
    },
    {
        "E164": "7",
        "phone_code": "7",
        "country_name": "Russia"
    },
    {
        "E164": "250",
        "phone_code": "250",
        "country_name": "Rwanda"
    },
    {
        "E164": "590",
        "phone_code": "590",
        "country_name": "Saint Barthelemy"
    },
    {
        "E164": "290",
        "phone_code": "290",
        "country_name": "Saint Helena"
    },
    {
        "E164": "1",
        "phone_code": "1-869",
        "country_name": "Saint Kitts and Nevis"
    },
    {
        "E164": "1",
        "phone_code": "1-758",
        "country_name": "Saint Lucia"
    },
    {
        "E164": "1",
        "phone_code": "590",
        "country_name": "Saint Martin"
    },
    {
        "E164": "508",
        "phone_code": "508",
        "country_name": "Saint Pierre and Miquelon"
    },
    {
        "E164": "1",
        "phone_code": "1-784",
        "country_name": "Saint Vincent and the Grenadines"
    },
    {
        "E164": "685",
        "phone_code": "685",
        "country_name": "Samoa"
    },
    {
        "E164": "378",
        "phone_code": "378",
        "country_name": "San Marino"
    },
    {
        "E164": "239",
        "phone_code": "239",
        "country_name": "Sao Tome and Principe"
    },
    {
        "E164": "966",
        "phone_code": "966",
        "country_name": "Saudi Arabia"
    },
    {
        "E164": "221",
        "phone_code": "221",
        "country_name": "Senegal"
    },
    {
        "E164": "381",
        "phone_code": "381",
        "country_name": "Serbia"
    },
    {
        "E164": "248",
        "phone_code": "248",
        "country_name": "Seychelles"
    },
    {
        "E164": "232",
        "phone_code": "232",
        "country_name": "Sierra Leone"
    },
    {
        "E164": "65",
        "phone_code": "65",
        "country_name": "Singapore"
    },
    {
        "E164": "1",
        "phone_code": "1-721",
        "country_name": "Sint Maarten"
    },
    {
        "E164": "421",
        "phone_code": "421",
        "country_name": "Slovakia"
    },
    {
        "E164": "386",
        "phone_code": "386",
        "country_name": "Slovenia"
    },
    {
        "E164": "677",
        "phone_code": "677",
        "country_name": "Solomon Islands"
    },
    {
        "E164": "252",
        "phone_code": "252",
        "country_name": "Somalia"
    },
    {
        "E164": "27",
        "phone_code": "27",
        "country_name": "South Africa"
    },
    {
        "E164": "82",
        "phone_code": "82",
        "country_name": "South Korea"
    },
    {
        "E164": "211",
        "phone_code": "211",
        "country_name": "South Sudan"
    },
    {
        "E164": "34",
        "phone_code": "34",
        "country_name": "Spain"
    },
    {
        "E164": "94",
        "phone_code": "94",
        "country_name": "Sri Lanka"
    },
    {
        "E164": "249",
        "phone_code": "249",
        "country_name": "Sudan"
    },
    {
        "E164": "597",
        "phone_code": "597",
        "country_name": "Suriname"
    },
    {
        "E164": "47",
        "phone_code": "47",
        "country_name": "Svalbard and Jan Mayen"
    },
    {
        "E164": "268",
        "phone_code": "268",
        "country_name": "Swaziland"
    },
    {
        "E164": "46",
        "phone_code": "46",
        "country_name": "Sweden"
    },
    {
        "E164": "41",
        "phone_code": "41",
        "country_name": "Switzerland"
    },
    {
        "E164": "963",
        "phone_code": "963",
        "country_name": "Syria"
    },
    {
        "E164": "886",
        "phone_code": "886",
        "country_name": "Taiwan"
    },
    {
        "E164": "992",
        "phone_code": "992",
        "country_name": "Tajikistan"
    },
    {
        "E164": "255",
        "phone_code": "255",
        "country_name": "Tanzania"
    },
    {
        "E164": "66",
        "phone_code": "66",
        "country_name": "Thailand"
    },
    {
        "E164": "228",
        "phone_code": "228",
        "country_name": "Togo"
    },
    {
        "E164": "690",
        "phone_code": "690",
        "country_name": "Tokelau"
    },
    {
        "E164": "676",
        "phone_code": "676",
        "country_name": "Tonga"
    },
    {
        "E164": "1",
        "phone_code": "1-868",
        "country_name": "Trinidad and Tobago"
    },
    {
        "E164": "216",
        "phone_code": "216",
        "country_name": "Tunisia"
    },
    {
        "E164": "90",
        "phone_code": "90",
        "country_name": "Turkey"
    },
    {
        "E164": "993",
        "phone_code": "993",
        "country_name": "Turkmenistan"
    },
    {
        "E164": "1",
        "phone_code": "1-649",
        "country_name": "Turks and Caicos Islands"
    },
    {
        "E164": "688",
        "phone_code": "688",
        "country_name": "Tuvalu"
    },
    {
        "E164": "1",
        "phone_code": "1-340",
        "country_name": "U.S. Virgin Islands"
    },
    {
        "E164": "256",
        "phone_code": "256",
        "country_name": "Uganda"
    },
    {
        "E164": "380",
        "phone_code": "380",
        "country_name": "Ukraine"
    },
    {
        "E164": "971",
        "phone_code": "971",
        "country_name": "United Arab Emirates"
    },
    {
        "E164": "44",
        "phone_code": "44",
        "country_name": "United Kingdom"
    },
    {
        "E164": "1",
        "phone_code": "1",
        "country_name": "United States"
    },
    {
        "E164": "598",
        "phone_code": "598",
        "country_name": "Uruguay"
    },
    {
        "E164": "998",
        "phone_code": "998",
        "country_name": "Uzbekistan"
    },
    {
        "E164": "678",
        "phone_code": "678",
        "country_name": "Vanuatu"
    },
    {
        "E164": "39",
        "phone_code": "379",
        "country_name": "Vatican"
    },
    {
        "E164": "58",
        "phone_code": "58",
        "country_name": "Venezuela"
    },
    {
        "E164": "84",
        "phone_code": "84",
        "country_name": "Vietnam"
    },
    {
        "E164": "681",
        "phone_code": "681",
        "country_name": "Wallis and Futuna"
    },
    {
        "E164": "212",
        "phone_code": "212",
        "country_name": "Western Sahara"
    },
    {
        "E164": "967",
        "phone_code": "967",
        "country_name": "Yemen"
    },
    {
        "E164": "260",
        "phone_code": "260",
        "country_name": "Zambia"
    },
    {
        "E164": "263",
        "phone_code": "263",
        "country_name": "Zimbabwe"
    }
]