<template>
    <!-- <Dialog :v:model:visible="store.state.isWantToAddTarget">

    </Dialog> -->
    <Dialog v-model:visible="store.state.isWantToAddTarget" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '50vw'}" :modal="true" header="Atur Target Harian">
        <Fieldset legend="Hafalan">
            <div class="flex flex-column">
                <div class="w-1/2 mr-1">
                    <span class="p-float-label" >
                        <InputText v-model="v$.targetHafalan.$model" type="number" class="w-full" placeholder="Jumlah Anak Kandung dan Tiri"
                        @blur="v$.targetHafalan.$touch"/>
                        <label class="text-base">Target</label>
                    </span>
                    <div class="flex ml-2 mt-1" v-for="error of v$.targetHafalan.$errors" :key="error.$uid">
                        <small class="p-error">{{ error.$message }}</small>
                    </div>
                </div>
                <div class="field w-1/2 ml-1">
                    <span class="p-float-label">
                        <Dropdown v-model="v$.satuanHafalan.$model" class="w-full" :options="satuanTarget" optionValue="value" optionLabel="name" placeholder="Jenis Kelamin"
                        @blur="v$.satuanHafalan.$touch"/>
                        <label class="text-base">Satuan</label>
                    </span>
                    <div class="flex ml-2 mt-1" v-for="error of v$.satuanHafalan.$errors" :key="error.$uid">
                        <small v-if="v$.satuanHafalan.$errors" class="p-error">{{ error.$message }}</small>
                    </div>
                </div>
            </div>
        </Fieldset>
        <Fieldset legend="Murojah Hafalan Baru" class="mt-2">
            <div class="flex flex-column">
                <div class="w-1/2 mr-1">
                    <span class="p-float-label" >
                        <InputText v-model="v$.targetMurojahBaru.$model" type="number" class="w-full" placeholder="Jumlah Anak Kandung dan Tiri"
                        @blur="v$.targetMurojahBaru.$touch"/>
                        <label class="text-base">Target</label>
                    </span>
                    <div class="flex ml-2 mt-1" v-for="error of v$.targetMurojahBaru.$errors" :key="error.$uid">
                        <small class="p-error">{{ error.$message }}</small>
                    </div>
                </div>
                <div class="field w-1/2 ml-1">
                    <span class="p-float-label">
                        <Dropdown v-model="v$.satuanMurojahBaru.$model" class="w-full" :options="satuanTarget" optionValue="value" optionLabel="name" placeholder="Jenis Kelamin"
                        @blur="v$.satuanMurojahBaru.$touch"/>
                        <label class="text-base">Satuan</label>
                    </span>
                    <div class="flex ml-2 mt-1" v-for="error of v$.satuanMurojahBaru.$errors" :key="error.$uid">
                        <small v-if="v$.satuanMurojahBaru.$errors" class="p-error">{{ error.$message }}</small>
                    </div>
                </div>
            </div>
        </Fieldset>
        <Fieldset legend="Murojah Hafalan Lama" class="mt-2">
            <div class="flex flex-column">
                <div class="w-1/2 mr-1">
                    <span class="p-float-label" >
                        <InputText v-model="v$.targetMurojah.$model" type="number" class="w-full" placeholder="Jumlah Anak Kandung dan Tiri"
                        @blur="v$.targetMurojah.$touch"/>
                        <label class="text-base">Target</label>
                    </span>
                    <div class="flex ml-2 mt-1" v-for="error of v$.targetMurojah.$errors" :key="error.$uid">
                        <small class="p-error">{{ error.$message }}</small>
                    </div>
                </div>
                <div class="field w-1/2 ml-1">
                    <span class="p-float-label">
                        <Dropdown v-model="v$.satuanMurojah.$model" class="w-full" :options="satuanTarget" optionValue="value" optionLabel="name" placeholder="Jenis Kelamin"
                        @blur="v$.satuanMurojah.$touch"/>
                        <label class="text-base">Satuan</label>
                    </span>
                    <div class="flex ml-2 mt-1" v-for="error of v$.satuanMurojah.$errors" :key="error.$uid">
                        <small v-if="v$.satuanMurojah.$errors" class="p-error">{{ error.$message }}</small>
                    </div>
                </div>
            </div>
        </Fieldset>
        <template #footer>
            <button icon="pi" @click="store.commit('mutateInputTargetDialog', false)" class="border rounded p-2 mx-2">
                Cancel
            </button>
            <button class="border rounded bg-ltDarkGreen text-white py-2 px-4 mx-2 hover:bg-ltLightGreen" label="Login" icon="pi" @click="inputTarget" >
                Simpan
            </button>
        </template>
    </Dialog>
</template>
<script setup>
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { satuanTargetList } from '@/utils/reusableVar'
import useVuelidate from '@vuelidate/core';
import axios from 'axios';
import { useToast } from 'primevue/usetoast';
import { helpers, minValue, required } from '@vuelidate/validators';

const store = useStore()
const toast = useToast()

// const isVisible = $store.state.isWantToAddTarget
const satuanTarget = ref(satuanTargetList)

const inputTargetRules = {
    satuanHafalan: {
        required: helpers.withMessage('Satuan tidak boleh kosong', required),
    },
    targetHafalan : {
        required: helpers.withMessage('Jumlah Target tidak boleh kosong', required),
        minValue: helpers.withMessage('Jumlah Target tidak boleh lebih kecil dari 0', minValue(0)),
    },
    satuanMurojah: {
        required: helpers.withMessage('Satuan tidak boleh kosong', required),
    },
    targetMurojah : {
        required: helpers.withMessage('Jumlah Target tidak boleh kosong', required),
        minValue: helpers.withMessage('Jumlah Target tidak boleh lebih kecil dari 0', minValue(0)),
    },
    satuanMurojahBaru: {
        required: helpers.withMessage('Satuan tidak boleh kosong', required),
    },
    targetMurojahBaru : {
        required: helpers.withMessage('Jumlah Target tidak boleh kosong', required),
        minValue: helpers.withMessage('Jumlah Target tidak boleh lebih kecil dari 0', minValue(0)),
    },
}

const initInputTarget = () => {
    store.state.inputTarget.muridId = null 
    store.state.inputTarget.satuanHafalan = null 
    store.state.inputTarget.targetHafalan = null 
    store.state.inputTarget.satuanMurojah = null 
    store.state.inputTarget.targetMurojah = null 
    store.state.inputTarget.satuanMurojahBaru = null 
    store.state.inputTarget.targetMurojahBaru = null 
    v$.value.$reset()
}


const v$ = useVuelidate(inputTargetRules, store.state.inputTarget)

const inputTarget = async() => {
    const isFormCorrect = await v$.value.$validate()

    if (isFormCorrect) {
        store.state.isLoading = true
        await axios.post('/v2/target', store.state.inputTarget)
        .then(res => {
            toast.add({
                severity: 'success',
                summary: 'Input Target Berhasil',
                detail: res.data.message,
                life: 3000,
            })
            store.state.isWantToAddTarget = false
            initInputTarget()
            store.commit('fetchTahfidzGuruDetail')
        })
        .catch(err => {
            toast.add({
                severity: 'error',
                summary: 'Data gagal dimasukkan',
                detail: err.response.data.message,
                life: 3000
            })
        })
        .finally(() => {
            store.state.isLoading = false
        })
    }
}

onMounted(() => {
    console.log(store.state.isWantToAddTarget)
    console.log(store)
})

</script>

