<template>
    <Dialog v-model:visible="store.state.isWantToCheckStatusMurid"  :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '50vw'}" :modal="true" header="Status Murid">
        <DataTable :value="store.state.jamTahfidzDetails">
            <template #empty>
                Tidak ada data Setoran
            </template>
            <template #header>
                <button class="rounded border bg-ltDarkGreen p-2 text-white" @click="store.state.isWantToAddSession = true">Tambah Sesi</button>
            </template>
            <Column header="Nama" field="muridTahfidzDetail.pesantrenDetail.muridDetail.murid.nama"/>
            <Column header="Tipe Sesi">
                <template #body="{data}">
                    <span v-if="data.tipeSesi === 'Hafalan'">Hafalan</span>
                    <span v-else-if="data.tipeSesi === 'Murojah_Baru'">Murojah Hafalan Baru</span>
                    <span v-else>Murojah Hafalan Lama</span>
                </template>
            </Column>
            <Column header="Jumlah Setoran">
                <template #body="{data}">
                    {{ `${data.jumlahBaris} Baris / ${data.jumlahHalaman} Halaman` }}
                </template>
            </Column>
            <column header="Target">
                <template #body="{data}">
                    <span v-if="data.target !== null">
                        {{ `${data.target} ${data.satuanTarget}` }}
                    </span>
                    <span v-else>
                        No Data.
                    </span>
                </template>
            </column>
            <Column header="Status" >
                <template #body="{data}" >
                    <div v-if="data.target !== null">
                        <Chip label="Tuntas" v-if="calculateStatus(data) === true"/> 
                        <Chip label="Tidak Tuntas" v-else-if="calculateStatus(data) === false"/>
                        <Chip label="Error" v-else-if="calculateStatus(data) === null"/>
                    </div>
                </template>
            </Column>
        </DataTable>
    </Dialog>
</template>

<script setup>
import { useStore } from 'vuex';

const store = useStore()

const calculateStatus = (data) => {
    const target = data.target 
    const satuanTarget = data.satuanTarget

    const awalSetoran = data.awalSetoran
    const akhirSetoran = data.akhirSetoran

    const jumlahBaris = data.jumlahBaris
    const jumlahHalaman = data.jumlahHalaman
    let jumlahAyat = 0

    // Ayat 
    if (satuanTarget === 'Ayat') {
        // Kalo Surahnya sama 
        if (awalSetoran.nomorSurah === akhirSetoran.nomorSurah){
            jumlahAyat = akhirSetoran.ayat - awalSetoran.ayat
        }
        // Kalo Surahnya Beda atau di akhir surah atau ganti surah 
        // else if (akhirSetoran.nomorSurah - awalSetoran.nomorSurah === 1) {
        
        // }
        // Kalo Surahnya loncat lebih dari 1 
        
        if (jumlahAyat < target) return false
        else if (jumlahAyat === 0) return null 
        else return true
    }
    // Baris 
    if (satuanTarget === 'Baris') {
        if (jumlahBaris < target) {
            return false 
        }
        else return true
    }
    // Halaman 
    if (satuanTarget === 'Halaman') {
        if (jumlahHalaman < target) {
            return false
        }
        else return true
    }
    // Juz
    if (satuanTarget === 'Juz') {
        if(jumlahHalaman / 20 > target) {
            return true
        }
        else return false
    }
}

</script>